import { AxiosError } from 'axios';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';

import { UserMessage } from '../../../../types/UserMessage';
import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { FormikPasswordInput } from '../../../common/PasswordInput/FormikPasswordInput';
import { Box } from '../../../common/ui/Box';
import { FormikStrengthMeter } from '../../StrengthMeter/FormikStrengthMeter';
import { useUserUpdate } from '../hooks/useUserUpdate';

interface FormikSetNewPasswordState {
    password: string;
    passwordRepeat: string;
}

const validate = (values: FormikSetNewPasswordState): FormikErrors<FormikSetNewPasswordState> => {
    const errors: FormikErrors<FormikSetNewPasswordState> = {};

    if (!values.password) {
        errors.password = translate('setNewPassword.error.newPasswordRequired');
    } else if (values.password.length < 6) {
        errors.password = translate('setNewPassword.error.newPasswordTooShort');
    }

    if (!values.passwordRepeat || values.password !== values.passwordRepeat) {
        errors.passwordRepeat = translate('setNewPassword.error.passwordRepeatInvalid');
    }

    return errors;
};

interface SetNewPasswordPopupProps {
    user: UserMessage;
    isOpen: boolean;
    onDismiss: () => void;
}

type SetNewPasswordError = AxiosError<{
    errorCode: 'PASSWORD_SAFETY';
}>;

export const SetNewPasswordPopup = ({ user, isOpen, onDismiss }: SetNewPasswordPopupProps) => {
    const updateUserMutation = useUserUpdate(user.id);
    const initialValues = { password: '', passwordRepeat: '' };

    const onSubmit = (values: FormikSetNewPasswordState, meta: FormikHelpers<FormikSetNewPasswordState>) => {
        return updateUserMutation
            .mutateAsync({
                ...user,
                password: values.password,
            })
            .then(() => {
                onDismiss();
            })
            .catch((error) => {
                const axiosError = error as SetNewPasswordError;

                if (axiosError.response?.data.errorCode === 'PASSWORD_SAFETY') {
                    meta.setErrors({
                        password: translate('setNewPassword.error.insecurePassword'),
                    });
                }
            });
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss}>
            <Box padding={6}>
                <Text variant="title" as="h2">
                    {translate('user.resetpassword')}
                </Text>
                <Formik<FormikSetNewPasswordState>
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {(formikProps) => (
                        <Form>
                            <Box marginTop={5}>
                                <Box display="grid" gridRowGap="12px">
                                    <FormikPasswordInput label={translate('user.password.new1')} name="password" />
                                    <FormikStrengthMeter name="password" />
                                    <FormikPasswordInput
                                        label={translate('user.password.new2')}
                                        name="passwordRepeat"
                                    />
                                </Box>
                            </Box>

                            <Box marginTop={5}>
                                <Box display="flex" justifyContent="flex-end">
                                    <ButtonGroup>
                                        <Button type="button" onClick={onDismiss}>
                                            {translate('actions.cancel')}
                                        </Button>

                                        <Button emphasis="high" type="submit" isLoading={formikProps.isSubmitting}>
                                            {translate('actions.save')}
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};
