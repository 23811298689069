import { Tag } from '../../cdl/Tag/Tag';
import { OfferState } from '../../offer/model/OfferState';
import { translate } from '../helpers/translate.helper';

import { getStateEmphasis, getStateVariant } from './offerStatusTagHelpers';

interface OfferStatusTagProps {
    state: OfferState;
    variant: 'customer' | 'supplier';
}

export const OfferStatusTag = ({ state, variant }: OfferStatusTagProps) => {
    return (
        <Tag variant={getStateVariant(state, variant)} emphasis={getStateEmphasis(state, variant)}>
            {translate(`state.LUBES.${state}.title`)}
        </Tag>
    );
};
