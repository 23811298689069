import { ReactNode } from 'react';

import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { DataSheet } from '../../../../common/DataSheet/DataSheet';
import { formatDateDelivery } from '../../../../common/helpers/formatDateDelivery.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { Port } from '../../../../port/Port';
import { OfferModel } from '../../../model/OfferModel';

interface InitialEnquiryPopupProps {
    offer: OfferModel;
    isLoading: boolean;
    isOpen: boolean;
    onDismiss: () => void;
    children: ReactNode;
}

export const InitialEnquiryModal = ({ isOpen, onDismiss, offer, isLoading, children }: InitialEnquiryPopupProps) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} width={1000}>
            <Box padding={6} display="grid" gap={5}>
                <Text variant="title">{translate('order.history.drawerTitle')}</Text>

                <Box>
                    <DataSheet>
                        {isLoading ? <DataSheet.Skeleton rows={2} /> : null}

                        {offer && offer.port ? (
                            <>
                                <DataSheet.Label>{translate('order.port')}</DataSheet.Label>
                                <Port port={offer.port} vesselId={offer.vesselId} />
                                <DataSheet.Label>{translate('order.dateDeliveryConfirmed')}</DataSheet.Label>
                                <Text>{formatDateDelivery({ date: offer.dateDelivery })}</Text>
                            </>
                        ) : null}
                    </DataSheet>
                </Box>

                {children}
            </Box>
        </Modal>
    );
};
