import { useLocation, useNavigate } from '@tanstack/react-router';

import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../helpers/translate.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { IconPlus } from '../../icons/cdl/Plus';
import { Box } from '../../ui/Box';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelCustomerSidebarNavigation = () => {
    const role = useRole();
    const { context } = useProductContext();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box display="grid" gap={5}>
            {role.hasAnyCompanyWriteRights() ? (
                <Box width="100%" paddingX={4}>
                    <Button
                        width="100%"
                        onClick={() => void navigate({ to: '/fuel/create', state: { originLocation: location } })}
                        emphasis="high"
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {translate('navigation.createnew')}
                    </Button>
                </Box>
            ) : null}

            <Box display="flex" flexDirection="column">
                <SidebarNavigationItem to="/fuel/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/orders">{translate('navigation.orders')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/planning/demand">
                    {translate('navigation.planning')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/vessels">{translate('navigation.vessels')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/key-ports">{translate('navigation.keyPorts')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/contracts">{translate('navigation.contracts')}</SidebarNavigationItem>

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="/fuel/assignments">
                        {translate('navigation.suppliers')}
                    </SidebarNavigationItem>
                ) : null}

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="/fuel/agents">{translate('navigation.agents')}</SidebarNavigationItem>
                ) : null}

                <SidebarNavigationItem to="/fuel/statistics">{translate('navigation.reporting')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/fuel/global-prices">
                    {translate('navigation.globalPrices')}
                </SidebarNavigationItem>
            </Box>
        </Box>
    );
};
