import { ComponentType } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { get } from '../../ui/get';

export const ContextSwitchControl = styled(components.Control)`
    && {
        background: transparent;
        border: 2px solid transparent;
        outline: none;
        box-shadow: none;
        min-height: 40px;
        padding: ${get('space.3')}px 10px ${get('space.3')}px ${get('space.1')}px;
        
        &:hover {
            border-color: transparent;
            cursor: pointer;
            background-color: ${get('colors.background.inset')};
        }
        
` as ComponentType;
