import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomMultiValueLabel = styled.div`
    font-size: ${get('fontSizes.1')}px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 ${get('space.2')}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
