import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconRotateClockwise2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M14.755 5.53a7.031 7.031 0 0 0-5.381-.052 1 1 0 1 1-.748-1.856A9.031 9.031 0 0 1 17.706 19H20a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1v-5a1 1 0 1 1 2 0v2.783A7.03 7.03 0 0 0 14.755 5.53Zm-9.125.63a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1ZM4.06 10a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V11a1 1 0 0 1 1-1Zm.57 4.1a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm2.53 3.27a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1ZM11 18.94a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
