import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { SearchResultPage } from '../search/SearchResultPage';

const SearchSearchSchema = z.object({
    query: z.string(),
    ordersPage: fallback(z.number(), 0).default(0),
    offersPage: fallback(z.number(), 0).default(0),
});

export const Route = createFileRoute('/_app/search')({
    validateSearch: zodSearchValidator(SearchSearchSchema),
    component: SearchResultPage,
});
