import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { useChartTitleTranslations } from '../hooks/useChartTitleTranslations';
import { useFixedMoneyDecimals } from '../hooks/useFixedMoneyDecimals';
import { barOptions, ChartType } from '../utils/barOptions';
import { chartColors } from '../utils/chartColors';

import { ChartGrid } from './ChartGrid';
import { ChartWrapper } from './ChartWrapper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface PortsTabContentProps {
    portStatistics: BaseStatisticsMessage['port'];
}

export const PortsTabStatistics = ({ portStatistics }: PortsTabContentProps) => {
    const chartTitles = useChartTitleTranslations();
    const portNames = portStatistics.map((port) => port.context.name);
    const backgroundColor = chartColors;

    const formatMoneyDecimals = useFixedMoneyDecimals();

    const completedOrdersData = {
        labels: portNames,
        datasets: [
            {
                label: ChartType.COMPLETED,
                data: portStatistics.map((port) => port.completedCount),
                backgroundColor,
            },
        ],
    };

    const pplData = {
        labels: portNames,
        datasets: [
            {
                label: ChartType.PPL,
                data: portStatistics.map((port) => formatMoneyDecimals(port.ppl.value)),
                backgroundColor,
            },
        ],
    };

    const totalData = {
        labels: portNames,
        datasets: [
            {
                label: ChartType.TOTAL,
                data: portStatistics.map((port) => port.total.value),
                backgroundColor,
            },
        ],
    };

    const volumeData = {
        labels: portNames,
        datasets: [
            {
                label: ChartType.VOLUME,
                data: portStatistics.map((port) => Math.round(port.volume)),
                backgroundColor,
            },
        ],
    };
    return (
        <ChartGrid>
            <ChartWrapper title={chartTitles.completedOrders}>
                <Bar data={completedOrdersData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.pricePerUnit}>
                <Bar data={pplData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.total}>
                <Bar data={totalData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Bar data={volumeData} options={barOptions} />
            </ChartWrapper>
        </ChartGrid>
    );
};
