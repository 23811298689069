import { useRole } from '../../hooks/useRole';
import { NavigationLinkProps } from '../../Link/NavigationLink';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

import { LockedSidebarItem } from './LockedSidebarItem';

interface ContextualLiteSidebarItemProps extends Pick<NavigationLinkProps, 'to' | 'children'> {}

export const ContextualLiteSidebarItem = ({ to, children }: ContextualLiteSidebarItemProps) => {
    const role = useRole();

    if (role.usesCloselinkLite()) {
        return <LockedSidebarItem>{children}</LockedSidebarItem>;
    } else {
        return <SidebarNavigationItem to={to}>{children}</SidebarNavigationItem>;
    }
};
