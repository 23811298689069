import { translate } from '../../helpers/translate.helper';
import { useRole } from '../../hooks/useRole';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const LubesSupplierSidebarNavigation = () => {
    const role = useRole();

    return (
        <div>
            <SidebarNavigationItem to="/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/offers">{translate('navigation.orders')}</SidebarNavigationItem>

            {role.hasAnyAdminRights() ? (
                <SidebarNavigationItem to="/customers">{translate('navigation.customers')}</SidebarNavigationItem>
            ) : null}

            <SidebarNavigationItem to="/statistics">{translate('navigation.reporting')}</SidebarNavigationItem>
        </div>
    );
};
