import storage from 'local-storage-fallback';
import { useState, useEffect, useRef } from 'react';

type DefaultValue = boolean | string | null | Date;

function useLocalStorageState(
    key: string,
    defaultValue: DefaultValue | (() => DefaultValue),
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
    const [state, setState] = useState<DefaultValue>(() => {
        const valueInLocalStorage = storage.getItem(key);
        if (valueInLocalStorage) {
            return deserialize(valueInLocalStorage);
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            storage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        storage.setItem(key, serialize(state));
    }, [key, state, serialize]);

    return [state, setState] as const;
}

export { useLocalStorageState };
