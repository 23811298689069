import {
    calculatePpl,
    calculateTotal,
    calculateVolume,
    calculateVolumeWithPrices,
    findDefaultUnitSize,
} from '../../common/helpers/calculate.utilities';
import { Item } from '../../common/models/item.model';
import { Samplekit } from '../../common/models/samplekit.model';

function Order(order) {
    const { samplekits, items, ...rest } = order;

    Object.assign(this, rest);

    this.items = items.map((item) => new Item(item));
    this.samplekits = samplekits.map((samplekit) => new Samplekit(samplekit));
}

Order.prototype.construct = function () {
    this.items = [];
    this.samplekits = [];
    this.receiverSupplierIds = [];
    this.addItem();
    this.compute();
};

Order.prototype.initWithVessel = function (vessel) {
    this.vesselId = vessel.id;
    this.customerId = vessel.customerId;
};

Order.prototype.initWithOrder = function (order) {
    if (order.vessel && order.vessel.active) {
        this.vesselId = order.vesselId;
    }
    if (moment().diff(order.dateDelivery) <= 0) {
        this.dateDelivery = order.dateDelivery;
    }
    this.agent = order.agent;
    this.agentId = order.agentId;
    this.customerId = order.customerId;
    this.portId = order.portId;
    this.port = order.port;
    this.buyerReference = order.buyerReference;
    this.items = initWithItems(order.items);
    this.samplekits = initWithSampleKits(order.samplekits);
    this.agentdetails = order.agentdetails;
    this.receiverSupplierIds = order.receiverSupplierIds || [];
    this.spot = order.spot;
    this.attributes = order.attributes;

    this.compute();
};

function initWithItems(items) {
    const resultItems = [];
    items.forEach((orderItem) => {
        orderItem.deleted = false;
        orderItem.price = undefined;
        orderItem.total = undefined;
        orderItem.changes = undefined;
        orderItem.added = undefined;
        orderItem.translationFailed = undefined;
        resultItems.push(orderItem);
    });

    return resultItems;
}

Order.prototype.initWithRequestProduct = function (requestProducts, productVolumes) {
    const items = [];

    requestProducts.forEach((product) => {
        const item = new Item();

        item.unitSize = findDefaultUnitSize(product.packType);
        item.productId = product.id;
        item.product = product;
        item.pack = product.packTypeDefault;
        if (product.unitDefault) {
            item.unit = product.unitDefault;
        }

        if (productVolumes !== undefined) {
            const volume = productVolumes[product.id];
            item.units = Math.floor(volume / item.unitSize);
        }

        item.compute();
        items.push(item);
    });

    this.items = items;

    this.compute();
};

function initWithSampleKits(orderSampleKits) {
    const kits = [];
    orderSampleKits?.forEach((orderSamplekit) => {
        if (!orderSamplekit.deleted) {
            kits.push(orderSamplekit);
        }
    });
    return kits;
}

Order.prototype.addItem = function (item) {
    if (!this.items) this.items = [];
    if (!item || this.items.indexOf(item) === this.items.length - 1) {
        this.items.push(new Item());
    }
};

Order.prototype.removeItem = function (item) {
    if (this.items.length > 1) {
        this.items.splice(this.items.indexOf(item), 1);
        this.compute();
    }
};

Order.prototype.addSamplekit = function (samplekit) {
    if (!this.samplekits) this.samplekits = [];
    if (!samplekit) samplekit = new Samplekit();
    this.samplekits.push(samplekit);
};

Order.prototype.removeSamplekit = function (samplekit) {
    this.samplekits.splice(this.samplekits.indexOf(samplekit), 1);
    this.compute();
};

Order.prototype.compute = function () {
    this.computeItems();
    this.computeOrder();
};

Order.prototype.computeOrder = function () {
    this.total = calculateTotal(this.items, undefined, this.samplekits);
    this.totalWithoutSamplekits = calculateTotal(this.items, undefined, undefined);
    this.volume = calculateVolume(this.items);
    this.volumeWithPrices = calculateVolumeWithPrices(this.items);
    this.ppl = calculatePpl(this.totalWithoutSamplekits, this.volumeWithPrices);
};

Order.prototype.prepare = function () {
    this.items.splice(this.items.length - 1, 1);
    if (this.buyerReference !== undefined && this.buyerReference.length === 0) {
        this.buyerReference = undefined;
    }

    return this;
};

Order.prototype.unprepare = function () {
    this.addItem();
    return this;
};

Order.prototype.computeItems = function () {
    this.items.forEach((item) => {
        item.compute();
    });
};
Order.prototype.hasReceiver = function () {
    return (this.receiverSupplierIds && this.receiverSupplierIds.length) || this.spot;
};

Order.prototype.hasContractReceiver = function () {
    return this.receiverSupplierIds.length;
};

Order.prototype.isComplete = function () {
    let complete = this.portId && this.dateDelivery && this.vesselId && this.hasReceiver();

    this.items.forEach((item) => {
        complete = complete && item.isComplete();
    });
    this.samplekits.forEach((samplekit) => {
        complete = complete && samplekit.isComplete();
    });
    return complete && this.items.some((item) => !item.deleted);
};

Order.prototype.isCompleteUnprepared = function () {
    let complete = this.portId && this.dateDelivery && this.vesselId && this.hasReceiver();

    this.items.slice(0, this.items.length - 1).forEach((item) => {
        complete = complete && item.isComplete();
    });
    this.samplekits.forEach((samplekit) => {
        complete = complete && samplekit.isComplete();
    });

    return complete && this.items.some((item) => !item.deleted);
};

Order.prototype.isCompleteForPriceCalculation = function () {
    return this.portId && this.items && this.items.length > 0 && this.items[0].isComplete();
};

Order.prototype.isDraft = function () {
    return this.state === 'DRAFT';
};

Order.prototype.deliveryPast = function () {
    return new Date(this.dateDelivery) < new Date();
};

Order.prototype.isSpot = function () {
    return this.spot;
};

Order.prototype.isContracted = function () {
    return this.receiverSupplierIds && this.receiverSupplierIds.length > 0;
};

Order.prototype.isClosed = function () {
    return this.state === 'CLOSED';
};

Order.prototype.isOpen = function () {
    return this.state === 'OPEN';
};

Order.prototype.isFinished = function () {
    return this.state === 'CANCELED' || this.state === 'CLOSED';
};

export { Order };
