function Samplekit(samplekit) {
    if (samplekit) {
        Object.assign(this, samplekit);
    }
}

Samplekit.prototype.isComplete = function () {
    return this.name && this.quantity;
};

Samplekit.prototype.isCompleteWithPrices = function () {
    return this.isComplete() && this.value;
};

Samplekit.prototype.compute = function () {
    if (!this.isComplete()) {
        this.total = undefined;
    }
};

export { Samplekit };
