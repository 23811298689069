import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconChartPie = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.883 2.207A1.9 1.9 0 0 1 12 4v7a1 1 0 0 0 1 1h6.8a2 2 0 0 1 2 2 .998.998 0 0 1-.026.226 10 10 0 1 1-12-12 .998.998 0 0 1 .11-.02ZM10 4.23A8 8 0 1 0 19.77 14H13a3 3 0 0 1-3-3V4.23Zm4.422-1.546a1 1 0 0 1 .91-.127 10 10 0 0 1 6.111 6.11A1 1 0 0 1 20.5 10H16a2 2 0 0 1-2-2V3.5a1 1 0 0 1 .422-.816ZM16 5.054V8h2.945A8 8 0 0 0 16 5.055Z"
            clipRule="evenodd"
        />
    </svg>
);
