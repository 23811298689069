import { ProductMessage } from '../../../../types/ProductMessage';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { IconArchive } from '../../../common/icons/cdl/Archive';
import { IconArrowBackUp } from '../../../common/icons/cdl/ArrowBackUp';
import { useProductUpdate } from '../hooks/useProductUpdate';

interface ArchiveProductButtonProps {
    product: ProductMessage;
}

export const ArchiveProductButton = (props: ArchiveProductButtonProps) => {
    const productUpdateMutation = useProductUpdate();
    const { addToast, addErrorToast } = useToasts();

    const onEditButtonClick = () => {
        if (props.product.archived) {
            if (window.confirm(translate('productsOverview.restoreConfirmPrompt'))) {
                productUpdateMutation.mutateAsync(
                    {
                        product: {
                            ...props.product,
                            archived: false,
                        },
                        id: props.product.id,
                    },
                    {
                        onSuccess: () => {
                            addToast(translate('productsOverview.restoreSuccessToast'));
                        },
                        onError: () => {
                            addErrorToast(translate('productsOverview.restoreErrorToast'));
                        },
                    }
                );
                return;
            }
        }

        if (window.confirm(translate('productsOverview.archiveConfirmPrompt'))) {
            productUpdateMutation.mutateAsync(
                {
                    product: {
                        ...props.product,
                        archived: true,
                    },
                    id: props.product.id,
                },
                {
                    onSuccess: () => {
                        addToast(translate('productsOverview.archiveSuccessToast'));
                    },
                    onError: () => {
                        addErrorToast(translate('productsOverview.archiveErrorToast'));
                    },
                }
            );
        }
    };

    const Icon = props.product.archived ? IconArrowBackUp : IconArchive;
    const tooltip = props.product.archived
        ? translate('productsOverview.restoreTooltip')
        : translate('productsOverview.archiveTooltip');

    return <IconButton emphasis="transparent" tooltipLabel={tooltip} Icon={Icon} onClick={onEditButtonClick} />;
};
