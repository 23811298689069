import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { FormikSwitch } from '../../../cdl/Switch/FormikSwitch';
import { Text } from '../../../cdl/Text/Text';
import { FormikFuelIsoSpecSelect } from '../../../order/fuel/FormikFuelIsoSpecSelect';
import { FormikHumanReadableEnumValue } from '../../form-elements/formik/FormikHumanReadableEnumValue';
import { formatFuelMoney } from '../../helpers/formatFuelMoney.helper';
import { useEnums } from '../../hooks/useEnums';
import { IconX } from '../../icons/cdl/X';
import { Item } from '../../models/item.model';
import { Td } from '../../Table/Td';
import { Tr } from '../../Table/Tr';
import { Box } from '../../ui/Box';

import { EnergyContentDisplay } from './EnergyContentDisplay';
import { PhysicalSupplierDisplay } from './PhysicalSupplierDisplay';
import { PriceDisplay } from './PriceDisplay';
import { ProductNameDisplay } from './ProductNameDisplay';
import { UnitsDisplay } from './UnitsDisplay';
import { useProductTableConfig } from './useProductTableConfig';

export const ProductRow = (props) => {
    const {
        editable,
        showPrices,
        editPrices,
        editEnergyContent,
        showCounterOffers,
        showPhysicalSupplierColumn,
        showEnergyContentColumn,
    } = useProductTableConfig();

    const [itemField, , itemFieldHelpers] = useField({
        name: props.name,
        validate: (productValue) => {
            if (productValue?.deleted || productValue === undefined) {
                return undefined;
            }

            const errors = {};

            if (editPrices) {
                if (!productValue?.price) {
                    errors.price = true;
                } else if (Number(productValue.price.value) === 0) {
                    errors.price = true;
                }
            }

            if (!productValue?.units) {
                errors.units = true;
            } else if (String(productValue?.units) === '0' || String(productValue?.units).endsWith('.')) {
                errors.units = true;
            }

            return Object.keys(errors).length > 0 ? errors : undefined;
        },
    });

    const item = itemField.value;

    const [contractIdField] = useField('contractId');
    const isContract = !!contractIdField.value;

    const { values: offer } = useFormikContext();

    const { getEnumField } = useEnums();

    useEffect(() => {
        if (item.compute) {
            item.compute();
        }
    }, [item]);

    const onProductSelect = (product) => {
        const existingItem = itemField.value;

        const previousPrice = {
            value: existingItem.price?.value ?? null,
            currency: existingItem.price?.currency ?? null,
        };

        const defaultUnitSize = getEnumField('packType', product.packTypeDefault, 'defaultUnitSize');

        const newItem = new Item({
            ...existingItem,
            productId: product.id,
            sulphurContent: product.sulphurContent,
            group: product.group,
            unit: product.unitDefault,
            product,
            packType: product.packTypeDefault,
            unitSize: defaultUnitSize,
            price: editPrices && previousPrice.value ? previousPrice : null,
            isoSpec: null,
            energyContent: {
                value: null,
            },
        });

        itemFieldHelpers.setValue(newItem);
    };

    const latestCounterOfferItem =
        offer.hasOpenCounterOffers &&
        offer.hasOpenCounterOffers() &&
        !offer.getLatestCounterOffer().items[props.index].deleted &&
        offer.getLatestCounterOffer().items[props.index];

    return (
        <Tr
            key={props.name}
            inactive={item.deleted}
            focused={item.added && !item.deleted}
            style={{
                height: '64px',
            }}
        >
            <Td>
                <ProductNameDisplay
                    name={props.name}
                    onSelect={onProductSelect}
                    isContract={isContract}
                    editable={editable}
                />
            </Td>
            <Td>
                <FormikHumanReadableEnumValue
                    name={`${props.name}.product.group`}
                    enumType="productGroup"
                    fallback="-"
                />{' '}
                <FormikHumanReadableEnumValue
                    name={`${props.name}.product.sulphurContent`}
                    enumType="sulphurContent"
                    fallback=""
                />
            </Td>
            <Td>
                {!editable || item.deleted ? (
                    <FormikHumanReadableEnumValue name={`${props.name}.isoSpec`} enumType="fuelIsoSpec" fallback="-" />
                ) : (
                    <FormikFuelIsoSpecSelect name={`${props.name}.isoSpec`} />
                )}
            </Td>
            {showEnergyContentColumn ? (
                <Td>
                    <EnergyContentDisplay
                        editable={editable && editEnergyContent && !item.deleted}
                        name={`${props.name}.energyContent`}
                    />
                </Td>
            ) : null}
            {showPhysicalSupplierColumn ? (
                <Td>
                    <PhysicalSupplierDisplay name={props.name} editable={editable} />
                </Td>
            ) : null}
            <Td>
                <UnitsDisplay
                    name={props.name}
                    editable={editable}
                    itemDeleted={item.deleted}
                    isContract={isContract}
                    index={props.index}
                />
            </Td>
            <Td>
                <FormikHumanReadableEnumValue name={`${props.name}.unit`} enumType="productUnit" />
            </Td>

            {showPrices ? (
                <>
                    {showCounterOffers && offer.hasOpenCounterOffers() ? (
                        <Td>
                            {latestCounterOfferItem ? (
                                <Text color={item.deleted ? 'inherit' : 'clear-blue.0'}>
                                    {formatFuelMoney({
                                        value: latestCounterOfferItem.counterPrice.value,
                                        currency: latestCounterOfferItem.counterPrice.currency,
                                    })}
                                </Text>
                            ) : (
                                '-'
                            )}
                        </Td>
                    ) : null}

                    <Td>
                        <PriceDisplay name={props.name} editable={editable && editPrices} />
                    </Td>

                    <Td>
                        {item.total?.value
                            ? formatFuelMoney({
                                  value: item.total.value,
                                  currency: item.total.currency,
                              })
                            : '-'}
                    </Td>
                </>
            ) : null}

            {editable ? (
                <Td>
                    <Box display="flex" minWidth="42px" justifyContent="center">
                        {item.new ? (
                            <IconButton type="button" onClick={props.onRowRemoveClicked} Icon={IconX} />
                        ) : (
                            <FormikSwitch
                                name={`${props.name}.deleted`}
                                disabled={item.total?.value ? item.total.value === 0 : false}
                                invert
                            />
                        )}
                    </Box>
                </Td>
            ) : null}
        </Tr>
    );
};

ProductRow.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onRowRemoveClicked: PropTypes.func.isRequired,
};
