import PropTypes from 'prop-types';
import { useState } from 'react';

import { DropdownMenu } from '../../../../cdl/DropdownMenu/DropdownMenu';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconExport } from '../../../../common/icons/cdl/Export';
import { Box } from '../../../../common/ui/Box';
import { useMessagesExport } from '../hooks/useMessagesExport';
import { useOfferExport } from '../hooks/useOfferExport';
import { useVesselEdit } from '../hooks/useVesselEdit';

import { ShareOrderPopup } from './ShareOrderPopup';

export const OfferExportDropdown = ({ offer }) => {
    const role = useRole();

    const vesselEditMutation = useVesselEdit(offer.vesselId);

    const { downloadPdf } = useOfferExport();
    const { downloadMessages } = useMessagesExport(offer.id);

    const { addLoadingToast } = useToasts();

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const onShareOrderSuccess = (vesselEmailAddress) => {
        setShowConfirmPopup(false);

        if (!vesselEmailAddress) {
            return;
        }

        vesselEditMutation.mutate({
            vesselId: offer.vesselId,
            vessel: {
                ...offer.vessel,
                email: vesselEmailAddress,
            },
        });
    };

    const hasOfferWriteRights = (offer) => {
        return role.hasWriteRights(offer.customerId) || role.hasWriteRights(offer.supplierId);
    };

    const options = [];

    if (offer && hasOfferWriteRights(offer) && role.isCustomer()) {
        options.push({
            label: translate('offer.detail.header.actions.export.share'),
            onSelect: async () => {
                setShowConfirmPopup(true);
            },
        });
    }

    options.push({
        label: translate('offer.detail.header.actions.export.pdf'),
        onSelect: () => {
            return addLoadingToast(
                () => downloadPdf(offer.id),
                translate('offer.detail.header.actions.export.downloadingPdf'),
                translate('offer.detail.header.actions.export.success'),
                translate('global.genericToastError')
            );
        },
    });

    options.push({
        label: translate('offer.detail.header.actions.export.messages'),
        onSelect: () => downloadMessages(offer.offerNumber),
    });

    return (
        <Box height={24} display="flex" alignItems="center">
            <DropdownMenu
                tooltip={
                    role.isSupplier()
                        ? translate('offer.detail.header.actions.exportTooltip')
                        : translate('offer.detail.header.actions.share')
                }
                options={options}
                align="end"
                Icon={IconExport}
            />

            {showConfirmPopup ? (
                <ShareOrderPopup
                    isOpen={showConfirmPopup}
                    onSuccess={onShareOrderSuccess}
                    onDismiss={() => setShowConfirmPopup(false)}
                    offer={offer}
                />
            ) : null}
        </Box>
    );
};

OfferExportDropdown.propTypes = {
    offer: PropTypes.object.isRequired,
};
