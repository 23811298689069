import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { Integration } from '../integrations/Integration';
import { IntegrationsOverviewPage } from '../integrations/IntegrationsOverviewPage';

const integrationsSearchSchema = z.object({
    integration: fallback(z.nativeEnum(Integration), Integration.MAERSK).default(Integration.MAERSK),
});

export const Route = createFileRoute('/_app/integrations')({
    component: IntegrationsOverviewPage,
    validateSearch: zodSearchValidator(integrationsSearchSchema),
});
