import { DeclinedApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { StatusBar } from '../../cdl/StatusBar/StatusBar';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconCircleX } from '../../common/icons/cdl/CircleX';

interface DeclinedStatusCalloutProps {
    approvalRequest: DeclinedApprovalRequestMessage;
}

export const DeclinedStatusBar = ({ approvalRequest }: DeclinedStatusCalloutProps) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.responseMessage) {
            return translate('approvalRequest.statusBar.declinedNoReason.subHeadline', {
                date: formatDateTime({ date: approvalRequest.dateDeclined }),
            });
        }

        return translate('approvalRequest.statusBar.declined.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateDeclined }),
            responseMessage: approvalRequest.responseMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.declined.headline');
    const subHeadline = makeSubHeadline();

    return (
        <StatusBar variant="negative" headline={headline} subHeadline={subHeadline} leadingVisual={<IconCircleX />} />
    );
};
