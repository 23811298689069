import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { AssignmentsFilterSelects } from './components/AssignmentsFilterSelects';
import { AssignmentsTable } from './components/AssignmentsTable';
import { CreateAssignmentButton } from './components/CreateAssignmentButton';

export const AssignmentsPage = () => {
    useDocumentTitle(translate('page.assigmentoverview'));

    return (
        <Page>
            <PageGrid>
                <Box display="flex" alignItems="center" justifyContent="space-between" gap={5}>
                    <Text variant="headline">{translate('assignments.title')}</Text>
                    <CreateAssignmentButton />
                </Box>
                <AssignmentsFilterSelects />
                <AssignmentsTable />
            </PageGrid>
        </Page>
    );
};
