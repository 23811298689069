import { Text } from '../../../cdl/Text/Text';
import { Error } from '../../../common/Error/Error';
import { Route } from '../../../routes/_app.customer.detail.$id';
import { useCustomerDetails } from '../../../common/hooks/useCustomerDetails';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { AdminSettings } from '../../AdminSettings';
import { GeneralInformation } from '../../GeneralInformation';

import { FuelCustomerSettings } from './FuelCustomerSettings';
import { LubesCustomerSettings } from './LubesCustomerSettings';
import { useCustomerDetailsEdit } from './useCustomerDetailsEdit';

export const CustomerDetailPage = () => {
    const role = useRole();
    const { id } = Route.useParams();

    const customerDetailQuery = useCustomerDetails(id);
    const editCustomerDetailsMutation = useCustomerDetailsEdit();

    useDocumentTitle(customerDetailQuery.data ? customerDetailQuery.data.name : null);

    if (customerDetailQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (customerDetailQuery.isError) {
        return (
            <Page>
                <Section>
                    <Error />
                </Section>
            </Page>
        );
    }

    const isFuelCustomer = customerDetailQuery.data.type === 'FUEL';

    const onCustomerDetailEditSubmit = (data) => {
        editCustomerDetailsMutation.mutate({ id, customerData: data });
    };

    return (
        <Page>
            <Box display="grid" gap={9} gridTemplateColumns="1fr 1fr">
                <Box gridColumn="1/3">
                    <Text as="h1" variant="headline">
                        {customerDetailQuery.data.name}
                    </Text>
                </Box>

                <Box gridColumn="1" gridRow="2">
                    <GeneralInformation
                        onEditSubmit={onCustomerDetailEditSubmit}
                        companyDetails={customerDetailQuery.data}
                    />

                    {role.isAdmin() ? (
                        <Box marginTop={9}>
                            <AdminSettings
                                onEditSubmit={onCustomerDetailEditSubmit}
                                companyDetails={customerDetailQuery.data}
                                companyType="CUSTOMER"
                            />
                        </Box>
                    ) : null}
                </Box>

                <Box gridColumn="2" gridRow="2">
                    {isFuelCustomer ? (
                        <FuelCustomerSettings customerId={id} />
                    ) : (
                        <LubesCustomerSettings customerId={id} />
                    )}
                </Box>
            </Box>
        </Page>
    );
};
