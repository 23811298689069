import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createVessel } from '../api/clients/vessel.api';
import { queries } from '../api/queryKeys/queries';

export const useVesselCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createVessel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.vessels._def });
        },
    });
};
