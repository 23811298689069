import { useState } from 'react';
import styled from 'styled-components';

import { Text } from '../cdl/Text/Text';
import { CenteredPageError } from '../common/Error/CenteredPageError';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { CloselinkMap } from '../common/Map/CloselinkMap';
import { Page } from '../common/Page/Page';
import { Box } from '../common/ui/Box';

import { GlobalPricesPortInformation } from './components/GlobalPricesPortInformation';
import { GlobalPricesPortMarker } from './components/GlobalPricesPortMarker';
import { MarkerColors } from './components/MarkerColors';
import { NoGlobalPrices } from './components/NoGlobalPrices';
import { ProductCategoryFilter } from './components/ProductCategoryFilter';
import { GlobalPricesPortColor } from './enums/GlobalPricesPortColor';
import { useBunkerMetricCredentials } from './hooks/useBunkerMetricCredentials';
import { useGlobalPrices } from './hooks/useGlobalPrices';
import { getMarkerColor } from './utils/getMarkerColor';
import { getProductCategories } from './utils/getProductCategories';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 16px;
    right: 16px;
    z-index: 1;
    border-radius: 8px;
    background-color: white;
    padding: 8px 16px;
    box-shadow: 0px 12px 24px -4px #1936434d;
`;

// We need to avoid flickering of the markers when dragged by hiding them on map drag.
// This is a workaround since google-map-react has this bug with react v18 and is not maintained anymore.

export const GlobalPricesPage = () => {
    useDocumentTitle(translate('globalPrices.title'));

    const [showMarker, setShowMarker] = useState(false);
    const [productCategoryFilter, setProductCategoryFilter] = useState<string[]>([]);

    const bunkerMetricCredentialsQuery = useBunkerMetricCredentials();
    const hasCredentials = !!bunkerMetricCredentialsQuery.data?.credentials?.length;
    const globalPricesQuery = useGlobalPrices(hasCredentials);

    if (bunkerMetricCredentialsQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (bunkerMetricCredentialsQuery.isError) {
        return (
            <Page>
                <CenteredPageError />
            </Page>
        );
    }

    if (!bunkerMetricCredentialsQuery.data?.credentials?.length) {
        return (
            <Page>
                <Box display="flex" marginTop="200px" justifyContent="center">
                    <NoGlobalPrices />
                </Box>
            </Page>
        );
    }

    const ports = globalPricesQuery.data?.portsWithPrices;

    const handleFilterChange = (category: string) => {
        if (!productCategoryFilter.length || !productCategoryFilter.includes(category)) {
            setProductCategoryFilter((prevState) => [...prevState, category]);
        } else {
            const index = productCategoryFilter.indexOf(category);
            const newProductCategoryFilter = Array.from(productCategoryFilter);
            newProductCategoryFilter.splice(index, 1);
            setProductCategoryFilter(newProductCategoryFilter);
        }
    };

    return (
        <Page padding={0}>
            <Header>
                <Text variant="headline">{translate('globalPrices.title')}</Text>

                {ports ? (
                    <ProductCategoryFilter
                        onChange={handleFilterChange}
                        productCategories={getProductCategories(ports)}
                    />
                ) : null}
            </Header>

            <Box width="calc(100vw - 220px)" height="calc(100vh - 60px)">
                <CloselinkMap
                    minZoom={3}
                    defaultZoom={3}
                    defaultCenter={{ lat: 31.0, lng: 13.5 }}
                    zoomControl={true}
                    onDrag={() => setShowMarker(false)}
                    onDragEnd={() => setShowMarker(true)}
                    onApiIsLoaded={() => setShowMarker(true)}
                >
                    {ports
                        ? ports.map((port) => {
                              const productCategories = port.prices.map(
                                  (price) => `${price.productGroup} ${price.sulphurContent}`
                              );

                              const hasFilteredProductCategory = productCategoryFilter.length
                                  ? productCategories.some((category) => productCategoryFilter.includes(category))
                                  : true;

                              return (
                                  <GlobalPricesPortMarker
                                      style={{
                                          transform: `scale(${showMarker && hasFilteredProductCategory ? 1 : 0})`,
                                          opacity: showMarker && hasFilteredProductCategory ? 1 : 0,
                                          transition: 'transform 0.1s, opacity 0.3s',
                                      }}
                                      key={port.locode}
                                      color={getMarkerColor(port.colorLevel)}
                                      lat={port.coordinates.latitude}
                                      lng={port.coordinates.longitude}
                                  >
                                      <GlobalPricesPortInformation port={port} />
                                  </GlobalPricesPortMarker>
                              );
                          })
                        : undefined}
                </CloselinkMap>
            </Box>

            <MarkerColors colors={Object.values(GlobalPricesPortColor)} />
        </Page>
    );
};
