import { AxiosResponse } from 'axios';

import {
    LubesConsumptionPresetsRequestMessage,
    LubesConsumptionPresetsResponseMessage,
} from '../../../../types/LubesConsumptionPresetsRequestMessage';
import { VesselCreateRequestMessage } from '../../../../types/VesselCreateRequestMessage';
import { VesselMessage } from '../../../../types/VesselMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

interface UpdateVesselParams {
    vesselId: string;
    vessel: unknown;
}

export const updateVessel = ({ vesselId, vessel }: UpdateVesselParams): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.put(`/v1/vessel/${vesselId}`, vessel);
};

interface CreateVesselParams {
    vessel: VesselCreateRequestMessage;
}

export const createVessel = ({ vessel }: CreateVesselParams): Promise<VesselMessage> => {
    return authorizedAxiosInstance.post('/v1/vessel', vessel);
};

interface CreateLubricationChartParams {
    vesselId: string;
    file: File;
}

export const createLubricationChart = ({ vesselId, file }: CreateLubricationChartParams): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    return authorizedAxiosInstance.post(`/v1/vessel/${vesselId}/lubrication-chart`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export interface GetVesselListParams {
    searchQuery?: string;
    customerIds?: string[] | string;
    active?: boolean;
}

export const getVesselList = async ({
    searchQuery,
    customerIds,
    active,
}: GetVesselListParams): Promise<VesselMessage[]> => {
    const params = new URLSearchParams();
    if (active) {
        params.append('active', active.toString());
    }
    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }
    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }
    const response = await authorizedAxiosInstance.get(`/v1/vessel/list?${params.toString()}`);
    return response.data;
};

export const deleteLubricationChart = (vesselId: string): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.delete(`/v1/vessel/${vesselId}/lubrication-chart`);
};

export const getLubesConsumptionPresets = async (vesselId: string): Promise<LubesConsumptionPresetsResponseMessage> => {
    const response = await authorizedAxiosInstance.get(`/v2/vessels/${vesselId}/presets`);
    return response.data;
};

interface UpdatePresetsParams {
    vesselId: string;
    presets: LubesConsumptionPresetsRequestMessage;
}

export const updateLubesConsumptionPresets = async ({
    vesselId,
    presets,
}: UpdatePresetsParams): Promise<LubesConsumptionPresetsResponseMessage> => {
    return authorizedAxiosInstance.put(`/v2/vessels/${vesselId}/presets`, presets);
};
