import { keepPreviousData } from '@tanstack/react-query';

import { ProductContext } from '../../types/ProductContext';
import { queries } from '../common/api/queryKeys/queries';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

import { OrderModel } from './model/OrderModel';

interface useOrderPaginationProps {
    page?: number;
    searchQuery?: string;
    customerIds?: string[];
    supplierIds?: string[];
    offerStates?: string[];
    types?: ProductContext[];
    read?: boolean;
    states?: string[];
    sortField?: string;
    sortDirection?: string;
}

export const useOrderPagination = ({ page = 0, ...rest }: useOrderPaginationProps) => {
    return usePaginationQuery<OrderModel>({
        queryKey: queries.orders.pagination({ page, ...rest }).queryKey,
        queryFn: queries.orders.pagination({ page, ...rest }).queryFn,
        prefetchQueryKey: queries.orders.pagination({ page: page + 1, ...rest }).queryKey,
        prefetchQueryFn: queries.orders.pagination({ page: page + 1, ...rest }).queryFn,
        options: { placeholderData: keepPreviousData },
    });
};
