import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { OrderState } from '../../order/model/OrderModel';
import { useCloudFleetManagerReimportRequisition } from '../useCloudFleetManagerReimportRequisition';

import { IntegrationOrderSelect } from './IntegrationOrderSelect';

export const ReimportRequisitionSection = () => {
    const [orderId, setOrderId] = useState<string | undefined>(undefined);
    const cloudFleetManagerReimportRequisitionMutation = useCloudFleetManagerReimportRequisition();
    const { addToast, addErrorToast } = useToasts();

    const createOrder = () => {
        if (orderId) {
            cloudFleetManagerReimportRequisitionMutation.mutate(
                {
                    orderId: orderId,
                },
                {
                    onSuccess: () => {
                        addToast(translate('integrationsOverview.orderSuccessToast'));
                    },
                    onError: (error) => {
                        if (error.response?.status === 400) {
                            addErrorToast(error.response.data);
                        } else {
                            addErrorToast(translate('integrationsOverview.unknownError'));
                        }
                    },
                }
            );
        }
    };

    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.reimportRequisition')}
            </Text>
            <Text variant="small" color="foreground.subtle">
                {translate('integrationsOverview.reimportRequisitionSubtitle')}
            </Text>

            <Box width="100%" maxWidth="400px">
                <IntegrationOrderSelect
                    label={translate('integrationsOverview.reimportRequisitionSelectLabel')}
                    value={orderId}
                    onChange={(option) => {
                        setOrderId(option.order.id);
                    }}
                    states={[OrderState.DRAFT]}
                />
            </Box>

            <Button disabled={!orderId} onClick={createOrder}>
                {translate('integrationsOverview.reimportRequisition')}
            </Button>
        </Box>
    );
};
