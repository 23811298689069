import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { AssignmentsPage } from '../assignment/common/AssignmentsPage';

const assignmentsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    customerIds: fallback(z.array(z.string()), []).default([]),
});

export const Route = createFileRoute('/_app/assignments')({
    component: AssignmentsPage,
    validateSearch: zodSearchValidator(assignmentsSearchSchema),
});
