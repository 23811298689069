import { ReactNode } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { Box } from '../../../common/ui/Box';

interface VesselInfoProps {
    label: string;
    value?: ReactNode;
}

export const VesselDetail = ({ label, value }: VesselInfoProps) => {
    if (!value) {
        return null;
    }

    return (
        <Box display="grid">
            <Text fontWeight="semiBold">{label}</Text>
            <Text>{value}</Text>
        </Box>
    );
};
