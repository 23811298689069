import { Text } from '../cdl/Text/Text';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { Page } from '../common/Page/Page';
import { TabSwitch } from '../common/TabSwitch/TabSwitch';
import { Box } from '../common/ui/Box';
import { Route } from '../routes/_app.integrations';

import { CloudFleetManagerPageContent } from './CloudFleetManagerPageContent';
import { Integration } from './Integration';
import { MaerskPageContent } from './MaerskPageContent';
import { OnePortPageContent } from './OnePortPageContent';

export const IntegrationsOverviewPage = () => {
    useDocumentTitle(translate('page.integrationsOverview'));

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    return (
        <Page>
            <Box display="grid" rowGap={4}>
                <Text variant="headline" as="h1">
                    {translate('integrationsOverview.headline')}
                </Text>

                <TabSwitch
                    selectedContext={search.integration}
                    onSelect={(integration) => navigate({ search: { integration } })}
                    totals={{
                        [Integration.MAERSK]: null,
                        [Integration.ONE_PORT]: null,
                        [Integration.CLOUD_FLEET_MANAGER]: null,
                    }}
                />

                {search.integration === Integration.MAERSK ? <MaerskPageContent /> : null}
                {search.integration === Integration.ONE_PORT ? <OnePortPageContent /> : null}
                {search.integration === Integration.CLOUD_FLEET_MANAGER ? <CloudFleetManagerPageContent /> : null}
            </Box>
        </Page>
    );
};
