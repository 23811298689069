import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { keyPortsSearchSchema } from '../../types/keyPortsSearchSchema';
import { FuelKeyPortOverviewPage } from '../keyport/FuelKeyPortOverviewPage';

export const Route = createFileRoute('/_app/fuel/key-ports')({
    component: FuelKeyPortOverviewPage,
    validateSearch: zodSearchValidator(keyPortsSearchSchema),
});
