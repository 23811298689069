import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../common/Overlay/Overlay';
import { Box } from '../../common/ui/Box';
import { OrderState } from '../model/OrderModel';

import { EnquiryCreationContextProvider } from './context/EnquiryCreationContextProvider';
import { FuelEnquiryForm } from './FuelEnquiryForm';
import { FuelEnquiryHeader } from './FuelEnquiryHeader';
import { useCreateContractOrder } from './hooks/useCreateContractOrder';
import { useEnquiryCreate } from './hooks/useEnquiryCreate';
import { useFuelEnquiryUpdate } from './hooks/useFuelEnquiryUpdate';
import { usePrefilledFormState } from './hooks/usePrefilledFormState';
import { useUpdateContractOrder } from './hooks/useUpdateContractOrder';
import { mapToFuelOrderRequestObject } from './mapToFuelOrderRequestObject';

export const FuelCreateEnquiryFlow = () => {
    return createOverlay(
        <EnquiryCreationContextProvider>
            <FuelCreateEnquiry />
        </EnquiryCreationContextProvider>
    );
};

const FuelCreateEnquiry = () => {
    useDocumentTitle(translate('fuelEnquiry.createNewTitle'));
    const { data, isLoading } = usePrefilledFormState();

    const createContractOrder = useCreateContractOrder();
    const updateContractOrder = useUpdateContractOrder();

    const createEnquiry = useEnquiryCreate();
    const updateEnquiry = useFuelEnquiryUpdate();

    // Formik automatically sets isSubmitting = false in async onSubmit functions.
    // To prevent this we make onSubmit synchronous, but include an async function.
    // https://github.com/jaredpalmer/formik/issues/2442#issuecomment-832191270
    // https://formik.org/docs/api/withFormik#the-formikbag

    const onSubmit = (values: any) => {
        const handleAsyncSubmit = async () => {
            const order = mapToFuelOrderRequestObject(values, OrderState.OPEN);

            const hasOrderId = !!values.orderId;
            const isContractOrder = !!values.contractId;

            if (hasOrderId) {
                if (isContractOrder) {
                    return updateContractOrder(order, values.orderId);
                }

                return updateEnquiry(order, values.orderId);
            }

            if (isContractOrder) {
                return createContractOrder(order);
            }

            return createEnquiry(order);
        };

        handleAsyncSubmit();
    };

    if (isLoading) {
        return (
            <Box minHeight="100%" backgroundColor="background.subtle">
                <FuelEnquiryHeader
                    title={translate('fuelEnquiry.createNewTitle')}
                    onCancelClick={() => history.back()}
                />

                <LoadingIndicator variant="page" />
            </Box>
        );
    }

    return (
        <Box paddingBottom={11} minHeight="100%" backgroundColor="background.subtle">
            <FuelEnquiryForm data={data} onSubmit={onSubmit} />
        </Box>
    );
};
