import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { statisticsSearchSchema } from '../../types/statisticsSearchSchema';
import { FuelStatisticsPage } from '../statistics/FuelStatisticsPage';

export const Route = createFileRoute('/_app/fuel/statistics')({
    component: FuelStatisticsPage,
    validateSearch: zodSearchValidator(statisticsSearchSchema),
});
