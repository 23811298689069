import { ParsedLocation, redirect } from '@tanstack/react-router';

import { convertToSha1Hash } from '../common/helpers/convertToSh1Hash.helper';
import { SessionService } from '../user/session/SessionService';

const isAuthenticated = () => {
    return SessionService.getInstance().isAuthenticated();
};

export const authenticate = async ({ emailAddress, password }: { password: string; emailAddress: string }) => {
    return SessionService.getInstance().authenticate({
        username: emailAddress,
        password: await convertToSha1Hash(password),
    });
};

export const requireAuthentication = (location: ParsedLocation) => {
    if (!isAuthenticated()) {
        throw redirect({
            to: '/login',
            search: {
                redirect: location.href,
            },
        });
    }
};
