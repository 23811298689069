import { useState } from 'react';

import { DateRangePicker } from '../../../cdl/DateRangePicker/DateRangePicker';
import { Text } from '../../../cdl/Text/Text';
import { Error } from '../../../common/Error/Error';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { generateStatisticsDateRangePresets } from '../../../common/helpers/generateStatisticsDateRangePresets';
import { sortLubesCategory } from '../../../common/helpers/sortByCategory.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconGraph } from '../../../common/icons/cdl/Graph';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../../common/ui/Box';
import { EditPresetsButton } from '../../VesselConsumptionPresets/EditPresetsButton';
import { useLubesConsumptionPresets } from '../../VesselConsumptionPresets/hooks/useLubesConsumptionPresets';

import { AverageConsumptionCategory } from './components/AverageConsumptionCategory';
import { AverageConsumptionTitle } from './components/AverageConsumptionTitle';
import { EmptyAverageConsumptionCategory } from './components/EmptyAverageConsumptionCategory';
import { InternalConsumptionConfig } from './components/InternalConsumptionConfig';
import { useAverageConsumption } from './hooks/useAverageConsumption';
import { generateDaysArrayFromRange } from './utils/generateDaysArrayFromRange';

const PREFERRED_NUMBER_OF_POINTS = 30;
const MIN_RESOLUTION = 1;

const generateResolutionFromDateRange = (dateRange: Date[], preferredNumberOfPoints: number) => {
    const daysArrayFromRange: Date[] = generateDaysArrayFromRange(dateRange);
    return Math.max(Math.floor(daysArrayFromRange.length / preferredNumberOfPoints), MIN_RESOLUTION);
};

interface AverageConsumptionSectionProps {
    vesselId: string;
    customerId: string;
}

export const AverageConsumptionSection = ({ vesselId, customerId }: AverageConsumptionSectionProps) => {
    const role = useRole();
    const dateRangePresets = generateStatisticsDateRangePresets();
    const [dateRange, setDateRange] = useState<Date[]>(dateRangePresets[0].range);
    const [preferredNumberOfPoints, setPreferredNumberOfPoints] = useState<number>(PREFERRED_NUMBER_OF_POINTS);

    const resolution = generateResolutionFromDateRange(dateRange, preferredNumberOfPoints);

    const averageConsumptionQuery = useAverageConsumption({
        vesselId,
        dateFrom: dateRange[0].toISOString(),
        dateTo: dateRange[1].toISOString(),
        resolution,
    });
    const presetsQuery = useLubesConsumptionPresets({ vesselId });

    if (averageConsumptionQuery.isPending || presetsQuery.isPending) {
        return (
            <Box>
                <HeaderWithLine>
                    <AverageConsumptionTitle />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box width="320px">
                            <DateRangePicker
                                presets={dateRangePresets}
                                dates={dateRange}
                                onDatesChange={(newDateRange) => setDateRange(newDateRange)}
                            />
                        </Box>
                    </Box>
                </HeaderWithLine>

                <LoadingIndicator />
            </Box>
        );
    }

    if (averageConsumptionQuery.isError || presetsQuery.isError) {
        return (
            <Box>
                <HeaderWithLine>
                    <AverageConsumptionTitle />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box width="320px">
                            <DateRangePicker
                                presets={dateRangePresets}
                                dates={dateRange}
                                onDatesChange={(newDateRange) => setDateRange(newDateRange)}
                            />
                        </Box>
                    </Box>
                </HeaderWithLine>

                <Error />
            </Box>
        );
    }

    if (!presetsQuery.data?.presets.length) {
        return (
            <Box>
                <HeaderWithLine>
                    <AverageConsumptionTitle />
                    <Box display="flex" gap={4} alignItems="center">
                        <Box width="320px">
                            <DateRangePicker
                                presets={dateRangePresets}
                                dates={dateRange}
                                onDatesChange={(newDateRange) => setDateRange(newDateRange)}
                            />
                        </Box>

                        {role.hasWriteRights(customerId) ? (
                            <EditPresetsButton vesselId={vesselId} customerId={customerId} />
                        ) : null}
                    </Box>
                </HeaderWithLine>

                <Box display="flex" flexDirection="column" alignItems="center" color="foreground.subtle" paddingY={5}>
                    <IconGraph width={32} height={32} />
                    <Text variant="small" fontWeight="semiBold">
                        {translate('vessel.averageConsumption.noPresets.title')}
                    </Text>
                    <Text variant="small">{translate('vessel.averageConsumption.noPresets.description')}</Text>
                </Box>
            </Box>
        );
    }

    const presetsByCategory = Object.groupBy(presetsQuery.data.presets, (preset) => preset.vesselTankCategory);
    const consumptionsByCategory = Object.groupBy(
        averageConsumptionQuery.data.averageConsumptions,
        (consumptions) => consumptions.category
    );

    return (
        <Box>
            <HeaderWithLine>
                <AverageConsumptionTitle />
                <Box display="flex" gap={4} alignItems="center">
                    <Box width="320px">
                        <DateRangePicker
                            presets={dateRangePresets}
                            dates={dateRange}
                            onDatesChange={(newDateRange) => setDateRange(newDateRange)}
                        />
                    </Box>

                    {role.hasWriteRights(customerId) ? (
                        <EditPresetsButton vesselId={vesselId} customerId={customerId} />
                    ) : null}
                </Box>
            </HeaderWithLine>

            <Box display="grid" gridTemplateColumns="1fr 1fr" gridAutoRows="240px" gap={9} paddingY={5}>
                {Object.keys(presetsByCategory)
                    .sort(sortLubesCategory)
                    .map((category) => {
                        const consumptionsForCategory = consumptionsByCategory[category];
                        const presetForCategory = presetsByCategory[category]?.at(0);

                        if (!consumptionsForCategory || consumptionsForCategory.length < 2 || !presetForCategory) {
                            return (
                                <EmptyAverageConsumptionCategory
                                    key={category}
                                    category={category}
                                    dateRange={dateRange}
                                />
                            );
                        }

                        return (
                            <AverageConsumptionCategory
                                key={category}
                                preset={presetForCategory}
                                consumptions={consumptionsForCategory}
                                dateRange={dateRange}
                            />
                        );
                    })}
            </Box>

            <InternalConsumptionConfig
                preferredNumberOfPoints={preferredNumberOfPoints}
                resolution={resolution}
                setPreferredNumberOfPoints={setPreferredNumberOfPoints}
            />
        </Box>
    );
};
