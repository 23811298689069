import translationsEn from '../../../translations/en/translation.json';

interface KeyReplacement {
    key: string;
    value: string;
}

interface Replacements {
    [key: string]: string;
}

export type TranslationKeyEn = keyof typeof translationsEn;

const getTranslationByKey = (key: TranslationKeyEn, replacements?: Replacements) => {
    const translation = translationsEn[key] as string;

    if (!translation) {
        return '';
    }

    if (!replacements) {
        return translation;
    }

    const keyReplacements: KeyReplacement[] = [];

    for (const [key, value] of Object.entries(replacements)) {
        const replaceableKey = `{{${key}}}`;
        keyReplacements.push({ key: replaceableKey, value });
    }

    let translationWithReplacements = translation;

    keyReplacements.forEach((entry) => {
        translationWithReplacements = translationWithReplacements.replace(entry.key, entry.value);
    });

    return translationWithReplacements;
};

export const translate = (key: TranslationKeyEn, replacements?: Replacements): string => {
    return getTranslationByKey(key, replacements);
};

export const conditionalTranslate = (
    bool: boolean,
    trueKey: TranslationKeyEn,
    falseKey: TranslationKeyEn,
    replacements?: Replacements
): string => {
    return translate(bool ? trueKey : falseKey, replacements);
};

export const translateOrNull = (key: TranslationKeyEn, replacement?: Replacements): null | string => {
    const translation = translate(key, replacement);

    if (!translation || translation === key) {
        return null;
    }

    return translation;
};
