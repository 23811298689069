import { AgentMessage } from '../../../types/AgentMessage';
import { ApprovalRequestState } from '../../../types/ApprovalRequestState';
import { CustomerCompanyMessage } from '../../../types/CustomerCompanyMessage';
import { PortMessage } from '../../../types/PortMessage';
import { ProductContext } from '../../../types/ProductContext';
import { SupplierCompanyMessage } from '../../../types/SupplierCompanyMessage';
import { VesselMessage } from '../../../types/VesselMessage';
import { ItemModel } from '../../common/models/ItemModel';
import { SamplekitModel } from '../../common/models/SamplekitModel';
import { OfferState } from '../../offer/model/OfferState';

export enum OrderState {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

// This should reflect the exact response we get from the GatewayService
// Keep in sync with: https://github.com/closelink/GatewayService/blob/main/src/main/java/net/closelink/gatewayservice/service/clients/pojos/objects/OrderMessage.java
interface GatewayOrderMessage {
    // From Order Entity
    id: string;
    dateCreated: string;
    dateUpdated: string;
    customerId: string;
    supplierId: string;
    vesselId: string;
    portId: string;
    state: OrderState;
    agent?: string;
    dateDelivery?: number;
    dateCanceled?: number;
    dateStarted?: number;
    dateOrdered?: number;
    buyerReference?: string;
    orderNumber?: string;
    cancelReason?: string;
    items: ItemModel[];
    samplekits: SamplekitModel[];
    createdBy?: string;
    updatedBy?: string;
    testOrder: boolean;
    comment: string;
    chosenOfferId?: string;
    read: boolean;
    spot: boolean;
    receiverSupplierIds: string[];
    assignedSupplierIds: string[];
    excludeSupplierGroupIds?: string[];
    offerCount: number;
    offerState?: OfferState;
    attributes?: Map<string, string>;
    creationComment?: string;
    agentId?: string;
    type: ProductContext;
    contractId?: string;
    eta?: string;
    etd?: string;
    validityTime?: number;
    validUntil?: string;
    fileIds?: string[];
    approvalRequestState?: ApprovalRequestState;

    // Added by the GatewayService
    agentInformation?: AgentMessage;
    customer?: CustomerCompanyMessage;
    supplier?: SupplierCompanyMessage;
    vessel: VesselMessage;
    port?: PortMessage;
    createdByUser: any; // Should be typed at some point, currently only used in js files
    updatedByUser: any; // Should be typed at some point, currently only used in js files
    contract: any; // Should be typed at some point, currently only used in js files
    files: any[]; // Should be typed at some point, currently only used in js files
}

// Main order model used in the frontend
// Used for both LUBES and FUEL
// This should only include the properties of the gateway response that are actually used in the frontend
export type OrderModel = Omit<
    GatewayOrderMessage,
    'dateCanceled' | 'dateOrdered' | 'createdBy' | 'updatedBy' | 'excludeSupplierGroupIds'
> & {
    isClosed(): boolean;

    isDraft(): boolean;

    isFinished(): boolean;

    isOpen(): boolean;

    isSpot(): boolean;
};
