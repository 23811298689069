import { type FormikErrors, setIn } from 'formik';
import moment from 'moment';

import { validateOrder as validateOrderAPI } from '../common/api/clients/order.api';
import { countActiveItems } from '../common/FormikProductTable/lubes/utils/items.helpers';
import { translate } from '../common/helpers/translate.helper';
import { validateRequiredFields } from '../common/helpers/validateRequired.helper';

import { FormikEditOrderValues } from './edit/OrderEditForm';

const validateItems = (items: FormikEditOrderValues['items']) => {
    let errors: FormikErrors<FormikEditOrderValues> = {};

    if (!items || countActiveItems(items) === 0) {
        errors.items = translate('errormessage.empty');
    } else {
        items.forEach((item, index) => {
            if (!item.productId) {
                errors = setIn(errors, `items.${index}.productId`, true);
            }

            if (!item.units || Number(item.units) === 0) {
                errors = setIn(errors, `items.${index}.units`, true);
            }

            if (!item.unitSize || Number(item.unitSize) === 0) {
                errors = setIn(errors, `items.${index}.unitSize`, true);
            }
        });
    }

    return errors;
};

const validateSamplekits = (samplekits: FormikEditOrderValues['samplekits']) => {
    let errors: FormikErrors<FormikEditOrderValues> = {};

    if (!samplekits) {
        return errors;
    }

    samplekits.forEach((samplekit, index) => {
        if (!samplekit.name) {
            errors = setIn(errors, `samplekits.${index}.name`, true);
        }

        if (!samplekit.quantity || Number(samplekit.quantity) === 0) {
            errors = setIn(errors, `samplekits.${index}.quantity`, true);
        }
    });

    return errors;
};

const validateBuyerReference = async (order: FormikEditOrderValues) => {
    const errors: FormikErrors<FormikEditOrderValues> = {};

    try {
        if (order.customerId && order.buyerReference) {
            const response = await validateOrderAPI({
                type: 'LUBES',
                customerId: order.customerId,
                buyerReference: order.buyerReference ? order.buyerReference : null,
                id: order.id,
            });

            const buyerReferenceError = response.validationErrors.find((error) => error.path === 'buyerReference');

            if (buyerReferenceError) {
                errors.buyerReference = translate('order.buyerReferenceAlreadyExists');
            }
        }
    } catch {
        return errors;
    }

    return errors;
};

const validateDeliveryDate = (date: number | undefined) => {
    const errors: FormikErrors<FormikEditOrderValues> = {};

    if (moment(date).isBefore(moment().startOf('day'))) {
        errors.dateDelivery = translate('errormessage.isInPast');
    }

    return errors;
};

export const validateOrder = async (values: FormikEditOrderValues): Promise<FormikErrors<FormikEditOrderValues>> => {
    const errors: FormikErrors<FormikEditOrderValues> = {
        ...validateItems(values.items),
        ...validateSamplekits(values.samplekits),
        ...validateDeliveryDate(values.dateDelivery),
        ...validateRequiredFields(values, ['vesselId', 'portId', 'dateDelivery']),
        ...(await validateBuyerReference(values)),
    };

    return errors;
};
