import { useField } from 'formik';
import { OnChangeValue } from 'react-select';

import { Select } from '../../cdl/Select/Select';
import { translate } from '../../common/helpers/translate.helper';

interface FeatureFlagOption {
    label: string;
    value: boolean;
}

interface FeatureFlagSelectProps {
    launchDarklyValue: boolean;
    name: string;
}

export const FeatureFlagSelect = ({ launchDarklyValue, name }: FeatureFlagSelectProps) => {
    const [field, , helpers] = useField(name);

    const options: FeatureFlagOption[] = [
        {
            label: 'True',
            value: true,
        },
        {
            label: 'False',
            value: false,
        },
    ];

    const onChange = (option: OnChangeValue<FeatureFlagOption, false>) => {
        helpers.setValue(option?.value);
    };

    const selectedValue = options?.find((option) => option.value === field.value);

    return (
        <Select<FeatureFlagOption>
            isClearable
            options={options}
            label={translate('devTools.featureFlags.overrideSelectLabel', {
                name,
                launchDarklyValue: launchDarklyValue.toString(),
            })}
            value={selectedValue}
            onChange={onChange}
        />
    );
};
