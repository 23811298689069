import { conditionalTranslate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

interface ActiveFlagProps {
    active: boolean;
}

export const ActiveFlag = ({ active }: ActiveFlagProps) => {
    return (
        <Flex alignItems={'center'}>
            <span>{conditionalTranslate(active, 'global.active', 'global.inactive')}</span>
            <Box
                backgroundColor={active ? 'positive.emphasis' : 'negative.emphasis'}
                width="5px"
                height="5px"
                marginTop={1} // to align the dot with the text more pleasing
                marginLeft={4}
                borderRadius="100%"
            />
        </Flex>
    );
};
