import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { statisticsSearchSchema } from '../../types/statisticsSearchSchema';
import { LubesStatisticsPage } from '../statistics/LubesStatisticsPage';

export const Route = createFileRoute('/_app/_lubes/statistics')({
    component: LubesStatisticsPage,
    validateSearch: zodSearchValidator(statisticsSearchSchema),
});
