import { TagProps } from '../../cdl/Tag/Tag';
import { OfferState } from '../../offer/model/OfferState';

const getCustomerStateVariant = (state: OfferState): TagProps['variant'] => {
    switch (state) {
        case OfferState.ENQUIRY:
        case OfferState.ORDER:
        case OfferState.ENQUIRY_CANCELED:
        case OfferState.QUOTE_CANCELED:
        case OfferState.CANCELED:
        case OfferState.CUSTOMER_ADJUSTED:
            return 'default';
        case OfferState.QUOTED:
        case OfferState.ACKNOWLEDGED:
            return 'accent';
        case OfferState.CONFIRMED:
        case OfferState.DELIVERED:
            return 'positive';
        case OfferState.SUPPLIER_ADJUSTED:
            return 'attention';
        default:
            return 'default';
    }
};

const getSupplierStateVariant = (state: OfferState): TagProps['variant'] => {
    switch (state) {
        case OfferState.ENQUIRY_CANCELED:
        case OfferState.QUOTE_CANCELED:
        case OfferState.CANCELED:
        case OfferState.SUPPLIER_ADJUSTED:
        case OfferState.QUOTED:
        case OfferState.ACKNOWLEDGED:
            return 'default';
        case OfferState.ORDER:
        case OfferState.ENQUIRY:
            return 'accent';
        case OfferState.CUSTOMER_ADJUSTED:
            return 'attention';
        case OfferState.DELIVERED:
            return 'positive';
        default:
            return 'default';
    }
};

export const getStateVariant = (state: OfferState, variant: 'customer' | 'supplier'): TagProps['variant'] => {
    switch (variant) {
        case 'customer':
            return getCustomerStateVariant(state);
        case 'supplier':
            return getSupplierStateVariant(state);
    }
};

export const getStateEmphasis = (state: OfferState, variant: 'customer' | 'supplier'): TagProps['emphasis'] => {
    switch (variant) {
        case 'customer':
            return [OfferState.QUOTED].includes(state);
        case 'supplier':
            return [OfferState.ENQUIRY, OfferState.ORDER].includes(state);
    }
};
