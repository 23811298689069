import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { markOrderRead } from '../../../../common/api/clients/order.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useAbly } from '../../../../common/hooks/useAbly';
import { useRole } from '../../../../common/hooks/useRole';

export const useLubesOrderDetails = (orderId: string) => {
    const markOrderReadMutation = useMutation({ mutationFn: markOrderRead });
    const { subscribe, unsubscribe } = useAbly();
    const role = useRole();

    const orderQuery = useQuery(queries.orders.detail(orderId));

    useEffect(() => {
        // Only mark order as read once after initial fetch
        if (!orderQuery.data?.read && role.hasWriteRights(orderQuery.data?.customerId) && !role.isAdmin()) {
            markOrderReadMutation.mutate({
                orderId: orderId,
                read: true,
            });
        }
    }, [orderQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!orderQuery.data) {
            return;
        }

        const orderTopic = `customers:${orderQuery.data.customerId}:orders:${orderQuery.data.id}`;

        subscribe(orderTopic, (message: { name: string }) => {
            if (message.name === 'UPDATED') {
                orderQuery.refetch();
            }
        });

        return () => {
            unsubscribe(orderTopic);
        };
    }, [orderQuery, subscribe, unsubscribe]);

    return orderQuery;
};
