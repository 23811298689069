import { Form, Formik } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { translate } from '../../common/helpers/translate.helper';
import { getManualOverrides } from '../../common/launchDarkly/getManualOverrides';
import { Box } from '../../common/ui/Box';

import { FeatureFlagSelect } from './FeatureFlagSelect';

interface FeatureFlagFormState {
    [key: string]: boolean | null;
}

export const FeatureFlags = () => {
    const allFlags = useFlags();

    const updateFlags = (flags: FeatureFlagFormState) => {
        localStorage.setItem('override-flags', JSON.stringify(flags));
        window.location.reload();
    };

    const resetOverrides = () => {
        localStorage.removeItem('override-flags');
        window.location.reload();
    };

    const overrides = getManualOverrides();

    const initialState: FeatureFlagFormState = Object.keys(allFlags).reduce((acc, flag) => {
        acc[flag] = overrides[flag] ?? null;
        return acc;
    }, {} as FeatureFlagFormState);

    const hasFeatureFlags = Object.keys(allFlags).length > 0;

    return (
        <Formik<FeatureFlagFormState> initialValues={initialState} onSubmit={updateFlags} enableReinitialize>
            <Form>
                <Text variant="subtitle1" as="h3" marginBottom={0}>
                    {translate('devTools.featureFlags.headline')}
                </Text>

                <Text variant="subtitle2">{translate('devTools.featureFlags.description')}</Text>

                {hasFeatureFlags ? (
                    <Box display="flex" marginTop={3} flexDirection="column" rowGap={3}>
                        {Object.keys(allFlags)
                            .sort()
                            .map((flagName) => {
                                return (
                                    <FeatureFlagSelect
                                        key={flagName}
                                        name={flagName}
                                        launchDarklyValue={allFlags[flagName]}
                                    />
                                );
                            })}
                    </Box>
                ) : (
                    <Text variant="body" as="p">
                        {translate('devTools.featureFlags.empty')}
                    </Text>
                )}

                <Box marginTop={4}>
                    <ButtonGroup>
                        {hasFeatureFlags ? (
                            <Button type="submit">{translate('devTools.featureFlags.save')}</Button>
                        ) : null}
                        <Button type="button" destructive onClick={resetOverrides}>
                            {translate('devTools.featureFlags.reset')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Form>
        </Formik>
    );
};
