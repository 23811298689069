import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelAdminSidebarItems = () => {
    return (
        <>
            <SidebarNavigationItem to="/fuel/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/orders">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/offers">{translate('navigation.offers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/key-ports">{translate('navigation.keyPorts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/planning/demand">{translate('navigation.planning')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/contracts">{translate('navigation.contracts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/statistics">{translate('navigation.reporting')}</SidebarNavigationItem>
        </>
    );
};
