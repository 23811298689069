import { useLocation, useNavigate, useSearch } from '@tanstack/react-router';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

import { scrollOverlayToTop } from '../../../common/Overlay/Overlay';

import { EnquiryCreationContext } from './EnquiryCreationContext';

export const EnquiryCreationContextProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { orderId } = useSearch({ from: '/_app/fuel/create' });

    const page = location.state?.page ?? 1;

    const setPage = useCallback(
        (page) => {
            if (orderId) {
                navigate({
                    to: `/fuel/create`,
                    search: {
                        orderId,
                    },
                    state: {
                        ...location.state,
                        page,
                    },
                });
                return;
            }

            navigate({
                to: '/fuel/create',
                state: {
                    ...location.state,
                    page,
                },
            });
        },
        [orderId, navigate, location.state]
    );

    // Redirect to first step on page refresh.
    useEffect(() => {
        if (!orderId) {
            setPage(1);
        }
    }, [orderId]);

    useEffect(() => {
        scrollOverlayToTop();
    }, [page]);

    const contextValue = {
        page,
        setPage,
    };

    return <EnquiryCreationContext.Provider value={contextValue}>{children}</EnquiryCreationContext.Provider>;
};

EnquiryCreationContextProvider.propTypes = {
    children: PropTypes.node,
};
