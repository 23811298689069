import { ChartOptions } from 'chart.js';

import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';

import { ChartType } from './barOptions';

export const pieOptions: ChartOptions<'pie'> = {
    maintainAspectRatio: false, // Needed to make the chart responsive
    plugins: {
        legend: {
            position: 'left',
        },
        tooltip: {
            displayColors: false,
            callbacks: {
                label: (context) => {
                    const rawDataPoint = context.dataset.data.at(context.dataIndex);

                    if (!rawDataPoint) {
                        return '';
                    }

                    const sum = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                    const percentage = Math.round((Number(context.raw) / sum) * 100);

                    switch (context.dataset.label) {
                        case ChartType.COMPLETED:
                            return translate('reporting.pieTooltipLabels.completedOrders', {
                                orders: formatNumber({ number: rawDataPoint }),
                                percentage: percentage.toString(),
                            });
                        case ChartType.TOTAL:
                            return translate('reporting.pieTooltipLabels.total', {
                                total: formatNumber({
                                    number: rawDataPoint,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                                percentage: percentage.toString(),
                            });
                        case ChartType.VOLUME:
                            return translate('reporting.pieTooltipLabels.volume', {
                                volume: formatNumber({ number: rawDataPoint }),
                                percentage: percentage.toString(),
                            });
                        case ChartType.PPL:
                            return '';
                    }
                },
            },
        },
    },
};
