import { Link } from '../../cdl/Link/Link';
import { Text } from '../../cdl/Text/Text';
import { DataSheet } from '../DataSheet/DataSheet';
import { formatAddress } from '../helpers/formatAddress.helper';
import { translate } from '../helpers/translate.helper';
import { useProductContext } from '../hooks/useProductContext';
import { useRole } from '../hooks/useRole';

import { CoreDataPopup } from './CoreDataPopup';

interface VesselInfoPopupProps {
    vessel: any;
}

export const VesselInfoPopup = ({ vessel }: VesselInfoPopupProps) => {
    const { isFuel } = useProductContext();
    const role = useRole();

    return (
        <CoreDataPopup heading={translate('offer.detail.coreData.vesselPopup.headline')}>
            <DataSheet>
                <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.name')}</DataSheet.Label>
                <Text>
                    {role.isCustomer() || role.isAdmin() ? (
                        <Link to="/vessel/$id" size="small" params={{ id: vessel.id }}>
                            {vessel.name}
                        </Link>
                    ) : (
                        vessel.name
                    )}
                </Text>

                <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.imo')}</DataSheet.Label>
                <Text>{vessel.imo}</Text>

                {vessel.data?.mmsiNumber ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.mmsi')}</DataSheet.Label>
                        <Text>{vessel.data?.mmsiNumber}</Text>
                    </>
                ) : null}
                {!isFuel && vessel.data?.type ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.type')}</DataSheet.Label>
                        <Text>{vessel.data?.type}</Text>
                    </>
                ) : null}
                {isFuel && vessel.types ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.types')}</DataSheet.Label>
                        <Text>{vessel.types.join(', ')}</Text>
                    </>
                ) : null}
                {isFuel && vessel.services ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.services')}</DataSheet.Label>
                        <Text>{vessel.services.join(', ')}</Text>
                    </>
                ) : null}
                {vessel.data?.classSociety ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.vesselPopup.registrationPlace')}
                        </DataSheet.Label>
                        <Text>{vessel.data?.classSociety}</Text>
                    </>
                ) : null}
                {vessel.data?.dwt ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.deadweight')}</DataSheet.Label>
                        <Text>
                            {translate('order.vesselinfo.tonnes', {
                                grossTonnage: vessel.data.dwt,
                            })}
                        </Text>
                    </>
                ) : null}
                {vessel.data?.gt ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.grossTonnage')}</DataSheet.Label>
                        <Text>{vessel.data.gt}</Text>
                    </>
                ) : null}
                {vessel.invoiceAddress ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.vesselPopup.invoiceAddress')}
                        </DataSheet.Label>
                        <Text>{formatAddress({ address: vessel.invoiceAddress })}</Text>
                    </>
                ) : null}
                {vessel.invoiceVAT ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.vesselPopup.invoiceVAT')}</DataSheet.Label>
                        <Text>{vessel.invoiceVAT}</Text>
                    </>
                ) : null}
            </DataSheet>
        </CoreDataPopup>
    );
};
