import { linkOptions } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { Section } from '../../../common/Section/Section';
import { OfferModel } from '../../../offer/model/OfferModel';
import { useOfferPagination } from '../../../offer/useOfferPagination';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';

export const FuelSupplierRecentActivities = () => {
    const [page, setPage] = useState(0);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const paginatedOffersQuery = useOfferPagination({
        page,
        supplierRead: false,
        sortField: 'dateUpdated',
        sortDirection: 'DESC',
        types: [ProductContext.FUEL],
    });

    if (paginatedOffersQuery.isPending) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <LoadingIndicator />
                </Section>
            </div>
        );
    }

    if (paginatedOffersQuery.isError) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <Error />
                </Section>
            </div>
        );
    }

    const recentActivitiesData = paginatedOffersQuery.data!!;

    if (!recentActivitiesData.content.length) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <RecentActivitiesEmptyState />
                </Section>
            </div>
        );
    }

    const openOffer = (offer: OfferModel) => {
        return linkOptions({ to: '/fuel/offer/$id', params: { id: offer.id } });
    };

    return (
        <div ref={wrapperRef}>
            <Section title={translate('dashboard.recentactivities')}>
                <TableBuilder<OfferModel>
                    data={recentActivitiesData.content}
                    rowLink={openOffer}
                    isLoading={paginatedOffersQuery.isPlaceholderData && paginatedOffersQuery.isFetching}
                    grow={false}
                >
                    <TableBuilderColumn<OfferModel> header={translate('order.vendorreference')} width="15%" truncate>
                        {(offer) => offer.vendorReference || '-'}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('offer.customer')} width="15%" truncate>
                        {(offer) => offer.customer.name}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('vessel.name')} width="15%" truncate>
                        {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('order.port')} width="25%">
                        {(offer) => formatPort({ port: offer.port, locode: false })}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('order.dateDelivery')} width="15%">
                        {(offer) => {
                            if (!offer.dateDelivery) {
                                return null;
                            }
                            return formatDate({ date: offer.dateDelivery, timeZone: 'UTC' });
                        }}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('order.status')} width="15%">
                        {(offer) => <OfferStatusTag state={offer.state} variant="supplier" />}
                    </TableBuilderColumn>
                </TableBuilder>

                <CenteredPagination
                    pageTotal={paginatedOffersQuery.pageTotal}
                    currentPage={page}
                    onPageChange={(page) => {
                        setPage(page);
                        if (wrapperRef.current) {
                            window.scrollTo(0, wrapperRef.current.offsetTop);
                        }
                    }}
                />
            </Section>
        </div>
    );
};
