import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { SuppliersOverviewPage } from '../assignment/lubes/SuppliersOverviewPage';

const suppliersSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    customerIds: fallback(z.array(z.string()), []).default([]),
});

export const Route = createFileRoute('/_app/_lubes/suppliers')({
    component: SuppliersOverviewPage,
    validateSearch: zodSearchValidator(suppliersSearchSchema),
});
