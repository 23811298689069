import { fallback } from '@tanstack/router-zod-adapter';
import z from 'zod';

export const agentsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
    tab: fallback(z.string(), 'active').default('active'),
    customerIds: fallback(z.array(z.string()), []).default([]),
    portIds: fallback(z.array(z.string()), []).default([]),
});
