import * as RadixAccordion from '@radix-ui/react-accordion';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

import { IconChevronDown } from '../../../common/icons/cdl/ChevronDown';
import { IconX } from '../../../common/icons/cdl/X';
import { MiddleDotSeparator } from '../../../common/Separators/MiddleDotSeparator';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';
import { IconButton } from '../../IconButton/IconButton';
import { Text } from '../../Text/Text';

interface StyledAccordionTriggerProps extends ComponentProps<typeof RadixAccordion.Trigger> {
    disabled?: boolean;
}

const StyledAccordionTrigger = styled(RadixAccordion.Trigger)<StyledAccordionTriggerProps>`
    padding: ${get('space.1')}px ${get('space.4')}px;
    display: flex;
    gap: ${get('space.5')}px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: ${get('radii.1')}px;
    border: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    user-select: none;

    &:hover,
    &:focus {
        background-color: ${(props) => (props.disabled ? 'transparent' : get('colors.background.subtle'))};
    }

    &[data-state='open'] {
        background-color: ${get('colors.background.subtle')};

        svg {
            transform: rotate(-180deg);
        }
    }
`;

const StyledAccordionHeader = styled(RadixAccordion.Header)`
    padding: ${get('space.4')}px 0;
    margin: 0;
    position: relative;
`;

const StyledAccordionItem = styled(RadixAccordion.Item)`
    border-bottom: 1px solid ${get('colors.border.muted')};
    min-width: min-content;

    &:last-of-type {
        border: none;
    }
`;

const StyledAccordionContent = styled(RadixAccordion.Content)`
    overflow: hidden;

    &[data-state='open'] {
        animation: slideDown 100ms ease-in-out;
    }

    &[data-state='closed'] {
        animation: slideUp 100ms ease-in-out;
    }

    @keyframes slideDown {
        from {
            opacity: 0;
            height: 0;
        }
        to {
            opacity: 100%;
            height: var(--radix-accordion-content-height);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 100%;
            height: var(--radix-accordion-content-height);
        }
        to {
            opacity: 0;
            height: 0;
        }
    }
`;

export interface AccordionItemProps {
    id: string;
    title: string;
    summary?: ReactNode;
    disabled?: boolean;
    onRemove?: () => void;
    children: ReactNode;
}

export const AccordionItem = (props: AccordionItemProps) => {
    return (
        <StyledAccordionItem value={props.id} disabled={props.disabled}>
            <StyledAccordionHeader>
                <StyledAccordionTrigger disabled={props.disabled}>
                    <Box
                        display="flex"
                        alignItems="center"
                        color={props.disabled ? 'foreground.disabled' : 'foreground.muted'}
                    >
                        <Text variant="body" fontWeight="semiBold" style={{ whiteSpace: 'nowrap' }}>
                            {props.title}
                        </Text>
                        {props.summary ? (
                            <>
                                <MiddleDotSeparator />
                                {typeof props.summary === 'string' ? (
                                    <Text variant="body" style={{ whiteSpace: 'nowrap' }}>
                                        {props.summary}
                                    </Text>
                                ) : (
                                    props.summary
                                )}
                            </>
                        ) : null}
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        color={props.disabled ? 'foreground.disabled' : 'foreground.subtle'}
                    >
                        <Box padding={4}>
                            <IconChevronDown height={16} width={16} style={{ transition: 'transform 150ms' }} />
                        </Box>
                    </Box>
                </StyledAccordionTrigger>

                {props.onRemove ? (
                    <Box position="absolute" right="48px" top="10px">
                        <IconButton
                            type="button"
                            Icon={IconX}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onRemove?.();
                            }}
                        />
                    </Box>
                ) : null}
            </StyledAccordionHeader>

            <StyledAccordionContent>
                <Box padding={4} paddingTop={0}>
                    {props.children}
                </Box>
            </StyledAccordionContent>
        </StyledAccordionItem>
    );
};
