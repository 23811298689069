import { useFormikContext } from 'formik';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { MoneyMessage } from '../../../../../types/MoneyMessage';
import { Callout } from '../../../../cdl/Callout/Callout';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { Text } from '../../../../cdl/Text/Text';
import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { formatLubesPrice } from '../../../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconReplace } from '../../../../common/icons/cdl/Replace';
import { IconX } from '../../../../common/icons/cdl/X';
import { InfoPopover } from '../../../../common/InfoPopover/InfoPopover';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';
import { PackageItemRequest, usePackagePrices } from '../hooks/usePackagePrices';
import { useProductsByIds } from '../hooks/useProductsByIds';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';
import { translateProduct } from '../util/translateProduct';

const PriceSummary = styled.div`
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-items: end;
    padding: 4px 8px;
    background-color: ${get('colors.background.inset')};
    border-radius: ${get('radii.1')}px;
`;

interface PackagePriceSummary {
    productId: string;
    isTranslated: boolean;
    isTranslationSuccessful: boolean;
    productName?: string;
    baseProductName: string;
    unitPrice?: MoneyMessage;
    totalPrice?: MoneyMessage;
}

export const FormikPackagePrices = () => {
    const { values } = useFormikContext<FormikCreateEnquiryValues>();

    const receiverSuppliers = values.assignedSuppliers.filter((supplier) =>
        values.receiverSupplierIds.includes(supplier?.id)
    );

    const receiverSupplierGroupIdsSet = [...new Set(receiverSuppliers.map((supplier) => supplier.supplierGroup.id))];

    const supplierAdjustedItems = receiverSuppliers.map((supplier) => {
        return {
            supplier: supplier,
            items: values.items?.map((item) => {
                return {
                    translatedProduct: item.product ? translateProduct(item.product, supplier) : undefined,
                    packType: item.packType,
                    volume: item.volume,
                };
            }),
        };
    });

    const translatedItemsRequest = supplierAdjustedItems.reduce<PackageItemRequest[]>((acc, item) => {
        item.items?.forEach((item) => {
            if (!item.translatedProduct) {
                return;
            }

            acc.push({
                productId: item.translatedProduct.productId,
                packType: item.packType,
                volume: item.volume,
            });
        });

        return acc;
    }, []);

    const productIds = supplierAdjustedItems.reduce<Set<string>>((acc, item) => {
        item.items.forEach((item) => {
            if (item.translatedProduct?.baseProductId) {
                acc.add(item.translatedProduct.baseProductId);
            }
            if (item.translatedProduct?.productId) {
                acc.add(item.translatedProduct.productId);
            }
        });

        return acc;
    }, new Set<string>());

    const productsQuery = useProductsByIds(Array.from(productIds));

    const packagePricesQuery = usePackagePrices(
        values.portId,
        values.customerId,
        receiverSupplierGroupIdsSet,
        translatedItemsRequest
    );

    if (
        receiverSupplierGroupIdsSet.length > 0 &&
        values.portId &&
        (packagePricesQuery.isPending || productsQuery.isPending) &&
        translatedItemsRequest.length > 0 &&
        translatedItemsRequest.every((it) => it.volume)
    ) {
        return (
            <Box display="flex" alignItems="end" flexDirection="column" gap={5}>
                <Box display="flex" flexWrap="wrap" width="100%" gap={4} justifyContent="flex-end">
                    {receiverSupplierGroupIdsSet.map((id) => {
                        return <Skeleton key={id} width={200} height={56} style={{ margin: 0 }} />;
                    })}
                </Box>
                <Box>
                    <Callout variant="default" description={translate('order.additionalcharges')} />
                </Box>
            </Box>
        );
    }

    if (packagePricesQuery.isError || productsQuery.isError) {
        return null;
    }

    return (
        <Box display="flex" alignItems="end" flexDirection="column" gap={5}>
            <Box display="flex" flexWrap="wrap" width="100%" gap={4} justifyContent="flex-end">
                {supplierAdjustedItems.map((supplierAdjustedItem) => {
                    const supplier = supplierAdjustedItem.supplier;

                    const itemsWithPrices = supplierAdjustedItem.items
                        ?.map((item) => {
                            if (!item.translatedProduct) {
                                return null;
                            }

                            const productId = item.translatedProduct.productId;
                            const baseProductId = item.translatedProduct.baseProductId;
                            const unitPrice = packagePricesQuery.data?.find(
                                (price) =>
                                    price.productId === productId && price.supplierGroupId === supplier.supplierGroup.id
                            )?.price;

                            return {
                                productId: productId,
                                isTranslated: item.translatedProduct.isTranslated,
                                isTranslationSuccessful: item.translatedProduct.isSuccessful,
                                productName: productsQuery.data?.find((product) => product.id === productId)?.name,
                                baseProductName: productsQuery.data?.find((product) => product.id === baseProductId)
                                    ?.name,
                                unitPrice: unitPrice,
                                totalPrice: unitPrice
                                    ? {
                                          value: unitPrice.value * (item.volume ? item.volume : 0),
                                          currency: unitPrice.currency,
                                      }
                                    : undefined,
                            };
                        })
                        .filter((it) => it !== null) as PackagePriceSummary[];

                    const totalPrice = itemsWithPrices.reduce((acc, item) => {
                        if (!item?.totalPrice) {
                            return acc;
                        }

                        return acc + item.totalPrice.value;
                    }, 0);

                    const hasMissingPrices = itemsWithPrices.some((it) => !it?.unitPrice);

                    return (
                        <PriceSummary key={supplier.id}>
                            <Text variant="small">{supplier.name}</Text>

                            <Box display="flex" alignItems="center" gap={2}>
                                <Text variant="subtitle1">
                                    {hasMissingPrices
                                        ? translate('global.notavailable')
                                        : formatMoney({
                                              value: totalPrice,
                                              currency: itemsWithPrices[0]?.totalPrice?.currency || 'USD',
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          })}
                                </Text>
                                <InfoPopover>
                                    <TableBuilder variant="inline" data={itemsWithPrices}>
                                        <TableBuilderColumn header={translate('order.packagePrices.product')}>
                                            {(row: PackagePriceSummary) => {
                                                const productNameOrDefault =
                                                    row.productName || translate('global.notavailable');

                                                if (row.isTranslated) {
                                                    return (
                                                        <Tooltip
                                                            label={translate('order.equivalentTooltip', {
                                                                productName: row.baseProductName,
                                                            })}
                                                            showArrow
                                                        >
                                                            <Box display="flex" alignItems="center" gap={4}>
                                                                <IconReplace width={16} height={16} />
                                                                <span>{productNameOrDefault}</span>
                                                            </Box>
                                                        </Tooltip>
                                                    );
                                                }

                                                if (!row.isTranslationSuccessful) {
                                                    return (
                                                        <Box display="flex" alignItems="center" gap={4}>
                                                            <IconX width={16} height={16} />
                                                            <span>{productNameOrDefault}</span>
                                                        </Box>
                                                    );
                                                }

                                                return productNameOrDefault;
                                            }}
                                        </TableBuilderColumn>
                                        <TableBuilderColumn
                                            header={translate('order.packagePrices.pricePerLiter')}
                                            numeric
                                        >
                                            {(row: PackagePriceSummary) =>
                                                row.unitPrice ? formatLubesPrice(row.unitPrice) : '-'
                                            }
                                        </TableBuilderColumn>
                                        <TableBuilderColumn header={translate('order.packagePrices.total')} numeric>
                                            {(row: PackagePriceSummary) => {
                                                if (!row.totalPrice) {
                                                    return '-';
                                                }

                                                return formatMoney({
                                                    ...row.totalPrice,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                });
                                            }}
                                        </TableBuilderColumn>
                                    </TableBuilder>
                                </InfoPopover>
                            </Box>
                        </PriceSummary>
                    );
                })}
            </Box>
            <Box>
                <Callout variant="default" description={translate('order.additionalcharges')} />
            </Box>
        </Box>
    );
};
