import { linkOptions } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { Section } from '../../../common/Section/Section';
import { OrderModel } from '../../../order/model/OrderModel';
import { OfferCount } from '../../../order/OrderOverview/lubes/OfferCount';
import { useOrderPagination } from '../../../order/useOrderPagination';
import { Port } from '../../../port/Port';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';

export const LubesCustomerOrderTable = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState(0);
    const role = useRole();

    const paginatedOrdersQuery = useOrderPagination({
        page,
        read: false,
        types: [ProductContext.LUBES],
    });

    if (paginatedOrdersQuery.isPending) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (paginatedOrdersQuery.isError) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <Error />
            </Section>
        );
    }

    if (!paginatedOrdersQuery.data.content.length) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <RecentActivitiesEmptyState />
            </Section>
        );
    }

    const buildRowLink = (order: OrderModel) => {
        if (order.isClosed() && order.chosenOfferId) {
            return linkOptions({ to: '/offer/$id', params: { id: order.chosenOfferId } });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            return linkOptions({ to: '/orders/draft/$id', params: { id: order.id } });
        } else {
            return linkOptions({ to: '/order/$id', params: { id: order.id } });
        }
    };

    return (
        <div ref={wrapperRef}>
            <Section title={translate('dashboard.recentactivities')}>
                <TableBuilder
                    data={paginatedOrdersQuery.data.content}
                    rowLink={(order) => buildRowLink(order)}
                    isLoading={paginatedOrdersQuery.isPlaceholderData && paginatedOrdersQuery.isFetching}
                    grow={false}
                >
                    <TableBuilderColumn<OrderModel>
                        header={translate('order.buyerreferenceshort')}
                        width="10%"
                        truncate
                    >
                        {(order) => order.buyerReference || '-'}
                    </TableBuilderColumn>

                    {!role.isOneCompanyUser(ProductContext.LUBES) && (
                        <TableBuilderColumn<OrderModel>
                            header={role.isCustomer() ? translate('order.company') : translate('order.customer')}
                            width="10%"
                            truncate
                        >
                            {(order) => formatCompanyName({ company: order.customer }) || null}
                        </TableBuilderColumn>
                    )}

                    <TableBuilderColumn<OrderModel> header={translate('order.vessel')} width="15%" truncate>
                        {(order) => formatVessel({ vessel: order.vessel, short: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={translate('order.port')} width="15%">
                        {(order) => {
                            if (!order.port) {
                                return null;
                            }
                            return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={translate('order.dateDeliveryShort')} width="10%">
                        {(order) => {
                            if (!order.dateDelivery) {
                                return null;
                            }
                            return formatDate({ date: order.dateDelivery, timeZone: 'UTC' });
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={translate('order.status')} width="5%">
                        {(order) => {
                            if (!order.offerState) {
                                return null;
                            }
                            return <OfferStatusTag state={order.offerState} variant="customer" />;
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={translate('order.offercount')} width="5%">
                        {(order) => {
                            return (
                                <OfferCount
                                    offerCount={order.offerCount}
                                    totalOffers={order.receiverSupplierIds.length}
                                    includesSpot={order.spot}
                                />
                            );
                        }}
                    </TableBuilderColumn>
                </TableBuilder>

                <CenteredPagination
                    pageTotal={paginatedOrdersQuery.pageTotal}
                    currentPage={page}
                    onPageChange={(page) => {
                        setPage(page);
                        if (wrapperRef.current) {
                            window.scrollTo(0, wrapperRef.current.offsetTop);
                        }
                    }}
                />
            </Section>
        </div>
    );
};
