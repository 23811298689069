import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { PortsOverviewPage } from '../admin/port/list/PortsOverviewPage';

const portsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
});

export const Route = createFileRoute('/_app/ports')({
    component: PortsOverviewPage,
    validateSearch: zodSearchValidator(portsSearchSchema),
});
