import { SurchargeGatewayMessage } from '../../../../../types/SurchargeGatewayMessage';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { Error } from '../../../../common/Error/Error';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatLubesPrice } from '../../../../common/helpers/formatLubesPrice.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useRole } from '../../../../common/hooks/useRole';
import { IconTrash } from '../../../../common/icons/cdl/Trash';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../../../common/TableEmptyState/TableEmptyState';
import { useSurchargeDelete } from '../hooks/useSurchargeDelete';
import { useSurcharges } from '../hooks/useSurcharges';

interface SurchargesTableProps {
    groupId: string;
}

export const SurchargesTable = ({ groupId }: SurchargesTableProps) => {
    const surchargesQuery = useSurcharges(groupId);
    const surchargeDeleteMutation = useSurchargeDelete(groupId);
    const role = useRole();
    const { addErrorToast } = useToasts();
    const { getHumanReadableValue } = useEnums();

    if (surchargesQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (surchargesQuery.isError) {
        return <Error />;
    }

    if (!surchargesQuery.data.length) {
        return <TableEmptyState text={translate('surcharges.emptylist')} />;
    }

    const removeSurcharge = (id: string) => {
        surchargeDeleteMutation.mutate(id, {
            onError: () => {
                addErrorToast('surcharges.toasts.deleteSurchargeError');
            },
        });
    };

    return (
        <TableBuilder data={surchargesQuery.data}>
            <TableBuilderColumn<SurchargeGatewayMessage> header={translate('surcharges.customer')} width="30%">
                {(row) =>
                    row.customer ? formatCompanyName({ company: row.customer, includeType: role.isAdmin() }) : '-'
                }
            </TableBuilderColumn>

            <TableBuilderColumn<SurchargeGatewayMessage> header={translate('surcharges.packtype')}>
                {(row) => getHumanReadableValue('packType', row.packType)}
            </TableBuilderColumn>

            <TableBuilderColumn<SurchargeGatewayMessage> header={translate('surcharges.group')}>
                {(row) => (row.group ? getHumanReadableValue('productGroup', row.group) : '-')}
            </TableBuilderColumn>

            <TableBuilderColumn<SurchargeGatewayMessage> numeric header={translate('surcharges.value')}>
                {(row) => formatLubesPrice(row.value)}
            </TableBuilderColumn>
            <TableBuilderColumn<SurchargeGatewayMessage> width="5%" header={''}>
                {(row) => (
                    <IconButton
                        emphasis="transparent"
                        destructive
                        Icon={IconTrash}
                        onClick={() => removeSurcharge(row.id)}
                    />
                )}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
