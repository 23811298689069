import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { FuelSuppliersOverviewPage } from '../assignment/fuel/FuelSuppliersOverviewPage';

const AssignmentsSearchSchema = z.object({
    page: fallback(z.number(), 0),
    customerIds: fallback(z.array(z.string()), []),
});

export const Route = createFileRoute('/_app/fuel/assignments')({
    validateSearch: zodSearchValidator(AssignmentsSearchSchema),
    component: FuelSuppliersOverviewPage,
});
