import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { ProductContext } from '../../types/ProductContext';
import { VesselSearchSchema } from '../../types/vesselDetailsSearchSchema';
import { queries } from '../common/api/queryKeys/queries';
import { queryClient } from '../lib/queryClient';
import { LubesVesselDetailPage } from '../vessel/VesselDetailPage/LubesVesselDetailPage';

export const Route = createFileRoute('/_app/_lubes/vessel/$id')({
    validateSearch: zodSearchValidator(VesselSearchSchema),
    component: LubesVesselDetailPage,
    async loader({ params: { id } }) {
        const vessel = await queryClient.fetchQuery({ ...queries.vessels.detail(id) });

        if (vessel.customer.type === ProductContext.FUEL) {
            throw redirect({
                to: '/fuel/vessel/$id',
                replace: true,
                params: {
                    id,
                },
            });
        }
    },
});
