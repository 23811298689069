import { translate } from '../../helpers/translate.helper';
import { useRole } from '../../hooks/useRole';
import { Box } from '../../ui/Box';

import { SupplierCompanySelect, SupplierCompanySelectProps } from './SupplierCompanySelect';

// To be used at overview pages within a flex container

interface OverviewSupplierCompanySelectProps<IsMulti extends boolean>
    extends Omit<SupplierCompanySelectProps<IsMulti>, 'dropdownWidth' | 'placeholder' | 'isMulti'> {}

export const OverviewSupplierCompanySelect = (props: OverviewSupplierCompanySelectProps<true>) => {
    const role = useRole();
    return (
        <Box minWidth="250px">
            <SupplierCompanySelect
                {...props}
                dropdownWidth="520px"
                placeholder={
                    role.isAdmin() ? translate('placeholder.filter.supplier') : translate('placeholder.filter.company')
                }
                isMulti
            />
        </Box>
    );
};
