import { createFileRoute } from '@tanstack/react-router';

import { getOfferById } from '../common/api/clients/gateway.api';
import { getRole } from '../common/hooks/useRole';
import { FuelOfferDetailPage } from '../offer/detail/fuel/FuelOfferDetailPage';

export const Route = createFileRoute('/_app/fuel/offer/$id')({
    async loader(ctx) {
        getOfferById(ctx.params.id).then((offer) => {
            getRole().updateContext(offer.type);
        });
    },
    component: FuelOfferDetailPage,
});
