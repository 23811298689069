import { createFileRoute, redirect } from '@tanstack/react-router';

import { AblyService } from '../common/services/AblyService';
import { queryClient } from '../lib/queryClient';
import { RoleService } from '../user/session/RoleService';
import { SessionStorageService } from '../user/session/SessionStorageService';

export const Route = createFileRoute('/logout')({
    loader() {
        SessionStorageService.resetToken();
        RoleService.getInstance().reset();
        AblyService.disconnect();
        queryClient.clear();

        throw redirect({
            to: '/login',
            // replacing history in order to prevent back-button to contain
            // potentially confidential data
            replace: true,
        });
    },
});
