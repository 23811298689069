import { Link, LinkProps as _LinkProps } from '@tanstack/react-router';

import { useRouteInCategory } from './useRouteInCategory';

export interface NavigationLinkProps extends Omit<_LinkProps, 'to'> {
    to: Exclude<_LinkProps['to'], undefined>;
    className?: string;
}

export const NavigationLink = (props: NavigationLinkProps) => {
    const isRouteInCategory = useRouteInCategory(props.to);

    return (
        <Link {...props} className={[props.className, isRouteInCategory ? 'active' : null].filter(Boolean).join(' ')} />
    );
};
