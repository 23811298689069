import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { Button } from '../../../cdl/Button/Button';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { queryKeys } from '../../../common/api/queryKeys';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';
import { AcknowledgePopup } from '../common/components/AcknowledgePopup';
import { ConfirmOrderPopup } from '../common/ConfirmOrderPopup';
import { useSwitchState } from '../common/hooks/useSwitchState';

import { LubesConfirmDeliveryPopup } from './LubesConfirmDeliveryPopup';

interface LubesSupplierOfferDetailStatusBarProps {
    offer: OfferModel;
}

export const LubesSupplierOfferDetailStatusBar = ({ offer }: LubesSupplierOfferDetailStatusBarProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { changeState } = useSwitchState();

    const navigateToQuote = () => {
        navigate({ to: `/offer/$id/quote`, params: { id: offer.id } });
        queryClient.removeQueries({ queryKey: queryKeys.offer(offer.id) });
    };

    switch (offer.state) {
        case OfferState.ENQUIRY: {
            const headlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotenquiry.label'
                : 'statusbar.supplier.enquiry.label';

            const subHeadlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotenquiry.subtitle'
                : 'statusbar.supplier.enquiry.subtitle';

            const buttonTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotenquiry.button'
                : 'statusbar.supplier.enquiry.button';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                    trailingVisual={
                        <Button emphasis="high" onClick={navigateToQuote}>
                            {translate(buttonTranslationKey)}
                        </Button>
                    }
                />
            );
        }
        case OfferState.QUOTED: {
            const headlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotquoted.label'
                : 'statusbar.supplier.quoted.label';

            const subHeadlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotquoted.subtitle'
                : 'statusbar.supplier.quoted.subtitle';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (!offer.deliveryPast()) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.supplier.confirmed.label')}
                        subHeadline={translate('statusbar.supplier.confirmed.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.delivery.label')}
                    subHeadline={translate('statusbar.supplier.delivery.subtitle')}
                    trailingVisual={
                        <Button
                            emphasis="high"
                            onClick={() => {
                                changeState({
                                    offer,
                                    destinationState: OfferState.DELIVERED,
                                    component: LubesConfirmDeliveryPopup,
                                });
                            }}
                        >
                            {translate('statusbar.supplier.delivery.button')}
                        </Button>
                    }
                />
            );
        }
        case OfferState.ORDER: {
            const headlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotorder.label'
                : 'statusbar.supplier.order.label';

            const subHeadlineTranslationKey = offer.isSpot()
                ? 'statusbar.supplier.spotorder.subtitle'
                : 'statusbar.supplier.order.subtitle';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                    trailingVisual={
                        <ButtonGroup>
                            <Button
                                emphasis="medium"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.ACKNOWLEDGED,
                                        component: AcknowledgePopup,
                                    });
                                }}
                            >
                                {translate('statusbar.supplier.order.acknowledgeButton')}
                            </Button>

                            <Button
                                emphasis="high"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.CONFIRMED,
                                        component: ConfirmOrderPopup,
                                    });
                                }}
                            >
                                {translate('statusbar.supplier.order.confirmButton')}
                            </Button>
                        </ButtonGroup>
                    }
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.customerAdjusted.label')}
                    subHeadline={translate('statusbar.supplier.customerAdjusted.subtitle')}
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.supplierAdjusted.label')}
                    subHeadline={translate('statusbar.supplier.supplierAdjusted.subtitle')}
                />
            );
        }
        case OfferState.ACKNOWLEDGED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.acknowledged.label')}
                    subHeadline={translate('statusbar.supplier.acknowledged.subtitle')}
                    trailingVisual={
                        <Button
                            emphasis="high"
                            onClick={() => {
                                changeState({
                                    offer,
                                    destinationState: OfferState.CONFIRMED,
                                    component: ConfirmOrderPopup,
                                });
                            }}
                        >
                            {translate('statusbar.supplier.acknowledged.button')}
                        </Button>
                    }
                />
            );
        }
        case OfferState.DELIVERED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.supplier.delivered.label')}
                    subHeadline={translate('statusbar.supplier.delivered.subtitle')}
                />
            );
        }
        case OfferState.DELIVERY_CONFIRMED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.supplier.deliveryconfirmed.label')}
                    subHeadline={translate('statusbar.supplier.deliveryconfirmed.subtitle')}
                />
            );
        }
        case OfferState.INVOICED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.supplier.invoiced.label')}
                    subHeadline={translate('statusbar.supplier.invoiced.subtitle')}
                />
            );
        }
        case OfferState.CANCELED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.canceled.label')}
                    subHeadline={translate('statusbar.supplier.canceled.subtitle')}
                />
            );
        }
        case OfferState.QUOTE_CANCELED: {
            if (!offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.supplier.enquirycanceled.label')}
                        subHeadline={translate('statusbar.supplier.enquirycanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.spotqoutedeclined.label')}
                    subHeadline={translate('statusbar.supplier.spotqoutedeclined.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_CANCELED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.supplier.enquirycanceled.label')}
                        subHeadline={translate('statusbar.supplier.enquirycanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.enquirycanceled.label')}
                    subHeadline={translate('statusbar.supplier.enquirycanceled.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.enquirydeclined.label')}
                    subHeadline={translate('statusbar.supplier.enquirydeclined.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_EXPIRED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.supplier.spotenquirycanceled.label')}
                        subHeadline={translate('statusbar.supplier.spotenquirycanceled.subtitle')}
                    />
                );
            }

            return null;
        }
        case OfferState.QUOTE_DECLINED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.supplier.spotqoutedeclined.label')}
                        subHeadline={translate('statusbar.supplier.spotqoutedeclined.subtitle')}
                    />
                );
            }
        }
    }
    return null;
};
