import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { InfoPopover } from '../../common/InfoPopover/InfoPopover';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { CustomerFilterActions } from './components/CustomerFilterActions';
import { CustomersOverviewTable } from './components/CustomersOverviewTable';

export const CustomersOverviewPage = () => {
    useDocumentTitle(translate('page.customers'));

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center" gap={4}>
                        <Text variant="headline" as="h1">
                            {translate('assignments.customers.subheadline')}
                        </Text>

                        <InfoPopover width="250px" side="right">
                            <Text
                                variant="extraSmall"
                                dangerouslySetInnerHTML={{
                                    __html: translate('assignments.customers.infoPopover'),
                                }}
                            ></Text>
                        </InfoPopover>
                    </Box>
                </Box>

                <Box display="flex">
                    <CustomerFilterActions />
                </Box>

                <CustomersOverviewTable />
            </PageGrid>
        </Page>
    );
};
