import { CustomerGroupSelect } from '../../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';
import { Route } from '../../../routes/_app.fuel.contracts';

export const ContractOverviewTableControls = () => {
    const role = useRole();

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="250px">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, searchQuery, page: 0 }) });
                    }}
                    value={search.searchQuery}
                    placeholder={translate('contracts.searchInputPlaceholder')}
                />
            </Box>

            {role.isAdmin() ? (
                <Box minWidth="250px" maxWidth="80%">
                    <CustomerGroupSelect
                        onChange={(groupIds) => {
                            navigate({
                                search: (previousSearch) => ({
                                    ...previousSearch,
                                    customerGroupIds: groupIds.map((groupId) => groupId),
                                    page: 0,
                                }),
                            });
                        }}
                        placeholder={translate('customerGroupSelect.filterByCustomerGroups')}
                        value={search.customerGroupIds}
                        isMulti
                        dropdownWidth="520px"
                    />
                </Box>
            ) : null}
        </Box>
    );
};
