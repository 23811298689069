import { Tag } from '../../cdl/Tag/Tag';
import { Text } from '../../cdl/Text/Text';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { HeaderWithLine } from '../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { Route as LubesRoute } from '../../routes/_app._lubes.agents.$id';
import { Route as FuelRoute } from '../../routes/_app.fuel.agents.$id';
import { ArchiveRestoreButton } from '../components/ArchiveRestoreButton';

import { AgentContactDataSheet } from './components/AgentContactDataSheet';
import { AgentDetailsDataSheet } from './components/AgentDetailsDataSheet';
import { EditAgentButton } from './components/EditAgentButton';
import { useAgentDetails } from './hooks/useAgentDetails';

export const AgentDetailPage = () => {
    const { isFuel } = useProductContext();
    const Route = isFuel ? FuelRoute : LubesRoute;

    const { id } = Route.useParams();
    const role = useRole();
    const agentQuery = useAgentDetails(id);

    const agent = agentQuery.data;

    useDocumentTitle(translate('page.agentDetails', { name: agent?.name || '' }));

    if (agentQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (agentQuery.isError || !agent) {
        return <CenteredPageError />;
    }

    const contactAvailable =
        agent.contact?.name || agent.contact?.phoneNumber || agent.contact?.emailAddress || agent.contact?.faxNumber;

    return (
        <Page>
            <Box display="flex" flexDirection="column" gap={9}>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                    <Box display="flex" flexDirection="row" gap={5} alignItems="center">
                        <Text variant="headline" as="h1">
                            {agent.name}
                        </Text>
                        {!agent.active ? <Tag variant="default">{translate('agents.detail.archivedTag')}</Tag> : null}
                        {agent.source ? <Tag variant="default">{translate('agents.detail.importedTag')}</Tag> : null}
                    </Box>
                    {role.hasWriteRights() && !agent.source ? (
                        <Box display="flex" gap={4}>
                            <ArchiveRestoreButton agent={agent} />
                            <EditAgentButton agent={agent} />
                        </Box>
                    ) : null}
                </Box>

                <Box display="grid" gap={9} gridTemplateColumns={'1fr' + (contactAvailable ? ' 1fr' : '')}>
                    <Box display="flex" flexDirection="column" gap={5}>
                        <HeaderWithLine>
                            <Text variant="title" as="h2">
                                {translate('agents.detail.sectionHeadline')}
                            </Text>
                        </HeaderWithLine>
                        <AgentDetailsDataSheet agent={agent} />
                    </Box>
                    {agent.contact && contactAvailable ? (
                        <Box display="flex" flexDirection="column" gap={5}>
                            <HeaderWithLine>
                                <Text variant="title" as="h2">
                                    {translate('agents.detail.contact.headline')}
                                </Text>
                            </HeaderWithLine>
                            <AgentContactDataSheet contact={agent.contact} />
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Page>
    );
};
