import { useNavigate } from '@tanstack/react-router';

import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { IconFileInvoice } from '../../../common/icons/cdl/FileInvoice';
import { Box } from '../../../common/ui/Box';

interface ContractRecommendationProps {
    portName: string;
}

export const ContractRecommendation = (props: ContractRecommendationProps) => {
    const navigate = useNavigate();

    const onContractTenderClick = () => {
        navigate({ to: '/fuel/contracts' });
    };

    return (
        <Box
            display="grid"
            gap={5}
            gridTemplateColumns="auto 1fr auto"
            alignItems="center"
            padding="20px"
            backgroundColor="accent.background"
            borderColor="accent.border"
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="4px"
            boxShadow="large"
        >
            <Box display="flex" gap={4}>
                <IconFileInvoice height={24} width={24} />
                <Text variant="subtitle1">{translate('demand.contractRecommendation.title')}</Text>
            </Box>

            <Text
                variant="small"
                color="foreground.muted"
                textAlign="center"
                dangerouslySetInnerHTML={{
                    __html: translate('demand.contractRecommendation.description', { portName: props.portName }),
                }}
            />

            <Button emphasis="high" size="medium" onClick={onContractTenderClick}>
                {translate('demand.contractRecommendation.button')}
            </Button>
        </Box>
    );
};
