import type { LDFlagSet } from 'launchdarkly-js-sdk-common';
import storage from 'local-storage-fallback';

export const getManualOverrides = (): LDFlagSet => {
    try {
        const overrides = JSON.parse(storage.getItem('override-flags') || '{}');
        return Object.keys(overrides).reduce((acc, key) => {
            if (overrides[key] === null || overrides[key] === undefined) {
                return acc;
            }

            acc[key] = overrides[key];
            return acc;
        }, {} as LDFlagSet);
    } catch {
        return {};
    }
};
