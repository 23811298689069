import { useLocation, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { useToasts } from '../../cdl/Toast/useToasts';
import { TextButton } from '../../common/buttons/TextButton';
import { translate } from '../../common/helpers/translate.helper';
import { Item } from '../../common/models/item.model';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { CreateNewOrderFromContractPopup } from '../detail/fuel/CreateNewOrderFromContractPopup';
import { useOrderCancel } from '../detail/fuel/useOrderCancel';

import { ContractProductBoxContent } from './ContractProductBoxContent';

function updateOrderWithContractProduct(order: any, contractProduct: any) {
    return {
        ...order,
        contractId: contractProduct.contract.id,
        contract: contractProduct.contract,
        items: order.items
            .filter(
                (item: any) =>
                    item.product.group === contractProduct.product.group &&
                    item.product.sulphurContent === contractProduct.product.sulphurContent
            )
            .map(
                (item: any) =>
                    new Item({
                        ...item,
                        product: contractProduct.product,
                        productId: contractProduct.product.id,
                    })
            ),
    };
}

interface ContractProductOrderCardProps {
    product: any;
    order: any;
    showCreateOrderButton: boolean;
    hasPlattsCredentials: boolean;
}

export const ContractProductOrderCard = ({
    product,
    order,
    showCreateOrderButton,
    hasPlattsCredentials,
}: ContractProductOrderCardProps) => {
    const [showOrderPopup, setShowOrderPopup] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { addErrorToast } = useToasts();

    const cancelOrderMutation = useOrderCancel(order);

    const handleCreateNewEnquiry = async ({ cancelExistingOrder }: { cancelExistingOrder: boolean }) => {
        try {
            if (cancelExistingOrder) {
                await cancelOrderMutation.cancelOrder(null);
            }

            navigate({
                to: '/fuel/create',
                state: {
                    order: updateOrderWithContractProduct(order, product),
                    originLocation: location,
                },
            });
        } catch {
            addErrorToast(translate('fuelOrder.toast.cancelOrder.error'));
        } finally {
            setShowOrderPopup(false);
        }
    };

    return (
        <Box width="260px" variant="shadow" marginRight="16px" marginBottom="16px" backgroundColor="white">
            <ContractProductBoxContent
                contractProduct={product}
                additionalFooterContent={
                    showCreateOrderButton ? (
                        <>
                            <Box borderTop={1} width="100%" />
                            <Flex justifyContent="center" padding={5}>
                                <TextButton style={{ fontSize: '16px' }} onClick={() => setShowOrderPopup(true)}>
                                    {translate('fuelOrder.offerList.createEnquiryFromContract.orderNow')}
                                </TextButton>
                            </Flex>
                        </>
                    ) : null
                }
                hasPlattsCredentials={hasPlattsCredentials}
                customerId={order.customerId}
            />
            {showOrderPopup ? (
                <CreateNewOrderFromContractPopup
                    order={order}
                    isOpen={showOrderPopup}
                    isSubmitting={cancelOrderMutation.isPending}
                    onCreateNewEnquiry={handleCreateNewEnquiry}
                    onDismiss={() => setShowOrderPopup(false)}
                />
            ) : null}
        </Box>
    );
};
