import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';
import { useOfferDetails } from '../../../offer/detail/common/hooks/useOfferDetails';

export const useOfferOrder = (order) => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();
    const navigate = useNavigate();
    const { prefetch } = useOfferDetails();

    const updateOrderMutation = useMutation({
        mutationFn: updateOrder,
        onSuccess: (updatedOrder) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.order(updatedOrder.id) });

            return prefetch(updatedOrder.chosenOfferId);
        },
    });

    const orderOffer = async (offer) => {
        const newOrder = {
            ...order,
            state: 'CLOSED',
            chosenOfferId: offer.id,
        };

        return updateOrderMutation.mutateAsync(
            {
                orderId: order.id,
                order: newOrder,
            },
            {
                onSuccess: (updatedOrder) => {
                    setTimeout(() => {
                        navigate({ to: '/fuel/offer/$id', params: { id: updatedOrder.chosenOfferId } });
                    });
                },
                onError: () => {
                    addErrorToast(translate('fuelOrder.toast.orderOffer.error'));
                },
            }
        );
    };

    return { orderOffer, isPending: updateOrderMutation.isPending };
};
