import { Switch } from '../../cdl/Switch/Switch';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { checkPreference, flag } from '../../flags';

interface ToggleProps {
    name: string;
    onSelect: (name: string, checked: boolean) => void;
    label: string;
}

const Toggle = ({ name, onSelect, label }: ToggleProps) => {
    const checked = checkPreference(name);
    const onChange = (checked: boolean) => onSelect(name, checked);

    return <Switch checked={checked} label={label} onChange={onChange} />;
};

export const DevFlags = () => {
    const updateToggle = (key: string, checked: boolean) => flag(key, checked);

    return (
        <Box display="flex" flexDirection="column" rowGap={3}>
            <Text variant="subtitle1" as="h3" marginBottom={4}>
                {translate('devTools.devFlags.headline')}
            </Text>
            <Box display="flex" flexDirection="column" rowGap={2}>
                <Toggle name="FormikDebugTools" label="Formik debug tools" onSelect={updateToggle} />
                <Toggle name="PreferredNumberOfPoints" label="PreferredNumberOfPoints" onSelect={updateToggle} />
            </Box>
        </Box>
    );
};
