import { DemandInformationCategory } from '../../../types/DemandInformationMessage';
import { CategoryState } from '../../../types/ReachInformationMessage';
import { Tag } from '../../cdl/Tag/Tag';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

interface DemandInformationCategoryWithDaysLeft extends DemandInformationCategory {
    daysLeft: number;
}

interface DaysLeftSailingProps {
    categories: DemandInformationCategory[];
}

export const DemandVesselDaysLeftSailing = ({ categories }: DaysLeftSailingProps) => {
    const allDaysLeft: number[] = categories
        .filter((category): category is DemandInformationCategoryWithDaysLeft => category.daysLeft !== undefined)
        .map((category) => category.daysLeft);

    if (!categories.length || !allDaysLeft.length) {
        return <div />;
    }

    if (categories.some((category) => category.categoryState === CategoryState.OUT_OF_REACH)) {
        return (
            <Box>
                <Tag variant="negative" emphasis>
                    {translate('demand.outOfReach')}
                </Tag>
            </Box>
        );
    }

    const leastDaysLeft = Math.min(...allDaysLeft);

    const daysLeftString =
        leastDaysLeft === 1
            ? translate('demand.dayLeft')
            : translate('demand.daysLeft', {
                  days: leastDaysLeft.toString(),
              });

    if (categories.some((category) => category.categoryState === CategoryState.SAFETY_RESERVE)) {
        return (
            <Tooltip label={daysLeftString} showArrow>
                <span>
                    <Tag variant="attention">{translate('demand.onSafetyReserve')}</Tag>
                </span>
            </Tooltip>
        );
    }

    return <Text variant="fieldLabel">{daysLeftString}</Text>;
};
