import moment from 'moment-timezone';

import { EnergyContentUnit } from '../../../types/EnergyContentMessage';
import { FuelCreateOrderRequest } from '../../common/api/clients/order.api';
import { OrderState } from '../model/OrderModel';

const valueOrNull = (value: any) => {
    return value ? value : null;
};

export const mapToFuelOrderRequestObject = (values: any, state: OrderState): FuelCreateOrderRequest => {
    return {
        type: 'FUEL',
        state: state,
        customerId: values.customerId,
        vesselId: values.vesselId,
        portId: values.portId,
        contractId: valueOrNull(values.contractId),
        eta:
            valueOrNull(values.eta) && moment.tz(moment.utc(values.eta).toArray(), values.timeZoneId).toISOString(true),
        etd:
            valueOrNull(values.etd) && moment.tz(moment.utc(values.etd).toArray(), values.timeZoneId).toISOString(true),
        agentId: values.agentId,
        testOrder: values.testOrder,
        buyerReference: valueOrNull(values.buyerReference),
        comment: valueOrNull(values.comment),
        validityTime: valueOrNull(values.validityTime),
        items: values.items
            .filter((item: any) => !!item.productId)
            .map((item: any) => {
                return {
                    ...item,
                    units: valueOrNull(item.units),
                    isoSpec: valueOrNull(item.isoSpec),
                    energyContent: item.energyContent?.value
                        ? { value: Number(item.energyContent.value), unit: EnergyContentUnit.MJKG }
                        : null,
                };
            }),
        assignedSupplierIds: values.assignedSupplierIds ? values.assignedSupplierIds : null,
        receiverSupplierIds: values.receiverSupplierIds ? values.receiverSupplierIds : null,
        spot: values.spot ?? null,
        fileIds: values.files?.map((file: any) => file.id) ?? [],
        chosenOfferId: null,
        samplekits: null,
    };
};
