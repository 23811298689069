import { jwtDecode, JwtPayload } from 'jwt-decode';

export class JWTHelper {
    static decode(token: string): JwtPayload {
        if (!token) {
            throw new Error('Token is empty');
        }

        return jwtDecode(token);
    }

    static hasTokenExpired(token: string): boolean {
        const payload = this.decode(token);

        if (Object.hasOwn(payload, 'exp')) {
            const currentTime = new Date().getTime();
            // There is no type narrowing yet for Object.hasOwn, so we have to use the non-null assertion operator
            // https://github.com/microsoft/TypeScript/issues/44253
            const expTime = new Date(payload.exp! * 1000).getTime();
            return currentTime > expTime;
        }

        return false;
    }
}
