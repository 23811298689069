import { ReactNode, useMemo } from 'react';
import { components, ControlProps, default as ReactSelect, Props as ReactSelectProps } from 'react-select';

import { Caption, CaptionProps } from '../Caption/Caption';

import { CustomClearIndicator } from './components/CustomClearIndicator';
import { CustomControl } from './components/CustomControl';
import { CustomDropdownIndicator } from './components/CustomDropdownIndicator';
import { CustomMenu } from './components/CustomMenu';
import { CustomMenuList } from './components/CustomMenuList';
import { CustomOption } from './components/CustomOption';
import { CustomPlaceholder } from './components/CustomPlaceholder';
import { CustomSingleValue } from './components/CustomSingleValue';
import { CustomValueContainer } from './components/CustomValueContainer';

export interface SelectProps<Option>
    extends Pick<ReactSelectProps<Option, false>, 'value' | 'onChange' | 'options' | 'onBlur' | 'isClearable'> {
    label?: string;
    placeholder?: string;
    caption?: ReactNode;
    CaptionIcon?: CaptionProps['Icon'];
    positive?: boolean;
    negative?: boolean;
    markAsRequired?: boolean;
    disabled?: boolean;
    overrides?: ReactSelectProps<Option, false>['components'];
    onInputChange?: ReactSelectProps<Option, false>['onInputChange'];
    filterOption?: ReactSelectProps<Option, false>['filterOption'];
}

export function Select<Option = unknown>({
    label,
    caption,
    CaptionIcon,
    placeholder = '',
    positive = false,
    negative = false,
    markAsRequired = false,
    disabled = false,
    ...rest
}: SelectProps<Option>) {
    const CustomControlMemoized = useMemo(() => {
        return function CustomControlMemoizedFn({ children, ...rest }: ControlProps<Option>) {
            return (
                <CustomControl
                    {...rest}
                    label={label}
                    $positive={positive}
                    $negative={negative}
                    markAsRequired={markAsRequired}
                >
                    {children}
                </CustomControl>
            );
        };
    }, [label, positive, negative, markAsRequired]);

    return (
        <div>
            <ReactSelect<Option, false>
                isDisabled={disabled}
                placeholder={placeholder}
                options={rest.options}
                isSearchable={false}
                isMulti={false}
                value={rest.value}
                onChange={rest.onChange}
                onInputChange={rest.onInputChange}
                isClearable={rest.isClearable}
                components={{
                    ...components,
                    Option: CustomOption as any,
                    Menu: CustomMenu as any,
                    MenuList: CustomMenuList as any,
                    Control: CustomControlMemoized as any,
                    SingleValue: CustomSingleValue as any,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CustomDropdownIndicator as any,
                    Placeholder: CustomPlaceholder as any,
                    ValueContainer: CustomValueContainer as any,
                    ClearIndicator: CustomClearIndicator as any,
                    ...rest.overrides,
                }}
                onBlur={rest.onBlur}
            />
            {caption ? (
                <Caption $negative={negative} Icon={CaptionIcon}>
                    {caption}
                </Caption>
            ) : null}
        </div>
    );
}
