import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { vesselsSearchSchema } from '../../types/vesselsSearchSchema';
import { LubesVesselOverviewPage } from '../vessel/VesselOverview/LubesVesselOverviewPage';

export const Route = createFileRoute('/_app/vessels')({
    component: LubesVesselOverviewPage,
    validateSearch: zodSearchValidator(vesselsSearchSchema),
});
