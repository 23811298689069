import { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { getOrder, getOrders } from '../../common/api/clients/gateway.api';
import { OrderModel } from '../../order/model/OrderModel';

import { IntegrationOrderOptionDoubleLineOption } from './IntegrationOrderOptionDoubleLineOption';

export interface IntegrationOrderSelectOption {
    label: string;
    value: string;
    order: OrderModel;
}

interface IntegrationOrderSelectProps extends Omit<ComboBoxProps<DefaultOption, false>, 'onChange' | 'value'> {
    value: string | undefined;
    onChange: (option: IntegrationOrderSelectOption) => void;
    states?: string[] | string;
}

export function IntegrationOrderSelect({ value, onChange, label, states }: IntegrationOrderSelectProps) {
    const [selectedValue, setSelectedValue] = useState<IntegrationOrderSelectOption | null>(null);

    const loadOrdersBySearchQuery = async (searchQuery: string) => {
        const response = await getOrders({ searchQuery, states });
        return response.content.map((order) => {
            return {
                label: order.buyerReference || order.orderNumber,
                value: order.id,
                order,
            };
        });
    };

    const loadOrderById = useCallback(async (orderId: string): Promise<IntegrationOrderSelectOption> => {
        const order = await getOrder(orderId);
        return {
            label: order.buyerReference || order.orderNumber,
            value: order.id,
            order,
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (value) {
            loadOrderById(value).then((option: IntegrationOrderSelectOption) => {
                if (isMounted) {
                    setSelectedValue(option);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [value, loadOrderById]);

    const updateSelectedValue = (option: OnChangeValue<IntegrationOrderSelectOption, false>) => {
        onChange(option as IntegrationOrderSelectOption);
    };

    return (
        <ComboBox<IntegrationOrderSelectOption, false>
            label={label}
            onChange={updateSelectedValue}
            value={selectedValue}
            dropdownWidth="600px"
            loadOptions={loadOrdersBySearchQuery}
            overrides={{ Option: IntegrationOrderOptionDoubleLineOption as any }}
        />
    );
}
