import { useFormikContext } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';

interface FormikSpotModalProps {
    onSubmit: (values: FormikCreateEnquiryValues) => void;
    isOpen: boolean;
    onDismiss: () => void;
}

export const FormikSpotModal = ({ onSubmit, isOpen, onDismiss }: FormikSpotModalProps) => {
    const { values, isSubmitting } = useFormikContext<FormikCreateEnquiryValues>();
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} dismissible={false} scrollable={false} size="small">
            <Box padding={6} display="flex" flexDirection="column" gap={5}>
                <Text variant="title">{translate('alert.orderconfirmcreate.title')}</Text>

                <Text variant="small" color="foreground.muted">
                    {values.spot && values.receiverSupplierIds?.length === 0
                        ? translate('alert.orderconfirmcreate.excludemessage')
                        : translate('alert.orderconfirmcreate.includemessage')}
                </Text>

                <Text variant="small" color="foreground.muted">
                    {translate('alert.orderconfirmcreate.message')}
                </Text>

                <Box display="flex" justifyContent="flex-end" gap={3}>
                    <Button type="button" onClick={onDismiss}>
                        {translate('alert.orderconfirmcreate.btncancel')}
                    </Button>

                    <Button type="button" isLoading={isSubmitting} onClick={() => onSubmit(values)} emphasis="high">
                        {translate('alert.orderconfirmcreate.btnok')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
