import { useParams } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { UserType } from '../../../../types/UserType';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../../cdl/Text/Text';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { Box } from '../../../common/ui/Box';
import { ApiKeysTable } from '../../customer/detail/components/ApiKeysTable';
import { CreateApiKeyButton } from '../../customer/detail/components/CreateApiKeyButton';

import { CreateSupplierCompany } from './components/CreateSupplierCompany';
import { EditSupplierGroupForm } from './components/EditSupplierGroupForm';
import { LubesSurcharges } from './components/LubesSurcharges';
import { SpotPortAvailability } from './components/SpotPortAvailability';
import { SupplierGroupDataSheet } from './components/SupplierGroupDataSheet';
import { SupplierGroupLogoButton } from './components/SupplierGroupLogoButton';
import { SuppliersTable } from './components/SuppliersTable';
import { useSupplierGroupDetails } from './hooks/useSupplierGroupDetails';

export const SupplierGroupDetailPage = () => {
    const { id: groupId } = useParams({ from: '/_app/supplier-group/$id' });

    const role = useRole();
    const { push, pop } = useDrawer();

    const supplierGroupDetailsQuery = useSupplierGroupDetails(groupId);

    useDocumentTitle(translate('page.suppliergroupshow', { name: supplierGroupDetailsQuery.data?.name || '' }));

    if (supplierGroupDetailsQuery.isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (supplierGroupDetailsQuery.isError) {
        return <CenteredPageError />;
    }

    const supplierGroup = supplierGroupDetailsQuery.data;

    const handleSupplierGroupEditClick = () => {
        push({
            content: <EditSupplierGroupForm onSuccess={() => pop()} initialValues={supplierGroup} />,
        });
    };

    const handleCompanyCreateClick = () => {
        push({
            content: (
                <CreateSupplierCompany
                    onSuccess={() => pop()}
                    groupId={groupId}
                    isTestGroup={supplierGroup.testCompany}
                />
            ),
        });
    };

    return (
        <Page>
            <Box display="flex" flexDirection="column" gap={9}>
                <Box display="flex" flexDirection="column" gap={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text variant="headline" as="h1">
                            {supplierGroup.name}
                        </Text>
                        {role.hasAdminRights() ? (
                            <Box display="flex" gap={4}>
                                <SupplierGroupLogoButton id={groupId} logo={supplierGroup.logo} />
                                <Button emphasis="high" onClick={handleSupplierGroupEditClick}>
                                    {translate('suppliergroup.edit')}
                                </Button>
                            </Box>
                        ) : null}
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={5}>
                    <HeaderWithLine>
                        <Text variant="title" as="h2">
                            {translate('suppliers.detailsSectionHeadline')}
                        </Text>
                    </HeaderWithLine>
                    <Box display="flex" flexDirection="row" alignItems="start">
                        <SupplierGroupDataSheet supplierGroup={supplierGroup} />
                        <Box width="300px">
                            {supplierGroup.logo ? (
                                <img style={{ width: '300px' }} alt="" src={supplierGroup.logo?.url} />
                            ) : null}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={5}>
                    <HeaderWithLine>
                        <Text variant="title" as="h2">
                            {translate('suppliers.subheadline')}
                        </Text>

                        {role.hasAdminRights() ? (
                            <Button onClick={handleCompanyCreateClick}>{translate('suppliers.create')}</Button>
                        ) : null}
                    </HeaderWithLine>
                    <SuppliersTable groupId={groupId} />
                </Box>

                {supplierGroup.lubesSettings.spotAllowed && role.hasAdminRights() ? (
                    <Box display="flex" flexDirection="column" gap={5}>
                        <HeaderWithLine>
                            <Text variant="title" as="h2">
                                {translate('company.lubesSpotPortAvailability')}
                            </Text>
                        </HeaderWithLine>
                        <SpotPortAvailability
                            groupId={groupId}
                            spotSettings={supplierGroup.lubesSettings}
                            type={ProductContext.LUBES}
                        />
                    </Box>
                ) : null}

                {role.isAdmin() ? <LubesSurcharges groupId={groupId} /> : null}

                {supplierGroup.fuelSettings.spotAllowed && role.hasAdminRights() ? (
                    <Box display="flex" flexDirection="column" gap={5}>
                        <HeaderWithLine>
                            <Text variant="title" as="h2">
                                {translate('company.fuelSpotPortAvailability')}
                            </Text>
                        </HeaderWithLine>
                        <SpotPortAvailability
                            groupId={groupId}
                            spotSettings={supplierGroup.fuelSettings}
                            type={ProductContext.FUEL}
                        />
                    </Box>
                ) : null}

                {role.isAdmin() ? (
                    <Box display="flex" flexDirection="column" gap={5}>
                        <HeaderWithLine>
                            <Text variant="title" as="h2">
                                {translate('apikey.title')}
                            </Text>

                            <CreateApiKeyButton groupId={groupId} userType={UserType.SUPPLIER} />
                        </HeaderWithLine>

                        <ApiKeysTable groupId={groupId} userType={UserType.SUPPLIER} />
                    </Box>
                ) : null}
            </Box>
        </Page>
    );
};
