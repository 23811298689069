import React from 'react';

import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { FormikSwitch } from '../../../../cdl/Switch/FormikSwitch';
import { IconX } from '../../../icons/cdl/X';
import { ItemModel } from '../../../models/ItemModel';

interface ProductRowActionProps {
    name: string;
    item: ItemModel;
    isDeleteable: boolean;
    onRowRemoveClicked: () => void;
    isLastRemainingEntry: boolean;
    isLastSelectedEntry: boolean;
}

export const ProductRowAction = ({
    name,
    item,
    isDeleteable,
    onRowRemoveClicked,
    isLastRemainingEntry,
    isLastSelectedEntry,
}: ProductRowActionProps) => {
    if (isLastRemainingEntry && !item.productId && !item.units) {
        return null;
    }

    if (item.new || isDeleteable) {
        return <IconButton type="button" onClick={onRowRemoveClicked} Icon={IconX} />;
    }

    if (!item.new && isLastRemainingEntry) {
        return null;
    }

    return <FormikSwitch disabled={isLastSelectedEntry} invert name={`${name}.deleted`} />;
};
