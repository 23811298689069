import { calculatePpl } from '../../common/helpers/calculate.utilities';
import { calculateTotal } from '../../common/helpers/calculate.utilities';
import { calculateVolume } from '../../common/helpers/calculate.utilities';
import { calculateVolumeWithPrices } from '../../common/helpers/calculate.utilities';
import { Item } from '../../common/models/item.model';
import { Samplekit } from '../../common/models/samplekit.model';
import { Surcharge } from '../../common/models/surcharge.model';

function Offer(offer) {
    const { samplekits, items, surcharges, ...rest } = offer;

    Object.assign(this, rest);

    this.items = items.map((item) => new Item(item));
    this.samplekits = samplekits.map((samplekit) => new Samplekit(samplekit));
    this.surcharges = surcharges.map((surcharge) => new Surcharge(surcharge));
}

Offer.prototype.addItem = function (item) {
    if (!this.items) this.items = [];
    if (!item || this.items.indexOf(item) === this.items.length - 1) {
        this.items.push(new Item());
    }
};

Offer.prototype.removeItem = function (item) {
    if (this.items.length > 1) {
        this.items.splice(this.items.indexOf(item), 1);
        this.compute();
    }
};

Offer.prototype.addSurcharge = function (surcharge) {
    if (!this.surcharges) this.surcharges = [];
    if (!surcharge) surcharge = new Surcharge();
    this.surcharges.push(surcharge);
};

Offer.prototype.addSamplekit = function (samplekit) {
    if (!this.samplekits) this.samplekits = [];
    if (!samplekit) samplekit = new Samplekit();
    this.samplekits.push(samplekit);
};

Offer.prototype.removeSamplekit = function (samplekit) {
    this.samplekits.splice(this.samplekits.indexOf(samplekit), 1);
    this.compute();
};

Offer.prototype.removeSurcharge = function (surcharge) {
    this.surcharges.splice(this.surcharges.indexOf(surcharge), 1);
    this.compute();
};

Offer.prototype.compute = function () {
    this.computeItems();

    this.total = calculateTotal(this.items, this.surcharges, this.samplekits);
    this.totalWithoutSamplekits = calculateTotal(this.items, this.surcharges, undefined);
    this.volume = calculateVolume(this.items);
    this.volumeWithPrices = calculateVolumeWithPrices(this.items);
    this.ppl = calculatePpl(this.totalWithoutSamplekits, this.volumeWithPrices);
};

Offer.prototype.computeItems = function () {
    this.items.forEach((item) => item.compute());
};

Offer.prototype.hasValidCoreData = function () {
    return this.portId && this.dateDelivery && this.vesselId;
};

Offer.prototype.hasValidCoreDataSupplierQuote = function () {
    let complete =
        this.portId && this.dateDelivery && this.vesselId && this.supplyMode && this.noticeDays !== undefined;

    if (this.isSpot()) {
        complete =
            complete &&
            this.paymentTermReference &&
            (this.paymentTermReferenceDays || this.paymentTermReference === 'PREPAYMENT');
    }

    return complete;
};

Offer.prototype.hasValidCoreDataSupplier = function () {
    let complete = this.portId && this.dateDelivery && this.vesselId && this.supplyMode;

    if (this.isSpot()) {
        complete =
            complete &&
            this.paymentTermReference &&
            (this.paymentTermReferenceDays || this.paymentTermReference === 'PREPAYMENT');
    }

    return complete;
};

Offer.prototype.hasValidProducts = function (validatePrices = true) {
    let complete = true;

    this.items.forEach((item) => {
        const isItemComplete = validatePrices ? item.isCompleteWithPrices() : item.isComplete();
        complete = complete && (isItemComplete || item.deleted);
    });

    this.surcharges.forEach((surcharge) => {
        complete = complete && surcharge.isCompleteWithPrices();
    });

    this.samplekits.forEach((samplekit) => {
        complete = complete && (samplekit.isCompleteWithPrices() || samplekit.deleted);
    });

    return complete && this.items.some((item) => !item.deleted);
};

Offer.prototype.hasValidCustomerProducts = function () {
    let complete = true;

    this.items.forEach((item) => {
        complete = complete && (item.isComplete() || item.deleted);
    });

    this.surcharges.forEach((surcharge) => {
        complete = complete && surcharge.isCompleteWithPrices();
    });

    this.samplekits.forEach((samplekit) => {
        complete = complete && (samplekit.isComplete() || samplekit.deleted);
    });

    return complete && this.items.some((item) => !item.deleted);
};

Offer.prototype.isSupplierComplete = function () {
    return this.hasValidCoreDataSupplier() && this.hasValidProducts();
};

Offer.prototype.isCustomerComplete = function () {
    return this.hasValidCoreData() && this.hasValidCustomerProducts();
};

Offer.prototype.isFinished = function () {
    return (
        this.state === 'DELIVERY_CONFIRMED' ||
        this.state === 'DELIVERED' ||
        this.state === 'INVOICED' ||
        this.state === 'CANCELED' ||
        this.state === 'ENQUIRY_CANCELED' ||
        this.state === 'ENQUIRY_DECLINED' ||
        this.state === 'QUOTE_DECLINED' ||
        this.state === 'QUOTE_CANCELED' ||
        this.state === 'ENQUIRY_EXPIRED'
    );
};

Offer.prototype.isCanceled = function () {
    return (
        this.state === 'CANCELED' ||
        this.state === 'ENQUIRY_CANCELED' ||
        this.state === 'ENQUIRY_DECLINED' ||
        this.state === 'QUOTE_DECLINED' ||
        this.state === 'QUOTE_CANCELED' ||
        this.state === 'ENQUIRY_EXPIRED'
    );
};

Offer.prototype.deliveryPast = function () {
    return new Date(this.dateDelivery) < new Date();
};

Offer.prototype.isSpot = function () {
    return this.enquiryType === 'SPOT';
};

Offer.prototype.isAssigned = function () {
    return this.enquiryType === 'ASSIGNED';
};

Offer.prototype.isForwarded = function () {
    return this.enquiryType === 'FORWARDED';
};

Offer.prototype.isAnonymous = function () {
    return this.enquiryType === 'ANONYMOUS';
};

Offer.prototype.displayQuoteDetails = function () {
    return this.isAssigned() || this.isForwarded() || this.isAnonymous() || this.isOrder() || this.isQuotedEnquiry();
};

Offer.prototype.isOrder = function () {
    return (
        this.state === 'ORDER' ||
        this.state === 'CUSTOMER_ADJUSTED' ||
        this.state === 'SUPPLIER_ADJUSTED' ||
        this.state === 'ADJUSTED' ||
        this.state === 'CONFIRMED' ||
        this.state === 'ACKNOWLEDGED' ||
        this.state === 'CANCELED' ||
        this.state === 'DELIVERED' ||
        this.state === 'DELIVERY_CONFIRMED' ||
        this.state === 'INVOICED'
    );
};

Offer.prototype.isQuotedEnquiry = function () {
    return this.state === 'QUOTED' || this.state === 'QUOTE_DECLINED' || this.state === 'QUOTE_CANCELED';
};

Offer.prototype.isEnquiry = function () {
    return this.state === 'QUOTED' || this.state === 'ENQUIRY';
};

Offer.prototype.isOrderable = function () {
    return (
        this.state === 'QUOTED' ||
        ((this.isAssigned() || this.isForwarded() || this.isAnonymous()) && this.state === 'ENQUIRY')
    );
};

Offer.prototype.isEditable = function () {
    const excludedStates = [
        'ENQUIRY',
        'INVOICED',
        'CANCELED',
        'QUOTE_DECLINED',
        'QUOTE_CANCELED',
        'ENQUIRY_DECLINED',
        'ENQUIRY_EXPIRED',
        'ENQUIRY_CANCELED',
    ];

    return !excludedStates.some((state) => this.state === state);
};

Offer.prototype.isSpotEnquiry = function () {
    return this.isSpot() && (this.state === 'QUOTED' || this.state === 'ENQUIRY');
};

Offer.prototype.isUnquotedAssignedOrForwarded = function () {
    return (
        (this.isAssigned() || this.isForwarded() || this.isAnonymous()) && !(this.isOrder() || this.isQuotedEnquiry())
    );
};

Offer.prototype.isUnquoted = function () {
    return !(this.isOrder() || this.isQuotedEnquiry());
};

Offer.prototype.wasQuoted = function () {
    return !!this.dateQuoted;
};

Offer.prototype.isQuotedAssigned = function () {
    return (
        (this.isAssigned() || this.isForwarded() || this.isAnonymous()) &&
        (this.dateQuoted || this.isOrder() || this.isQuotedEnquiry())
    );
};

Offer.prototype.hasOpenCounterOffers = function () {
    return this.counterOffers?.length > 0 && this.counterOffers[0].state === 'OPEN';
};

Offer.prototype.getLatestCounterOffer = function () {
    if (this.counterOffers?.length > 0) {
        return this.counterOffers[0];
    }

    return null;
};

export { Offer };
