import { useLocation, useNavigate } from '@tanstack/react-router';

import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { useBunkerStopEnquiryCreation } from '../hooks/useBunkerStopEnquiryCreation';

export const BunkerPlanCreateEnquiryButton = ({ bunkerStopId }: { bunkerStopId: string }) => {
    const bunkerStopEnquiryCreation = useBunkerStopEnquiryCreation();
    const { addErrorToast } = useToasts();
    const navigate = useNavigate();
    const location = useLocation();

    const onCreateEnquiryClick = async (bunkerStopId: string) => {
        await bunkerStopEnquiryCreation.mutateAsync(
            { bunkerStopId },
            {
                onSuccess: (bunkerStopMessage) => {
                    navigate({
                        to: '/fuel/create',
                        search: { orderId: bunkerStopMessage.orderId },
                        state: { originLocation: location },
                    });
                },
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
    };

    return (
        <DiscoverableButton
            emphasis="high"
            onClick={(event) => {
                event.stopPropagation();
                onCreateEnquiryClick(bunkerStopId);
            }}
            isLoading={bunkerStopEnquiryCreation.isPending}
        >
            {translate('bunkerPlan.table.createEnquiryBtn')}
        </DiscoverableButton>
    );
};
