export const isValidEmailAddress = (emailAddress?: string) => {
    if (!emailAddress) {
        return false;
    }

    // https://stackoverflow.com/a/48800/3276759
    const emailAddressRegex = new RegExp('^\\S+@\\S+\\.\\S+$');

    return emailAddressRegex.test(emailAddress);
};
