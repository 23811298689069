import * as React from 'react';
import { useRef, useState } from 'react';

import { BaseInput } from '../../cdl/Input/components/BaseInput';
import { InputContainer } from '../../cdl/Input/components/InputContainer';
import { translate } from '../helpers/translate.helper';
import { useRole } from '../hooks/useRole';
import { IconSearch } from '../icons/cdl/Search';
import styled from 'styled-components';
import { get } from '../ui/get.js';
import { useNavigate } from '@tanstack/react-router';

const GlobalSearchInputContainer = styled(InputContainer)`
    background-color: ${({ isInputFocused }) => (isInputFocused ? get('colors.background.inset') : 'transparent')};
    transition: background-color 100ms ease;

    &:hover {
        background-color: ${get('colors.background.inset')};
    }
`;

const SimpleButton = styled.button`
    border: none;
    padding: 0;
    margin-left: 10px;
    color: ${get('colors.foreground.subtle')};
`;

const useRoleSpecificPlaceholder = () => {
    const role = useRole();

    const getTranslationKey = () => {
        if (role.isSupplier()) {
            return 'globalsearch.placeholder.supplier';
        }
        if (role.isCustomer()) {
            return 'globalsearch.placeholder.customer';
        }
        if (role.isAdmin()) {
            return 'globalsearch.placeholder.admin';
        }
    };

    const translationKey = getTranslationKey();

    return translationKey ? translate(translationKey) : '';
};

const getQueryFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('query') || '';
};

export const GlobalSearch = () => {
    const navigate = useNavigate();
    const role = useRole();
    const [query, setQuery] = useState(() => getQueryFromURL());
    const placeholder = useRoleSpecificPlaceholder();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const searchInputRef = useRef(null);
    const onSearchSubmit = (event) => {
        event.preventDefault();

        if (searchInputRef.current) {
            // focus the input as a convenience for the user
            searchInputRef.current.focus();
        }

        if (query) {
            navigate({ to: '/search', search: { query } });
        }
    };

    return (
        <form onSubmit={onSearchSubmit} width="100%" height="100%">
            <GlobalSearchInputContainer isInputFocused={isInputFocused}>
                <SimpleButton type="submit">
                    <IconSearch height={24} width={24} />
                </SimpleButton>

                <BaseInput
                    ref={searchInputRef}
                    $hasLeadingVisual
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    placeholder={placeholder}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                />
            </GlobalSearchInputContainer>
        </form>
    );
};
