import { Text } from '../../cdl/Text/Text';
import { DataSheet } from '../../common/DataSheet/DataSheet';
import { createdBy } from '../../common/helpers/createdBy.helper';
import { formatDateDelivery } from '../../common/helpers/formatDateDelivery.helper';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { usePaymentTermsFormat } from '../../common/hooks/usePaymentTermsFormat';
import { Box } from '../../common/ui/Box';
import { Port } from '../../port/Port';
import { OfferModel } from '../model/OfferModel';

interface CustomerApprovalDataSheetProps {
    offer: OfferModel;
}

export const CustomerApprovalDataSheet = ({ offer }: CustomerApprovalDataSheetProps) => {
    const formatPaymentTerms = usePaymentTermsFormat();
    const { getHumanReadableValue } = useEnums();

    const isFuel = offer.type === 'FUEL';
    const isLubes = offer.type === 'LUBES';

    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={9} alignItems="start">
            <DataSheet>
                <DataSheet.Label>{translate('offer.detail.coreData.vessel')}</DataSheet.Label>
                <Text>{formatVessel({ vessel: offer.vessel })}</Text>

                <DataSheet.Label>{translate('offer.detail.coreData.dateDelivery')}</DataSheet.Label>
                <Text>{formatDateDelivery({ date: offer.dateDelivery })}</Text>

                {isFuel && offer.eta ? (
                    <>
                        <DataSheet.Label>{translate('order.detail.coreData.eta')}</DataSheet.Label>
                        <Text>{formatDateTime({ date: offer.eta, timeZoneId: offer.port?.timeZoneId })}</Text>{' '}
                    </>
                ) : null}

                {isFuel && offer.etd ? (
                    <>
                        <DataSheet.Label>{translate('order.detail.coreData.etd')}</DataSheet.Label>
                        <Text>{formatDateTime({ date: offer.etd, timeZoneId: offer.port?.timeZoneId })}</Text>{' '}
                    </>
                ) : null}

                {offer.port ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.port')}</DataSheet.Label>
                        <Port port={offer.port} vesselId={offer.vesselId} showTooltip />
                    </>
                ) : null}

                {offer.agentInformation ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.agent')}</DataSheet.Label>
                        <Text>{offer.agentInformation.name}</Text>
                    </>
                ) : null}

                <DataSheet.Label>{translate('offer.detail.coreData.company')}</DataSheet.Label>
                <Text>{offer.customer.name}</Text>

                {offer.buyerReference ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.buyerReference')}</DataSheet.Label>
                        <Text>{offer.buyerReference}</Text>
                    </>
                ) : null}
            </DataSheet>

            <DataSheet>
                <DataSheet.Label>{translate('offer.detail.coreData.supplier')}</DataSheet.Label>
                <Text>{offer.supplier.name}</Text>

                {offer.supplyMode ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.supplyMode')}</DataSheet.Label>
                        <Text>{getHumanReadableValue('supplyMode', offer.supplyMode)}</Text>
                    </>
                ) : null}

                {isLubes && offer.noticeDays ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.noticeDays')}</DataSheet.Label>
                        <Text>{offer.noticeDays}</Text>
                    </>
                ) : null}

                <DataSheet.Label>{translate('offer.detail.coreData.dateCreated')}</DataSheet.Label>
                <Text>{createdBy({ order: offer })}</Text>

                {offer.paymentTermReference && (offer.isSpot() || isFuel) ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.paymentTermsLabel')}</DataSheet.Label>
                        <Text>{formatPaymentTerms(offer.paymentTermReference, offer.paymentTermReferenceDays)}</Text>
                    </>
                ) : null}
            </DataSheet>
        </Box>
    );
};
