import { useNavigate } from '@tanstack/react-router';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { useOrderEdit } from '../hooks/useOrderEdit';

import { OrderEditForm } from './OrderEditForm';

export const OrderEditFormikWrapper = (props) => {
    const orderEditMutation = useOrderEdit();
    const navigate = useNavigate();

    const validate = (values) => {
        const errors = {};

        if (!values.eta) {
            errors.eta = 'REQUIRED';
        }

        if (!values.etd) {
            errors.etd = 'REQUIRED';
        }

        if (values.items.every((item) => item.deleted) || values.items.some((item) => !item.isComplete())) {
            errors.invalidProducts = true;
        }

        return errors;
    };

    const onSubmit = async (values) => {
        const order = {
            ...values,
            eta: moment.tz(moment.utc(values.eta).toArray(), values.port.timeZoneId).toISOString(),
            etd: moment.tz(moment.utc(values.etd).toArray(), values.port.timeZoneId).toISOString(),
            buyerReference: values.buyerReference !== '' ? values.buyerReference : null,
        };

        await orderEditMutation.mutateAsync({
            orderId: values.id,
            order,
        });

        navigate({ to: '/fuel/order/$id', params: { id: values.id } });
    };

    return (
        <Formik
            initialValues={props.data}
            validateOnMount
            validate={validate}
            component={OrderEditForm}
            onSubmit={onSubmit}
        />
    );
};

OrderEditFormikWrapper.propTypes = {
    data: PropTypes.object,
};
