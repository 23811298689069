import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { postStocks } from '../../../common/api/clients/stock.api';
import { queries } from '../../../common/api/queryKeys/queries';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { VesselTankStocks } from '../../VesselTanks/VesselTankStocks';
import { EditTanksModal } from '../EditTanksModal/EditTanksModal';
import { InsertRemainingOnBoardPopup } from '../InsertRemainingOnBoardPopup/InsertRemainingOnBoardPopup';
import { VesselTanksPlaceholder } from '../components/VesselTanksPlaceholder';

export const RemainingOnBoard = (props) => {
    const queryClient = useQueryClient();
    const [remainingOnBoardPopupOpen, setRemainingOnBoardPopupOpen] = useState(false);
    const [editTanksPopupOpen, setEditTanksPopupOpen] = useState(false);
    const { addToast, addErrorToast } = useToasts();
    const role = useRole();
    const vesselId = props.vessel.id;

    const createStocks = async (stocks) => {
        try {
            await postStocks(stocks);

            addToast(translate('vessel.remainingOnBoard.successToast'));
        } catch {
            addErrorToast(translate('vessel.remainingOnBoard.errorToast'));
        }
    };

    const { mutateAsync: mutateStocks } = useMutation({
        mutationFn: createStocks,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vesselId)._ctx.tanks.queryKey });
        },
    });

    const {
        data: vesselTankResponse,
        isPending,
        isSuccess,
    } = useQuery({
        ...queries.vessels.detail(vesselId)._ctx.tanks,
        refetchOnWindowFocus: !editTanksPopupOpen,
    });

    const onPopupSubmit = async ({ data }) => {
        const dateMeasured = new Date(data.dateMeasured).toISOString();

        const createEntry = ({ vesselTankId, quantity }) => {
            return {
                imo: props.vessel.imo,
                customerGroupId: props.vessel.customer.customerGroupId,
                dateMeasured,
                vesselTankId,
                quantity,
            };
        };

        const stocks = Object.entries(data.stocks).map(([key, stock]) => {
            return createEntry({
                vesselTankId: key,
                quantity: stock.value,
            });
        });

        await mutateStocks(stocks);

        setRemainingOnBoardPopupOpen(false);
    };

    return (
        <Section
            title={translate('vessel.remainingOnBoard.headline')}
            actions={
                role.isAdmin() && vesselTankResponse && vesselTankResponse.vesselTanks.length > 0 ? (
                    <Box display="flex" gap={4}>
                        <Button onClick={() => setEditTanksPopupOpen(true)}>
                            {translate('vessel.remainingOnBoard.editTanks')}
                        </Button>
                        <Button onClick={() => setRemainingOnBoardPopupOpen(true)}>
                            {translate('vessel.remainingOnBoard.insertButton')}
                        </Button>
                    </Box>
                ) : null
            }
        >
            {isPending ? <LoadingIndicator /> : null}
            {isSuccess && vesselTankResponse.vesselTanks.length > 0 ? (
                <VesselTankStocks vesselTanks={vesselTankResponse.vesselTanks} />
            ) : null}
            {isSuccess && !vesselTankResponse.vesselTanks.length && !role.isAdmin() ? <VesselTanksPlaceholder /> : null}
            {role.isAdmin() && vesselTankResponse && vesselTankResponse.vesselTanks.length === 0 ? (
                <Box justifyContent="center" alignItems="center" display="flex" height="100%">
                    <Button emphasis="high" onClick={() => setEditTanksPopupOpen((prevState) => !prevState)}>
                        {translate('vessel.remainingOnBoard.createTanks')}
                    </Button>
                </Box>
            ) : null}
            <InsertRemainingOnBoardPopup
                onSubmit={onPopupSubmit}
                open={remainingOnBoardPopupOpen}
                onDismiss={() => setRemainingOnBoardPopupOpen(false)}
                vessel={props.vessel}
                tanks={vesselTankResponse?.vesselTanks || []}
            />
            <EditTanksModal
                onDismiss={() => setEditTanksPopupOpen(false)}
                vesselId={props.vessel.id}
                customerId={props.vessel.customerId}
                isOpen={editTanksPopupOpen}
            />
        </Section>
    );
};

RemainingOnBoard.propTypes = {
    vessel: PropTypes.object,
};
