import PropTypes from 'prop-types';

import { PriceCompareFuelMessage } from '../../../../types/PriceCompareFuelMessage';
import { ReachInformationCategory } from '../../../../types/ReachInformationMessage';
import { sortFuelCategory } from '../../../common/helpers/sortByCategory.helper';
import { Box } from '../../../common/ui/Box';
import { Frame } from '../ScheduleSection/ScheduleStop';

import { FuelPriceCompareCategory } from './FuelPriceCompareCategory';

interface FuelSchedulePriceCompareProps {
    prices: PriceCompareFuelMessage[];
    reachInformation?: { [productGroupSulphurContent: string]: ReachInformationCategory };
    hasPlattsCredentials: boolean;
    customerId: string;
}

export const FuelSchedulePriceCompare = ({
    prices,
    reachInformation,
    hasPlattsCredentials,
    customerId,
}: FuelSchedulePriceCompareProps) => {
    const groupedProductCategories = prices.reduce<{ [accessor: string]: PriceCompareFuelMessage[] }>(
        (acc, priceData) => {
            const { productCategory, sulphurContent } = priceData;
            const accessor = `${productCategory}_${sulphurContent}`;
            if (!acc[accessor]) {
                acc[accessor] = [priceData];
            } else {
                acc[accessor].push(priceData);
            }
            return acc;
        },
        {}
    );

    return (
        <Box>
            <Box marginX={4} backgroundColor="background.inset" height="8px" />
            <Frame>
                {Object.keys(groupedProductCategories)
                    .sort(sortFuelCategory)
                    .map((category) => {
                        return (
                            <FuelPriceCompareCategory
                                priceData={groupedProductCategories[category]}
                                reachInformation={reachInformation?.[category]}
                                key={category}
                                hasPlattsCredentials={hasPlattsCredentials}
                                customerId={customerId}
                            />
                        );
                    })}
            </Frame>
        </Box>
    );
};

FuelSchedulePriceCompare.propTypes = {
    prices: PropTypes.array.isRequired,
};
