import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCake = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 3a1 1 0 0 1 .746.333l1.459 1.632A3 3 0 0 1 14.829 8H18a4 4 0 0 1 4 4v2.711a.991.991 0 0 1 0 .183V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5.105a.994.994 0 0 1 0-.185V12a4 4 0 0 1 4-4h3.172a3 3 0 0 1 .532-2.929l1.55-1.737A1 1 0 0 1 12 3Zm.02 5h-.038a1 1 0 0 1-.768-1.617l.787-.882.719.804.013.014A1 1 0 0 1 12.02 8Zm-.052 2H6a2 2 0 0 0-2 2v2h.021a1.399 1.399 0 0 0 1.167-.583A3.4 3.4 0 0 1 8 12a3.4 3.4 0 0 1 2.813 1.417A1.4 1.4 0 0 0 12.02 14a1.399 1.399 0 0 0 1.167-.583A3.399 3.399 0 0 1 16 12a3.4 3.4 0 0 1 2.813 1.417 1.4 1.4 0 0 0 1.166.583H20v-2a2 2 0 0 0-2-2h-6.032ZM20 16a3.4 3.4 0 0 1-2.812-1.417A1.4 1.4 0 0 0 15.979 14a1.4 1.4 0 0 0-1.166.583A3.399 3.399 0 0 1 12 16a3.4 3.4 0 0 1-2.812-1.417A1.4 1.4 0 0 0 7.979 14a1.4 1.4 0 0 0-1.166.583A3.4 3.4 0 0 1 4 16v3h16v-3Z"
            clipRule="evenodd"
        />
    </svg>
);
