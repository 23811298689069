import PropTypes from 'prop-types';
import { useState } from 'react';

import { OfferApprovalSettingsMessage } from '../../types/OfferApprovalSettingsMessage';
import { useRole } from '../common/hooks/useRole';
import { Flex } from '../common/ui/Flex';

import { ApprovalRequestsDropdown } from './ApprovalRequest/ApprovalRequestsDropdown';
import { OrderedRequestButton } from './ApprovalRequest/RequestButton/OrderedRequestButton';
import { OfferModel } from './model/OfferModel';
import { RequestApprovalMultipleApproversPopup } from './MultipleApprovers/RequestApprovalMultipleApproversPopup';

interface OfferApprovalActivityProps {
    offer: OfferModel;
    offerApprovalSettings: OfferApprovalSettingsMessage;
}

export const OfferApprovalActivity = (props: OfferApprovalActivityProps) => {
    const [showPopup, setShowPopup] = useState(false);
    const role = useRole();
    const onRequestApprovalButtonClicked = () => {
        setShowPopup(true);
    };

    const onPopupDismiss = () => {
        setShowPopup(false);
    };

    return (
        <Flex width={props.offer.approvalRequests.length ? '570px' : '200px'} justifyContent="flex-end">
            {props.offer.approvalRequests.length ? (
                <ApprovalRequestsDropdown approvalRequests={props.offer.approvalRequests} />
            ) : null}

            {role.isCustomer() && role.hasWriteRights(props.offer.customerId) ? (
                <OrderedRequestButton
                    offerApprovalSettings={props.offerApprovalSettings}
                    approvalRequests={props.offer.approvalRequests}
                    onRequestApprovalButtonClicked={onRequestApprovalButtonClicked}
                />
            ) : null}

            {role.isCustomer() ? (
                <RequestApprovalMultipleApproversPopup
                    offerApprovalSettings={props.offerApprovalSettings}
                    open={showPopup}
                    onDismiss={onPopupDismiss}
                    offerId={props.offer.id}
                    customerId={props.offer.customerId}
                />
            ) : null}
        </Flex>
    );
};

OfferApprovalActivity.propTypes = {
    offer: PropTypes.object.isRequired,
    offerApprovalSettings: PropTypes.object,
};
