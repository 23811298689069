import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { ProductsOverviewPage } from '../product/overview/ProductsOverviewPage';

const productsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
});

export const Route = createFileRoute('/_app/_lubes/products')({
    component: ProductsOverviewPage,
    validateSearch: zodSearchValidator(productsSearchSchema),
});
