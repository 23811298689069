import { useRole } from './useRole';

export const useProductContext = () => {
    const role = useRole();

    return {
        isFuel: role?.context === 'FUEL',
        isLubes: role?.context === 'LUBES',
        possibleContexts: role?.getPossibleContexts() || [],
        context: role?.context,
    };
};
