import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { useEffect, useState } from 'react';

import { Modal } from '../../cdl/Modal/Modal';
import { useLocalStorageState } from '../../common/hooks/useLocalStorageState';
import { Box } from '../../common/ui/Box';

import { DevFlags } from './DevFlags';
import { FeatureFlags } from './FeatureFlags';
import { FlaskIconButton } from './FlaskIconButton';
import { Shortcuts } from './Shortcuts';

interface DevToolsModalProps {
    open: boolean;
    onDismiss: () => void;
}

const DevToolsModal = (props: DevToolsModalProps) => {
    return (
        <Modal isOpen={props.open} onDismiss={props.onDismiss} size="large" scrollable={false}>
            <Box padding={6} display="flex" flexDirection="column" rowGap={6}>
                <FeatureFlags />
                <Shortcuts />
                <DevFlags />
            </Box>
        </Modal>
    );
};

declare global {
    interface Window {
        toggleToolbox: () => void;
    }
}

export const Toolbox = () => {
    const [showModal, setShowModal] = useState(false);
    const [showToolbox, setShowToolbox] = useLocalStorageState('dev-tools', false);

    useEffect(() => {
        if (window.location.search.includes('dev-tools=false')) {
            setShowToolbox(false);
        }

        if (window.location.search.includes('dev-tools=true')) {
            setShowToolbox(true);
        }

        window.toggleToolbox = () => setShowToolbox((v) => !v);
    }, [setShowToolbox]);

    if (!showToolbox) {
        return null;
    }

    if (!showModal) {
        return (
            <>
                <TanStackRouterDevtools />
                <FlaskIconButton onClick={() => setShowModal(true)} />
            </>
        );
    }

    return <DevToolsModal open={showModal} onDismiss={() => setShowModal(false)} />;
};
