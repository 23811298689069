import { MouseEvent, useState } from 'react';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { Input, InputProps } from '../../cdl/Input/Input';
import { IconEye } from '../icons/cdl/Eye';
import { IconEyeOff } from '../icons/cdl/EyeOff';

export const PasswordInput = (props: InputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleSwitchVisibilityClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setShowPassword((currentShowPassword) => !currentShowPassword);
    };

    return (
        <Input
            type={showPassword ? 'text' : 'password'}
            trailingVisual={
                props.value ? (
                    <IconButton
                        emphasis="transparent"
                        Icon={showPassword ? IconEye : IconEyeOff}
                        onClick={handleSwitchVisibilityClick}
                        type="button"
                    />
                ) : null
            }
            {...props}
        />
    );
};
