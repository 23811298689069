import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAlertOctagon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g clipPath="url(#a)">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 3c-.14 0-.28.029-.409.084L6.02 5.472a1.044 1.044 0 0 0-.546.545l-2.39 5.575a1.036 1.036 0 0 0 0 .816l2.389 5.574c.105.245.3.44.544.545l5.575 2.389c.26.111.556.112.816 0l5.573-2.388h.002c.245-.106.44-.301.544-.545m0 0 2.39-5.575c.111-.26.111-.555 0-.815l-2.388-5.574a1.043 1.043 0 0 0-.545-.546l-5.575-2.389A1.036 1.036 0 0 0 12 3m-1.196-1.754a3.036 3.036 0 0 1 2.391 0l5.575 2.389a3.044 3.044 0 0 1 1.595 1.594v.001l2.388 5.573v.001a3.032 3.032 0 0 1 0 2.392l-2.388 5.575a3.043 3.043 0 0 1-1.594 1.595h-.002l-5.573 2.388a3.032 3.032 0 0 1-2.392 0l-5.575-2.389a3.036 3.036 0 0 1-1.596-1.595m0 0-2.388-5.574a3.037 3.037 0 0 1 0-2.392l2.39-5.575a3.044 3.044 0 0 1 1.593-1.595l5.576-2.388M12 7a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1Zm-1 9a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
