import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { FuelPricingDate } from '../../../types/FuelPricingDate';
import { Button } from '../../cdl/Button/Button';
import { Callout } from '../../cdl/Callout/Callout';
import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { FormDatePicker } from '../../common/form-elements/DatePicker/DeprecatedDatePicker';
import { Form } from '../../common/form-elements/Form/Form';
import { useFormContext } from '../../common/form-elements/Form/useFormContext';
import { FormSelect } from '../../common/form-elements/Select/Select';
import { FormSwitch } from '../../common/form-elements/Switch/FormSwitch';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { IconPlus } from '../../common/icons/cdl/Plus';
import { FormMultiPortSelect } from '../../common/MultiPortSelect/MultiPortSelect';
import { Box } from '../../common/ui/Box.tsx';
import { PaymentTerms } from '../../offer/PaymentTerms';

import { FormCondition } from './FormCondition';
import { FormFuelSupplierSelect } from './FormFuelSupplierSelect';
import { ProductForm } from './ProductForm';
import { ProductList } from './ProductList';
import { checkTenderValidity, checkValidity } from './validateContract';

export const ContractForm = ({ title, data = { tender: { enabled: false } }, onSubmit: onSubmitCallback, errors }) => {
    const [formState, setFormState] = useState(data);
    const [productGroups, setProductGroups] = useState(
        data.productGroups?.map((productGroup, index) => ({
            ...productGroup,
            id: index, // id is used as key in DataTable
        })) || []
    );
    const validate = useCallback(
        () =>
            formState.tender.enabled
                ? checkTenderValidity(formState, productGroups)
                : checkValidity(formState, productGroups),
        [formState, productGroups]
    );

    const [isValid, setValid] = useState(validate());
    const [showError, setShowError] = useState(false);
    const { push, pop } = useDrawer();
    const { enums, getHumanReadableValue } = useEnums();
    const isTenderSelectionEnabled = data?.tender?.enabled || !data.id;

    const createOptionsFromEnum = (enumType) => {
        return Object.keys(enums[enumType]).map((key) => ({
            label: getHumanReadableValue(enumType, key),
            value: key,
        }));
    };

    const contractBasisOptions = [
        {
            label: translate('contractBasis.DELIVERED'),
            value: 'DELIVERED',
        },
        {
            label: translate('contractBasis.EX_WHARF'),
            value: 'EX_WHARF',
        },
    ];

    const pricingDateOptions = Object.keys(enums).length ? createOptionsFromEnum('pricingDate') : [];
    const pricingDateMonthTypeOptions = Object.keys(enums).length ? createOptionsFromEnum('pricingDateMonthType') : [];

    useEffect(() => errors?.length && filterErrors(errors), [errors]);

    const filterErrors = (errors) => {
        const duplicateError = errors.find((error) => error.code === 'DUPLICATE' && error.path === 'reference');

        if (duplicateError) {
            setShowError(true);
        }
    };

    /**
     * Product Handlers
     */
    const onProductEditSubmit = (data) => {
        const editedProductGroupIndex = productGroups.findIndex((p) => p.id === data.id);

        const updatedProductGroups = [...productGroups];
        updatedProductGroups.splice(editedProductGroupIndex, 1, data);

        setProductGroups(updatedProductGroups);
        pop();
    };

    const onProductAddSubmit = (data) => {
        setProductGroups(
            productGroups.concat({
                ...data,
                id: productGroups.length,
            })
        );
        pop();
    };

    const openEditProductGroupModal = (productGroup) => {
        push({
            offsetParent: false,
            content: (
                <ProductForm
                    title={translate('contracts.product.editTitle')}
                    data={productGroup}
                    onSubmit={onProductEditSubmit}
                    contract={formState}
                />
            ),
        });
    };

    const openAddProductGroupModal = () => {
        push({
            offsetParent: false,
            content: (
                <ProductForm
                    title={translate('contracts.product.addTitle')}
                    onSubmit={onProductAddSubmit}
                    contract={formState}
                />
            ),
        });
    };

    const deleteProductGroup = (productGroup) => {
        const deletedProductGroupIndex = productGroups.findIndex((p) => p.id === productGroup.id);

        const updatedProductGroups = [...productGroups];
        updatedProductGroups.splice(deletedProductGroupIndex, 1);

        setProductGroups(updatedProductGroups);
    };

    useEffect(() => {
        setValid(validate());
    }, [productGroups, formState, validate]);

    /**
     * Contract Form Handlers
     */
    const onSubmit = ({ data, event }) => {
        event.preventDefault();
        onSubmitCallback({
            data: {
                ...data,
                productGroups: productGroups.map((productGroup) => {
                    return {
                        ...productGroup,
                        products: productGroup.products.map((productData) => {
                            return {
                                ...productData,
                                productId: productData.product.id,
                                pricingCodeId: productData.pricingCode?.id,
                                priceDifference: productData.priceDifference
                                    ? {
                                          value: productData.priceDifference.value,
                                          currency: 'USD',
                                      }
                                    : undefined,
                            };
                        }),
                    };
                }),
            },
        });
    };

    const onChange = ({ data }) => {
        setFormState(() => {
            if (data.pricingDate && data.pricingDate === FuelPricingDate.MONTHLY_AVERAGE) {
                return { ...data, pricingDateDays: undefined };
            }

            if (data.pricingDate && data.pricingDate !== FuelPricingDate.MONTHLY_AVERAGE) {
                return { ...data, pricingDateMonthType: undefined };
            }

            return data;
        });
        if (showError) {
            setShowError(false);
        }
    };

    return (
        <Form height="100%" data={formState} onSubmit={onSubmit} onChange={onChange}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={showError || !isValid}>
                {isTenderSelectionEnabled ? (
                    <FormSwitch
                        label={translate('contracts.tender')}
                        description={translate('contracts.tenderDescription')}
                        dataPath="tender.enabled"
                    />
                ) : null}

                <FormTextInput
                    placeholder={translate('contracts.placeholder.contractReference')}
                    label={translate('contracts.contractReference')}
                    dataPath="reference"
                    required
                />

                {showError ? <Callout variant="negative" description={translate('contracts.duplicateError')} /> : null}

                {formState.tender.enabled ? (
                    <FormDatePicker
                        placeholder={translate('contracts.placeholder.dueDate')}
                        label={translate('contracts.dueDate')}
                        dataPath="tender.dueDate"
                        required
                    />
                ) : (
                    <PrefillFormFuelSupplierSelect />
                )}

                {showError ? <Callout varaint="error" description={translate('contracts.duplicateError')} /> : null}

                <FormDatePicker
                    placeholder={translate('contracts.placeholder.startDate')}
                    label={translate('contracts.startDate')}
                    dataPath="startDate"
                    required
                />

                <FormDatePicker
                    placeholder={translate('contracts.placeholder.endDate')}
                    label={translate('contracts.endDate')}
                    dataPath="endDate"
                    disabledBeforeDateDataPath="startDate"
                    disabled={!formState.startDate}
                    required
                />

                {formState.startDate > formState.endDate ? (
                    <Callout varaint="error" description={translate('contracts.dateRangeError')} />
                ) : null}

                <FormMultiPortSelect
                    placeholder={translate('contracts.placeholder.deliveryPorts')}
                    dataPath="portIds"
                    label={translate('contracts.deliveryPorts')}
                    required
                />

                <FormSelect
                    placeholder={translate('contracts.placeholder.basis')}
                    dataPath="contractBasis"
                    options={contractBasisOptions}
                    label={translate('contracts.basis')}
                    required={!formState.tender.enabled}
                />

                <FormSelect
                    placeholder={translate('contracts.placeholder.pricingDate')}
                    defaultValue="DELIVERY_DATE"
                    dataPath="pricingDate"
                    options={pricingDateOptions}
                    label={translate('contracts.pricingDate')}
                    required={!formState.tender.enabled}
                />

                <FormCondition condition={(formData) => formData?.pricingDate !== 'MONTHLY_AVERAGE'}>
                    <Tooltip label={translate('contracts.pricingDaysTooltip')}>
                        <div>
                            <FormTextInput
                                dataPath="pricingDateDays"
                                label={translate('contracts.pricingDays')}
                                placeholder={translate('contracts.placeholder.pricingDays')}
                                type="number"
                                required={!formState.tender.enabled}
                            />
                        </div>
                    </Tooltip>
                </FormCondition>

                <FormCondition condition={(formData) => formData?.pricingDate === 'MONTHLY_AVERAGE'}>
                    <FormSelect
                        placeholder={translate('contracts.placeholder.pricingDateMonthType')}
                        defaultValue="DELIVERY_DATE"
                        dataPath="pricingDateMonthType"
                        options={pricingDateMonthTypeOptions}
                        label={translate('contracts.pricingDateMonthType')}
                        required={!formState.tender.enabled}
                    />
                </FormCondition>

                <FormPaymentTerms required={!formState.tender.enabled} />

                <Box marginTop={6}>
                    <Text as="h2" variant="subtitle1">
                        {translate('contracts.productsTitle')}
                    </Text>
                </Box>

                {productGroups.length ? (
                    <Box marginBottom={6}>
                        <ProductList
                            productGroups={productGroups}
                            onEditClick={(productGroup) => {
                                openEditProductGroupModal(productGroup);
                            }}
                            onDeleteClick={(productGroup) => {
                                deleteProductGroup(productGroup);
                            }}
                            contract={formState}
                        />
                    </Box>
                ) : null}

                <div>
                    <Button
                        type="button"
                        onClick={openAddProductGroupModal}
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {translate('contracts.add')}
                    </Button>
                </div>
            </DrawerFormLayout>
        </Form>
    );
};

ContractForm.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
};

const FormPaymentTerms = ({ required }) => {
    const { data, setFormData } = useFormContext();

    const onChange = ({ days, reference }) => {
        setFormData({
            ...data,
            paymentTermReference: reference,
            paymentTermReferenceDays: days,
        });
    };

    return (
        <PaymentTerms
            onChange={onChange}
            days={data.paymentTermReferenceDays}
            reference={data.paymentTermReference}
            label={translate('contracts.paymentTerms')}
            required={required}
        />
    );
};

FormPaymentTerms.propTypes = {
    required: PropTypes.bool,
};

const PrefillFormFuelSupplierSelect = () => {
    const { data, setFormData } = useFormContext();

    return (
        <FormFuelSupplierSelect
            required
            dataPath="supplierId"
            onChange={(option) => {
                const defaultPaymentTermReferenceDays = option.assignment.defaultPaymentTermReferenceDays;
                const defaultPaymentTermReference = option.assignment.defaultPaymentTermReference;

                setFormData({
                    ...data,
                    supplierId: option.value,
                    paymentTermReference:
                        defaultPaymentTermReference !== null ? defaultPaymentTermReference : data.paymentTermReference,
                    paymentTermReferenceDays:
                        defaultPaymentTermReferenceDays !== null
                            ? defaultPaymentTermReferenceDays
                            : data.paymentTermReferenceDays,
                });
            }}
        />
    );
};
