import moment from 'moment';

import { formatDateTime } from '../helpers/formatDateTime.helper';
import { translate } from '../helpers/translate.helper';

export const getTableValidity = (validUntil?: number | string) => {
    if (!validUntil) {
        return null;
    }

    const validUntilDate = moment(validUntil);

    if (validUntilDate.isBefore(moment())) {
        return translate('order.validityExpired');
    }

    if (validUntilDate.isAfter(moment().add(1, 'days'))) {
        return formatDateTime({
            date: validUntil,
            format: 'MMM D, h:mm a',
        });
    }

    return formatDateTime({
        date: validUntil,
        format: 'h:mm a',
    });
};
