function Surcharge(item) {
    if (item) {
        Object.assign(this, item);
    }
}

Surcharge.prototype.isCompleteWithPrices = function () {
    if (this.surchargeType === 'CUSTOM' || this.value?.value < 0) {
        return this.name && this.value;
    }

    return !!this.value;
};

export { Surcharge };
