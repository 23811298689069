import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { createOrder } from '../../../../common/api/clients/order.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { translate } from '../../../../common/helpers/translate.helper';

export const useDraftCreate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createOrder,
        onSuccess: (response) => {
            queryClient.removeQueries({ queryKey: queryKeys.orders() });
            queryClient.removeQueries({ queryKey: queryKeys.ordersTotals() });

            navigate({ to: '/orders/draft/$id', params: { id: response.id }, replace: true });
        },
        onError: () => {
            addErrorToast(translate('alert.defaulterror.message'));
        },
    });
};
