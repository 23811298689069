import { Text } from '../../../../../../cdl/Text/Text';
import { Tooltip } from '../../../../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../../../common/hooks/useEnums';
import { MiddleDotSeparator } from '../../../../../../common/Separators/MiddleDotSeparator';
import { Box } from '../../../../../../common/ui/Box';
import { useLubesOfferPaymentTerms } from '../hooks/useLubesOfferPaymentTerms';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

import { NoticeDaysDisplay } from './NoticeDaysDisplay';

export const OfferDetails = () => {
    const { offer, order } = useOfferCardContext();
    const { getHumanReadableValue } = useEnums();
    const paymentTermString = useLubesOfferPaymentTerms(offer);

    if (offer.isUnquoted()) {
        const emptyValues = Array.from({ length: 3 }, (_, index) => (
            <Text key={index} variant="extraSmall">
                -
            </Text>
        ));
        return (
            <Box display="flex" flexDirection="column" rowGap={2} paddingTop={4} alignItems="start">
                {emptyValues}
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" rowGap={2} paddingTop={4} alignItems="start">
            <Box display="flex">
                <Tooltip label={translate('order.detail.coreData.dateDelivery')} showArrow side="left">
                    <Text
                        variant="extraSmall"
                        color={offer.changes.dateDeliveryChanged ? 'attention.foreground' : 'inherit'}
                        fontWeight={offer.changes.dateDeliveryChanged ? 'semiBold' : 'normal'}
                    >
                        {formatDateTime({
                            date: offer.dateDelivery,
                            format: 'll',
                        })}
                    </Text>
                </Tooltip>

                {offer.noticeDays ? (
                    <Text variant="extraSmall">
                        <MiddleDotSeparator />
                    </Text>
                ) : null}

                <NoticeDaysDisplay
                    noticeDays={offer.noticeDays}
                    dateDelivery={offer.dateDelivery}
                    orderState={order.state}
                />
            </Box>

            <Tooltip label={translate('order.supplyMode.label')} showArrow side="left">
                <Text variant="extraSmall">{getHumanReadableValue('supplyMode', offer.supplyMode)}</Text>
            </Tooltip>

            <Tooltip label={translate('order.detail.paymentTerm')} showArrow side="left">
                <Text variant="extraSmall">{paymentTermString}</Text>
            </Tooltip>

            {offer.vendorReference ? (
                <Tooltip label={translate('order.vendorreference')} side="left" showArrow>
                    <Text variant="extraSmall">{offer.vendorReference}</Text>
                </Tooltip>
            ) : null}
        </Box>
    );
};
