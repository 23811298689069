import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { reimportRequisition, ReimportRequisitionRequest } from '../common/api/clients/cloud-fleet-manager.api';

export const useCloudFleetManagerReimportRequisition = () => {
    return useMutation<void, AxiosError<string>, ReimportRequisitionRequest>({
        mutationFn: reimportRequisition,
    });
};
