import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { FuelCreateOrderRequest, updateOrder } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';

import { useSelectedOfferForFuelOrder } from './useContractOrder';

export const useUpdateContractOrder = () => {
    const { addErrorToast } = useToasts();
    const navigate = useNavigate();

    const fetchSelectedOfferId = useSelectedOfferForFuelOrder();

    const updateOrderMutation = useMutation({ mutationFn: updateOrder });

    return (order: FuelCreateOrderRequest, orderId: string) => {
        return updateOrderMutation.mutateAsync(
            {
                order,
                orderId,
            },
            {
                onSuccess: async (order: any) => {
                    try {
                        const selectedOfferId = await fetchSelectedOfferId(order.id);

                        navigate({ to: '/fuel/offer/$id', params: { id: selectedOfferId } });
                    } catch {
                        navigate({ to: '/fuel/order/$id', params: { id: order.id } });
                    }
                },
                onError: () => {
                    addErrorToast(translate('fuelOrder.toast.updateOrder.error'));
                },
            }
        );
    };
};
