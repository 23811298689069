import { fallback } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { generateStatisticsDateRangePresets } from '../js/common/helpers/generateStatisticsDateRangePresets';
import { StatisticTab } from '../js/statistics/components/StatisticTab';

const presets = generateStatisticsDateRangePresets();

export const statisticsSearchSchema = z.object({
    tab: fallback(z.nativeEnum(StatisticTab), StatisticTab.GENERAL).default(StatisticTab.GENERAL),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    from: fallback(z.number(), presets[0].range[0].getTime()).default(presets[0].range[0].getTime()),
    to: fallback(z.number(), presets[0].range[1].getTime()).default(presets[0].range[1].getTime()),
});
