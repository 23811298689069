
            import AppSettings from './app/js/constants';

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', AppSettings.googleAnalyticsId);

            window.addEventListener('pushstate', () => {
                gtag('event', 'page_view', {
                    page_location: window.location.href,
                });
            });
        