export enum SurchargeType {
    AGENCY_FEE = 'AGENCY_FEE',
    BARGE_CHARGE = 'BARGE_CHARGE',
    CUSTOM = 'CUSTOM',
    CUSTOMS = 'CUSTOMS',
    FREIGHT_AND_TRANSPORTATION_CHARGE = 'FREIGHT_AND_TRANSPORTATION_CHARGE',
    MINIMUM_QUANTITY = 'MINIMUM_QUANTITY',
    PORT_FEE = 'PORT_FEE',
    SHORT_NOTICE = 'SHORT_NOTICE',
    PUMPING = 'PUMPING',
}
