import { ProductContext } from '../../../../types/ProductContext';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { useRole } from '../../../common/hooks/useRole';
import { Route } from '../../../routes/_app._lubes.customers';

export const CustomerFilterActions = () => {
    const role = useRole();
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    if (role.isOneCompanyUser(ProductContext.LUBES)) {
        return null;
    }

    return (
        <OverviewSupplierCompanySelect
            value={search.supplierIds}
            onChange={(supplierIds) => {
                navigate({ search: (previousSearch) => ({ ...previousSearch, supplierIds, page: 0 }) });
            }}
            context={ProductContext.LUBES}
        />
    );
};
