import { AxiosPromise } from 'axios';

import { authorizedAxiosInstance } from '../../common/api/instances/AuthorizedAxiosInstance';
import { baseAxiosInstance } from '../../common/api/instances/BaseAxiosInstance';

export const login = (username: string, password: string): AxiosPromise => {
    return baseAxiosInstance.get('/login', {
        headers: {
            authorization: 'Basic ' + btoa(username + ':' + password),
        },
    });
};

export const renew = () => {
    return authorizedAxiosInstance.get('/login/renew');
};

export const getUser = async () => {
    return authorizedAxiosInstance.get('/v1/user').then((response) => response.data);
};
