import { UserRole } from '../../../types/UserRole';
import { Select, SelectProps } from '../../cdl/Select/Select';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { useRole } from '../../common/hooks/useRole';
import { IconInfoCircle } from '../../common/icons/cdl/InfoCircle';

interface RoleSelectOption {
    label: string;
    value: string;
}

export interface RoleSelectProps extends Omit<SelectProps<RoleSelectOption>, 'value' | 'onChange'> {
    value: string;
    onChange: (newRole: string | undefined) => void;
}

export const RoleSelect = (props: RoleSelectProps) => {
    const { getHumanReadableValue } = useEnums();
    const role = useRole();

    const options: RoleSelectOption[] = Object.values(UserRole)
        .filter((it) => {
            if (it === UserRole.CUSTOM) {
                return false;
            }

            if (!role.isAdmin() && role.accessLevel !== 'ADMIN') {
                return it !== UserRole.GROUP_ADMIN;
            }

            return true;
        })

        .map((it) => {
            return {
                label: getHumanReadableValue('role', it),
                value: it,
            };
        });

    const value = { value: props.value, label: getHumanReadableValue('role', props.value) };

    const userRoleWithoutCustom = props.value as Exclude<UserRole, UserRole.CUSTOM>;

    return (
        <Select<RoleSelectOption>
            {...props}
            options={options}
            onChange={(newValue) => {
                props.onChange(newValue?.value);
            }}
            value={value}
            CaptionIcon={props.caption ? undefined : IconInfoCircle}
            caption={
                props.caption ||
                (userRoleWithoutCustom && translate(`user.userroleexplanation.${userRoleWithoutCustom}`))
            }
        />
    );
};
