import { useEffect, useReducer } from 'react';
import { useSearch } from '@tanstack/react-router';

import { useToasts } from '../../cdl/Toast/useToasts';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { translate } from '../../common/helpers/translate.helper';
import { Route } from '../../routes/offer.$offerId.approval-request';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../common/ui/Box';

import { ERRORS } from './errors.constants';
import {
    getOfferAndApprovalRequestsById,
    updateApprovalRequest as updateApprovalRequestApi,
} from './offer-approval-request.api';
import { OfferApprovalRequestContent } from './OfferApprovalRequestContent';

const TYPES = {
    GET_PENDING: 'GET_PENDING',
    GET_SUCCESS: 'GET_SUCCESS',
    GET_ERROR_GONE: 'GET_ERROR_GONE',
    GET_ERROR_UNAUTHORIZED: 'GET_ERROR_UNAUTHORIZED',
    GET_ERROR: 'GET_ERROR',

    UPDATE_APPROVAL_REQUEST_PENDING: 'UPDATE_APPROVAL_REQUEST_PENDING',
    UPDATE_APPROVAL_REQUEST_SUCCESS: 'UPDATE_APPROVAL_REQUEST_SUCCESS',
    UPDATE_APPROVAL_REQUEST_ERROR: 'UPDATE_APPROVAL_REQUEST_ERROR',
};

const initialState = {
    offer: null,
    approvalRequests: [],
    fetchingData: true,
    error: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.GET_PENDING: {
            return initialState;
        }
        case TYPES.GET_SUCCESS: {
            return {
                offer: action.offers[0],
                approvalRequests: action.approvalRequests,
                fetchingData: false,
                error: null,
            };
        }
        case TYPES.GET_ERROR_GONE: {
            return {
                offer: initialState.offer,
                approvalRequests: initialState.approvalRequests,
                fetchingData: false,
                error: ERRORS.GONE,
            };
        }
        case TYPES.GET_ERROR_UNAUTHORIZED: {
            return {
                offer: initialState.offer,
                approvalRequests: initialState.approvalRequests,
                fetchingData: false,
                error: ERRORS.UNAUTHORIZED,
            };
        }
        case TYPES.GET_ERROR: {
            return {
                offer: initialState.offer,
                approvalRequests: initialState.approvalRequests,
                fetchingData: false,
                error: ERRORS.DEFAULT,
            };
        }
        case TYPES.UPDATE_APPROVAL_REQUEST_PENDING: {
            return {
                ...state,
                updatingApprovalRequestState: action.state,
            };
        }
        case TYPES.UPDATE_APPROVAL_REQUEST_SUCCESS: {
            state.approvalRequests[0] = {
                ...state.approvalRequests[0],
                ...action.updatedApprovalRequest,
            };

            return {
                ...state,
                approvalRequests: state.approvalRequests,
                updatingApprovalRequestState: null,
            };
        }
        case TYPES.UPDATE_APPROVAL_REQUEST_ERROR: {
            return {
                ...state,
                updatingApprovalRequestState: null,
            };
        }
        default:
            throw new Error();
    }
};

export const OfferApprovalRequest = () => {
    const { offerId } = Route.useParams();
    const { token } = useSearch({ from: '/offer/$offerId/approval-request' });
    const { addToast, addErrorToast } = useToasts();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({
            type: TYPES.GET_PENDING,
        });

        getOfferAndApprovalRequestsById({ offerId, token })
            .then((response) => {
                dispatch({
                    type: TYPES.GET_SUCCESS,
                    offers: response.offers,
                    approvalRequests: response.approvalRequests,
                });
            })
            .catch((error) => {
                if (!error.response) {
                    dispatch({ type: TYPES.GET_ERROR });
                    return;
                }

                if (error.response.status === 410) {
                    dispatch({ type: TYPES.GET_ERROR_GONE });
                    return;
                }

                if (error.response.status === 403) {
                    dispatch({ type: TYPES.GET_ERROR_UNAUTHORIZED });
                    return;
                }

                dispatch({ type: TYPES.GET_ERROR });
            });
    }, [offerId, token]);

    useEffect(() => {
        if (state.offer) {
            document.title = translate('approvalRequest.pageTitle', {
                vesselName: state.offer.vessel.name,
                portName: state.offer.port.name,
            });
        }
    }, [state.offer]);

    const updateApprovalRequest = ({ approvalRequest }) => {
        if (state.updatingApprovalRequestState) {
            return;
        }

        dispatch({
            type: TYPES.UPDATE_APPROVAL_REQUEST_PENDING,
            state: approvalRequest.state,
        });

        updateApprovalRequestApi({
            approvalRequestId: approvalRequest.id,
            approvalRequest,
            token,
        })
            .then((updatedApprovalRequest) => {
                dispatch({
                    type: TYPES.UPDATE_APPROVAL_REQUEST_SUCCESS,
                    updatedApprovalRequest,
                });

                if (approvalRequest.state === APPROVAL_STATE.DECLINED) {
                    addToast(translate('approvalRequest.toasts.approvalRequestDeclinedSuccess'));
                } else if (approvalRequest.state === APPROVAL_STATE.APPROVED) {
                    addToast(translate('approvalRequest.toasts.approvalRequestApprovedSuccess'));
                }
            })
            .catch(() => {
                dispatch({ type: TYPES.UPDATE_APPROVAL_REQUEST_ERROR });

                addErrorToast(translate('approvalRequest.toasts.approvalRequestUpdateError'));
            });
    };

    const onApprovalRequestApproved = (message) => {
        const latestApprovalRequest = state.approvalRequests[0];

        updateApprovalRequest({
            approvalRequest: {
                ...latestApprovalRequest,
                state: APPROVAL_STATE.APPROVED,
                responseMessage: message ? message : null,
            },
        });
    };

    const onApprovalRequestDeclined = (message) => {
        const latestApprovalRequest = state.approvalRequests[0];

        updateApprovalRequest({
            approvalRequest: {
                ...latestApprovalRequest,
                state: APPROVAL_STATE.DECLINED,
                responseMessage: message ? message : null,
            },
        });
    };

    if (state.fetchingData) {
        return <LoadingIndicator variant="full-height" />;
    }

    return (
        <Box height="100vh" backgroundColor="background.default">
            <OfferApprovalRequestContent
                approvalRequests={state.approvalRequests}
                offer={state.offer}
                onApprovalRequestDeclined={onApprovalRequestDeclined}
                onApprovalRequestApproved={onApprovalRequestApproved}
                updatingApprovalRequestState={state.updatingApprovalRequestState}
                error={state.error}
            />
        </Box>
    );
};
