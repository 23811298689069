import { createQueryKeys } from '@lukemorales/query-key-factory';

import { VesselPaginationTotalsParams } from '../../../vessel/VesselOverview/useVesselPaginationTotals';
import { getAverageConsumption } from '../clients/consumptions.api';
import { getFuelVesselConsumptionPresets } from '../clients/fuelStocks.api';
import {
    getOrders,
    GetOrdersParams,
    getPaginatedVessels,
    GetPaginatedVesselsParams,
    getVessel,
    getVesselTanksByVesselId,
} from '../clients/gateway.api';
import { getLubesConsumptionPresets, getVesselList, GetVesselListParams } from '../clients/vessel.api';
import { getVesselBudget } from '../clients/vesselBudgets.api';

export const vessels = createQueryKeys('vessels', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getVessel(id),
        contextQueries: {
            budgets: {
                queryKey: null,
                queryFn: () => getVesselBudget(id),
            },
            tanks: {
                queryKey: null,
                queryFn: () => getVesselTanksByVesselId(id),
            },
            fuelConsumptionPresets: {
                queryKey: null,
                queryFn: () => getFuelVesselConsumptionPresets(id),
            },
            averageConsumption: (dateFrom, dateTo, resolution) => ({
                queryKey: [dateFrom, dateTo, resolution],
                queryFn: () => getAverageConsumption({ vesselId: id, dateFrom, dateTo, resolution }),
            }),
            lubesConsumptionPresets: {
                queryKey: null,
                queryFn: () => getLubesConsumptionPresets(id),
            },
            orders: (params: GetOrdersParams) => ({
                queryKey: [{ params }],
                queryFn: () => getOrders(params),
            }),
        },
    }),
    list: (params: GetVesselListParams) => ({
        queryKey: [{ params }],
        queryFn: () => getVesselList(params),
    }),
    pagination: (params: GetPaginatedVesselsParams) => ({
        queryKey: [{ params }],
        queryFn: () => getPaginatedVessels(params),
    }),
    totals: (params: VesselPaginationTotalsParams) => ({
        queryKey: [{ params }],
    }),
});
