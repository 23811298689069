import { ReactNode } from 'react';

import { ApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { ProductContext } from '../../../types/ProductContext';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { OverlayHeader } from '../../common/Overlay/OverlayHeader';
import { Box } from '../../common/ui/Box';
import { LubesReadOnlyProductTable } from '../../order/detail/lubes/components/LubesReadOnlyTable/LubesReadOnlyProductTable';
import { OfferModel } from '../model/OfferModel';
import { CustomerApprovalDataSheet } from '../OfferCoreData/CustomerApprovalDataSheet';

import { ApprovalRequestContentContainer } from './ApprovalRequestContentContainer';
import { ApprovalRequestsDropdown } from './ApprovalRequestsDropdown';
import { FuelApprovalContent } from './FuelApprovalContent';

interface ApprovalRequestBasicContentProps {
    offer: OfferModel;
    approvalRequests: ApprovalRequestMessage[];
    children: ReactNode;
}

export const ApprovalRequestBasicContent = ({
    offer,
    approvalRequests,
    children,
}: ApprovalRequestBasicContentProps) => {
    const isLubes = offer.type === ProductContext.LUBES;
    const subTitle = isLubes ? offer.offerNumber : offer.vessel.name;

    return (
        <OverlayHeader
            title={
                <Box display="flex" gap={5}>
                    <Text variant="title">{translate('approvalRequest.header.title')}</Text>
                    {isLubes ? (
                        <Text variant="title" color="foreground.subtle">
                            {subTitle}
                        </Text>
                    ) : null}
                </Box>
            }
            trailingVisual={<ApprovalRequestsDropdown approvalRequests={approvalRequests} />}
        >
            <ApprovalRequestContentContainer>
                {children}

                {isLubes ? (
                    <>
                        <CustomerApprovalDataSheet offer={offer} />
                        <LubesReadOnlyProductTable
                            items={offer.items}
                            surcharges={offer.surcharges}
                            sampleKits={offer.samplekits}
                            totals={{ volume: offer.volume, price: offer.ppl, total: offer.total }}
                            showPrices
                        />
                    </>
                ) : (
                    <FuelApprovalContent offer={offer} />
                )}
            </ApprovalRequestContentContainer>
        </OverlayHeader>
    );
};
