import {
    ApprovalRequestMessage,
    ApprovedApprovalRequestMessage,
    DeclinedApprovalRequestMessage,
} from '../../../types/ApprovalRequestMessage';
import { ApprovalRequestState } from '../../../types/ApprovalRequestState';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { OfferModel } from '../model/OfferModel';

import { ApprovedState } from './ApprovedState';
import { DeclinedState } from './DeclinedState';
import { ERRORS } from './errors.constants';
import { GeneralError } from './GeneralError';
import { NotAvailable } from './NotAvailable';
import { PendingState } from './PendingState';
import { UnauthorizedError } from './UnauthorizedError';

interface OfferApprovalRequestContentProps {
    approvalRequests: ApprovalRequestMessage[];
    offer: OfferModel;
    onApprovalRequestDeclined: (message?: string) => void;
    onApprovalRequestApproved: (message?: string) => void;
    updatingApprovalRequestState: ApprovalRequestState;
    error: string;
}

export const OfferApprovalRequestContent = ({
    approvalRequests,
    offer,
    onApprovalRequestDeclined,
    onApprovalRequestApproved,
    updatingApprovalRequestState,
    error,
}: OfferApprovalRequestContentProps) => {
    if (error) {
        switch (error) {
            case ERRORS.GONE: {
                return <NotAvailable />;
            }
            case ERRORS.UNAUTHORIZED: {
                return <UnauthorizedError />;
            }
            case ERRORS.DEFAULT:
            default: {
                return <GeneralError />;
            }
        }
    }

    const newestApprovalRequest = approvalRequests.at(0);

    if (!newestApprovalRequest) {
        return <GeneralError />;
    }

    switch (newestApprovalRequest.state) {
        case APPROVAL_STATE.DECLINED: {
            return (
                <DeclinedState
                    offer={offer}
                    declinedApprovalRequest={newestApprovalRequest as DeclinedApprovalRequestMessage}
                    approvalRequests={approvalRequests}
                />
            );
        }
        case APPROVAL_STATE.APPROVED: {
            return (
                <ApprovedState
                    offer={offer}
                    approvedApprovalRequest={newestApprovalRequest as ApprovedApprovalRequestMessage}
                    approvalRequests={approvalRequests}
                />
            );
        }
        case APPROVAL_STATE.PENDING: {
            return (
                <PendingState
                    offer={offer}
                    approvalRequests={approvalRequests}
                    onApprovalRequestDeclined={onApprovalRequestDeclined}
                    onApprovalRequestApproved={onApprovalRequestApproved}
                    updatingApprovalRequestState={updatingApprovalRequestState}
                />
            );
        }
    }
};
