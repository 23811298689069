import { createFileRoute } from '@tanstack/react-router';

import { getOfferById } from '../common/api/clients/gateway.api';
import { queryKeys } from '../common/api/queryKeys';
import { getRole } from '../common/hooks/useRole';
import { queryClient } from '../lib/queryClient';
import { LubesOfferDetailPage } from '../offer/detail/lubes/LubesOfferDetailPage';

export const Route = createFileRoute('/_app/_lubes/offer/$id')({
    async loader(context) {
        queryClient
            .ensureQueryData({
                queryKey: queryKeys.offer(context.params.id),
                queryFn: () => getOfferById(context.params.id),
            })
            .then((offer) => {
                getRole().updateContext(offer.type);
            });
    },
    component: LubesOfferDetailPage,
});
