import { createFileRoute, redirect } from '@tanstack/react-router';

import { ProductContext } from '../../types/ProductContext';
import { LubesAgentDetailPage } from '../agent/detail/LubesAgentDetailPage';
import { queries } from '../common/api/queryKeys/queries';
import { queryClient } from '../lib/queryClient';

export const Route = createFileRoute('/_app/_lubes/agents/$id')({
    component: LubesAgentDetailPage,
    async loader({ params: { id } }) {
        const agent = await queryClient.fetchQuery({ ...queries.agents.detail(id) });

        if (agent.customer.type === ProductContext.FUEL) {
            throw redirect({
                to: '/fuel/agents/$id',
                replace: true,
                params: {
                    id,
                },
            });
        }
    },
});
