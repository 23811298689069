import { ChangeEvent, useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { Input, InputProps } from '../../cdl/Input/Input';
import { Tag } from '../../cdl/Tag/Tag';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

export interface TagListInputProps extends Omit<InputProps, 'onChange' | 'value'> {
    values: string[];
    onChange: (values: string[]) => void;
    /**
     * Define an validation function that checks the input before adding the value to the list.
     * */
    validate?: (value: string) => string | null;
}

export const TagListInput = ({ values, validate, caption, onChange, negative, ...rest }: TagListInputProps) => {
    const [inputValue, setInputValue] = useState('');
    const [validationError, setValidationError] = useState<string | null>(null);

    const addValueToList = () => {
        const trimmedValue = inputValue.trim();
        if (!trimmedValue) {
            return;
        }

        if (values.includes(trimmedValue)) {
            setValidationError(translate('errormessage.duplicate'));
            return;
        }

        if (validate?.(trimmedValue)) {
            setValidationError(validate(trimmedValue));
            return;
        }

        const newValues = [...values, trimmedValue];
        onChange(newValues);
        setInputValue('');
    };

    const onRemove = (index: number) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        onChange(newValues);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setValidationError(null);
    };

    return (
        <div>
            <Box display="flex" alignItems="start" gap={4}>
                <Input
                    {...rest}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            addValueToList();
                            e.preventDefault();
                        }
                    }}
                    caption={validationError || caption}
                    negative={negative || !!validationError}
                />
                <Box marginTop={rest.label ? 6 : 0}>
                    <Button size="large" type="button" onClick={addValueToList}>
                        {translate('btn.add')}
                    </Button>
                </Box>
            </Box>

            <Box display="flex" flexWrap="wrap" gap={2} marginTop={3}>
                {values.map((value, index) => (
                    <Tag key={index} variant="default" onRemoveClick={() => onRemove(index)}>
                        {value}
                    </Tag>
                ))}
            </Box>
        </div>
    );
};
