import { MoneyMessage } from '../../../types/MoneyMessage';
import { Text } from '../../cdl/Text/Text';
import { DataSheet } from '../../common/DataSheet/DataSheet';
import { createdBy } from '../../common/helpers/createdBy.helper';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { usePaymentTermsFormat } from '../../common/hooks/usePaymentTermsFormat';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { Port } from '../../port/Port';
import { OfferModel } from '../model/OfferModel';

import { OfferDataSheetValue } from './OfferDatasheetValue';

interface CustomerOfferDatasheetProps {
    offer: OfferModel;
    fee?: MoneyMessage;
}

export const OfferDatasheet = ({ offer, fee }: CustomerOfferDatasheetProps) => {
    const role = useRole();
    const { getHumanReadableValue } = useEnums();
    const formatPaymentTerms = usePaymentTermsFormat();
    const { isFuel, isLubes } = useProductContext();

    const makeCustomerWording = () => {
        return role.isCustomer()
            ? translate('offer.detail.coreData.company')
            : translate('offer.detail.coreData.customer');
    };

    const makeSupplierWording = () => {
        return role.isSupplier()
            ? translate('offer.detail.coreData.company')
            : translate('offer.detail.coreData.supplier');
    };

    const getSupplyModeString = () => {
        const supplyModeString = getHumanReadableValue('supplyMode', offer.supplyMode);

        if (isFuel && offer.supplyModeIncludesDeliveryCosts !== undefined) {
            const deliveryCostsIncluded = offer.supplyModeIncludesDeliveryCosts
                ? translate('fuelOrder.offerList.supplyModeIncludesDeliveryCosts')
                : translate('fuelOrder.offerList.supplyModeExcludesDeliveryCosts');

            return `${supplyModeString} ${deliveryCostsIncluded}`;
        }

        return supplyModeString;
    };

    return (
        <DataSheet>
            {role.isCustomer() ? (
                <>
                    <DataSheet.Label>{makeCustomerWording()}</DataSheet.Label>
                    <Text>{formatCompanyName({ company: offer.customer, includeType: role.isAdmin() })}</Text>
                </>
            ) : null}

            {role.isSupplier() ? (
                <>
                    <DataSheet.Label>{makeSupplierWording()}</DataSheet.Label>
                    <Text>{formatCompanyName({ company: offer.supplier, includeType: role.isAdmin() })}</Text>
                </>
            ) : null}

            {offer.port ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.port')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.portChanged}>
                        <Port port={offer.port} vesselId={offer.vesselId} showTooltip />
                    </OfferDataSheetValue>
                </>
            ) : null}

            <DataSheet.Label>{translate('offer.detail.coreData.dateDelivery')}</DataSheet.Label>
            <OfferDataSheetValue changed={offer.changes.dateDeliveryChanged}>
                {formatDate({ date: offer.dateDelivery, timeZone: 'UTC' })}
            </OfferDataSheetValue>

            {isFuel && offer.eta && offer.port ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.eta')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.etaChanged}>
                        {formatDateTime({
                            date: offer.eta,
                            timeZoneId: offer.port.timeZoneId,
                        })}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {isFuel && offer.etd && offer.port ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.etd')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.etdChanged}>
                        {formatDateTime({
                            date: offer.etd,
                            timeZoneId: offer.port.timeZoneId,
                        })}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {offer.buyerReference ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.buyerReference')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.buyerReferenceChanged}>
                        {offer.buyerReference}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {offer.vendorReference ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.vendorReference')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.vendorReferenceChanged}>
                        {offer.vendorReference}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {offer.supplyMode ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.supplyMode')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.supplyModeChanged}>
                        {getSupplyModeString()}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {isLubes && offer.noticeDays ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.noticeDays')}</DataSheet.Label>
                    <OfferDataSheetValue changed={offer.changes.noticeDaysChanged}>
                        {offer.noticeDays}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {isFuel || offer.isSpot() ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.paymentTermsLabel')}</DataSheet.Label>
                    <OfferDataSheetValue
                        changed={
                            offer.changes.paymentTermReferenceChanged || offer.changes.paymentTermReferenceDaysChanged
                        }
                    >
                        {offer.paymentTermReference
                            ? formatPaymentTerms(offer.paymentTermReference, offer.paymentTermReferenceDays)
                            : '-'}
                    </OfferDataSheetValue>
                </>
            ) : null}

            {isLubes ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.offerNumber')}</DataSheet.Label>
                    <Text>{offer.offerNumber}</Text>
                </>
            ) : null}

            {role.isSupplier() && fee ? (
                <>
                    <DataSheet.Label>{translate('offer.detail.coreData.transactionFee')}</DataSheet.Label>
                    <Text>
                        {formatMoney({
                            value: fee.value,
                            currency: fee.currency,
                            minimumFractionDigits: 2,
                        })}
                    </Text>
                </>
            ) : null}

            <DataSheet.Label>{translate('offer.detail.coreData.dateCreated')}</DataSheet.Label>
            <Text>{createdBy({ order: offer })}</Text>
        </DataSheet>
    );
};
