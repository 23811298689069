import { useContextDays } from '@rehookify/datepicker';
import React from 'react';

import { formatDate } from '../../../common/helpers/formatDate.helper';
import { Characters } from '../../../common/helpers/unicode.helper';
import { IconCalendar } from '../../../common/icons/cdl/Calendar';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { Caption, CaptionProps } from '../../Caption/Caption';
import { InputLabel } from '../../Input/components/CoreInput';
import { InputContainer, InputContainerProps } from '../../Input/components/InputContainer';
import { InputLabelText } from '../../Input/components/InputLabelText';
import { LeadingVisualWrapper } from '../../Input/components/LeadingVisualWrapper';

interface CustomDateInputProps extends InputContainerProps {
    label?: string;
    markAsRequired?: boolean;
    caption?: string;
    CaptionIcon?: CaptionProps['Icon'];
}

export const CustomDateInput = ({ label, markAsRequired, caption, CaptionIcon, ...rest }: CustomDateInputProps) => {
    const { selectedDates } = useContextDays();

    return (
        <InputLabel>
            {label ? (
                <InputLabelText $isFocused={rest.$isFocused}>
                    {label}
                    {markAsRequired ? (
                        <>
                            &nbsp;
                            {Characters.BULLET}
                        </>
                    ) : null}
                </InputLabelText>
            ) : null}
            <InputContainer {...rest}>
                <LeadingVisualWrapper>
                    <IconCalendar width={24} height={24} color={theme.colors.foreground.muted} />
                </LeadingVisualWrapper>
                <Box height="36px" padding="6px 10px" style={{ cursor: 'text' }}>
                    {selectedDates[0] ? formatDate({ date: selectedDates[0].getTime() }) : null}
                </Box>
            </InputContainer>
            {caption ? (
                <Caption $negative={rest.$negative} Icon={CaptionIcon}>
                    {caption}
                </Caption>
            ) : null}
        </InputLabel>
    );
};
