import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { PopperContentProps } from '@radix-ui/react-popper';
import { ComponentType } from 'react';

import { IconDots } from '../../common/icons/cdl/Dots';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';

import { DropdownArrow } from './DropdownArrow';
import { DropdownMenuContent } from './DropdownMenuContent';
import { DropdownMenuItem } from './DropdownMenuItem';

interface Option {
    onSelect: DropdownMenuItemProps['onSelect'];
    label: string;
}

interface DropdownMenuProps extends Pick<PopperContentProps, 'align'> {
    options: Option[];
    tooltip?: string;
    Icon?: ComponentType;
    emphasis?: 'none' | 'medium';
}

export const DropdownMenu = ({ emphasis = 'none', ...props }: DropdownMenuProps) => {
    const trigger = (
        <RadixDropdownMenu.Trigger asChild onClick={(event) => event.stopPropagation()}>
            <IconButton Icon={props.Icon ?? IconDots} emphasis={emphasis} />
        </RadixDropdownMenu.Trigger>
    );

    return (
        <RadixDropdownMenu.Root modal={false}>
            {props.tooltip ? <Tooltip label={props.tooltip}>{trigger}</Tooltip> : trigger}

            <DropdownMenuContent sideOffset={5} align={props.align ?? 'start'}>
                {props.options.map((option, index) => (
                    <DropdownMenuItem
                        key={index}
                        onClick={(event) => event.stopPropagation()}
                        onSelect={option.onSelect}
                    >
                        <Text variant="small">{option.label}</Text>
                    </DropdownMenuItem>
                ))}
                <DropdownArrow />
            </DropdownMenuContent>
        </RadixDropdownMenu.Root>
    );
};
