import storage from 'local-storage-fallback';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { translate } from '../../common/helpers/translate.helper';

import { CopyToClipboardButton } from './CopyToClipboardButton';

export const Shortcuts = () => {
    return (
        <div>
            <Text variant="subtitle1">{translate('devTools.shortcuts.headline')}</Text>
            <ButtonGroup marginTop={4}>
                <CopyToClipboardButton value={() => storage.getItem('cl-session-token')} />
                {/* @ts-ignore (allow undefined functions to be called to throw an error) */}
                <Button destructive onClick={() => window.expectUndefinedFunctionToThrow()}>
                    {translate('devTools.shortcuts.throwError')}
                </Button>
            </ButtonGroup>
        </div>
    );
};
