import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { Box } from '../../common/ui/Box';
import { theme } from '../../common/ui/theme';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar = styled(Box)<ProgressBarProps>((props) => ({
    backgroundColor: theme.colors.accent.background,
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    width: `${props.progress}%`,
}));

interface VolumeBarProps {
    percentage: number;
    label: string;
    volume: number;
}

export const VolumeBar = ({ percentage, label, volume }: VolumeBarProps) => {
    const { isLubes } = useProductContext();
    const unit = isLubes ? 'L' : 'mt';
    return (
        <Box
            position="relative"
            width="100%"
            borderRadius={1}
            borderColor={theme.colors.border.muted}
            borderStyle="solid"
            borderWidth="1px"
        >
            <ProgressBar progress={percentage} />
            <Box position="relative" zIndex="2" display="flex" justifyContent="space-between" padding={4} gap={4}>
                <Text variant="small">{label}</Text>
                <Text variant="small" style={{ whiteSpace: 'nowrap' }}>
                    {formatNumber({
                        number: volume,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}{' '}
                    {unit}
                </Text>
            </Box>
        </Box>
    );
};
