import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { useChartTitleTranslations } from '../hooks/useChartTitleTranslations';
import { barOptions, ChartType } from '../utils/barOptions';
import { chartColors } from '../utils/chartColors';
import { pieOptions } from '../utils/pieOptions';

import { ChartGrid } from './ChartGrid';
import { ChartWrapper } from './ChartWrapper';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

interface ProductsTabStatisticsProps {
    productStatistics: BaseStatisticsMessage['product'];
}

export const ProductsTabStatistics = ({ productStatistics }: ProductsTabStatisticsProps) => {
    const chartTitles = useChartTitleTranslations();
    const productNames = productStatistics.map((product) => product.context.name);
    const backgroundColor = chartColors;

    const totalData = {
        labels: productNames,
        datasets: [
            {
                label: ChartType.TOTAL,
                data: productStatistics.map((product) => product.total.value),
                backgroundColor,
            },
        ],
    };

    const volumeData = {
        labels: productNames,
        datasets: [
            {
                label: ChartType.VOLUME,
                data: productStatistics.map((product) => Math.round(product.volume)),
                backgroundColor,
            },
        ],
    };
    return (
        <ChartGrid>
            <ChartWrapper title={chartTitles.total}>
                <Pie data={totalData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Pie data={volumeData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.total}>
                <Bar data={totalData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Bar data={volumeData} options={barOptions} />
            </ChartWrapper>
        </ChartGrid>
    );
};
