import { ApprovedApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { StatusBar } from '../../cdl/StatusBar/StatusBar';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';

interface ApprovedCalloutProps {
    approvalRequest: ApprovedApprovalRequestMessage;
}

export const ApprovedStatusBar = ({ approvalRequest }: ApprovedCalloutProps) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.responseMessage) {
            return translate('approvalRequest.statusBar.approvedNoComment.subHeadline', {
                date: formatDateTime({
                    date: approvalRequest.dateApproved,
                }),
                author: approvalRequest.controllerEmailAddress,
            });
        }

        return translate('approvalRequest.statusBar.approved.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateApproved }),
            author: approvalRequest.controllerEmailAddress,
            responseMessage: approvalRequest.responseMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.approved.headline');
    const subHeadline = makeSubHeadline();

    return (
        <StatusBar
            variant="positive"
            headline={headline}
            subHeadline={subHeadline}
            leadingVisual={<IconCircleCheck />}
        />
    );
};
