import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Route as RouteLubes } from '../../routes/_app._lubes.statistics';
import { Route as RouteFuel } from '../../routes/_app.fuel.statistics';
import { useSupplierStatistics } from '../hooks/useSupplierStatistics';

import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';
import { SupplierStatisticsContent } from './SupplierStatisticsContent';
import { SupplierStatisticsTabSwitch } from './SupplierStatisticsTabSwitch';

export const SupplierStatistics = () => {
    const { context } = useProductContext();
    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();

    const supplierStatisticsQuery = useSupplierStatistics({
        from: search.from,
        to: search.to,
        supplierIds: search.supplierIds,
        type: context,
    });

    if (supplierStatisticsQuery.isPending) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (supplierStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (supplierStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <SupplierStatisticsTabSwitch />
            <SupplierStatisticsContent statistics={supplierStatisticsQuery.data} />
        </div>
    );
};
