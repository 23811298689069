import { useNavigate } from '@tanstack/react-router';
import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { FormikPasswordResetState } from '../pages/PasswordResetPage';

export const FormikPasswordResetForm = () => {
    const navigate = useNavigate();
    const { isSubmitting, values } = useFormikContext<FormikPasswordResetState>();

    const handleBackToLoginClick = () => {
        navigate({
            to: '/login',
            state: {
                emailAddress: values.emailAddress,
            },
        });
    };

    return (
        <Form>
            <FormikInput name="emailAddress" type="email" label={translate('passwordReset.emailAddress')} />
            <Box marginTop={7}>
                <Button emphasis="high" size="large" isLoading={isSubmitting} width="100%">
                    {translate('passwordReset.next')}
                </Button>
                <Box marginTop={5} style={{ textAlign: 'center' }}>
                    <Button emphasis="low" width="100%" size="large" onClick={handleBackToLoginClick} type="button">
                        {translate('passwordReset.backToLogin')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
