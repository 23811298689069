import FileDownload from 'js-file-download';
import { useState } from 'react';

import { FileMessage } from '../../../../types/FileMessage';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { Text } from '../../../cdl/Text/Text';
import { TextButton } from '../../buttons/TextButton';
import { DeleteConfirmPopup } from '../../DeleteConfirmPopup/DeleteConfirmPopup';
import { extractFileName } from '../../helpers/extractFileName.helper';
import { formatDateTime } from '../../helpers/formatDateTime.helper';
import { translate } from '../../helpers/translate.helper';
import { IconPaperclip } from '../../icons/cdl/Paperclip';
import { IconX } from '../../icons/cdl/X';
import { Box } from '../../ui/Box';
import { theme } from '../../ui/theme';
import { useAttachmentsConfig } from '../hooks/useAttachmentsConfig';
import { useFileDelete } from '../hooks/useFileDelete';
import { useFileDownload } from '../hooks/useFileDownload';

import { AttachmentGrid } from './AttachmentGrid';

interface AttachmentRowProps {
    file: FileMessage;
    companyId?: string;
    counterCompanyName?: string;
}

export const AttachmentRow = ({ file, companyId, counterCompanyName }: AttachmentRowProps) => {
    const attachmentsConfig = useAttachmentsConfig();
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

    const fileDeleteMutation = useFileDelete();
    const fileDownloadMutation = useFileDownload();

    const deleteAttachment = (fileId: string) => {
        setIsLoading(true);
        return fileDeleteMutation.mutateAsync(fileId);
    };

    const downloadAttachment = async (fileId: string) => {
        const response = await fileDownloadMutation.mutateAsync(fileId);
        FileDownload(
            response.data,
            extractFileName(response.headers['content-disposition'] || '', translate('attachments.fileNameFallback'))
        );
    };

    const isUploadedFromUserCompany = file.companyId === companyId;

    return (
        <AttachmentGrid>
            <Box>
                <IconPaperclip color={theme.colors.accent.emphasis} />
            </Box>

            <Box>
                <TextButton
                    type="button"
                    onClick={() => downloadAttachment(file.id)}
                    style={{
                        wordBreak: 'break-all',
                    }}
                >
                    {file.fileName}
                </TextButton>
            </Box>

            {isUploadedFromUserCompany && attachmentsConfig.hasWriteRights ? (
                <Box gridRow="1/2" gridColumn="3/4" position="relative" width="32px">
                    <IconButton
                        type="button"
                        Icon={IconX}
                        onClick={() => {
                            setShowDeleteConfirmPopup(true);
                        }}
                        style={{ position: 'absolute', top: '-6px' }}
                    />

                    <DeleteConfirmPopup
                        isOpen={showDeleteConfirmPopup}
                        onSubmit={() => deleteAttachment(file.id)}
                        onDismiss={() => setShowDeleteConfirmPopup(false)}
                        isSubmitting={isLoading}
                        headline={translate('attachments.popup.deleteAttachment.headline')}
                        text={translate('attachments.popup.deleteAttachment.text')}
                        itemToDelete={file.fileName}
                        deleteButtonText={translate('attachments.popup.deleteAttachment.delete')}
                    />
                </Box>
            ) : null}

            <Box gridColumn="2/3" gridRow="2/3">
                <Text size={12} weight="normal" color={theme.colors.foreground.muted}>
                    {isUploadedFromUserCompany || !counterCompanyName
                        ? translate('attachments.uploadNote', {
                              //@ts-ignore
                              date: formatDateTime({
                                  date: file.dateCreated,
                              }),
                          })
                        : translate('attachments.uploadNoteWithCompany', {
                              //@ts-ignore
                              date: formatDateTime({
                                  date: file.dateCreated,
                              }),
                              company: counterCompanyName,
                          })}
                </Text>
            </Box>
        </AttachmentGrid>
    );
};
