import styled from 'styled-components';

import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconInfoSquare } from '../../../../common/icons/cdl/InfoSquare';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';

const InfoIconWrapper = styled(Box)`
    color: ${get('colors.foreground.muted')};

    &:hover {
        color: ${get('colors.foreground.default')};
    }
`;

interface FuelStockInfoIconProps {
    dateLastUpdated: string;
}

export const FuelStockInfoIcon = ({ dateLastUpdated }: FuelStockInfoIconProps) => {
    const formattedDate = formatDateTime({ date: dateLastUpdated });

    return (
        <Tooltip label={translate('vessel.fuelStocks.lastUpdated', { date: formattedDate })}>
            <span>
                <InfoIconWrapper>
                    <IconInfoSquare height={20} width={20} />
                </InfoIconWrapper>
            </span>
        </Tooltip>
    );
};
