import { DeleteIconButton } from '../../../common/buttons/DeleteIconButton';
import { OverviewReadButton } from '../../../common/buttons/OverviewReadButton/OverviewReadButton';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';
import { OrderModel } from '../../model/OrderModel';

import { DraftActionsDropdownMenu } from './DraftActionsDropdwonMenu';
import { DuplicateOrderButton } from './DuplicateOrderButton';
import { useOrderDelete } from './useOrderDelete';
import { useOrderMarkRead } from './useOrderMarkRead';

interface OrderActionsProps {
    order: OrderModel;
}

export const OrderActions = ({ order }: OrderActionsProps) => {
    const role = useRole();
    const { mutate: markRead } = useOrderMarkRead();
    const { mutate: deleteOrder } = useOrderDelete();

    const onMarkRead = (order: OrderModel) => {
        markRead({ order, orderId: order.id, read: !order.read });
    };

    const onDeleteConfirm = (orderId: string) => {
        deleteOrder(orderId);
    };

    if (order.isDraft()) {
        if (role.isAdmin()) {
            return (
                <DeleteIconButton
                    onDeleteConfirm={() => onDeleteConfirm(order.id)}
                    popupHeadline={translate('alert.deleteDraft.headline', {
                        reference: order.buyerReference || '',
                    })}
                    popupText={translate('alert.deleteDraft.text')}
                    tooltip={translate('alert.deleteDraft.headline', {
                        reference: order.buyerReference || '',
                    })}
                />
            );
        }

        if (role.isCustomer() && role.hasWriteRights(order.customerId)) {
            return <DraftActionsDropdownMenu order={order} onDeleteConfirm={onDeleteConfirm} onMarkRead={onMarkRead} />;
        }

        return null;
    }

    return (
        <Box display="flex" flexWrap="nowrap" columnGap={2}>
            {role.isCustomer() && role.hasWriteRights(order.customerId) ? (
                <OverviewReadButton onClick={() => onMarkRead(order)} isRead={order.read} />
            ) : null}

            {role.isCustomer() && role.hasWriteRights(order.customerId) ? <DuplicateOrderButton order={order} /> : null}
        </Box>
    );
};
