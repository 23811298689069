import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';

export const useFuelEnquiryUpdate = (): ((order: any, orderId: string) => any) => {
    const updateOrderMutation = useMutation({ mutationFn: updateOrder });

    const navigate = useNavigate();
    const { addErrorToast } = useToasts();

    return (order, orderId) => {
        return updateOrderMutation.mutateAsync(
            {
                order,
                orderId,
            },
            {
                onSuccess: (order: any) => {
                    setTimeout(() => {
                        navigate({ to: '/fuel/order/$id', params: { id: order.id } });
                    });
                },
                onError: () => {
                    addErrorToast(translate('fuelEnquiry.toast.updateEnquiry.error'));
                },
            }
        );
    };
};
