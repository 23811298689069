import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { FuelOfferOverviewPage } from '../offer/OfferOverview/fuel/FuelOfferOverviewPage';

const FuelOfferSortDirectionArray = ['ASC', 'DESC'] as const;

export type FuelOfferSortDirection = (typeof FuelOfferSortDirectionArray)[number];

const FuelOfferSortFieldsArray = [
    'dateUpdated',
    'dateDelivery',
    'dateCreated',
    'validUntil',
    'quoteValidUntil',
] as const;

export type FuelOfferSortFields = (typeof FuelOfferSortFieldsArray)[number];

const FuelOfferTabsArray = [
    'enquired',
    'offered',
    'ordered',
    'acknowledged',
    'confirmed',
    'delivered',
    'canceled',
    'closed',
] as const;

export type FuelOfferTabs = (typeof FuelOfferTabsArray)[number];

const FuelOffersSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    tab: fallback(z.enum(FuelOfferTabsArray), 'enquired').default('enquired'),
    sortField: fallback(z.enum(FuelOfferSortFieldsArray), 'validUntil').default('validUntil'),
    sortDirection: fallback(z.enum(FuelOfferSortDirectionArray), 'ASC').default('ASC'),
});

export const Route = createFileRoute('/_app/fuel/offers')({
    validateSearch: zodSearchValidator(FuelOffersSearchSchema),
    component: FuelOfferOverviewPage,
});
