import { ReactNode } from 'react';
import styled from 'styled-components';

import { ErrorBoundary } from '../../ErrorBoundary';
import { Sidebar } from '../Sidebar/Sidebar';
import { Topbar } from '../Topbar/Topbar';
import { Box } from '../ui/Box';

const Content = styled(Box).attrs({
    backgroundColor: 'background.default',
    position: 'absolute',
    left: '220px',
    top: '60px',
    minHeight: 'calc(100vh - 60px)', // Viewport without Topbar
    right: 0,
})``;

interface PageProps {
    children: ReactNode;
    padding?: number;
}

export const Page = ({ children, padding = 8 }: PageProps) => {
    return (
        <div className="reset">
            <Sidebar />
            <Topbar />
            <Content padding={padding}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </Content>
        </div>
    );
};
