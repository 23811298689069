import { createFileRoute } from '@tanstack/react-router';

import { queries } from '../common/api/queryKeys/queries';
import { queryClient } from '../lib/queryClient';
import { LubesOfferComparePage } from '../order/detail/lubes/LubesOfferComparePage';

export const Route = createFileRoute('/_app/_lubes/order/$id')({
    async loader(context) {
        queryClient.ensureQueryData(queries.orders.detail(context.params.id));
    },
    component: LubesOfferComparePage,
});
