import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Callout } from '../../../cdl/Callout/Callout';
import { Text } from '../../../cdl/Text/Text';
import { FormContextCompanySelect } from '../../../common/form-elements/CompanySelect/ContextCustomerCompanySelect';
import { Form } from '../../../common/form-elements/Form/Form';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Box } from '../../../common/ui/Box';

import { ItemsTable } from './ItemsTable';
import { PriceInfoSection } from './PriceInfoSection';
import { usePriceCompareOverview } from './usePriceCompareOverview';

export const CompareOverview = () => {
    const [valid, setValid] = useState(false);
    useDocumentTitle(translate('compare.title'));

    const role = useRole();
    const { context } = useProductContext();
    const navigate = useNavigate();
    const { state, actions, priceMap, isFetching } = usePriceCompareOverview({
        enabled: valid,
    });

    const addItem = () => actions.addItem();
    const onRemoveItemClick = (index) => actions.removeItem(index);
    const onPortAddedClick = () => actions.addPort();
    const onRemovePortButtonClick = (index) => actions.removePort(index);
    const onChange = ({ data }) => actions.syncData(data);

    const onEnquiryButtonClick = (portId) => {
        navigate({
            to: '/orders/new',
            state: {
                items: state.items,
                portId,
            },
        });
    };

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {translate('compare.title')}
                </Text>

                <Callout
                    heading={translate('compare.page.bar.headline')}
                    description={translate('compare.page.bar.description')}
                />

                <Form data={state} onChange={onChange} isValid={setValid}>
                    {!role.isOneCompanyUser(context) ? (
                        <Box minWidth="200px" maxWidth="33%">
                            <FormContextCompanySelect dataPath="customerId" fallbackContext="LUBES" />
                        </Box>
                    ) : null}

                    <Box marginTop={!role.isOneCompanyUser(context) ? 8 : 0}>
                        <ItemsTable
                            items={state.items}
                            onRemoveItemClick={onRemoveItemClick}
                            onAddItemClick={addItem}
                        />
                    </Box>

                    <Box marginTop={8}>
                        <PriceInfoSection
                            isFetching={isFetching}
                            portInformation={state.portInformation}
                            customerId={state.customerId}
                            priceMap={priceMap}
                            onRemovePortButtonClick={onRemovePortButtonClick}
                            onEnquiryButtonClick={onEnquiryButtonClick}
                            onPortAddedClick={onPortAddedClick}
                        />
                    </Box>
                </Form>
            </PageGrid>
        </Page>
    );
};
