import styled from 'styled-components';

import { Box } from '../../common/ui/Box';

export const ApprovalRequestContentContainer = styled(Box).attrs({
    display: 'grid',
    gap: 9,
    padding: 9,
    maxWidth: '1200px',
    marginX: 'auto',
})``;
