import { translate } from '../../helpers/translate.helper';
import { useRole } from '../../hooks/useRole';
import { Box } from '../../ui/Box';

import { CustomerCompanySelect, CustomerCompanySelectProps } from './CustomerCompanySelect';

// To be used at overview pages within a flex container

interface OverviewCustomerCompanySelectProps<IsMulti extends boolean>
    extends Omit<CustomerCompanySelectProps<IsMulti>, 'dropdownWidth' | 'placeholder' | 'isMulti'> {}

export const OverviewCustomerCompanySelect = (props: OverviewCustomerCompanySelectProps<true>) => {
    const role = useRole();
    return (
        <Box minWidth="250px">
            <CustomerCompanySelect
                {...props}
                dropdownWidth="520px"
                placeholder={
                    role.isAdmin() ? translate('placeholder.filter.customer') : translate('placeholder.filter.company')
                }
                isMulti
            />
        </Box>
    );
};
