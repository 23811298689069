import { useMatches } from '@tanstack/react-router';

import { useRole } from '../hooks/useRole';

// Mapping of category routes to their aliases.
const ACTIVE_PATH_ALIASES = {
    '/ports': '/port',
    '/orders': '/order',
    '/offers': '/offer',
    '/vessels': '/vessel',
    '/users': '/user',
    '/customers': '/customer',
    '/customer-groups': ['/customer-group', '/customer'],
    '/supplier-groups': ['/supplier-group', '/supplier'],
    '/suppliers': '/supplier',
    '/fuel/orders': '/fuel/order',
    '/fuel/offers': '/fuel/offer',
    '/fuel/vessels': '/fuel/vessel',
};

// Check if the resolved link is an alias.
const isAlias = (to: string): to is keyof typeof ACTIVE_PATH_ALIASES => to in ACTIVE_PATH_ALIASES;

// This is a temporary solution to highlight the active category in the sidebar.
export const useRouteInCategory = (to: string) => {
    const role = useRole();
    const matches = useMatches();

    const isRouteInCategory = () => {
        // Make offer routes to order subroutes for customers.
        if (role.isCustomer() && matches.some((match) => match.routeId.startsWith('/_app/offer'))) {
            return to.includes('/orders');
        }
        if (role.isCustomer() && matches.some((match) => match.routeId.startsWith('/_app/fuel/offer'))) {
            return to.includes('/fuel/orders');
        }

        // Handle subroutes with different paths
        if (isAlias(to)) {
            const aliases = ACTIVE_PATH_ALIASES[to];

            // Handle aliases with more than one subroute
            if (Array.isArray(aliases)) {
                return aliases.some((alias) => matches.at(-1)!.pathname.includes(alias));
            }

            return matches.at(-1)!.pathname.includes(aliases);
        }

        return to.includes(matches.at(-1)!.pathname);
    };

    return isRouteInCategory();
};
