import styled from 'styled-components';

import { useRole } from '../hooks/useRole';
import { ProductContextSwitch } from '../Topbar/ProductContextSwitch';
import { Box } from '../ui/Box';

import { AdminSidebarNavigation } from './admin/AdminSidebarNavigation';
import { ContactUs } from './components/ContactUs';
import { CustomerSidebarNavigation } from './customer/CustomerSidebarNavigation';
import { SupplierSidebarNavigation } from './supplier/SupplierSidebarNavigation';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 220px;
    background: linear-gradient(90deg, #fff 0%, #f9f9fa 100%);
    display: grid;
    grid-template-rows: 60px auto;
    height: 100%;
`;

export const Sidebar = () => {
    const role = useRole();

    return (
        <Wrapper borderRight={1}>
            <Box paddingX={5} paddingBottom={0} paddingTop={4}>
                <ProductContextSwitch />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                paddingX={5}
                paddingTop={4}
                paddingBottom={6}
                height="100%"
            >
                <div>
                    {role.isAdmin() ? <AdminSidebarNavigation /> : null}
                    {role.isCustomer() ? <CustomerSidebarNavigation /> : null}
                    {role.isSupplier() ? <SupplierSidebarNavigation /> : null}
                </div>
                {!role.isAdmin() ? <ContactUs /> : null}
            </Box>
        </Wrapper>
    );
};
