import { ProductContext } from '../../../../types/ProductContext';
import { Text } from '../../../cdl/Text/Text';
import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';
import { Route } from '../../../routes/_app._lubes.offers';

import { LubesOfferOverviewSortSelect } from './LubesOfferOverviewSortSelect';
import { LubesOfferOverviewTable } from './LubesOfferOverviewTable';

export const LubesOfferOverviewPage = () => {
    const role = useRole();
    const { context } = useProductContext();

    const title = t(role.isAdmin() ? 'offer.overviewPageTitle' : 'order.overviewPageTitle');
    useDocumentTitle(title);

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {title}
                </Text>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    <Box display="flex" columnGap={4}>
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={
                                    role.isAdmin()
                                        ? t('offer.searchInputPlaceholder')
                                        : t('order.searchInputPlaceholder')
                                }
                                value={search.searchQuery}
                                onChange={(searchQuery) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            searchQuery,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        </Box>

                        {role.isAdmin() ? (
                            <OverviewCustomerCompanySelect
                                context={ProductContext.LUBES}
                                value={search.customerIds}
                                onChange={(customerIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            customerIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <OverviewSupplierCompanySelect
                                context={ProductContext.LUBES}
                                value={search.supplierIds}
                                onChange={(supplierIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            supplierIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}
                    </Box>

                    <Box minWidth="250px">
                        <LubesOfferOverviewSortSelect
                            onChange={(sortValue) => {
                                navigate({
                                    search: (previousSearch) => ({
                                        ...previousSearch,
                                        sortValue,
                                        page: 0,
                                    }),
                                });
                            }}
                            value={search.sortValue}
                        />
                    </Box>
                </Box>

                <LubesOfferOverviewTable />
            </PageGrid>
        </Page>
    );
};
