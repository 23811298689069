import { useState } from 'react';

import { Button } from '../cdl/Button/Button';
import { Callout } from '../cdl/Callout/Callout';
import { Input } from '../cdl/Input/Input';
import { Text } from '../cdl/Text/Text';
import { useToasts } from '../cdl/Toast/useToasts';
import { translate } from '../common/helpers/translate.helper';
import { Box } from '../common/ui/Box';

import { IntegrationOfferSelect } from './components/IntegrationOfferSelect';
import { useOnePortOrder } from './useOnePortOrder';
import { useOnePortQuote } from './useOnePortQuote';
import { useOnePortRebuildMapping } from './useOnePortRebuildMapping';

export const OnePortPageContent = () => {
    return (
        <div>
            <Callout
                heading={translate('integrationsOverview.calloutOnePort.headline')}
                description={translate('integrationsOverview.calloutOnePort.description')}
            />
            <Box display="grid" rowGap={8} maxWidth="400px" marginTop={6}>
                <QuoteSection />
                <OrderSection />
                <RebuildSection />
            </Box>
        </div>
    );
};

const QuoteSection = () => {
    const [quoteState, setQuoteState] = useState({
        offerId: '',
        supplierId: '',
        customerId: '',
        vesselId: '',
    });
    const onePortQuoteMutation = useOnePortQuote();
    const { addToast, addErrorToast } = useToasts();

    const createQuote = () => {
        onePortQuoteMutation.mutate(
            {
                offerId: quoteState.offerId,
                supplierId: quoteState.supplierId,
                customerId: quoteState.customerId,
                vesselId: quoteState.vesselId,
            },
            {
                onSuccess: () => {
                    addToast(translate('integrationsOverview.quoteSuccessToast'));
                },
                onError: (error) => {
                    if (error.response?.status === 400) {
                        addErrorToast(error.response.data.reason);
                    } else {
                        addErrorToast(translate('integrationsOverview.unknownError'));
                    }
                },
            }
        );
    };
    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.createQuote')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={quoteState.offerId}
                    onChange={(option) => {
                        setQuoteState({
                            offerId: option.offer.id,
                            supplierId: option.offer.supplierId,
                            customerId: option.offer.customerId,
                            vesselId: option.offer.vesselId,
                        });
                    }}
                />
            </Box>

            <Button
                disabled={
                    !quoteState.offerId || !quoteState.supplierId || !quoteState.customerId || !quoteState.vesselId
                }
                onClick={createQuote}
            >
                {translate('integrationsOverview.createQuote')}
            </Button>
        </Box>
    );
};

const OrderSection = () => {
    const [orderState, setOrderState] = useState({
        offerId: '',
        supplierId: '',
        customerId: '',
        vesselId: '',
    });
    const onePortOrderMutation = useOnePortOrder();
    const { addToast, addErrorToast } = useToasts();

    const createOrder = () => {
        onePortOrderMutation.mutate(
            {
                offerId: orderState.offerId,
                supplierId: orderState.supplierId,
                customerId: orderState.customerId,
                vesselId: orderState.vesselId,
            },
            {
                onSuccess: () => {
                    addToast(translate('integrationsOverview.orderSuccessToast'));
                },
                onError: (error) => {
                    if (error.response?.status === 400) {
                        addErrorToast(error.response.data.reason);
                    } else {
                        addErrorToast(translate('integrationsOverview.unknownError'));
                    }
                },
            }
        );
    };

    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.createOrder')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={orderState.offerId}
                    onChange={(option) => {
                        setOrderState({
                            offerId: option.offer.id,
                            supplierId: option.offer.supplierId,
                            customerId: option.offer.customerId,
                            vesselId: option.offer.vesselId,
                        });
                    }}
                />
            </Box>

            <Button
                disabled={
                    !orderState.offerId || !orderState.supplierId || !orderState.customerId || !orderState.vesselId
                }
                onClick={createOrder}
            >
                {translate('integrationsOverview.createOrder')}
            </Button>
        </Box>
    );
};

const RebuildSection = () => {
    const [state, setState] = useState({
        offerId: '',
        confirmedOrderNumber: '',
    });
    const onePortRebuildMappingMutation = useOnePortRebuildMapping();
    const { addToast, addErrorToast } = useToasts();

    const rebuildMapping = () => {
        onePortRebuildMappingMutation.mutate(
            {
                offerId: state.offerId,
                confirmedOrderNumber: state.confirmedOrderNumber,
            },
            {
                onSuccess: () => {
                    addToast(translate('integrationsOverview.mappingSuccessToast'));
                },
                onError: (error) => {
                    const supportedErrorCodes = [400, 404, 500];

                    if (error.response?.status && supportedErrorCodes.includes(error.response.status)) {
                        addErrorToast(error.response.data);
                    } else {
                        addErrorToast(translate('integrationsOverview.unknownError'));
                    }
                },
            }
        );
    };

    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.rebuildMapping')}
            </Text>

            <Box width="100%" display="flex" flexDirection="column" gap={4}>
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={state.offerId}
                    onChange={(option) => {
                        setState({
                            ...state,
                            offerId: option.offer.id,
                        });
                    }}
                />

                <Input
                    value={state.confirmedOrderNumber}
                    onChange={(e) => setState({ ...state, confirmedOrderNumber: e.target.value })}
                    label={translate('integrationsOverview.confirmedOrderNumberLabel')}
                />
            </Box>

            <Button disabled={!state.offerId || !state.confirmedOrderNumber} onClick={rebuildMapping}>
                {translate('integrationsOverview.rebuildMapping')}
            </Button>
        </Box>
    );
};
