import { useLocation, useNavigate } from '@tanstack/react-router';

import { useProductContext } from './useProductContext';

export const useNavigateToOriginLocation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isFuel } = useProductContext();

    const originLocation = location.state?.originLocation;

    return () => {
        if (originLocation) {
            navigate({ to: originLocation.pathname, search: originLocation.search, state: originLocation.state });
        } else {
            navigate({ to: isFuel ? '/fuel/dashboard' : '/dashboard' });
        }
    };
};
