import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { CompanyMessage } from '../../../../types/CompanyMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Route } from '../../../routes/_app._lubes.customers';
import { AssignmentStatusBadge } from '../../common/components/AssignmentStatusBadge';
import { useAssignmentsPagination } from '../../common/hooks/useAssignmentsPagination';

export const CustomersOverviewTable = () => {
    const role = useRole();

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const supplierIds =
        search.supplierIds?.length > 0
            ? search.supplierIds
            : role.getCompaniesWithType(ProductContext.LUBES).map((company: CompanyMessage) => company.id);

    const customersPaginationQuery = useAssignmentsPagination({ supplierIds, page: search.page });

    if (customersPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (customersPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    if (customersPaginationQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder data={customersPaginationQuery.data.content}>
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.name')}>
                    {(row) => row.customer.name}
                </TableBuilderColumn>
                {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                    <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.company')}>
                        {(row) => row.supplier.name}
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.status')}>
                    {(row) => <AssignmentStatusBadge status={row.status} />}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={search.page}
                pageTotal={customersPaginationQuery.pageTotal}
                onPageChange={(page) => {
                    navigate({ search: (previousSearch) => ({ ...previousSearch, page }) });
                }}
            />
        </div>
    );
};
