import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { agentsSearchSchema } from '../../types/agentsSearchSchema';
import { LubesAgentsOverviewPage } from '../agent/LubesAgentsOverviewPage';

export const Route = createFileRoute('/_app/_lubes/agents/')({
    component: LubesAgentsOverviewPage,
    validateSearch: zodSearchValidator(agentsSearchSchema),
});
