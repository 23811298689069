import { createFileRoute, redirect } from '@tanstack/react-router';

import { ProductContext } from '../../types/ProductContext';
import { FuelAgentDetailPage } from '../agent/detail/FuelAgentDetailPage';
import { queries } from '../common/api/queryKeys/queries';
import { queryClient } from '../lib/queryClient';

export const Route = createFileRoute('/_app/fuel/agents/$id')({
    component: FuelAgentDetailPage,
    async loader({ params: { id } }) {
        const agent = await queryClient.fetchQuery({ ...queries.agents.detail(id) });

        if (agent.customer.type === ProductContext.LUBES) {
            throw redirect({
                to: '/agents/$id',
                replace: true,
                params: {
                    id,
                },
            });
        }
    },
});
