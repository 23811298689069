import * as Sentry from '@sentry/browser';

import { Role } from '../model/role.model';

let instance = null;

export class RoleService {
    role;

    static getInstance() {
        if (!instance) {
            instance = new RoleService();
        }
        return instance;
    }

    create(token) {
        this.role = new Role(token.authorities, token.accessLevel, token.companyAccesses);
        return this.role;
    }

    addUser(user) {
        this.role.user = user;
        return this.role;
    }

    addGroup(group) {
        this.role.group = group;
        return this.role;
    }

    addCompanies(companies) {
        this.role.addCompanies(companies);
        return this.role;
    }

    reset() {
        this.role = undefined;
        Sentry.setUser(null);
    }

    authenticated() {
        if (this.role) {
            Sentry.setUser({ id: this.role.user.id, email: this.role.user.email, segment: this.role.user.userType });
        }
    }

    get() {
        return this.role;
    }
}
