import React from 'react';

import { MoneyMessage } from '../../../../../../../types/MoneyMessage';
import { DefaultCell } from '../../../../../../cdl/TableBuilder/components/DefaultCell';
import { StyledDefaultTableBodyRow } from '../../../../../../cdl/TableBuilder/components/StyledDefaultTableBodyRow';
import { Text } from '../../../../../../cdl/Text/Text';
import { formatMoney } from '../../../../../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';

export interface Totals {
    volume: number;
    total?: MoneyMessage;
    price?: MoneyMessage;
}

interface TotalsRowProps {
    totals: Totals;
    showPrices: boolean;
}

export const TotalsRow = ({ totals, showPrices }: TotalsRowProps) => {
    return (
        <StyledDefaultTableBodyRow alternateRowColors active={false} selectable={false}>
            <DefaultCell />

            <DefaultCell colSpan={4}>
                <Text fontWeight="semiBold">{translate('global.total')}</Text>
            </DefaultCell>

            <DefaultCell numeric>
                <Text fontWeight="semiBold">{formatNumber({ number: totals.volume })}</Text>
            </DefaultCell>

            <DefaultCell />

            {showPrices ? (
                <DefaultCell numeric colSpan={1}>
                    {totals.price ? (
                        <Text fontWeight="semiBold">
                            {formatMoney({
                                value: totals.price.value,
                                currency: totals.price.currency,
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                            })}
                        </Text>
                    ) : null}
                </DefaultCell>
            ) : null}

            {showPrices ? (
                <DefaultCell numeric>
                    {totals.total ? (
                        <Text fontWeight="semiBold">
                            {formatMoney({
                                value: totals.total.value,
                                currency: totals.total.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Text>
                    ) : null}
                </DefaultCell>
            ) : null}
        </StyledDefaultTableBodyRow>
    );
};
