import { useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikTextArea } from '../../common/form-elements/formik/FormikTextArea';
import { Label } from '../../common/form-elements/Label/Label';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { IconCircle3 } from '../../common/icons/cdl/Circle3';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { Flex } from '../../common/ui/Flex';
import { Grid, GridItem } from '../../common/ui/Grid';

import { DataSummary } from './DataSummary';
import { FormikFuelEnquiryHeader } from './FormikFuelEnquiryHeader';
import { FuelEnquiryAttachments } from './FuelEnquiryAttachments';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';
import { PreviousButton } from './PreviousButton';
import { ProductSummary } from './ProductSummary';
import { SaveAsDraftButton } from './SaveAsDraftButton';

export const FuelOrderFlow = () => {
    const { isValid, submitForm, isSubmitting } = useFormikContext();
    const { setPage } = useEnquiryCreation();
    useDocumentTitle(translate('fuelEnquiry.orderTitle'));

    return (
        <Box>
            <FormikFuelEnquiryHeader title={translate('fuelEnquiry.orderTitle')} />

            <Box padding={6} display="grid" gap={6}>
                <CardVariation paddingX={0} paddingY={0}>
                    <StatusBar
                        headline={translate('fuelEnquiry.third.statusBarOrder.headline')}
                        subHeadline={translate('fuelEnquiry.third.statusBarOrder.subHeadline')}
                        type={StatusBarType.WHITE}
                        icon={<IconCircle3 />}
                    />
                </CardVariation>

                <CardVariation>
                    <Box paddingTop={4}>
                        <DataSummary />
                    </Box>
                </CardVariation>

                <CardVariation>
                    <ProductSummary />
                </CardVariation>

                <CardVariation>
                    <Grid>
                        <GridItem width={1 / 2}>
                            <FormikTextArea
                                label={translate('fuelEnquiry.third.textarea.title')}
                                placeholder={translate('fuelEnquiry.third.textarea.placeholder')}
                                name="comment"
                            />
                        </GridItem>
                        <GridItem width={1 / 2}>
                            <Label label={translate('fuelEnquiry.third.attachments.label')}>
                                <FuelEnquiryAttachments />
                            </Label>
                        </GridItem>
                    </Grid>

                    <Flex justifyContent="center" marginTop={8}>
                        <ButtonGroup>
                            <PreviousButton onClick={() => setPage(2)} />
                            <SaveAsDraftButton />
                            <Button emphasis="high" disabled={!isValid} onClick={submitForm} isLoading={isSubmitting}>
                                {translate('fuelEnquiry.navigation.sendOrder')}
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </CardVariation>

                <FormikDebug />
            </Box>
        </Box>
    );
};
