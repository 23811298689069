import { createFileRoute, Outlet } from '@tanstack/react-router';

import { getRole } from '../common/hooks/useRole';

export const Route = createFileRoute('/_app/_lubes')({
    beforeLoad: () => {
        getRole().updateContext('LUBES');
    },
    component: () => <Outlet />,
});
