import { translate } from '../../../common/helpers/translate.helper';
import { SortSelect } from '../../../common/SortSelect/SortSelect';
import { FuelOrderSortDirection, FuelOrderSortFields } from '../../../routes/_app.fuel.orders';

export interface FuelOrderSortOption {
    label: string;
    value: {
        sortField: FuelOrderSortFields;
        sortDirection: FuelOrderSortDirection;
    };
}

interface FuelOrderOverviewSortSelectProps {
    onChange: (value: FuelOrderSortOption['value']) => void;
    value: FuelOrderSortOption['value'];
}

export const FuelOrderOverviewSortSelect = ({ onChange, value }: FuelOrderOverviewSortSelectProps) => {
    const orderSortOptions: FuelOrderSortOption[] = [
        {
            label: translate('sortOptions.latest'),
            value: {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.deliveryDate'),
            value: {
                sortField: 'dateDelivery',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.dateCreated'),
            value: {
                sortField: 'dateCreated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.enquiryValidity'),
            value: {
                sortField: 'validUntil',
                sortDirection: 'ASC',
            },
        },
    ];

    const selectedOption = orderSortOptions.find(
        (option) => option.value.sortField === value.sortField && option.value.sortDirection === value.sortDirection
    ) as FuelOrderSortOption;

    return (
        <SortSelect
            value={selectedOption}
            options={orderSortOptions}
            onChange={(option) => {
                if (option) {
                    onChange(option.value as FuelOrderSortOption['value']);
                }
            }}
        />
    );
};
