import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { CustomersOverviewPage } from '../assignment/lubes/CustomersOverviewPage';

const customersSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    supplierIds: fallback(z.array(z.string()), []).default([]),
});

export const Route = createFileRoute('/_app/_lubes/customers')({
    component: CustomersOverviewPage,
    validateSearch: zodSearchValidator(customersSearchSchema),
});
