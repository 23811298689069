import { translate } from '../../../common/helpers/translate.helper';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';
import { Route } from '../../../routes/_app.customer-groups';

export const CustomerGroupSearch = () => {
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const handleChange = (newValue: string) => {
        navigate({ search: { searchQuery: newValue, page: 0 } });
    };

    return (
        <Box width="300px">
            <DebouncedSearchTextInput
                onChange={handleChange}
                value={search.searchQuery}
                placeholder={translate('customergroups.searchForCustomerGroup')}
            />
        </Box>
    );
};
