import { ReactNode } from 'react';

import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

interface DrawerFormLayoutProps {
    title: string;
    children: ReactNode;
    isSubmitButtonDisabled?: boolean;
    submitButtonText?: string;
}

export const DrawerFormLayout = ({
    isSubmitButtonDisabled = false,
    submitButtonText = translate('btn.save.default'),
    ...props
}: DrawerFormLayoutProps) => {
    return (
        <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
            <Box padding={7} paddingBottom={6}>
                <Text as="h1" variant="title">
                    {props.title}
                </Text>
            </Box>

            <Box paddingX={7} display="flex" flexDirection="column" overflowY="auto" rowGap={5}>
                {props.children}
            </Box>

            <Box padding={7}>
                <Button emphasis="high" type="submit" size="large" disabled={isSubmitButtonDisabled} width="100%">
                    {submitButtonText}
                </Button>
            </Box>
        </Box>
    );
};
