import { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { getOfferById, getOffers } from '../../common/api/clients/gateway.api';
import { OfferModel } from '../../offer/model/OfferModel';

import { IntegrationOfferOptionDoubleLineOption } from './IntegrationOfferOptionDoubleLineOption';

export interface IntegrationOfferSelectOption {
    label: string;
    value: string;
    offer: OfferModel;
}

interface IntegrationOfferSelectProps extends Omit<ComboBoxProps<DefaultOption, false>, 'onChange' | 'value'> {
    value: string;
    onChange: (option: IntegrationOfferSelectOption) => void;
}

export function IntegrationOfferSelect({ value, onChange, label }: IntegrationOfferSelectProps) {
    const [selectedValue, setSelectedValue] = useState<IntegrationOfferSelectOption | null>(null);

    const loadOffersBySearchQuery = async (searchQuery: string) => {
        const response = await getOffers({ searchQuery });
        return response.content.map((offer) => {
            return {
                label: offer.buyerReference || offer.offerNumber,
                value: offer.id,
                offer,
            };
        });
    };

    const loadOfferById = useCallback(async (offerId: string): Promise<IntegrationOfferSelectOption> => {
        const offer = await getOfferById(offerId);
        return {
            label: offer.buyerReference || offer.offerNumber,
            value: offer.id,
            offer,
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (value) {
            loadOfferById(value).then((option: IntegrationOfferSelectOption) => {
                if (isMounted) {
                    setSelectedValue(option);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [value, loadOfferById]);

    const updateSelectedValue = (option: OnChangeValue<IntegrationOfferSelectOption, false>) => {
        onChange(option as IntegrationOfferSelectOption);
    };

    return (
        <ComboBox<IntegrationOfferSelectOption, false>
            label={label}
            onChange={updateSelectedValue}
            value={selectedValue}
            loadOptions={loadOffersBySearchQuery}
            dropdownWidth="600px"
            overrides={{ Option: IntegrationOfferOptionDoubleLineOption as any }}
        />
    );
}
