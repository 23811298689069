import { useNavigate } from '@tanstack/react-router';
import { Fragment, useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconArrowRampRight3 } from '../../../../common/icons/cdl/ArrowRampRight3';
import { IconCopy } from '../../../../common/icons/cdl/Copy';
import { IconExport } from '../../../../common/icons/cdl/Export';
import { IconList } from '../../../../common/icons/cdl/List';
import { IconPencil } from '../../../../common/icons/cdl/Pencil';
import { IconX } from '../../../../common/icons/cdl/X';
import { Box } from '../../../../common/ui/Box';
import { useOrderExport } from '../../../../offer/detail/common/hooks/useOrderExport';
import { toLubesUpdateOrderRequest } from '../../../model/order.mapper';
import { OrderModel, OrderState } from '../../../model/OrderModel';
import { useOrderRedispatch } from '../../common/useOrderRedispatch';
import { useOrderUpdate } from '../hooks/useOrderUpdate';

import { OrderCancelModal } from './OrderCancelModal';

interface LubesOfferCompareActionBarProps {
    order: OrderModel;
}

export const LubesOfferCompareActionBar = ({ order }: LubesOfferCompareActionBarProps) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const updateOrderMutation = useOrderUpdate();
    const { downloadPdf } = useOrderExport();
    const { addLoadingToast } = useToasts();

    const role = useRole();
    const navigate = useNavigate();
    const redispatchOrderMutation = useOrderRedispatch();
    const { addToast, addErrorToast } = useToasts();

    const prefillOrder = (order: OrderModel) => {
        navigate({
            to: '/orders/new',
            state: {
                order,
            },
        });
    };

    const onCancelPopupSubmit = async (cancelReason: string) => {
        await updateOrderMutation.mutateAsync({
            order: {
                ...toLubesUpdateOrderRequest(order),
                state: OrderState.CANCELED,
                cancelReason,
            },
            orderId: order.id,
        });

        setShowCancelModal(false);
    };

    const buttonList = [];

    if (!role.isAdmin() && !order.isFinished() && !order.isDraft() && role.hasWriteRights(order.customerId)) {
        buttonList.push(
            <Button
                onClick={() =>
                    navigate({
                        to: '/order/edit/$id',
                        params: {
                            id: order.id,
                        },
                    })
                }
                leadingVisual={<IconPencil height={16} width={16} />}
            >
                {translate('order.detail.edit')}
            </Button>
        );
    }

    if (role.isCustomer() && role.hasWriteRights(order.customerId)) {
        buttonList.push(
            <Button
                aria-label="Copy order"
                onClick={() => {
                    prefillOrder(order);
                }}
                leadingVisual={<IconCopy height={16} width={16} />}
            >
                {translate('order.detail.copy')}
            </Button>
        );
    }

    buttonList.push(
        <Button
            onClick={() => {
                return addLoadingToast(
                    () => downloadPdf(order.id),
                    translate('offer.detail.header.actions.export.downloadingPdf'),
                    translate('offer.detail.header.actions.export.success'),
                    translate('global.genericToastError')
                );
            }}
            leadingVisual={<IconExport height={16} width={16} />}
        >
            {translate('order.detail.header.actions.export')}
        </Button>
    );

    if (role.isCustomer() && !order.isFinished() && !order.isDraft() && role.hasWriteRights(order.customerId)) {
        buttonList.push(
            <Button onClick={() => setShowCancelModal(true)} leadingVisual={<IconX height={16} width={16} />}>
                {translate('order.detail.btncancel')}
            </Button>
        );
    }

    if (role.isAdmin() && order.state !== OrderState.DRAFT) {
        buttonList.push(
            <Button
                onClick={() => navigate({ to: '/offers', search: { searchQuery: order.id } })}
                leadingVisual={<IconList height={16} width={16} />}
            >
                {translate('order.detail.header.actions.showOfferList')}
            </Button>
        );
    }

    if (role.isAdmin() && order.isOpen()) {
        buttonList.push(
            <Button
                onClick={() => {
                    redispatchOrderMutation.mutate(order.id, {
                        onSuccess: () => {
                            addToast(translate('order.detail.redispatchSuccessToast'));
                        },
                        onError: () => {
                            addErrorToast(translate('order.detail.redispatchErrorToast'));
                        },
                    });
                }}
                leadingVisual={<IconArrowRampRight3 height={16} width={16} />}
            >
                {translate('order.detail.header.actions.redispatch')}
            </Button>
        );
    }

    return (
        <>
            <Box display="flex" gap={4} alignItems="end">
                {buttonList.map((entry, index) => (
                    <Fragment key={index}>{entry}</Fragment>
                ))}
            </Box>

            {showCancelModal ? (
                <OrderCancelModal
                    isOpen={showCancelModal}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelModal(false)}
                    isSubmitting={updateOrderMutation.isPending}
                />
            ) : null}
        </>
    );
};
