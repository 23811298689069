import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { Route } from '../../../routes/_app.user.detail.$id';
import { useUser } from '../hooks/useUser';

import { BasicUserDataSheetContent } from './BasicUserDataSheetContent';

export const UserDataSheet = () => {
    const { id } = Route.useParams();
    const userQuery = useUser(id);

    useDocumentTitle(
        userQuery.data
            ? translate('page.usershow', {
                  name: `${userQuery.data.firstname} ${userQuery.data.lastname}`,
              })
            : ''
    );

    if (userQuery.isPending) {
        return <DataSheet.Skeleton rows={6} />;
    }

    if (userQuery.isError) {
        return <Error />;
    }

    return (
        <DataSheet>
            <BasicUserDataSheetContent user={userQuery.data} />
        </DataSheet>
    );
};
