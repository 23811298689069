import { Form, FormikProps, useFormikContext } from 'formik';
import React, { ReactNode } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { Callout } from '../../../../cdl/Callout/Callout';
import { FormikCheckbox } from '../../../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { Text } from '../../../../cdl/Text/Text';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { isValidEmailAddress } from '../../../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { FormikTagListInput } from '../../../../common/TagListInput/FormikTagListInput';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../model/OfferModel';

import { BasicAccordionWithoutHeader } from './BasicAccordionWithoutHeader';
import { FormikShareOrderReceiversState } from './ShareOrderPopup';

interface CheckboxEmailLabelProps {
    children: ReactNode;
    emailAddress?: string;
}

const CheckboxEmailLabel = ({ children, emailAddress }: CheckboxEmailLabelProps) => {
    return (
        <Box display="flex" flexDirection="row" columnGap={3}>
            <Text>{children}</Text>
            {emailAddress ? <Text color="foreground.muted">({emailAddress})</Text> : null}
        </Box>
    );
};

interface FormikShareOrderReceiversContentProps extends FormikProps<FormikShareOrderReceiversState> {
    offer: OfferModel;
    onDismiss: () => void;
}

export const FormikShareOrderFormContent = (props: FormikShareOrderReceiversContentProps) => {
    const { values } = useFormikContext<FormikShareOrderReceiversState>();
    const indent = '28px';

    const valuesToCheck = [
        values.sendToVessel,
        values.sendToAgent,
        values.sendToSurveyor,
        values.sendToOthers,
        values.copyToSelf,
    ];
    const noCheckboxSelected = valuesToCheck.every((value) => value === false);

    return (
        <Form>
            <Box display="flex" flexDirection="column" rowGap={5} padding={6}>
                <Text variant="title">{translate('shareOrderPopup.title')}</Text>

                <Box>
                    <Text variant="small" color="foreground.muted">
                        {translate('shareOrderPopup.description')}
                    </Text>

                    <BasicAccordionWithoutHeader isOpen={!props.offer.vessel.email && values.sendToVessel}>
                        <Box paddingTop={5}>
                            <Callout variant="accent" description={translate('shareOrderPopup.noMailAddressWarning')} />
                        </Box>
                    </BasicAccordionWithoutHeader>
                </Box>

                <Box paddingBottom={6}>
                    <Text variant="fieldLabel" color="foreground.muted">
                        {translate('shareOrderPopup.selectRecipients')}
                    </Text>

                    <Box display="flex" flexDirection="column" alignItems="start" rowGap={4} marginTop={2}>
                        <Box alignSelf="stretch">
                            <FormikCheckbox
                                name="sendToVessel"
                                label={
                                    <CheckboxEmailLabel emailAddress={props.offer.vessel.email}>
                                        {translate('shareOrderPopup.sendToVessel')}
                                    </CheckboxEmailLabel>
                                }
                            />

                            <Box marginLeft={indent}>
                                <BasicAccordionWithoutHeader isOpen={values.sendToVessel && !props.offer.vessel.email}>
                                    <Box paddingBottom={4}>
                                        <FormikInput
                                            label={translate('shareOrderPopup.emailAddress')}
                                            name="vesselEmailAddress"
                                            placeholder={translate('shareOrderPopup.placeholder.emailAddress')}
                                        />
                                    </Box>
                                </BasicAccordionWithoutHeader>
                            </Box>
                        </Box>

                        {props.offer.agentInformation?.contact?.emailAddress ? (
                            <FormikCheckbox
                                name="sendToAgent"
                                label={
                                    <CheckboxEmailLabel
                                        emailAddress={props.offer.agentInformation.contact.emailAddress}
                                    >
                                        {translate('shareOrderPopup.sendToAgent')}
                                    </CheckboxEmailLabel>
                                }
                            />
                        ) : null}

                        {props.offer.surveyor?.emailAddress ? (
                            <FormikCheckbox
                                name="sendToSurveyor"
                                label={
                                    <CheckboxEmailLabel emailAddress={props.offer.surveyor.emailAddress}>
                                        {translate('shareOrderPopup.sendToSurveyor')}
                                    </CheckboxEmailLabel>
                                }
                            />
                        ) : null}

                        <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
                            <FormikCheckbox name="sendToOthers" label={translate('shareOrderPopup.sendToOthers')} />

                            <Box paddingLeft={indent} width="100%">
                                <BasicAccordionWithoutHeader isOpen={values.sendToOthers}>
                                    <FormikTagListInput
                                        name="othersEmailAddresses"
                                        label={translate('shareOrderPopup.otherRecipients')}
                                        validate={(value) => {
                                            if (!isValidEmailAddress(value)) {
                                                return translate('shareOrderPopup.emailAddressError');
                                            }
                                            return null;
                                        }}
                                    />
                                </BasicAccordionWithoutHeader>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    <FormikCheckbox name="copyToSelf" label={translate('shareOrderPopup.copyToSelf')} />

                    <Box display="flex" columnGap={4}>
                        <Button type="button" onClick={props.onDismiss}>
                            {translate('btn.cancel')}
                        </Button>
                        <Button
                            type="submit"
                            emphasis="high"
                            disabled={noCheckboxSelected}
                            isLoading={props.isSubmitting}
                        >
                            {translate('btn.send')}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <FormikDebug />
        </Form>
    );
};
