import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Route as RouteLubes } from '../../routes/_app._lubes.statistics';
import { Route as RouteFuel } from '../../routes/_app.fuel.statistics';
import { useAdminStatistics } from '../hooks/useAdminStatistics';

import { AdminStatisticsContent } from './AdminStatisticsContent';
import { AdminStatisticsTabSwitch } from './AdminStatisticsTabSwitch';
import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';

export const AdminStatistics = () => {
    const { context } = useProductContext();
    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();

    const adminStatisticsQuery = useAdminStatistics({
        from: search.from,
        to: search.to,
        customerIds: search.customerIds,
        supplierIds: search.supplierIds,
        type: context,
    });

    if (adminStatisticsQuery.isPending) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (adminStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (adminStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <AdminStatisticsTabSwitch />
            <AdminStatisticsContent statistics={adminStatisticsQuery.data} />
        </div>
    );
};
