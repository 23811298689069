import { translate } from '../../../common/helpers/translate.helper';
import { SortSelect } from '../../../common/SortSelect/SortSelect';
import { Box } from '../../../common/ui/Box';
import { FuelOfferSortDirection, FuelOfferSortFields } from '../../../routes/_app.fuel.offers';

export interface FuelOfferSortOption {
    label: string;
    value: {
        sortField: FuelOfferSortFields;
        sortDirection: FuelOfferSortDirection;
    };
}

interface FuelOfferOverviewSortSelectProps {
    onChange: (value: FuelOfferSortOption['value']) => void;
    value: FuelOfferSortOption['value'];
}

export const FuelOfferOverviewSortSelect = ({ onChange, value }: FuelOfferOverviewSortSelectProps) => {
    const orderSortOptions: FuelOfferSortOption[] = [
        {
            label: translate('sortOptions.latest'),
            value: {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.deliveryDate'),
            value: {
                sortField: 'dateDelivery',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.dateCreated'),
            value: {
                sortField: 'dateCreated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.enquiryValidity'),
            value: {
                sortField: 'validUntil',
                sortDirection: 'ASC',
            },
        },
        {
            label: translate('sortOptions.offerValidity'),
            value: {
                sortField: 'quoteValidUntil',
                sortDirection: 'ASC',
            },
        },
    ];

    const selectedOption = orderSortOptions.find(
        (option) => option.value.sortField === value.sortField && option.value.sortDirection === value.sortDirection
    ) as FuelOfferSortOption;

    return (
        <Box width="250px">
            <SortSelect
                value={selectedOption}
                options={orderSortOptions}
                onChange={(option) => {
                    if (option) {
                        onChange(option.value as FuelOfferSortOption['value']);
                    }
                }}
            />
        </Box>
    );
};
