import { useNavigate } from '@tanstack/react-router';

import { DemandInformationVessel } from '../../../types/DemandInformationMessage';
import { DropdownMenu } from '../../cdl/DropdownMenu/DropdownMenu';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { useLubesShareDemandWithVessel } from '../hooks/useLubesShareDemandWithVessel';
import { getDefaultDemandRange } from '../utils/getDefaultDemandRange';

interface DemandLubesAdditionalActionsProps {
    vessel: DemandInformationVessel;
}

export const DemandLubesAdditionalActions = ({ vessel }: DemandLubesAdditionalActionsProps) => {
    const navigate = useNavigate();
    const { addToast, addErrorToast } = useToasts();

    const shareWithVesselMutation = useLubesShareDemandWithVessel();

    const navigateToCreateEnquiry = () => {
        navigate({ to: '/orders/new', state: { vesselId: vessel.id, customerId: vessel.customerId } });
    };

    const shareWithVessel = (vesselEmailAddress: string) => {
        const [fromDate, toDate] = getDefaultDemandRange();

        const requestMessage = {
            receiverEmailAddresses: [vesselEmailAddress],
            copyToSelf: true,
            fromDate,
            toDate,
        };

        shareWithVesselMutation.mutate(
            {
                vesselId: vessel.id,
                requestMessage,
            },
            {
                onSuccess: () => {
                    addToast(translate('demand.shareDemandWithVesselSuccess'));
                },
                onError: () => {
                    addErrorToast(translate('demand.shareDemandWithVesselError'));
                },
            }
        );
    };

    const options = [
        {
            label: translate('demand.createLubesEnquiry'),
            onSelect: navigateToCreateEnquiry,
        },
    ];

    if (vessel.email && vessel.latestStocks.length && vessel.stops.length) {
        options.push({
            label: translate('demand.shareDemandWithVessel'),
            onSelect: () => shareWithVessel(vessel.email!),
        });
    }

    return <DropdownMenu align="end" options={options} />;
};
