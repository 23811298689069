import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { SupplierGroupsOverviewPage } from '../admin/suppliergroup/SupplierGroupsOverviewPage';

const supplierGroupsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
});

export const Route = createFileRoute('/_app/supplier-groups')({
    component: SupplierGroupsOverviewPage,
    validateSearch: zodSearchValidator(supplierGroupsSearchSchema),
});
