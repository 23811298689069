import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { get } from '../../common/ui/get';

export const TextWithExternalLink = styled(Text).attrs({
    variant: 'small',
    as: 'p',
})`
    a {
        color: ${get('colors.accent.foreground')};

        &:hover,
        &:visited {
            color: ${get('colors.accent.foreground')};
            text-decoration: none;
        }
    }
`;
