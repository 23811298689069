import PropTypes from 'prop-types';

import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { AgentImportedLabel } from '../../common/AgentImportedLabel/AgentImportedLabel';
import { IconButton } from '../../cdl/IconButton/IconButton';
import { translate } from '../../common/helpers/translate.helper';
import { IconPencil } from '../../common/icons/cdl/Pencil';
import { Box } from '../../common/ui/Box';

import { ArchiveAgentPopupButton } from './ArchiveAgentPopupButton';
import { UpdateAgentForm } from '../UpdateAgentForm';
import { useAgentStateChange } from '../useAgentStateChange';

export const ActiveActions = ({ agent }) => {
    const agentStateChangeMutation = useAgentStateChange();
    const { push, pop } = useDrawer();

    const openEditModal = (agent) => {
        push({
            content: <UpdateAgentForm agentId={agent.id} onSuccess={pop} />,
        });
    };

    const onAgentArchive = (agent) => {
        const agentToArchive = {
            ...agent,
            active: false,
        };
        agentStateChangeMutation.mutate({
            agentId: agentToArchive.id,
            agent: agentToArchive,
        });
    };

    return agent.source ? (
        <AgentImportedLabel />
    ) : (
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
            <IconButton
                Icon={IconPencil}
                onClick={(e) => {
                    openEditModal(agent);
                    e.stopPropagation();
                }}
                tooltipLabel={translate('agents.editAgent')}
            />
            <ArchiveAgentPopupButton onConfirm={() => onAgentArchive(agent)} name={agent.name} />
        </Box>
    );
};

ActiveActions.propTypes = {
    agent: PropTypes.object.isRequired,
};
