import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { Box } from '../../../common/ui/Box';
import { Route } from '../../../routes/_app.assignments';

export const AssignmentsFilterSelects = () => {
    const navigate = Route.useNavigate();
    const search = Route.useSearch();

    return (
        <Box display="flex" gap={4}>
            <OverviewCustomerCompanySelect
                onChange={(customerIds) => {
                    navigate({
                        search: (previousSearch) => ({
                            ...previousSearch,
                            customerIds,
                            page: 0,
                        }),
                    });
                }}
                value={search.customerIds}
            />

            <OverviewSupplierCompanySelect
                value={search.supplierIds}
                onChange={(supplierIds) => {
                    navigate({
                        search: (previousSearch) => ({
                            ...previousSearch,
                            supplierIds,
                            page: 0,
                        }),
                    });
                }}
            />
        </Box>
    );
};
