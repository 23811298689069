import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { Tag } from '../../../cdl/Tag/Tag';
import { useEnums } from '../../../common/hooks/useEnums';
import { IconAlertOctagon } from '../../../common/icons/cdl/AlertOctagon';
import { IconAlertTriangle } from '../../../common/icons/cdl/AlertTriangle';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { IconCircleDashedX } from '../../../common/icons/cdl/CircleDashedX';
import { IconRotateClockwise2 } from '../../../common/icons/cdl/RotateClockwise2';

interface ApprovalStateTagProps {
    state: ApprovalRequestState;
}

export const ApprovalStateTag = (props: ApprovalStateTagProps) => {
    const { getHumanReadableValue } = useEnums();
    const translatedState = getHumanReadableValue('approvalRequestState', props.state);

    switch (props.state) {
        case ApprovalRequestState.PENDING:
            return (
                <Tag variant="accent" Icon={IconRotateClockwise2}>
                    {translatedState}
                </Tag>
            );
        case ApprovalRequestState.APPROVED:
            return (
                <Tag variant="positive" Icon={IconCheck}>
                    {translatedState}
                </Tag>
            );
        case ApprovalRequestState.DECLINED:
            return (
                <Tag variant="attention" emphasis Icon={IconAlertOctagon}>
                    {translatedState}
                </Tag>
            );
        case ApprovalRequestState.WITHDRAWN:
            return (
                <Tag variant="attention" Icon={IconAlertTriangle}>
                    {translatedState}
                </Tag>
            );
        case ApprovalRequestState.EXPIRED: {
            return (
                <Tag variant="default" Icon={IconCircleDashedX}>
                    {translatedState}
                </Tag>
            );
        }
    }
};
