import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { ContractOverviewPage } from '../contracts/ContractOverview/ContractOverviewPage';
import { ContractTab } from '../contracts/ContractOverview/contractTabsHelper';

const contractsSearchSchema = z.object({
    searchQuery: fallback(z.string(), '').default(''),
    customerGroupIds: fallback(z.array(z.string()), []).default([]),
    tab: fallback(z.nativeEnum(ContractTab), ContractTab.CONTRACTED).default(ContractTab.CONTRACTED),
    page: fallback(z.number(), 0).default(0),
});

export const Route = createFileRoute('/_app/fuel/contracts')({
    component: ContractOverviewPage,
    validateSearch: zodSearchValidator(contractsSearchSchema),
});
