import { ChartOptions } from 'chart.js';

import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';

export enum ChartType {
    COMPLETED = 'COMPLETED',
    PPL = 'PPL',
    TOTAL = 'TOTAL',
    VOLUME = 'VOLUME',
}

export const barOptions: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const, // Position the bars horizontal
    scales: {
        y: {
            grid: { display: false }, // Remove all x-axis grid lines
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            displayColors: false,
            callbacks: {
                label: (context) => {
                    const rawDataPoint = context.dataset.data.at(context.dataIndex);

                    if (!rawDataPoint) {
                        return '';
                    }

                    switch (context.dataset.label) {
                        case ChartType.COMPLETED:
                            return translate('reporting.barTooltipLabels.completedOrders', {
                                orders: formatNumber({ number: rawDataPoint }),
                            });
                        case ChartType.PPL:
                            return translate('reporting.barTooltipLabels.ppl', {
                                price: rawDataPoint.toString(),
                            });
                        case ChartType.TOTAL:
                            return translate('reporting.barTooltipLabels.total', {
                                total: formatNumber({
                                    number: rawDataPoint,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                            });
                        case ChartType.VOLUME:
                            return formatNumber({ number: rawDataPoint });
                    }
                },
            },
        },
    },
};
