import { Callout } from '../cdl/Callout/Callout';
import { translate } from '../common/helpers/translate.helper';
import { Box } from '../common/ui/Box';

import { ReimportRequisitionSection } from './components/ReimportRequisitionSection';

export const CloudFleetManagerPageContent = () => {
    return (
        <div>
            <Callout
                heading={translate('integrationsOverview.calloutCloudFleetManager.headline')}
                description={translate('integrationsOverview.calloutCloudFleetManager.description')}
            />
            <Box display="grid" maxWidth="800px" rowGap={8} marginTop={6}>
                <ReimportRequisitionSection />
            </Box>
        </div>
    );
};
