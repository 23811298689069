import { keepPreviousData } from '@tanstack/react-query';

import { GetOffersParams } from '../common/api/clients/gateway.api';
import { queries } from '../common/api/queryKeys/queries';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

import { OfferModel } from './model/OfferModel';

export const useOfferPagination = ({ page = 0, ...rest }: GetOffersParams) => {
    return usePaginationQuery<OfferModel>({
        queryKey: queries.offers.pagination({ page, ...rest }).queryKey,
        queryFn: queries.offers.pagination({ page, ...rest }).queryFn,
        prefetchQueryKey: queries.offers.pagination({ page: page + 1, ...rest }).queryKey,
        prefetchQueryFn: queries.offers.pagination({ page: page + 1, ...rest }).queryFn,
        options: { placeholderData: keepPreviousData },
    });
};
