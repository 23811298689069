import { linkOptions, useSearch } from '@tanstack/react-router';

import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../common/Error/Error';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { IconAnchor } from '../../common/icons/cdl/Anchor';
import { IconCheck } from '../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';
import { useVesselPagination } from '../useVesselPagination';

import { Route } from '../../routes/_app.vessels';
import { useVesselPaginationTotals } from './useVesselPaginationTotals';

export const VesselOverviewTable = () => {
    const search = useSearch({ strict: false });
    const navigate = Route.useNavigate();

    const role = useRole();
    const { isFuel, isLubes, context } = useProductContext();
    const active = search.tab === 'active';

    const handleCustomerIds = (customerIds) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return null;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const { data: vesselTotals } = useVesselPaginationTotals({
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
    });

    const vesselPaginationQuery = useVesselPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        active,
    });

    const totals = {
        active: vesselTotals?.active,
        inactive: vesselTotals?.inactive,
    };

    if (vesselPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) });
                    }}
                    totals={totals}
                />
                <LoadingIndicator />
            </div>
        );
    }

    if (vesselPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) });
                    }}
                    totals={totals}
                />

                <Error />
            </div>
        );
    }

    if (!vesselPaginationQuery.data.content.length) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) });
                    }}
                    totals={totals}
                />
                <TableEmptyState Icon={IconAnchor} text={translate('vessel.emptylist')} />
            </div>
        );
    }

    const buildLinkOptions = (vessel) => {
        if (!role.isAdmin() && isFuel) {
            return linkOptions({
                to: '/fuel/vessel/$id',
                params: {
                    id: vessel.id,
                },
            });
        }
        return linkOptions({
            to: '/vessel/$id',
            params: {
                id: vessel.id,
            },
        });
    };

    return (
        <div>
            <TabSwitch
                selectedContext={search.tab}
                onSelect={(tab) => {
                    navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) });
                }}
                totals={totals}
            />

            <TableBuilder data={vesselPaginationQuery.data.content} rowLink={buildLinkOptions} grow={false}>
                <TableBuilderColumn header={translate('vessel.name')} width="25%" truncate>
                    {(vessel) => vessel.name}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('vessel.imo')} width="15%">
                    {(vessel) => vessel.imo}
                </TableBuilderColumn>
                {role.isAdmin() || !role.isOneCompanyUser(context) ? (
                    <TableBuilderColumn header={translate('vessel.company')} width="25%" truncate>
                        {(vessel) =>
                            vessel.customer
                                ? formatCompanyName({
                                      company: vessel.customer,
                                      includeType: role.isAdmin(),
                                  })
                                : ''
                        }
                    </TableBuilderColumn>
                ) : null}
                {isLubes ? (
                    <TableBuilderColumn header={translate('vessel.defaultsupplier')} width="20%" truncate>
                        {(vessel) => vessel.defaultSupplier?.name || ''}
                    </TableBuilderColumn>
                ) : null}
                {role.isAdmin() ? (
                    <TableBuilderColumn header={translate('vessel.paying')}>
                        {(vessel) => (vessel.paying ? <IconCheck width={16} height={16} /> : null)}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>
            <CenteredPagination
                pageTotal={vesselPaginationQuery.pageTotal}
                currentPage={search.page}
                onPageChange={(page) => {
                    navigate({ search: (previousSearch) => ({ ...previousSearch, page }) });
                }}
            />
        </div>
    );
};
