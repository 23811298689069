import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconFilter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v2.172a3 3 0 0 1-.879 2.121L16 12.414V19a1 1 0 0 1-.684.949l-6 2A1 1 0 0 1 8 21v-8.113L3.78 8.245A3 3 0 0 1 3 6.227V4Zm2 1v1.227a1 1 0 0 0 .26.672l4.48 4.928a1 1 0 0 1 .26.673v7.113l4-1.334V12a1 1 0 0 1 .293-.707l4.414-4.414A1 1 0 0 0 19 6.172V5H5Z"
            clipRule="evenodd"
        />
    </svg>
);
