import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { UserRole } from '../../../../types/UserRole';
import { UserType } from '../../../../types/UserType';
import { DrawerFormLayout } from '../../../cdl/Drawer/DrawerFormLayout';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../../common/form-elements/formik/FormikEnumOptionSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { UserServerError } from '../../detail/hooks/useUserInvite';

import { InviteUserCustomerForm } from './InviteUserCustomerForm';
import { FormikInviteUserState } from './InviteUserForm';
import { InviteUserSupplierForm } from './InviteUserSupplierForm';

interface InviteUserBaseFormProps {
    error?: UserServerError;
}

export const InviteUserBaseForm = ({ error }: InviteUserBaseFormProps) => {
    const { values, setValues, isValid, validateForm, setSubmitting } = useFormikContext<FormikInviteUserState>();

    const role = useRole();

    useEffect(() => {
        validateForm();
        setSubmitting(false);
    }, [error, setSubmitting, validateForm]);

    const resetCompanyIdsOnGroupChange = () => {
        setValues({
            ...values,
            companyIds: [],
        });
    };

    const resetOnUserTypeChange = () => {
        setValues({
            ...values,
            role: undefined,
            groupId: '',
            companyIds: [],
        });
    };

    const resetCompanyIdsOnRoleChange = (role?: string) => {
        if (role === UserRole.GROUP_ADMIN) {
            setValues({
                ...values,
                companyIds: [],
            });
        }
    };

    return (
        <Form style={{ height: '100%' }}>
            <DrawerFormLayout
                title={translate('user.inviteTitle')}
                isSubmitButtonDisabled={!isValid}
                submitButtonText={translate('user.sendInvite')}
            >
                <FormikInput name="email" autoComplete="off" label={translate('user.mail')} type="email" />

                {role.isAdmin() ? (
                    <>
                        <FormikInput name="firstname" autoComplete="off" label={translate('user.firstname')} />
                        <FormikInput name="lastname" autoComplete="off" label={translate('user.lastname')} />
                        <FormikInput name="phone" autoComplete="off" label={translate('user.phoneInput')} />
                        <FormikEnumOptionSelect
                            enumType="userType"
                            name="userType"
                            label={translate('user.userType')}
                            filter={(option) => option.value !== 'ADMIN'}
                            onOptionChange={resetOnUserTypeChange}
                        />
                    </>
                ) : null}

                {values.userType === UserType.CUSTOMER ? (
                    <InviteUserCustomerForm
                        values={values}
                        onGroupChange={resetCompanyIdsOnGroupChange}
                        onRoleChange={resetCompanyIdsOnRoleChange}
                    />
                ) : null}
                {values.userType === UserType.SUPPLIER ? (
                    <InviteUserSupplierForm
                        values={values}
                        onGroupChange={resetCompanyIdsOnGroupChange}
                        onRoleChange={resetCompanyIdsOnRoleChange}
                    />
                ) : null}
                <FormikDebug />
            </DrawerFormLayout>
        </Form>
    );
};
