import { FuelOrderTabs } from '../../../../routes/_app.fuel.orders';
import { FuelOrderSortOption } from '../FuelOrderOverviewSortSelect';

export const selectSortOption = (tab: FuelOrderTabs): FuelOrderSortOption['value'] => {
    if (tab === 'enquired') {
        return {
            sortField: 'validUntil',
            sortDirection: 'ASC',
        };
    }

    return {
        sortField: 'dateUpdated',
        sortDirection: 'DESC',
    };
};
