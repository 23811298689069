import { ReactNode } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';
import { IconCircleX } from '../../common/icons/cdl/CircleX';
import { IconInfoCircle } from '../../common/icons/cdl/InfoCircle';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

interface WrapperProps extends Pick<StatusBarProps, 'variant'> {}

const StatusBarContentWrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    gap: ${get('space.5')}px;
    padding: ${get('space.5')}px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    ${variant({
        variants: {
            neutral: {
                backgroundColor: 'background.subtle',
                borderColor: 'border.muted',
            },
            positive: {
                backgroundColor: 'positive.background',
                borderColor: 'positive.border',
            },
            attention: {
                backgroundColor: 'attention.background',
                borderColor: 'attention.border',
            },
            negative: {
                backgroundColor: 'negative.background',
                borderColor: 'negative.border',
            },
        },
    })}
`;

const LeftPartWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${get('space.5')}px;
`;

const LeadingVisualWrapper = styled.div`
    flex-shrink: 0;
`;

const TrailingVisualWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;
    gap: 16px;
`;

const TextWrapper = styled.div`
    ${Text} {
        margin: 0;
    }
`;

interface StatusBarProps {
    variant?: 'neutral' | 'positive' | 'attention' | 'negative';
    leadingVisual?: ReactNode;
    trailingVisual?: ReactNode;
    headline: string;
    subHeadline: string;
}

export const StatusBar = ({
    headline,
    subHeadline,
    leadingVisual,
    trailingVisual,
    variant = 'neutral',
}: StatusBarProps) => {
    const selectLeadingVisual = () => {
        if (leadingVisual) {
            return leadingVisual;
        }

        switch (variant) {
            case 'neutral':
                return <IconInfoCircle height={24} width={24} />;
            case 'positive':
                return <IconCircleCheck height={24} width={24} />;
            case 'attention':
                return <IconCircleX height={24} width={24} />;
        }
    };

    return (
        <StatusBarContentWrapper variant={variant}>
            <LeftPartWrapper>
                <LeadingVisualWrapper>{selectLeadingVisual()}</LeadingVisualWrapper>
                <TextWrapper>
                    <Text as="h3" variant="subtitle1">
                        {headline}
                    </Text>
                    <Text as="p" variant="small" color="foreground.muted">
                        {subHeadline}
                    </Text>
                </TextWrapper>
            </LeftPartWrapper>

            {trailingVisual ? <TrailingVisualWrapper>{trailingVisual}</TrailingVisualWrapper> : null}
        </StatusBarContentWrapper>
    );
};
