import { useLocation } from '@tanstack/react-router';
import { useState } from 'react';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Section } from '../../common/Section/Section';
import { Box } from '../../common/ui/Box';
import { ContextualQuickStats } from '../common/components/ContextualQuickStats';
import { Greeting } from '../common/components/Greeting';
import { UpdateSection } from '../common/components/UpdateSection';
import { WelcomePopup } from '../common/components/WelcomePopup';

import { LubesCustomerOrderTable } from './components/LubesCustomerOrderTable';
import { LubesSupplierOfferTable } from './components/LubesSupplierOfferTable';

const TableSection = () => {
    const role = useRole();

    if (role.isAdmin()) {
        return null;
    }

    return (
        <Box>
            {role.isCustomer() ? <LubesCustomerOrderTable /> : null}
            {role.isSupplier() ? <LubesSupplierOfferTable /> : null}
        </Box>
    );
};

export const LubesDashboard = () => {
    const location = useLocation();
    const [showWelcomePopup, setShowWelcomePopup] = useState<boolean>(location.state?.showWelcomePopup || false);

    useDocumentTitle(translate('page.dashboardoverview'));

    return (
        <Page>
            <PageGrid>
                <Box display="flex">
                    <Text marginRight={4} variant="headline" as="h1">
                        {translate('dashboard.lubesHeadline')}
                    </Text>
                    <Greeting />
                </Box>

                <UpdateSection />

                <Section title={translate('dashboard.quickstats.headline')} paddingX={0} paddingY={0}>
                    <ContextualQuickStats />
                </Section>

                <TableSection />
            </PageGrid>
            <WelcomePopup isOpen={showWelcomePopup} onDismiss={() => setShowWelcomePopup(false)} />
        </Page>
    );
};
