import PropTypes from 'prop-types';

import { EmailAddressPopup } from '../../../common/EmailAddressPopup/EmailAddressPopup';
import { translate } from '../../../common/helpers/translate.helper';

export const SendToVesselPopup = (props) => {
    return (
        <EmailAddressPopup
            headline={translate('vessel.requisitionForm.sendToVesselPopup.headline')}
            description={translate('vessel.requisitionForm.sendToVesselPopup.description')}
            submitButtonText={translate('vessel.requisitionForm.sendToVesselPopup.submitButton')}
            onSubmit={props.onSubmit}
            onDismiss={props.onDismiss}
            open={props.open}
        />
    );
};

SendToVesselPopup.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    onSubmit: PropTypes.func,
};
