import { useNavigate } from '@tanstack/react-router';
import styled from 'styled-components';

import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { Button } from '../../../../cdl/Button/Button';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconCircleCheck } from '../../../../common/icons/cdl/CircleCheck';
import { IconCircleDotted } from '../../../../common/icons/cdl/CircleDotted';
import { IconCircleX } from '../../../../common/icons/cdl/CircleX';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OrderModel } from '../../../model/OrderModel';
import { MetaInformation } from '../../common/OfferCard/MetaInformation';
import { CounterOfferCreateState } from '../CounterOffer/components/CounterOfferCreateState';
import { CounterOfferReviewButton } from '../CounterOffer/components/CounterOfferReviewButton';
import { CounterOfferStateDescription } from '../CounterOffer/components/CounterOfferStateDescription';
import { useCounterOfferUpdates } from '../CounterOffer/hooks/useCounterOfferUpdates';

import { OfferCardValidity } from './OfferCardValidity';
import { OrderButton } from './OrderButton';

const OfferActionsGrid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: ${get('space.4')}px;
    border-left: 1px solid ${get('colors.border.subtle')};

    padding: ${get('space.5')}px;
`;
interface OfferActionsProps {
    offer: OfferModel;
    order: OrderModel;
}

export const OfferActions = ({ offer, order }: OfferActionsProps) => {
    const navigate = useNavigate();
    const role = useRole();

    useCounterOfferUpdates(offer);

    const hasWriteRights = role.hasWriteRights(offer.customerId);

    if (offer.isOrder()) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="positive">
                    <IconCircleCheck width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('fuelOrder.offerList.ordered')}
                    </Text>
                </MetaInformation>

                <Button
                    emphasis="high"
                    width="100%"
                    onClick={() => navigate({ to: '/fuel/offer/$id', params: { id: offer.id } })}
                >
                    {translate('fuelOrder.offerList.viewOrder')}
                </Button>
            </OfferActionsGrid>
        );
    }

    if (offer.isCanceled()) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleX width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('fuelOrder.offerList.canceled')}
                    </Text>
                </MetaInformation>

                {hasWriteRights ? (
                    <Box display="flex" style={{ columnGap: '4px' }}>
                        <Button width="100%" disabled>
                            {translate('counterOffer.createButton')}
                        </Button>
                        <Button emphasis="high" width="100%" disabled>
                            {translate('fuelOrder.offerList.placeOrder')}
                        </Button>
                    </Box>
                ) : null}
            </OfferActionsGrid>
        );
    }

    if (offer.state === 'ENQUIRY') {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleDotted width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('fuelOrder.offerList.waitingForOffer')}
                    </Text>
                </MetaInformation>

                {hasWriteRights ? (
                    <Box display="grid" gridTemplateColumns="1fr 1fr" style={{ columnGap: '8px' }}>
                        <Button width="100%" disabled>
                            {translate('counterOffer.createButton')}
                        </Button>
                        <Button emphasis="high" width="100%" disabled>
                            {translate('fuelOrder.offerList.placeOrder')}
                        </Button>
                    </Box>
                ) : null}
            </OfferActionsGrid>
        );
    }

    if (role.isAdmin()) {
        return (
            <OfferActionsGrid style={{ justifyContent: 'end' }}>
                <Button
                    emphasis="high"
                    width="100%"
                    onClick={() => navigate({ to: '/fuel/offer/$id', params: { id: offer.id } })}
                >
                    {translate('fuelOrder.offerList.viewOrder')}
                </Button>
            </OfferActionsGrid>
        );
    }

    if (offer.counterOffers[0]?.state === CounterOfferState.ACCEPTED) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="accent">
                    <IconCircleCheck width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('fuelOrder.offerList.counterOfferAccepted')}
                    </Text>
                </MetaInformation>

                {hasWriteRights ? <OrderButton offer={offer} order={order} /> : null}
            </OfferActionsGrid>
        );
    }

    return (
        <OfferActionsGrid>
            <Box display="flex" flexDirection="column" style={{ rowGap: '8px' }}>
                <OfferCardValidity offer={offer} />
                <CounterOfferStateDescription counterOffers={offer.counterOffers} />
            </Box>

            {hasWriteRights ? (
                <>
                    {offer.counterOffers[0]?.state === CounterOfferState.OPEN ? (
                        <CounterOfferReviewButton offer={offer} counterOffer={offer.counterOffers[0]} />
                    ) : (
                        <Box display="grid" gridTemplateColumns="1fr 1fr" style={{ columnGap: '8px' }}>
                            <CounterOfferCreateState offer={offer} />
                            <OrderButton offer={offer} order={order} />
                        </Box>
                    )}
                </>
            ) : null}
        </OfferActionsGrid>
    );
};
