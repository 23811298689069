import { linkOptions, useSearch } from '@tanstack/react-router';

import { CenteredPagination } from '../cdl/Pagination/CenteredPagination';
import { Error } from '../common/Error/Error.tsx';
import { formatDate } from '../common/helpers/formatDate.helper';
import { translate } from '../common/helpers/translate.helper';
import { useRole } from '../common/hooks/useRole';
import { IconHeadset } from '../common/icons/cdl/Headset';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { PortListing } from '../common/PortListing/PortListing';
import { TableEmptyState } from '../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../common/TabSwitch/TabSwitch';

import { ProductContext } from '../../types/ProductContext';
import { TableBuilder } from '../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../cdl/TableBuilder/TableBuilderColumn';
import { formatCompanyName } from '../common/helpers/formatCompanyName.helper';
import { useProductContext } from '../common/hooks/useProductContext';
import { Box } from '../common/ui/Box';
import { Route } from '../routes/_app._lubes.agents.index';
import { AgentTableActions } from './components/AgentTableActions';
import { useAgentPagination } from './useAgentPagination';
import { useAgentPaginationTotals } from './useAgentPaginationTotals';

export const AgentsOverviewTable = () => {
    const role = useRole();
    const { context } = useProductContext();

    const search = useSearch({ strict: false });
    const navigate = Route.useNavigate();

    const active = search.tab === 'active';

    const { data: agentTotals } = useAgentPaginationTotals({
        searchQuery: search.searchQuery,
        customerIds: search.customerIds,
        portIds: search.portIds,
    });

    const { data, pageTotal, isPending, isError } = useAgentPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        customerIds: search.customerIds,
        portIds: search.portIds,
        active,
    });

    const totals = {
        active: agentTotals?.active,
        archived: agentTotals?.archived,
    };

    if (isPending) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) })}
                    totals={totals}
                />
                <LoadingIndicator />
            </div>
        );
    }

    if (isError) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) })}
                    totals={totals}
                />
                <Error />
            </div>
        );
    }

    if (!data.content?.length) {
        return (
            <div>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) })}
                    totals={totals}
                />
                <TableEmptyState Icon={IconHeadset} text={translate('agents.emptyList')} />
            </div>
        );
    }

    const buildRowLink = (agent) => {
        if (agent.customer.type === ProductContext.FUEL) {
            return linkOptions({ to: '/fuel/agents/$id', params: { id: agent.id } });
        }
        return linkOptions({ to: '/agents/$id', params: { id: agent.id } });
    };

    return (
        <div>
            <TabSwitch
                selectedContext={search.tab}
                onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab, page: 0 }) })}
                totals={totals}
            />
            <TableBuilder data={data.content} rowLink={buildRowLink} grow={false}>
                <TableBuilderColumn header={translate('agents.name')} width="25%" truncate>
                    {(agent) => agent.name}
                </TableBuilderColumn>
                {role.isAdmin() || !role.isOneCompanyUser(context) ? (
                    <TableBuilderColumn header={translate('agents.company')} width="20%" truncate>
                        {(agent) =>
                            agent.customer
                                ? formatCompanyName({
                                      company: agent.customer,
                                      includeType: role.isAdmin(),
                                  })
                                : ''
                        }
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn header={translate('agents.ports')} width="20%">
                    {(agent) => (agent.ports.length ? <PortListing ports={agent.ports} /> : '')}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('agents.dateCreated')} width="10%">
                    {(agent) => formatDate({ date: agent.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('agents.lastEdit')} width="10%">
                    {(agent) => formatDate({ date: agent.dateUpdated })}
                </TableBuilderColumn>
                {role.hasAnyCompanyWriteRights() ? (
                    <TableBuilderColumn header="" width="5%" interactive>
                        {(agent) =>
                            role.hasWriteRights(agent.customerId) ? (
                                <Box display="flex" flexWrap="nowrap" justifyContent="flex-end">
                                    <AgentTableActions agent={agent} />
                                </Box>
                            ) : (
                                ''
                            )
                        }
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>
            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={search.page}
                onPageChange={(page) => navigate({ search: (previousSearch) => ({ ...previousSearch, page }) })}
            />
        </div>
    );
};
