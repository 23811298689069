import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconWorldOff = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.499 3.067A10 10 0 0 1 20.937 16.49 1 1 0 0 1 19.044 16h-.045a1 1 0 0 1 0-2h.75a7.998 7.998 0 0 0 .002-4h-2.846c.062.648.094 1.317.094 2a1 1 0 1 1-2 0c0-.693-.036-1.362-.104-2h-1.896a1 1 0 0 1 0-2h1.559a11.231 11.231 0 0 0-.644-1.958c-.328-.738-.692-1.273-1.047-1.61C12.518 4.099 12.227 4 12 4c-.426 0-1.118.4-1.79 1.776a1 1 0 0 1-1.797-.878 9.07 9.07 0 0 1 .033-.068l-.045.023a1 1 0 1 1-.901-1.786Zm8.052 1.758c.066.132.13.267.19.405.36.81.653 1.747.865 2.77h2.328a7.999 7.999 0 0 0-3.383-3.175ZM2.292 2.293a1 1 0 0 1 1.414 0L6.205 4.79c.054.036.105.078.152.126l9.824 9.85 5.525 5.526a1 1 0 0 1-1.414 1.414l-1.96-1.96a10 10 0 0 1-14.08-14.08l-1.96-1.96a1 1 0 0 1 0-1.414Zm3.382 4.796A8 8 0 0 0 5.052 8h1.533l-.91-.91ZM7.094 10H4.219a8 8 0 0 0-.024 4h2.899a21.014 21.014 0 0 1-.095-1.998c-.002-.67.03-1.337.095-2.002Zm.298 6H4.998a7.998 7.998 0 0 0 3.491 3.258 9.73 9.73 0 0 1-.232-.488A13.71 13.71 0 0 1 7.392 16Zm8.147 3.198a8 8 0 0 0 1.368-.87l-.722-.724a11.305 11.305 0 0 1-.646 1.594Zm-6.484-8.674c-.039.49-.057.981-.056 1.473V12c0 .693.037 1.362.105 2h3.423l-3.472-3.476ZM14.525 16H9.441c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.328 0 .819-.224 1.367-1.036.47-.695.889-1.699 1.185-2.938L14.525 16Z"
            clipRule="evenodd"
        />
    </svg>
);
