import { useFormikContext } from 'formik';
import { ChangeEvent, useRef, useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { Text } from '../../../../cdl/Text/Text';
import { TextButton } from '../../../../common/buttons/TextButton';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconTrash } from '../../../../common/icons/cdl/Trash';
import { Box } from '../../../../common/ui/Box';
import { FormikVesselDetailsState } from '../EditVesselForm';

interface FormikVesselAttachmentsSectionProps {
    lubricationChart?: {
        name: string;
        downloadUrl: string;
    };
}

export const FormikVesselLubricationChartSection = ({ lubricationChart }: FormikVesselAttachmentsSectionProps) => {
    const { setFieldValue } = useFormikContext<FormikVesselDetailsState>();
    const [fileData, setFileData] = useState({
        name: lubricationChart?.name,
        downloadUrl: lubricationChart?.downloadUrl,
    });
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }

        setFileData({ name: file.name, downloadUrl: undefined });

        setFieldValue('lubricationChart', {
            file,
            added: true,
            removed: false,
        });
    };

    const onRemove = () => {
        setFileData({ name: undefined, downloadUrl: undefined });

        setFieldValue('lubricationChart', {
            file: null,
            added: false,
            removed: !!lubricationChart?.name,
        });
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <Box display="grid" paddingBottom={!fileData.name ? 8 : 0}>
            <Text variant="subtitle1">{translate('vessel.edit.attachments')}</Text>

            <Box display="flex" flexDirection="column" gap={2} alignItems="start" marginTop={5}>
                <Text variant="fieldLabel" color="foreground.muted">
                    {translate('vessel.edit.lubricationChart')}
                </Text>
                <input
                    type="file"
                    onChange={onChange}
                    style={{
                        display: 'none',
                    }}
                    ref={fileInputRef}
                />
                <Button size="large" type="button" onClick={handleUploadClick}>
                    {translate('vessel.edit.uploadFile')}
                </Button>
            </Box>

            {fileData.name ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={4}>
                    {fileData.downloadUrl ? (
                        <a
                            href={fileData.downloadUrl}
                            download
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <TextButton
                                type="button"
                                style={{
                                    wordBreak: 'break-all',
                                }}
                            >
                                {fileData.name}
                            </TextButton>
                        </a>
                    ) : (
                        <Text variant="fieldLabel">{fileData.name}</Text>
                    )}
                    <IconButton type="button" Icon={IconTrash} onClick={onRemove} />
                </Box>
            ) : null}
        </Box>
    );
};
