import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { PortSelect } from '../../common/form-elements/PortSelect/PortSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { DebouncedSearchTextInput } from '../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../common/ui/Box';
import { Route as RouteLubes } from '../../routes/_app._lubes.agents.index';
import { Route as RouteFuel } from '../../routes/_app.fuel.agents.index';

export const AgentsOverviewTableControls = () => {
    const role = useRole();
    const { context } = useProductContext();

    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="250px">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) =>
                        navigate({ search: (previousSearch) => ({ ...previousSearch, searchQuery, page: 0 }) })
                    }
                    value={search.searchQuery}
                    placeholder={translate('agents.searchInputPlaceholder')}
                />
            </Box>

            {!role.isOneCompanyUser(context) ? (
                <OverviewCustomerCompanySelect
                    onChange={(customerIds) => {
                        navigate({
                            search: (previousSearch) => ({ ...previousSearch, customerIds, page: 0 }),
                        });
                    }}
                    value={search.customerIds}
                    context={!role.isAdmin() ? context : undefined}
                />
            ) : null}

            <Box minWidth="250px">
                <PortSelect
                    placeholder={translate('agents.portsPlaceholder')}
                    value={search.portIds}
                    isMulti
                    onChange={(portIds: string[]) => {
                        navigate({
                            search: (previousSearch) => ({ ...previousSearch, portIds, page: 0 }),
                        });
                    }}
                    dropdownWidth="520px"
                />
            </Box>
        </Box>
    );
};
