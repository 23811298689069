import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const LubesAdminSidebarItems = () => {
    return (
        <>
            <SidebarNavigationItem to="/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/orders">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/offers">{translate('navigation.offers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/key-ports">{translate('navigation.keyPorts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/demand">{translate('navigation.demandOverview')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/statistics">{translate('navigation.reporting')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/compare">{translate('navigation.compare')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/products">{translate('navigation.products')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/integrations">{translate('navigation.integrations')}</SidebarNavigationItem>
        </>
    );
};
