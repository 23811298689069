import { useField } from 'formik';
import React from 'react';

import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { FormikSwitch } from '../../../../cdl/Switch/FormikSwitch';
import { Text } from '../../../../cdl/Text/Text';
import { FormikFormattedNumberInput } from '../../../FormattedNumberInput/FormikFormattedNumberInput';
import { formatMoney } from '../../../helpers/formatMoney.helper';
import { formatNumber } from '../../../helpers/formatNumber.helper';
import { translate } from '../../../helpers/translate.helper';
import { IconX } from '../../../icons/cdl/X';
import { FormikMoneyInput } from '../../../MoneyInput/FormikMoneyInput';
import { Td } from '../../../Table/Td';
import { Tr } from '../../../Table/Tr';
import { Flex } from '../../../ui/Flex';
import { useLubesProductTableConfig } from '../hooks/useLubesProductTableConfig';

interface SamplekitRowProps {
    name: string;
    onRowRemoveClicked: () => void;
}

export const SamplekitRow = (props: SamplekitRowProps) => {
    const [field] = useField(props.name);
    const samplekit = field.value;

    const { showPrices, editable, editSurcharges } = useLubesProductTableConfig();

    const emptyColumns = showPrices ? 3 : 2;

    const canEditPrices = editable && editSurcharges;

    return (
        <Tr inactive={samplekit.deleted}>
            <Td>
                {editable ? (
                    <FormikInput name={`${props.name}.name`} disabled={samplekit.deleted} />
                ) : (
                    <Text variant="body">{samplekit.name}</Text>
                )}
            </Td>
            <Td>
                <Text variant="body">{translate('offer.edit.lubes.oilSampleKit.usedOilSampleKit')}</Text>
            </Td>
            <Td colSpan={2}>
                <Text variant="body">{translate('offer.edit.lubes.oilSampleKit.packet')}</Text>
            </Td>
            <Td>
                {editable ? (
                    <FormikFormattedNumberInput
                        name={`${props.name}.quantity`}
                        disabled={samplekit.deleted}
                        allowDecimals={false}
                        allowNegativeValue={false}
                    />
                ) : (
                    <Text variant="body">{formatNumber({ number: samplekit.quantity })}</Text>
                )}
            </Td>

            {/*@ts-ignore*/}
            <Td colSpan={emptyColumns} />

            {showPrices ? (
                <Td>
                    {!canEditPrices ? (
                        <Text variant="body" textAlign="right" display="block">
                            {samplekit.value?.value
                                ? formatMoney({
                                      value: samplekit.value.value,
                                      currency: samplekit.value.currency,
                                      maximumFractionDigits: 2,
                                  })
                                : '-'}
                        </Text>
                    ) : (
                        <FormikMoneyInput
                            name={`${props.name}.value.value`}
                            decimals={2}
                            allowNegativeValue
                            disabled={samplekit.deleted}
                            required
                        />
                    )}
                </Td>
            ) : null}

            {editable ? (
                <Td>
                    <Flex width="32px" justifyContent="center">
                        {samplekit.new ? (
                            <IconButton type="button" onClick={props.onRowRemoveClicked} Icon={IconX} />
                        ) : (
                            <FormikSwitch name={`${props.name}.deleted`} invert />
                        )}
                    </Flex>
                </Td>
            ) : null}
        </Tr>
    );
};
