import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import styled from 'styled-components';

import { ConsumptionCalculationBase } from '../../../types/ConumptionCalculationBase';
import { DemandInformationCategory, DemandInformationVessel } from '../../../types/DemandInformationMessage';
import { ProductContext } from '../../../types/ProductContext';
import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { Collapsable } from '../../cdl/Collapsable/Collapsable';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { TextButton } from '../../common/buttons/TextButton';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';
import { EditPresetsButton } from '../../vessel/VesselConsumptionPresets/EditPresetsButton';
import { QualityIndicator } from '../../vessel/VesselDetailPage/AvarageConsumptionSection/components/QualityIndicator';
import { sortCategory } from '../utils/sortCategory';

import { DemandAccordionHeader } from './DemandAccordionHeader';
import { ProductValue } from './DemandInformationTable/ProductValue';
import { StockTableHeading } from './DemandInformationTable/StockTableHeading';
import { DemandRemainingOnBoard } from './DemandRemainingOnBoard';
import { DemandTableInfoPopover } from './DemandTableInfoPopover';

interface DemandAccordionProps {
    vessel: DemandInformationVessel;
    disabled?: boolean;
}

const Wrapper = styled.div`
    &:hover ${DiscoverableButton} {
        opacity: 1;
    }
`;

export const DemandAccordion = ({ vessel, disabled = false }: DemandAccordionProps) => {
    const { context: productContext, isFuel, isLubes } = useProductContext();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const role = useRole();

    const sortedCategories = vessel.categories.sort(sortCategory(productContext));

    return (
        <Wrapper>
            <Collapsable
                isOpen={isOpen}
                header={
                    <DemandAccordionHeader
                        isOpen={isOpen}
                        disabled={disabled}
                        vessel={vessel}
                        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
                    />
                }
            >
                <Box paddingBottom={4} paddingX={4}>
                    <TableBuilder variant="inline" data={sortedCategories}>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={translate('demand.table.product')}
                            width="25%"
                        >
                            {(row) => <ProductValue row={row} vessel={vessel} />}
                        </TableBuilderColumn>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={<StockTableHeading latestStocks={vessel.latestStocks} />}
                            numeric
                            width="15%"
                        >
                            {(row) =>
                                typeof row?.remainingVolume === 'number' ? (
                                    <DemandRemainingOnBoard
                                        volume={row.remainingVolume}
                                        categoryState={row.categoryState}
                                    />
                                ) : (
                                    '-'
                                )
                            }
                        </TableBuilderColumn>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={
                                <DemandTableInfoPopover label={translate('demand.table.calculatedAverage')}>
                                    <div>
                                        <Text variant="extraSmall" display="block">
                                            {translate('demand.table.calculatedAveragePopoverText')}
                                        </Text>
                                    </div>
                                </DemandTableInfoPopover>
                            }
                            numeric
                            width="20%"
                        >
                            {(row) =>
                                typeof row.calculatedAverageDailyConsumption === 'number' ? (
                                    <Box display="flex" flexDirection="row" gap={4} justifyContent="end">
                                        {formatNumber({
                                            number: row.calculatedAverageDailyConsumption,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                        {row.qualityCategory ? (
                                            <QualityIndicator qualityCategory={row.qualityCategory} />
                                        ) : null}
                                    </Box>
                                ) : (
                                    '-'
                                )
                            }
                        </TableBuilderColumn>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={
                                isFuel ? (
                                    <DemandTableInfoPopover label={translate('demand.table.fixedAverage')}>
                                        <Box>
                                            <Text variant="extraSmall" display="block">
                                                {translate('demand.table.dailyAveragePopover.text')}
                                            </Text>
                                            {role.hasWriteRights(vessel.customerId) ? (
                                                <TextButton
                                                    style={{ fontSize: '12px' }}
                                                    onClick={() => {
                                                        navigate({
                                                            to: '/fuel/vessel/$id',
                                                            params: { id: vessel.id },
                                                        });
                                                    }}
                                                >
                                                    {translate('demand.table.dailyAveragePopover.btn')}
                                                </TextButton>
                                            ) : null}
                                        </Box>
                                    </DemandTableInfoPopover>
                                ) : (
                                    <DemandTableInfoPopover label={translate('demand.table.fixedAverage')}>
                                        <Box>
                                            <Text variant="extraSmall" display="block">
                                                {translate('demand.table.fixedAveragePopoverText')}
                                            </Text>

                                            {role.hasWriteRights(vessel.customerId) && isLubes ? (
                                                <EditPresetsButton
                                                    vesselId={vessel.id}
                                                    customerId={vessel.customerId}
                                                    variant="text"
                                                />
                                            ) : null}
                                        </Box>
                                    </DemandTableInfoPopover>
                                )
                            }
                            numeric
                            width="15%"
                        >
                            {(row) =>
                                typeof row.averageDailyConsumption === 'number'
                                    ? formatNumber({
                                          number: row.averageDailyConsumption,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                      })
                                    : '-'
                            }
                        </TableBuilderColumn>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={
                                isLubes ? (
                                    <DemandTableInfoPopover label={translate('demand.table.days')}>
                                        <Box>
                                            <Text variant="extraSmall" display="block">
                                                {isFuel
                                                    ? translate('demand.table.daysPopoverFuel')
                                                    : translate('demand.table.daysPopoverLubes')}
                                            </Text>
                                            {role.hasWriteRights(vessel.customerId) && isLubes ? (
                                                <EditPresetsButton
                                                    vesselId={vessel.id}
                                                    customerId={vessel.customerId}
                                                    variant="text"
                                                />
                                            ) : null}
                                        </Box>
                                    </DemandTableInfoPopover>
                                ) : (
                                    translate('demand.table.days')
                                )
                            }
                            numeric
                            width="10%"
                        >
                            {(row) => {
                                const value =
                                    typeof row.daysLeft === 'number'
                                        ? formatNumber({ number: row.daysLeft, maximumFractionDigits: 0 })
                                        : '-';

                                if (isFuel || !row.consumptionCalculationBase) {
                                    return value;
                                }

                                return (
                                    <Tooltip
                                        label={
                                            row.consumptionCalculationBase === ConsumptionCalculationBase.STATIC
                                                ? translate('demand.table.basedOnFixedAverage')
                                                : translate('demand.table.basedOnCalculatedAverage')
                                        }
                                    >
                                        <Text>{value}</Text>
                                    </Tooltip>
                                );
                            }}
                        </TableBuilderColumn>
                        <TableBuilderColumn<DemandInformationCategory>
                            header={translate('demand.table.robAtKeyPort')}
                            numeric
                            width="15%"
                        >
                            {(row) => {
                                const nextKeyPortCategory = vessel.stops
                                    .find((stop) => !stop.currentPort && stop.keyPort)
                                    ?.categories.find((keyPortCategory) => {
                                        switch (productContext) {
                                            case ProductContext.FUEL:
                                                return (
                                                    keyPortCategory.productGroup === row.productGroup &&
                                                    keyPortCategory.sulphurContent === row.sulphurContent
                                                );
                                            case ProductContext.LUBES:
                                                return keyPortCategory.tankCategory === row.tankCategory;
                                        }
                                    });

                                return typeof nextKeyPortCategory?.remainingVolume === 'number' ? (
                                    <DemandRemainingOnBoard
                                        volume={nextKeyPortCategory.remainingVolume}
                                        categoryState={nextKeyPortCategory.categoryState}
                                    />
                                ) : (
                                    '-'
                                );
                            }}
                        </TableBuilderColumn>
                    </TableBuilder>
                </Box>
            </Collapsable>
        </Wrapper>
    );
};
