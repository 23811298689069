import { StatusBar } from '../../cdl/StatusBar/StatusBar';
import { translate } from '../../common/helpers/translate.helper';
import { IconClock } from '../../common/icons/cdl/Clock';
import { OverlayHeader } from '../../common/Overlay/OverlayHeader';

import { ApprovalRequestContentContainer } from './ApprovalRequestContentContainer';

const NotAvailableStatusBar = () => {
    const headline = translate('approvalRequest.statusBar.expired.headline');
    const subHeadline = translate('approvalRequest.statusBar.expired.subHeadline');

    return <StatusBar variant="negative" headline={headline} subHeadline={subHeadline} leadingVisual={<IconClock />} />;
};

export const NotAvailable = () => {
    return (
        <OverlayHeader>
            <ApprovalRequestContentContainer>
                <NotAvailableStatusBar />
            </ApprovalRequestContentContainer>
        </OverlayHeader>
    );
};
