import PropTypes from 'prop-types';
import { Children, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { CheapIndicator } from '../../common/CheapIndicator/CheapIndicator';
import { IconChevronLeft } from '../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { CloakBox } from '../../common/Slider/CloakBox';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { LoadingPriceBox } from './LoadingPriceBox';

SwiperCore.use([Navigation]);

export const SupplierPriceSlider = ({ slidesPerView, isFetching, children, onSlideChange, cheapestDefaultScope }) => {
    const prevSlideControl = useRef(null);
    const nextSlideControl = useRef(null);

    return (
        <Box position="relative" paddingX={60}>
            <CloakBox left="0">
                {cheapestDefaultScope.prev ? <CheapIndicator left="-10px" /> : null}
                <IconButton type="button" Icon={IconChevronLeft} emphasis="medium" ref={prevSlideControl} />
            </CloakBox>
            <CloakBox right="0" reverseGradient>
                {cheapestDefaultScope.next ? <CheapIndicator right="-10px" /> : null}
                <IconButton type="button" Icon={IconChevronRight} emphasis="medium" ref={nextSlideControl} />
            </CloakBox>

            <Box>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={24}
                    slidesPerGroup={1}
                    loop={false}
                    navigation={{
                        prevEl: prevSlideControl.current,
                        nextEl: nextSlideControl.current,
                    }}
                    onSlideChange={(swiper) => {
                        onSlideChange(swiper.realIndex);
                    }}
                    style={{ padding: '20px 20px' }}
                >
                    {isFetching
                        ? Array.from(Array(slidesPerView).keys()).map((index) => {
                              return (
                                  <SwiperSlide key={`loading-slide-${index}`}>
                                      <Flex key={index} width="100%" justifyContent="center">
                                          <LoadingPriceBox />
                                      </Flex>
                                  </SwiperSlide>
                              );
                          })
                        : Children.map(children, (child, index) => {
                              return <SwiperSlide key={index}>{child}</SwiperSlide>;
                          })}
                </Swiper>
            </Box>
        </Box>
    );
};

SupplierPriceSlider.propTypes = {
    slidesPerView: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onSlideChange: PropTypes.func.isRequired,
    cheapestDefaultScope: PropTypes.object.isRequired,
    children: PropTypes.node,
};
