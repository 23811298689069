import { AdminStatisticsMessage } from '../../../types/StatisticsMessage';
import { useProductContext } from '../../common/hooks/useProductContext';
import { Route as RouteLubes } from '../../routes/_app._lubes.statistics';
import { Route as RouteFuel } from '../../routes/_app.fuel.statistics';

import { CustomersTabContent } from './CustomersTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticTab } from './StatisticTab';
import { SuppliersTabContent } from './SuppliersTabContent';
import { VesselsTabContent } from './VesselsTabContent';

interface AdminStatisticsContentProps {
    statistics: AdminStatisticsMessage;
}

export const AdminStatisticsContent = ({ statistics }: AdminStatisticsContentProps) => {
    const { context } = useProductContext();
    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();

    switch (search.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.VESSELS:
            return <VesselsTabContent vesselStatistics={statistics.vessel} />;
        case StatisticTab.SUPPLIERS:
            return <SuppliersTabContent supplierStatistics={statistics.supplier} />;
        case StatisticTab.CUSTOMERS:
            return <CustomersTabContent customerStatistics={statistics.customer} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
    }
};
