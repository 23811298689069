import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { vesselsSearchSchema } from '../../types/vesselsSearchSchema';
import { FuelVesselOverviewPage } from '../vessel/VesselOverview/FuelVesselOverviewPage';

export const Route = createFileRoute('/_app/fuel/vessels')({
    component: FuelVesselOverviewPage,
    validateSearch: zodSearchValidator(vesselsSearchSchema),
});
