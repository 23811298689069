import { Form, Formik, FormikProps } from 'formik';

import { FuelVesselConsumptionPreset } from '../../../../../types/FuelVesselConsumptionPreset';
import { DrawerFormLayout } from '../../../../cdl/Drawer/DrawerFormLayout';
import { Text } from '../../../../cdl/Text/Text';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../../common/helpers/translate.helper';

import { FormikVesselConsumptionPresetCategories } from './FormikVesselConsumptionPresetCategories';

export interface FormikVesselConsumptionPresetsState {
    presets: {
        sulphurContent: string;
        productGroup: string;
        averageDailyConsumption: string;
        safetyReserve: string;
        maximumIntake: string;
    }[];
}

interface EditVesselConsumptionPresetsFormProps {
    onSubmit: (data: { presets: FuelVesselConsumptionPreset[] }) => void;
    data: { presets: FuelVesselConsumptionPreset[] };
}

export const EditVesselConsumptionPresetsForm = ({ onSubmit, data }: EditVesselConsumptionPresetsFormProps) => {
    const initialFormikState = {
        presets: data.presets.length
            ? data.presets.map((preset) => {
                  return {
                      sulphurContent: preset.sulphurContent,
                      productGroup: preset.productGroup,
                      averageDailyConsumption: preset.averageDailyConsumption?.toString() || '',
                      safetyReserve: preset.safetyReserve?.toString() || '',
                      maximumIntake: preset.maximumIntake?.toString() || '',
                  };
              })
            : [
                  {
                      sulphurContent: '',
                      productGroup: '',
                      averageDailyConsumption: '',
                      safetyReserve: '',
                      maximumIntake: '',
                  },
              ],
    };

    const handleSubmit = (values: FormikVesselConsumptionPresetsState) => {
        const newPresets: FuelVesselConsumptionPreset[] = values.presets
            .filter((preset) => !!preset.productGroup)
            .map((preset) => {
                return {
                    sulphurContent: preset.sulphurContent,
                    productGroup: preset.productGroup,
                    averageDailyConsumption: Number(preset.averageDailyConsumption) || undefined,
                    safetyReserve: Number(preset.safetyReserve) || undefined,
                    maximumIntake: Number(preset.maximumIntake) || undefined,
                };
            });
        onSubmit({ presets: newPresets });
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={handleSubmit}>
            {(props: FormikProps<FormikVesselConsumptionPresetsState>) => (
                <Form style={{ height: '100%' }}>
                    <DrawerFormLayout
                        title={translate('vessel.fuelStocks.editPresetsForm.title')}
                        isSubmitButtonDisabled={!props.isValid}
                    >
                        <Text variant="body">{translate('vessel.fuelStocks.editPresetsForm.description')}</Text>
                        <FormikVesselConsumptionPresetCategories />
                        <FormikDebug />
                    </DrawerFormLayout>
                </Form>
            )}
        </Formik>
    );
};
