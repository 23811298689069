import { useFormikContext } from 'formik';
import * as uuid from 'uuid';

import { ScheduleMessage } from '../../../../../types/ScheduleMessage';
import { CompareOverlayFlow } from '../../../compare/overlay/CompareOverlayFlow';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';
import { formatLocalDateToUtcTimestamp } from '../util/formatLocalDateToUtcTimestamp';

interface FormikCompareOverlayProps {
    schedule: ScheduleMessage[];
}

const trackingId = uuid.v4();

export const FormikCompareOverlay = (props: FormikCompareOverlayProps) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikCreateEnquiryValues>();

    return (
        <CompareOverlayFlow
            trackingId={trackingId}
            order={values}
            schedules={props.schedule.map((mappedSchedule) => ({
                ...mappedSchedule,
                etaWithZone: {
                    date: mappedSchedule.eta,
                    timeZone: mappedSchedule.port?.timeZoneId,
                },
            }))}
            onSubmit={(changed) => {
                setFieldValue('receiverSupplierIds', changed.receiverSupplierIds);
                setFieldTouched('receiverSupplierIds', true);

                setFieldValue('portId', changed.portId);
                setFieldTouched('portId', true);

                if (changed.dateDelivery) {
                    const dateDeliveryFromSchedule = formatLocalDateToUtcTimestamp(new Date(changed.dateDelivery.date));

                    setFieldValue('dateDelivery', dateDeliveryFromSchedule);
                    setFieldTouched('dateDelivery', true);
                }
            }}
        />
    );
};
