import { useNavigate } from '@tanstack/react-router';
import styled from 'styled-components';

import { ApprovalRequestState } from '../../../../../../../types/ApprovalRequestState';
import { Button } from '../../../../../../cdl/Button/Button';
import { Text } from '../../../../../../cdl/Text/Text';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { useRole } from '../../../../../../common/hooks/useRole';
import { IconCircleCheck } from '../../../../../../common/icons/cdl/CircleCheck';
import { IconCircleDotted } from '../../../../../../common/icons/cdl/CircleDotted';
import { IconCircleX } from '../../../../../../common/icons/cdl/CircleX';
import { Box } from '../../../../../../common/ui/Box';
import { get } from '../../../../../../common/ui/get';
import { OfferState } from '../../../../../../offer/model/OfferState';
import { MetaInformation } from '../../../../common/OfferCard/MetaInformation';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

import { ApprovalRequestButton } from './ApprovalRequestButton';
import { ApprovalRequestMessageDisplay } from './ApprovalRequestMessageDisplay';

const OfferActionsGrid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: ${get('space.4')}px;
    border-left: 1px solid ${get('colors.border.muted')};
    padding: ${get('space.5')}px;
`;

export const OfferActions = () => {
    const { offer } = useOfferCardContext();
    const navigate = useNavigate();
    const role = useRole();

    if (offer.wasQuoted() && offer.isUnquoted() && !offer.isCanceled()) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleDotted width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('order.detail.offerCard.waitingForQuoteUpdate')}
                    </Text>
                </MetaInformation>

                {!offer.isSpot() ? <ApprovalRequestButton /> : null}
            </OfferActionsGrid>
        );
    }

    if (offer.isUnquoted() && !offer.isCanceled() && !offer.approvalRequests.length) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleDotted width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('order.detail.offerCard.waitingForQuote')}
                    </Text>
                </MetaInformation>

                <ApprovalRequestButton />
            </OfferActionsGrid>
        );
    }

    if (offer.approvalRequests.length && !offer.isOrder() && !offer.isCanceled()) {
        const latestApprovalRequest = offer.approvalRequests[0];
        switch (latestApprovalRequest.state) {
            case ApprovalRequestState.PENDING:
                return (
                    <OfferActionsGrid>
                        <MetaInformation variant="neutral">
                            <IconCircleDotted width={16} height={16} />
                            <Text variant="extraSmall" fontWeight={600}>
                                {translate('order.detail.offerCard.waitingForApproval')}
                            </Text>
                        </MetaInformation>

                        <ApprovalRequestButton />
                    </OfferActionsGrid>
                );

            case ApprovalRequestState.DECLINED:
                return (
                    <OfferActionsGrid>
                        <Box display="grid" rowGap={4}>
                            <MetaInformation variant="negative">
                                <IconCircleX width={16} height={16} />
                                <Text variant="extraSmall" fontWeight={600}>
                                    {translate('order.detail.offerCard.declined')}
                                </Text>
                            </MetaInformation>
                            <ApprovalRequestMessageDisplay message={latestApprovalRequest.responseMessage} />
                        </Box>

                        <ApprovalRequestButton />
                    </OfferActionsGrid>
                );

            case ApprovalRequestState.APPROVED:
                return (
                    <OfferActionsGrid>
                        <Box display="grid" rowGap={4}>
                            <MetaInformation variant="positive">
                                <IconCircleCheck width={16} height={16} />
                                <Text variant="extraSmall" fontWeight={600}>
                                    {translate('order.detail.offerCard.approved')}
                                </Text>
                            </MetaInformation>
                            <ApprovalRequestMessageDisplay message={latestApprovalRequest.responseMessage} />
                        </Box>

                        <ApprovalRequestButton />
                    </OfferActionsGrid>
                );

            case ApprovalRequestState.WITHDRAWN:
                return (
                    <OfferActionsGrid>
                        <Box display="grid" rowGap={4}>
                            <MetaInformation variant="attention">
                                <IconCircleDotted width={16} height={16} />
                                <Text variant="extraSmall" fontWeight={600}>
                                    {translate('order.detail.offerCard.withdrawn')}
                                </Text>
                            </MetaInformation>
                            <Text variant="action" color="foreground.muted">
                                {translate('order.detail.offerCard.withdrawnMessage')}
                            </Text>
                        </Box>

                        <ApprovalRequestButton />
                    </OfferActionsGrid>
                );

            case ApprovalRequestState.EXPIRED:
                return (
                    <OfferActionsGrid>
                        <MetaInformation variant="attention">
                            <IconCircleDotted width={16} height={16} />
                            <Text variant="extraSmall" fontWeight={600}>
                                {translate('order.detail.offerCard.requestExpired')}
                            </Text>
                        </MetaInformation>

                        <ApprovalRequestButton />
                    </OfferActionsGrid>
                );
        }
    }

    if (offer.isQuotedEnquiry() && !offer.isCanceled()) {
        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleCheck width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {translate('order.detail.offerCard.quoted')}
                    </Text>
                </MetaInformation>

                <ApprovalRequestButton />
            </OfferActionsGrid>
        );
    }

    if (offer.isCanceled()) {
        const status =
            offer.state === OfferState.ENQUIRY_DECLINED || offer.state === OfferState.QUOTE_DECLINED
                ? translate('order.detail.offerCard.enquiryDeclined')
                : translate('order.detail.offerCard.canceled');

        return (
            <OfferActionsGrid>
                <MetaInformation variant="neutral">
                    <IconCircleX width={16} height={16} />
                    <Text variant="extraSmall" fontWeight={600}>
                        {status}
                    </Text>
                </MetaInformation>

                {role.isAdmin() ? (
                    <Button width="100%" onClick={() => navigate({ to: '/offer/$id', params: { id: offer.id } })}>
                        {translate('order.detail.offerCard.viewOfferDetails')}
                    </Button>
                ) : null}
            </OfferActionsGrid>
        );
    }

    return (
        <OfferActionsGrid>
            <MetaInformation variant="positive">
                <IconCircleCheck width={16} height={16} />
                <Text variant="extraSmall" fontWeight={600}>
                    {translate('order.detail.offerCard.ordered')}
                </Text>
            </MetaInformation>

            <Button
                emphasis="high"
                width="100%"
                onClick={() => navigate({ to: '/offer/$id', params: { id: offer.id } })}
            >
                {translate('order.detail.offerCard.viewOrder')}
            </Button>
        </OfferActionsGrid>
    );
};
