import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { UserOverviewPage } from '../user/list/UserOverviewPage';

const usersSearchSchema = z.object({
    searchQuery: fallback(z.string(), '').default(''),
    customerGroupIds: fallback(z.array(z.string()), []).default([]),
    supplierGroupIds: fallback(z.array(z.string()), []).default([]),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    page: fallback(z.number(), 0).default(0),
});

export const Route = createFileRoute('/_app/users')({
    component: UserOverviewPage,
    validateSearch: zodSearchValidator(usersSearchSchema),
});
