import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { agentsSearchSchema } from '../../types/agentsSearchSchema';
import { FuelAgentsOverviewPage } from '../agent/FuelAgentsOverviewPage';

export const Route = createFileRoute('/_app/fuel/agents/')({
    component: FuelAgentsOverviewPage,
    validateSearch: zodSearchValidator(agentsSearchSchema),
});
