import styled, { keyframes } from 'styled-components';

import { IconFlask } from '../../common/icons/cdl/Flask';
import { get } from '../../common/ui/get';

interface FlaskIconButtonProps {
    onClick: () => void;
}

const rainbowKeyframes = keyframes`
    100%, 0% {
        color: rgb(255, 0, 0);
    }
    8% {
        color: rgb(255, 127, 0);
    }
    16% {
        color: rgb(255, 255, 0);
    }
    25% {
        color: rgb(127, 255, 0);
    }
    33% {
        color: rgb(0, 255, 0);
    }
    41% {
        color: rgb(0, 255, 127);
    }
    50% {
        color: rgb(0, 255, 255);
    }
    58% {
        color: rgb(0, 127, 255);
    }
    66% {
        color: rgb(0, 0, 255);
    }
    75% {
        color: rgb(127, 0, 255);
    }
    83% {
        color: rgb(255, 0, 255);
    }
    91% {
        color: rgb(255, 0, 127);
    }
`;

const IconButtonContainer = styled.div`
    position: fixed;
    bottom: ${get('space.8')}px;
    left: ${get('space.4')}px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${get('colors.background.default')};
    border-radius: ${get('radii.1')}px;
    box-shadow: ${get('shadows.large')};

    &:hover {
        animation: ${rainbowKeyframes} 2.5s linear infinite;
    }
`;

export const FlaskIconButton = (props: FlaskIconButtonProps) => {
    return (
        <IconButtonContainer onClick={props.onClick}>
            <IconFlask width={28} height={28} />
        </IconButtonContainer>
    );
};
