import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { createOrder, CreateOrderResponse } from '../../../../common/api/clients/order.api';
import { translate } from '../../../../common/helpers/translate.helper';

export const useEnquiryCreate = () => {
    const navigate = useNavigate();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createOrder,
        onSuccess: (order: CreateOrderResponse) => {
            navigate({ to: '/order/$id', params: { id: order.id } });
        },
        onError: () => {
            addErrorToast(translate('alert.defaulterror.message'));
        },
    });
};
