import { QuickStatsMessage } from '../../../../types/QuickStatsMessage';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';

import { StatsItem } from './StatsItem';

interface VolumeStatsItemProps {
    stats: QuickStatsMessage;
}

export const VolumeStatsItem = ({ stats }: VolumeStatsItemProps) => {
    const { isFuel } = useProductContext();
    const unit = isFuel ? 'mt' : 'L';

    return (
        <StatsItem
            item={`${formatNumber({ number: stats.volume })} ${unit}`}
            description={translate('dashboard.quickstats.volume')}
        />
    );
};
