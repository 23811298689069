import { useNavigate } from '@tanstack/react-router';
import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { translate } from '../../common/helpers/translate.helper';
import { FormikPasswordInput } from '../../common/PasswordInput/FormikPasswordInput';
import { Box } from '../../common/ui/Box';

import { Link } from './Link';
import { FormikLoginState } from './LoginPage';

export const FormikLoginForm = () => {
    const { isSubmitting, values } = useFormikContext<FormikLoginState>();
    const navigate = useNavigate();

    const handleForgotPasswordClick = () => {
        navigate({
            to: '/password-reset',
            state: {
                emailAddress: values.emailAddress,
            },
        });
    };

    return (
        <Form>
            <Box display="grid" gridRowGap={5} marginBottom={7} marginTop={6}>
                <FormikInput label={translate('login.emailLabel')} name="emailAddress" />
                <FormikPasswordInput
                    label={translate('login.passwordLabel')}
                    name="password"
                    meta={<Link onClick={handleForgotPasswordClick}>{translate('login.forgotPassword')}</Link>}
                />
            </Box>
            <Button emphasis="high" width="100%" isLoading={isSubmitting} size="large">
                {translate('login.loginButton')}
            </Button>
        </Form>
    );
};
