import { Button } from '../../cdl/Button/Button';
import { EmptyPagePlaceholder } from '../../cdl/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { useStartUserlikeChat } from '../../common/hooks/useStartUserlikeChat';
import { IconArrowNarrowRight } from '../../common/icons/cdl/ArrowNarrowRight';
import { IconWorldOff } from '../../common/icons/cdl/WorldOff';
import { Box } from '../../common/ui/Box';

export const NoGlobalPrices = () => {
    const startUserlikeChat = useStartUserlikeChat();

    return (
        <EmptyPagePlaceholder
            title={translate('globalPrices.noCredentials.title')}
            description={translate('globalPrices.noCredentials.description')}
            Icon={IconWorldOff}
            action={
                <Button emphasis="low" onClick={startUserlikeChat}>
                    <Box display="flex" flexDirection="row" alignItems="center" columnGap={3}>
                        {translate('globalPrices.noCredentials.getInTouch')}
                        <IconArrowNarrowRight />
                    </Box>
                </Button>
            }
        />
    );
};
