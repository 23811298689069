import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../../../../cdl/Button/Button';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { useRole } from '../../../../../../common/hooks/useRole';
import { useOfferApprovalSettings } from '../../../../../../offer/MultipleApprovers/hooks/useOfferApprovalSettings';
import { RequestApprovalMultipleApproversPopup } from '../../../../../../offer/MultipleApprovers/RequestApprovalMultipleApproversPopup';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

import { RequestButton } from './RequestButton';

export const ApprovalRequestButton = () => {
    const { offer } = useOfferCardContext();
    const role = useRole();
    const offerApprovalSettingsQuery = useOfferApprovalSettings(
        offer.id,
        !role.isAdmin() && role.hasWriteRights(offer.customerId)
    );
    const [showApproversPopup, setShowApproversPopup] = useState(false);
    const navigate = useNavigate();

    if (role.isAdmin()) {
        return (
            <Button width="100%" onClick={() => navigate({ to: '/offer/$id', params: { id: offer.id } })}>
                {translate('order.detail.offerCard.viewOfferDetails')}
            </Button>
        );
    }

    if (offerApprovalSettingsQuery.isPending && role.hasWriteRights(offer.customerId)) {
        return <Skeleton height={32} width="100%" />;
    }

    if (
        offerApprovalSettingsQuery.isError ||
        !offerApprovalSettingsQuery.data ||
        !role.hasWriteRights(offer.customerId)
    ) {
        return null;
    }

    return (
        <div>
            <RequestButton
                offerApprovalSettings={offerApprovalSettingsQuery.data}
                approvalRequests={offer.approvalRequests}
                onRequestApprovalButtonClicked={() => setShowApproversPopup(true)}
            />

            <RequestApprovalMultipleApproversPopup
                offerApprovalSettings={offerApprovalSettingsQuery.data}
                open={showApproversPopup}
                onDismiss={() => setShowApproversPopup(false)}
                offerId={offer.id}
                customerId={offer.customerId}
            />
        </div>
    );
};
