import { useNavigate } from '@tanstack/react-router';
import { SingleValue } from 'react-select';

import { ProductContext } from '../../../types/ProductContext';
import { Select } from '../../cdl/Select/Select';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { useProductContext } from '../hooks/useProductContext';
import { getRole } from '../hooks/useRole';
import { IconDiamond } from '../icons/Diamond';
import { Box } from '../ui/Box';

import { ContextSwitchControl } from './ContextSwitch/ContextSwitchControl';
import { ContextSwitchMenu } from './ContextSwitch/ContextSwitchMenu';
import { ContextSwitchSingleValue } from './ContextSwitch/ContextSwitchSingleValue';
import { ContextSwitchValueContainer } from './ContextSwitch/ContextSwitchValueContainer';

interface ProductContextSwitchOption {
    value: ProductContext;
    label: string;
}

export const ProductContextSwitch = () => {
    const { possibleContexts, context } = useProductContext();
    const navigate = useNavigate();

    if (possibleContexts.length === 1) {
        return (
            <Box
                display="grid"
                gridTemplateColumns="24px auto"
                alignItems="center"
                gap={4}
                marginLeft={4}
                marginTop={4}
            >
                <IconDiamond width={24} />
                <Text variant="subtitle1">{translate(`productContext.${context}`)}</Text>
            </Box>
        );
    }

    const possibleContextOptions: ProductContextSwitchOption[] = possibleContexts.map((context) => ({
        value: context,
        label: translate(`productContext.${context}`),
    }));

    const handleChange = (selectedOption: SingleValue<ProductContextSwitchOption>) => {
        if (selectedOption && selectedOption.value !== context) {
            getRole().updateContext(selectedOption.value);
            if (selectedOption.value === 'FUEL') {
                navigate({ to: '/fuel/dashboard' });
            } else {
                navigate({ to: '/dashboard' });
            }
        }
    };

    return (
        <Box>
            <Select
                overrides={{
                    Control: ContextSwitchControl as any,
                    SingleValue: ContextSwitchSingleValue as any,
                    ValueContainer: ContextSwitchValueContainer as any,
                    Menu: ContextSwitchMenu as any,
                }}
                options={possibleContextOptions}
                value={possibleContextOptions.filter((option) => option.value === context)}
                isClearable={false}
                onChange={handleChange}
            />
        </Box>
    );
};
