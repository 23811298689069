import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

import { ToastsProvider } from '../cdl/Toast/ToastsProvider';
import { CenteredPageError } from '../common/Error/CenteredPageError';
import { ThemeProvider } from '../common/ThemeProvider';
import { Toolbox } from '../dev-tools/components/Toolbox';

interface AppRouteContext {
    hasLoadedRoleInformation: boolean;
    queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<AppRouteContext>()({
    component: () => (
        <>
            <ThemeProvider>
                <Outlet />
                <Toolbox />
                <ToastsProvider />
            </ThemeProvider>
        </>
    ),
    errorComponent: () => {
        return <CenteredPageError />;
    },
});
