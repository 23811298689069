import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { Box } from '../../common/ui/Box';

interface CopyToClipboardButtonProps {
    value: string | (() => string | null);
}

export const CopyToClipboardButton = ({ value }: CopyToClipboardButtonProps) => {
    const [copied, setCopied] = useState(false);

    const onClick = () => {
        const computedValue = typeof value === 'function' ? value() : value;

        if (!computedValue) {
            return;
        }

        navigator.clipboard.writeText(computedValue).then(() => {
            setCopied(true);

            setTimeout(() => {
                setCopied(false);
            }, 3000);
        });
    };

    return (
        <Box display="flex" alignItems="center">
            <Button onClick={onClick}>Copy token to clipboard</Button>

            {copied ? (
                <Text marginLeft={3} variant="body" fontWeight="semiBold">
                    Done!
                </Text>
            ) : null}
        </Box>
    );
};
