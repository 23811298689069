import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAlertTriangle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.57 2.043a2.914 2.914 0 0 1 3.922 1.029l.002.004L22.6 16.612l.009.014a2.915 2.915 0 0 1-2.491 4.37H3.881a2.914 2.914 0 0 1-2.49-4.371l.008-.014 8.09-13.507a2.914 2.914 0 0 1 1.08-1.061ZM12 3.668a.914.914 0 0 0-.78.437L3.12 17.63a.914.914 0 0 0 .78 1.365h16.2a.914.914 0 0 0 .78-1.365L12.78 4.108v-.002a.914.914 0 0 0-.782-.438ZM12 8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Zm-1 8a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
