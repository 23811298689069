import { useNavigate } from '@tanstack/react-router';
import PropTypes from 'prop-types';

import { Button } from '../../../cdl/Button/Button';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { DeprecatedFormikAgentSelect } from '../../../common/form-elements/formik/DeprecatedFormikAgentSelect';
import { DeprecatedFormikPortSelectWithSchedules } from '../../../common/form-elements/formik/DeprecatedFormikPortSelectWithSchedules';
import { FormikDateTimePicker } from '../../../common/form-elements/formik/FormikDateTimePicker';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikPositiveNumberInput } from '../../../common/form-elements/formik/FormikPositiveNumberInput';
import { useFormikDirtyWarning } from '../../../common/form-elements/formik/useFormikDirtyWarning';
import { FormikFuelProductTable } from '../../../common/FormikProductTable/fuel/FormikFuelProductTable';
import { translate } from '../../../common/helpers/translate.helper';
import { useSchedules } from '../../../common/hooks/useSchedules';
import { IconInfoCircle } from '../../../common/icons/cdl/InfoCircle';
import { StatusBar } from '../../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../../common/StatusBar/StatusBarType';
import { Box } from '../../../common/ui/Box';
import { CardVariation } from '../../../common/ui/CardVariation';
import { Flex } from '../../../common/ui/Flex';
import { Grid, GridItem } from '../../../common/ui/Grid';
import { FormikBuyerReferenceInput } from '../FormikBuyerReferenceInput';

export const OrderEditForm = (props) => {
    useFormikDirtyWarning();

    const navigate = useNavigate();
    const { schedules } = useSchedules(props.values.vessel.imo);
    const navigateToCurrentOrder = () => {
        navigate({ to: '/fuel/order/$id', params: { id: props.values.id } });
    };

    const onCancelButtonClick = () => {
        if (props.dirty) {
            const confirmed = confirm(translate('confirmation.warning'));
            if (confirmed) {
                navigateToCurrentOrder();
            }
        } else {
            navigateToCurrentOrder();
        }
    };

    const onSubmitClick = () => {
        if (!props.isSubmitting) {
            return props.submitForm();
        }
    };

    const onPortSelect = (port) => {
        if (!schedules || !schedules.length) {
            return;
        }

        const matchingSchedule = schedules.find((schedule) => schedule.port?.id === port.id);

        if (!matchingSchedule) {
            return;
        }

        if (matchingSchedule.agentIds?.length === 1) {
            props.setFieldValue('agentId', matchingSchedule.agentIds[0], false);
        }
    };

    return (
        <Box padding={6} display="grid" gap={6}>
            <CardVariation paddingX={0} paddingY={0}>
                <StatusBar
                    headline={translate('order.edit.fuel.statusBar.headline')}
                    subHeadline={translate('order.edit.fuel.statusBar.subHeadline')}
                    type={StatusBarType.WHITE}
                    icon={<IconInfoCircle />}
                />
            </CardVariation>
            <CardVariation>
                <Grid>
                    <GridItem width={1 / 2}>
                        <DeprecatedFormikPortSelectWithSchedules
                            name="portId"
                            schedules={schedules}
                            onPortSelect={onPortSelect}
                        />
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <DeprecatedFormikAgentSelect name="agentId" />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikDateTimePicker label={translate('fuelEnquiry.first.etaLabel')} required name="eta" />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikDateTimePicker label={translate('fuelEnquiry.first.etdLabel')} required name="etd" />
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <FormikPositiveNumberInput
                            min={1}
                            name="validityTime"
                            label={translate('fuelEnquiry.third.validity.label')}
                            placeholder={translate('fuelEnquiry.third.validity.placeholder')}
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikBuyerReferenceInput
                            name="buyerReference"
                            customerId={props.values.customerId}
                            orderId={props.values.id}
                        />
                    </GridItem>
                </Grid>
            </CardVariation>
            <CardVariation>
                <FormikFuelProductTable
                    config={{
                        showPrices: false,
                        editPrices: false,
                        showPhysicalSupplierColumn: false,
                        showEnergyContentColumn: false,
                        editEnergyContent: false,
                        editSurcharges: false,
                        showCounterOffers: false,
                    }}
                />
                <Flex justifyContent="center" marginTop={9}>
                    <ButtonGroup>
                        <Button type="button" onClick={onCancelButtonClick}>
                            {translate('order.edit.fuel.cancel')}
                        </Button>
                        <Button
                            emphasis="high"
                            disabled={!props.isValid || props.isSubmitting}
                            onClick={onSubmitClick}
                            isLoading={props.isSubmitting}
                        >
                            {translate('order.edit.fuel.update')}
                        </Button>
                    </ButtonGroup>
                </Flex>
            </CardVariation>
            <FormikDebug />
        </Box>
    );
};

OrderEditForm.propTypes = {
    values: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};
