import { useNavigate } from '@tanstack/react-router';
import { Formik } from 'formik';

import { LubesUpdateOrderRequest } from '../../common/api/clients/order.api';
import { Error } from '../../common/Error/Error';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../common/Overlay/Overlay';
import { Route } from '../../routes/_app._lubes.order_.edit.$id';
import { useOrder } from '../lubes/hooks/useOrder';
import { useOrderEdit } from '../lubes/hooks/useOrderEdit';
import { toLubesUpdateOrderRequest } from '../model/order.mapper';
import { validateOrder } from '../schema';

import { FormikEditOrderValues, OrderEditForm } from './OrderEditForm';

export const OrderEditPage = () => {
    const { id } = Route.useParams();
    const { data: order, isPending, isError } = useOrder(id);
    const orderEditMutation = useOrderEdit();
    const navigate = useNavigate();

    if (isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (isError) {
        return <Error />;
    }

    const initialValues: FormikEditOrderValues = {
        id: order.id,
        vesselId: order.vesselId,
        customerId: order.customerId,
        portId: order.portId,
        dateDelivery: order.dateDelivery || null,
        buyerReference: order.buyerReference || '',
        agentId: order.agentId || '',
        items: order.items,
        samplekits: order.samplekits,
        state: order.state,
    };

    const onSubmit = async (values: FormikEditOrderValues) => {
        const updateOrderRequest: LubesUpdateOrderRequest = {
            // Propagate existing values from the current order
            ...toLubesUpdateOrderRequest(order),
            // Update editable values
            vesselId: values.vesselId,
            portId: values.portId,
            dateDelivery: values.dateDelivery || null,
            buyerReference: values.buyerReference !== '' ? values.buyerReference : null,
            agentId: values.agentId !== '' ? values.agentId : null,
            items: values.items,
            samplekits: values.samplekits,
            state: values.state || null,
        };

        await orderEditMutation.mutateAsync({
            orderId: values.id,
            order: updateOrderRequest,
        });

        navigate({ to: '/order/$id', params: { id: values.id } });
    };

    return createOverlay(
        <Formik<FormikEditOrderValues>
            initialValues={initialValues}
            component={OrderEditForm}
            validateOnMount
            validate={validateOrder}
            onSubmit={onSubmit}
        />
    );
};
