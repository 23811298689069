import { useMutation } from '@tanstack/react-query';
import { useLocation } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useState } from 'react';

import { Text } from '../../cdl/Text/Text';
import { createPasswordReset } from '../../common/api/clients/passwordReset.api';
import { isValidEmailAddress } from '../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { FormikPasswordResetForm } from '../components/FormikPasswordResetForm';
import { Link } from '../components/Link';
import { LoginPageContentBox } from '../components/LoginPageContentBox';

export interface FormikPasswordResetState {
    emailAddress: string;
}

const validate = (values: FormikPasswordResetState): FormikErrors<FormikPasswordResetState> => {
    const errors: FormikErrors<FormikPasswordResetState> = {};

    if (!isValidEmailAddress(values.emailAddress)) {
        errors.emailAddress = translate('passwordReset.error.invalidEmailAddress');
    }

    return errors;
};

interface PasswordResetErrorResponse {
    errors: { code: 'USER_NOT_FOUND' }[];
}

const PasswordResetPage = () => {
    const passwordResetMutation = useMutation({ mutationFn: createPasswordReset });
    const [sentSuccessful, setSentSuccessful] = useState(false);
    const location = useLocation();
    const emailAddressFromRoute = location.state?.emailAddress;

    useDocumentTitle(translate('passwordReset.documentTitle'));

    const handleSubmit = async (values: FormikPasswordResetState, meta: FormikHelpers<FormikPasswordResetState>) => {
        try {
            await passwordResetMutation.mutateAsync({
                emailAddress: values.emailAddress,
            });

            setSentSuccessful(true);
        } catch (error) {
            const axiosError = error as AxiosError<PasswordResetErrorResponse>;

            if (axiosError.response?.data.errors[0].code === 'USER_NOT_FOUND') {
                meta.setErrors({
                    emailAddress: translate('passwordReset.error.noUserFoundForEmail'),
                });
            } else {
                meta.setErrors({
                    emailAddress: translate('passwordReset.error.unknownError'),
                });
            }
        }
    };

    return (
        <LoginPageContentBox>
            {sentSuccessful ? (
                <>
                    <Text variant="title" as="h1" marginBottom={6}>
                        {translate('passwordReset.successfulReset')}
                    </Text>
                    <Text color="foreground.muted" as="p">
                        {translate('passwordReset.noEmailReceived')}{' '}
                        <Link onClick={() => setSentSuccessful(false)}>{translate('passwordReset.resendEmail')}</Link>
                    </Text>
                </>
            ) : (
                <>
                    <Text variant="title" as="h1">
                        {translate('passwordReset.resetPassword')}
                    </Text>
                    <Text color="foreground.muted" as="p" marginBottom={6}>
                        {translate('passwordReset.enterAssociatedEmailAddress')}
                    </Text>

                    <Formik
                        initialValues={{
                            emailAddress: emailAddressFromRoute || '',
                        }}
                        validate={validate}
                        onSubmit={handleSubmit}
                        component={FormikPasswordResetForm}
                    />
                </>
            )}
        </LoginPageContentBox>
    );
};

export { PasswordResetPage };
