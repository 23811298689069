import { useNavigate } from '@tanstack/react-router';

import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../helpers/translate.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { IconPlus } from '../../icons/cdl/Plus';
import { Box } from '../../ui/Box';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

import { ContextualLiteSidebarItem } from './ContextualLiteSidebarItem';

export const LubesCustomerSidebarNavigation = () => {
    const role = useRole();
    const { context } = useProductContext();
    const navigate = useNavigate();

    return (
        <Box display="grid" gap={5}>
            {role.hasAnyCompanyWriteRights() ? (
                <Box width="100%" paddingX={4}>
                    <Button
                        width="100%"
                        onClick={() => {
                            navigate({ to: '/orders/new' });
                        }}
                        emphasis="high"
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {translate('navigation.createnew')}
                    </Button>
                </Box>
            ) : null}

            <Box display="flex" flexDirection="column">
                <SidebarNavigationItem to="/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/orders">{translate('navigation.orders')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/demand">{translate('navigation.demandOverview')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/compare">{translate('navigation.compare')}</SidebarNavigationItem>

                <SidebarNavigationItem to="/vessels">{translate('navigation.vessels')}</SidebarNavigationItem>

                <ContextualLiteSidebarItem to="/key-ports">
                    {translate('navigation.keyPorts')}
                </ContextualLiteSidebarItem>

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="/suppliers">{translate('navigation.suppliers')}</SidebarNavigationItem>
                ) : null}

                {role.hasAnyAdminRights(context) ? (
                    <ContextualLiteSidebarItem to="/agents">{translate('navigation.agents')}</ContextualLiteSidebarItem>
                ) : null}

                <ContextualLiteSidebarItem to="/statistics">
                    {translate('navigation.reporting')}
                </ContextualLiteSidebarItem>
            </Box>
        </Box>
    );
};
