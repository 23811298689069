import PropTypes from 'prop-types';

import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { InitialEnquiryModal } from '../common/components/InitialEnquiryModal';
import { useInitialEnquiry } from '../common/hooks/useInitialEnquiry';
import Skeleton from 'react-loading-skeleton';
import { Box } from '../../../common/ui/Box';

export const FuelInitialEnquiryPopup = ({ isOpen, onDismiss, offerId }) => {
    const initialEnquiryQuery = useInitialEnquiry(offerId);
    const offer = initialEnquiryQuery.data;

    return (
        <InitialEnquiryModal
            isLoading={initialEnquiryQuery.isPending}
            offer={offer}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            {initialEnquiryQuery.isPending ? (
                <Box display="grid" gap={5}>
                    <Skeleton height={32} />
                    <Skeleton height={32} />
                    <Skeleton height={32} />
                </Box>
            ) : null}
            {initialEnquiryQuery.isError ? <Error /> : null}
            {offer ? <FuelShowOfferProductTable offer={offer} showPrices={false} showOnlyInitialValues /> : null}
        </InitialEnquiryModal>
    );
};

FuelInitialEnquiryPopup.propTypes = {
    offerId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onDismiss: PropTypes.func,
};
