import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { Tag } from '../../cdl/Tag/Tag';
import { Error } from '../../common/Error/Error';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconFileInvoice } from '../../common/icons/cdl/FileInvoice';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { PortListing } from '../../common/PortListing/PortListing';
import { Section } from '../../common/Section/Section.tsx';
import { TableEmptyState } from '../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';
import { Box } from '../../common/ui/Box.tsx';

import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Route } from '../../routes/_app.fuel.contracts';
import { ContractTableActions } from './components/ContractTableActions';
import { ContractTab, contractTabFilter } from './contractTabsHelper';
import { ProductListing } from './ProductListing';
import { useContractPaginationTotals } from './useContractPaginationTotals';
import { useContractsPagination } from './useContractsPagination';

export const ContractOverviewTable = () => {
    const role = useRole();

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const contractsTotalsQuery = useContractPaginationTotals({
        searchQuery: search.searchQuery,
        customerGroupIds: search.customerGroupIds,
    });
    const contractTotals = contractsTotalsQuery.data ?? {};

    const { data, isPending, isError, pageTotal } = useContractsPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        customerGroupIds: search.customerGroupIds,
        tender: contractTabFilter[search.tab].tender,
        active: contractTabFilter[search.tab].active,
    });

    if (isPending) {
        return (
            <Section>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab }) })}
                    totals={contractTotals}
                />
                <LoadingIndicator />
            </Section>
        );
    }

    if (isError) {
        return (
            <Section>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab }) })}
                    totals={contractTotals}
                />

                <Error />
            </Section>
        );
    }

    if (!data.content.length) {
        return (
            <Section>
                <TabSwitch
                    selectedContext={search.tab}
                    onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab }) })}
                    totals={contractTotals}
                />
                <TableEmptyState Icon={IconFileInvoice} text={translate('contracts.emptyList')} />
            </Section>
        );
    }

    return (
        <Section>
            <TabSwitch
                selectedContext={search.tab}
                onSelect={(tab) => navigate({ search: (previousSearch) => ({ ...previousSearch, tab }) })}
                totals={contractTotals}
            />
            <TableBuilder data={data.content} grow={false}>
                <TableBuilderColumn header={translate('contracts.contractReference')} width="15%" truncate>
                    {(contract) => contract.reference}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('contracts.ports')} width="15%">
                    {(contract) => <PortListing ports={contract.ports} />}
                </TableBuilderColumn>

                {search.tab !== ContractTab.TENDERED ? (
                    <TableBuilderColumn header={translate('contracts.supplier')} width="15%" truncate>
                        {(contract) => contract.supplier?.name}
                    </TableBuilderColumn>
                ) : null}

                {role.isAdmin() ? (
                    <TableBuilderColumn header={translate('contracts.customerGroup')} width="15%" truncate>
                        {(contract) => contract.customerGroup.name}
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn header={translate('contracts.startDate')} width="10%">
                    {(contract) => formatDate({ date: contract.startDate })}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('contracts.endDate')} width="10%">
                    {(contract) => formatDate({ date: contract.endDate })}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('contracts.products')} width="20%">
                    {(contract) => <ProductListing productGroups={contract.productGroups} />}
                </TableBuilderColumn>
                {search.tab === ContractTab.TENDERED ? (
                    <TableBuilderColumn header={translate('contracts.tenderDueDate')} width="10%">
                        {(contract) => (contract.tender?.dueDate ? formatDate({ date: contract.tender.dueDate }) : '')}
                    </TableBuilderColumn>
                ) : null}
                {search.tab === ContractTab.TENDERED ? (
                    <TableBuilderColumn header={translate('contracts.tenderStateTitle')} width="10%">
                        {(contract) => (
                            <Tag variant={contract.tender.state === 'SENT' ? 'positive' : 'default'} emphasis={false}>
                                {translate(`contracts.tenderState.${contract.tender?.state}`)}
                            </Tag>
                        )}
                    </TableBuilderColumn>
                ) : null}
                {!role.isAdmin() && role.hasWriteRights() ? (
                    <TableBuilderColumn header="" width="10%">
                        {(contract) => (
                            <Box display="flex" flexWrap="nowrap" justifyContent="flex-end">
                                <ContractTableActions contract={contract} />
                            </Box>
                        )}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={search.page}
                onPageChange={(page) => navigate({ search: (previousSearch) => ({ ...previousSearch, page }) })}
            />
        </Section>
    );
};
