import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { keyPortsSearchSchema } from '../../types/keyPortsSearchSchema';
import { LubesKeyPortOverviewPage } from '../keyport/LubesKeyPortOverviewPage';

export const Route = createFileRoute('/_app/_lubes/key-ports')({
    component: LubesKeyPortOverviewPage,
    validateSearch: zodSearchValidator(keyPortsSearchSchema),
});
