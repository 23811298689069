import { Form, Formik, FormikErrors, setIn } from 'formik';

import { VesselCreateRequestMessage } from '../../../types/VesselCreateRequestMessage';
import { Button } from '../../cdl/Button/Button';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { Text } from '../../cdl/Text/Text';
import { FormikCustomerCompanySelect } from '../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { isValidEmailAddress } from '../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { FormikTagListInput } from '../../common/TagListInput/FormikTagListInput';
import { Box } from '../../common/ui/Box';
import { FormikDefaultSupplierComboBox } from '../VesselDetailPage/EditVessel/components/FormikDefaultSupplierComboBox';

interface CreateVesselFormProps {
    onSubmit: (vessel: VesselCreateRequestMessage) => void;
}

interface FormikVesselDetailsState {
    customerId: string;
    name: string;
    imo: string;
    types: string[];
    services: string[];
    email: string;
    defaultSupplierId: string;
    invoiceVAT: string;
    invoiceAddress: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
    active: boolean;
    paying: boolean;
    directOrderAllowed: boolean;
}

export const CreateVesselForm = ({ onSubmit }: CreateVesselFormProps) => {
    const role = useRole();
    const { context, isLubes, isFuel } = useProductContext();
    const companyIdsForType = role.getCompaniesWithType(context).map((it) => it.id);
    const customerId = companyIdsForType.at(0);

    const initialValues: FormikVesselDetailsState = {
        customerId: customerId && role.isOneCompanyUser(context) ? customerId : '',
        name: '',
        imo: '',
        types: [],
        services: [],
        email: '',
        defaultSupplierId: '',
        invoiceVAT: '',
        invoiceAddress: {
            companyName: '',
            street: '',
            streetNumber: '',
            zipcode: '',
            city: '',
            country: '',
        },
        active: false,
        paying: false,
        directOrderAllowed: false,
    };

    const validateCreateVesselForm = (values: FormikVesselDetailsState) => {
        let errors: FormikErrors<FormikVesselDetailsState> = {};
        if (!role.isOneCompanyUser(context) && !values.customerId) {
            errors.customerId = translate('assignments.suppliers.companyRequired');
        }
        if (!values.name) {
            errors.name = translate('vessel.form.nameRequired');
        }
        if (!values.imo) {
            errors.imo = translate('vessel.form.imoRequired');
        }
        if (values.email && !isValidEmailAddress(values.email)) {
            errors.email = translate('shareOrderPopup.emailAddressError');
        }
        if (!values.invoiceAddress.companyName) {
            errors = setIn(errors, 'invoiceAddress.companyName', translate('vessel.form.companyNameRequired'));
        }
        if (!values.invoiceAddress.street) {
            errors = setIn(errors, 'invoiceAddress.street', translate('vessel.form.streetRequired'));
        }
        if (!values.invoiceAddress.streetNumber) {
            errors = setIn(errors, 'invoiceAddress.streetNumber', translate('vessel.form.streetNumberRequired'));
        }
        if (!values.invoiceAddress.zipcode) {
            errors = setIn(errors, 'invoiceAddress.zipcode', translate('vessel.form.zipCodeRequired'));
        }
        if (!values.invoiceAddress.city) {
            errors = setIn(errors, 'invoiceAddress.city', translate('vessel.form.cityRequired'));
        }
        if (!values.invoiceAddress.country) {
            errors = setIn(errors, 'invoiceAddress.country', translate('vessel.form.countryRequired'));
        }
        return errors;
    };

    const handleSubmit = (values: FormikVesselDetailsState) => {
        const vesselRequestMessage: VesselCreateRequestMessage = {
            customerId: values.customerId,
            name: values.name,
            imo: values.imo,
            types: values.types.length ? values.types : undefined,
            services: values.services.length ? values.services : undefined,
            email: values.email || undefined,
            defaultSupplierId: values.defaultSupplierId || undefined,
            invoiceVAT: values.invoiceVAT || undefined,
            invoiceAddress: values.invoiceAddress,
            active: values.active,
            paying: values.paying,
            directOrderAllowed: values.directOrderAllowed,
        };
        onSubmit(vesselRequestMessage);
    };

    return (
        <Formik initialValues={initialValues} validate={validateCreateVesselForm} onSubmit={handleSubmit}>
            {({ isSubmitting, values }) => (
                <Form style={{ height: '100%' }}>
                    <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                        <Box padding={7} paddingBottom={6}>
                            <Text variant="title">{translate('createVesselForm.title')}</Text>
                        </Box>

                        <Box display="flex" flexDirection="column" gap={7} paddingX={7} overflowY="auto">
                            <Box display="grid" gap={5}>
                                {!role.isOneCompanyUser(context) ? (
                                    <FormikCustomerCompanySelect
                                        label={translate('vessel.company')}
                                        context={context}
                                        name="customerId"
                                    />
                                ) : null}

                                <FormikInput name="name" label={translate('vessel.name')} />

                                <FormikInput name="imo" label={translate('vessel.imo')} />

                                <FormikInput name="email" label={translate('vessel.form.email')} />

                                {isFuel ? (
                                    <>
                                        <FormikTagListInput name="types" label={translate('vessel.typesInput.label')} />

                                        <FormikTagListInput
                                            name="services"
                                            label={translate('vessel.servicesInput.label')}
                                        />
                                    </>
                                ) : null}

                                {isLubes ? (
                                    <FormikDefaultSupplierComboBox
                                        name="defaultSupplierId"
                                        customerId={values.customerId}
                                        label={translate('vessel.form.defaultSupplier')}
                                        disabled={!values.customerId}
                                    />
                                ) : null}
                            </Box>

                            <Box display="grid" gap={5}>
                                <Text variant="subtitle1">{translate('vessel.form.invoiceInformation')}</Text>
                                <FormikInput name="invoiceVAT" label={translate('vessel.form.vat')} />
                                <FormikInput
                                    name="invoiceAddress.companyName"
                                    label={translate('global.address.company')}
                                    autoComplete="street-address"
                                />

                                <Box display="flex" flexDirection="column" gap={2}>
                                    <FormikInput
                                        name="invoiceAddress.street"
                                        label={translate('vessel.form.billingAddress')}
                                        placeholder={translate('vessel.form.streetName')}
                                    />
                                    <FormikInput
                                        name="invoiceAddress.streetNumber"
                                        placeholder={translate('vessel.form.streetNumber')}
                                    />
                                    <FormikInput
                                        name="invoiceAddress.zipcode"
                                        placeholder={translate('vessel.form.postalCode')}
                                    />
                                    <FormikInput
                                        name="invoiceAddress.city"
                                        placeholder={translate('vessel.form.townCity')}
                                    />
                                    <FormikInput
                                        name="invoiceAddress.country"
                                        placeholder={translate('global.address.country')}
                                    />
                                </Box>
                            </Box>

                            {role.isAdmin() ? (
                                <Box display="grid" rowGap={4} marginTop={4}>
                                    <Text variant="subtitle1" as="h2">
                                        {translate('vessel.form.adminSettings')}
                                    </Text>
                                    <FormikCheckbox name="active" label={translate('vessel.form.activeVessel')} />
                                    <FormikCheckbox name="paying" label={translate('vessel.form.payingVessel')} />
                                    <FormikCheckbox
                                        name="directOrderAllowed"
                                        label={translate('vessel.form.directOrder')}
                                    />
                                </Box>
                            ) : null}

                            <FormikDebug />
                        </Box>

                        <Box padding={7}>
                            <Button emphasis="high" type="submit" size="large" isLoading={isSubmitting} width="100%">
                                {translate('btn.save.default')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
