import { useNavigate } from '@tanstack/react-router';

import { translate } from '../../../common/helpers/translate.helper';
import { Route } from '../../../routes/_app.fuel.offer.$id_.edit';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { OverlayHeader } from '../../../common/Overlay/OverlayHeader';
import { Box } from '../../../common/ui/Box';
import { useOfferView } from '../hooks/useOfferView';

import { OfferEditFormikWrapper } from './OfferEditFormikWrapper';

export const FuelOfferEditFlow = () => {
    return createOverlay(<FuelOfferEdit />);
};

const FuelOfferEdit = () => {
    const navigate = useNavigate();
    const { id } = Route.useParams();
    const offerQuery = useOfferView(id);

    const documentTitle = offerQuery.data
        ? translate('page.offerEdit', {
              offerNumber: offerQuery.data.offerNumber,
          })
        : null;

    useDocumentTitle(documentTitle);

    const onCancelClick = () => {
        navigate({ to: '/fuel/offer/$id', params: { id } });
    };

    if (offerQuery.isPending) {
        return (
            <Box minHeight="100%" backgroundColor="background.subtle">
                <OverlayHeader title={translate('offer.edit.fuel.editHeadline')} onClose={onCancelClick}>
                    <LoadingIndicator variant="page" />
                </OverlayHeader>
            </Box>
        );
    }

    return (
        <Box paddingBottom={11} minHeight="100%" backgroundColor="background.subtle">
            <OverlayHeader
                title={
                    offerQuery.data.isOrder()
                        ? translate('offer.edit.fuel.orderHeadline')
                        : translate('offer.edit.fuel.offerHeadline')
                }
                onClose={onCancelClick}
            >
                <OfferEditFormikWrapper data={offerQuery.data} />
            </OverlayHeader>
        </Box>
    );
};
