import { useEffect } from 'react';

import { useMagicBellUserConfig } from '../Topbar/Notifications/useMagicBellUserConfig';

import { useEnums } from './useEnums';
import { useKeyPorts } from './useKeyPorts';

export const usePrefetchQueries = () => {
    const { prefetch: prefetchKeyPorts } = useKeyPorts();
    const { prefetch: prefetchEnums } = useEnums();
    const { prefetch: prefetchMagicBellUserConfig } = useMagicBellUserConfig();

    useEffect(() => {
        prefetchKeyPorts();
        prefetchEnums();
        prefetchMagicBellUserConfig();
    }, [prefetchEnums, prefetchKeyPorts, prefetchMagicBellUserConfig]);
};
