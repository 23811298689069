import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { useEnums } from '../../common/hooks/useEnums';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useChartTitleTranslations } from '../hooks/useChartTitleTranslations';
import { barOptions, ChartType } from '../utils/barOptions';
import { chartColors } from '../utils/chartColors';
import { pieOptions } from '../utils/pieOptions';

import { ChartGrid } from './ChartGrid';
import { ChartWrapper } from './ChartWrapper';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

interface ProductGroupsTabStatisticsProps {
    productGroupsStatistics: BaseStatisticsMessage['productGroup'];
}

export const ProductGroupsTabStatistics = ({ productGroupsStatistics }: ProductGroupsTabStatisticsProps) => {
    const { getHumanReadableValue } = useEnums();
    const { isLubes } = useProductContext();
    const chartTitles = useChartTitleTranslations();

    const productGroupNames = productGroupsStatistics.map((productGroupData) =>
        isLubes ? getHumanReadableValue('productGroup', productGroupData.productGroup) : productGroupData.productGroup
    );
    const backgroundColor = chartColors;

    const totalData = {
        labels: productGroupNames,
        datasets: [
            {
                label: ChartType.TOTAL,
                data: productGroupsStatistics.map((productGroupData) => productGroupData.total.value),
                backgroundColor,
            },
        ],
    };

    const volumeData = {
        labels: productGroupNames,
        datasets: [
            {
                label: ChartType.VOLUME,
                data: productGroupsStatistics.map((productGroupData) => Math.round(productGroupData.volume)),
                backgroundColor,
            },
        ],
    };
    return (
        <ChartGrid>
            <ChartWrapper title={chartTitles.total}>
                <Pie data={totalData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Pie data={volumeData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.total}>
                <Bar data={totalData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Bar data={volumeData} options={barOptions} />
            </ChartWrapper>
        </ChartGrid>
    );
};
