import { linkOptions } from '@tanstack/react-router';

import { CustomerGroupMessage } from '../../../../types/CustomerGroupMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Route } from '../../../routes/_app.customer-groups';
import { useCustomerGroupsPagination } from '../hooks/useCustomerGroupsPagination';

export const CustomerGroupsTable = () => {
    const search = Route.useSearch();
    const navigate = Route.useNavigate();
    const customerGroupsQuery = useCustomerGroupsPagination(search.searchQuery, search.page);

    if (customerGroupsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (customerGroupsQuery.isError) {
        return <CenteredPageError />;
    }

    if (customerGroupsQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder
                data={customerGroupsQuery.data.content}
                rowLink={(row: CustomerGroupMessage) =>
                    linkOptions({ to: '/customer-group/$id', params: { id: row.id } })
                }
                grow={false}
            >
                <TableBuilderColumn<CustomerGroupMessage> header={translate('customergroup.name')} width="50%" truncate>
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<CustomerGroupMessage> header={translate('global.date.created')} numeric>
                    {(row) => formatDateTime({ date: row.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn<CustomerGroupMessage> header={translate('customergroup.testcompany')}>
                    {(row) => (row.testCompany ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={search.page}
                pageTotal={customerGroupsQuery.pageTotal}
                onPageChange={(page) => {
                    navigate({
                        search: (previousSearch) => ({ ...previousSearch, page }),
                    });
                }}
            />
        </div>
    );
};
