import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { Tag } from '../../../cdl/Tag/Tag';
import { useEnums } from '../../../common/hooks/useEnums';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { OfferState } from '../../../offer/model/OfferState';

interface CombinedOfferApprovalStateTagProps {
    approvalRequestState?: ApprovalRequestState;
    offerState?: OfferState;
}

export const CombinedOfferApprovalStateTag = (props: CombinedOfferApprovalStateTagProps) => {
    const { getHumanReadableValue } = useEnums();

    if (!props.offerState) {
        return null;
    }

    if (!props.approvalRequestState) {
        return <OfferStatusTag state={props.offerState} variant="customer" />;
    }

    const humanReadableValue = getHumanReadableValue('approvalRequestState', props.approvalRequestState);

    switch (props.approvalRequestState) {
        case ApprovalRequestState.APPROVED: {
            return <Tag variant="positive">{humanReadableValue}</Tag>;
        }
        case ApprovalRequestState.PENDING: {
            return (
                <Tag variant="default" emphasis>
                    {humanReadableValue}
                </Tag>
            );
        }
        case ApprovalRequestState.DECLINED: {
            return (
                <Tag variant="attention" emphasis>
                    {humanReadableValue}
                </Tag>
            );
        }
        case ApprovalRequestState.WITHDRAWN: {
            return <Tag variant="attention">{humanReadableValue}</Tag>;
        }
        case ApprovalRequestState.EXPIRED: {
            return <Tag variant="default">{humanReadableValue}</Tag>;
        }
    }
};
