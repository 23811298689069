import { useRole } from '../../../common/hooks/useRole';
import { useVesselDetails } from '../../../common/hooks/useVesselDetails';
import { Box } from '../../../common/ui/Box';
import { Route } from '../../../routes/_app._lubes.vessel.$id';
import { AverageConsumptionSection } from '../../VesselDetailPage/AvarageConsumptionSection/AverageConsumptionSection';
import { LiteVesselBudget } from '../../VesselDetailPage/Budget/LiteVesselBudget';
import { VesselBudget } from '../../VesselDetailPage/Budget/VesselBudget';
import { LubesVesselDetailsSection } from '../../VesselDetailPage/components/LubesVesselDetailsSection';
import { LiteRemainingOnBoard } from '../../VesselDetailPage/RemainingOnBoard/LiteRemainingOnBoard';
import { RemainingOnBoard } from '../../VesselDetailPage/RemainingOnBoard/RemainingOnBoard';

export const LubesVesselDetailPageOverviewContent = () => {
    const role = useRole();

    const { id } = Route.useParams();
    const { data: vessel } = useVesselDetails(id);

    return (
        <Box display="grid" gridTemplateColumns="auto 25%" gap={9}>
            <Box display="flex" flexDirection="column" gap={9}>
                {role.usesCloselinkLite() ? (
                    <>
                        <LiteRemainingOnBoard />
                        <LiteVesselBudget />
                    </>
                ) : (
                    <>
                        <RemainingOnBoard vessel={vessel} />
                        <AverageConsumptionSection vesselId={vessel.id} customerId={vessel.customerId} />
                        <VesselBudget vessel={vessel} />
                    </>
                )}
            </Box>

            <LubesVesselDetailsSection vessel={vessel} />
        </Box>
    );
};
