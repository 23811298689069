import { ProductContext } from '../../../types/ProductContext';
import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box.tsx';
import { FuelSuppliersOverviewTable } from './components/FuelSuppliersOverviewTable';
import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';

import { Route } from '../../routes/_app.fuel.assignments';

export const FuelSuppliersOverviewPage = () => {
    const role = useRole();
    const navigate = Route.useNavigate();
    const search = Route.useSearch();

    useDocumentTitle(translate('assignments.overviewPageTitle'));

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {translate('assignments.overviewPageTitle')}
                </Text>

                {!role.isOneCompanyUser(ProductContext.FUEL) ? (
                    <Box display="flex">
                        <OverviewCustomerCompanySelect
                            onChange={(customerIds) => {
                                navigate({ search: { customerIds, page: 0 } });
                            }}
                            value={search.customerIds}
                            context={ProductContext.FUEL}
                        />
                    </Box>
                ) : null}

                <FuelSuppliersOverviewTable />
            </PageGrid>
        </Page>
    );
};
