import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { SortingKey } from '../demand/components/SortingKey';
import { DemandOverviewPage } from '../demand/pages/DemandOverviewPage';

const demandSearchSchema = z.object({
    groupId: z.string().optional(),
    sortingKey: fallback(z.nativeEnum(SortingKey), SortingKey.DAYS_LEFT_SAILING).default(SortingKey.DAYS_LEFT_SAILING),
});

export const Route = createFileRoute('/_app/_lubes/demand')({
    component: DemandOverviewPage,
    validateSearch: zodSearchValidator(demandSearchSchema),
});
