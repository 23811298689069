import { Link, LinkProps } from '@tanstack/react-router';
import styled from 'styled-components';

import { linkStyles } from '../../cdl/Link/linkStyles';

interface DemandLinkProps extends LinkProps {
    fontSize: string;
}

const StyledLink = styled(Link)<DemandLinkProps>`
    ${linkStyles}
`;

export const DemandLink = (props: DemandLinkProps) => {
    /* @ts-ignore */
    return <StyledLink {...props} />;
};
