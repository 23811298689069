import PropTypes from 'prop-types';

import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';

export const PreviousButton = ({ onClick }) => {
    return (
        <Button type="button" onClick={onClick}>
            {translate('fuelEnquiry.navigation.previous')}
        </Button>
    );
};

PreviousButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
