import { SupplierStatisticsMessage } from '../../../types/StatisticsMessage';
import { useProductContext } from '../../common/hooks/useProductContext';
import { Route as RouteLubes } from '../../routes/_app._lubes.statistics';
import { Route as RouteFuel } from '../../routes/_app.fuel.statistics';

import { CustomersTabContent } from './CustomersTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticTab } from './StatisticTab';

interface SupplierStatisticsContentProps {
    statistics: SupplierStatisticsMessage;
}

export const SupplierStatisticsContent = ({ statistics }: SupplierStatisticsContentProps) => {
    const { context } = useProductContext();
    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();

    switch (search.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.CUSTOMERS:
            return <CustomersTabContent customerStatistics={statistics.customer} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
        case StatisticTab.VESSELS:
            return null;
        case StatisticTab.SUPPLIERS:
            return null;
    }
};
