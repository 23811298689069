import {
    CustomDoubleLineOption,
    CustomDoubleLineOptionProps,
} from '../../cdl/Select/components/CustomDoubleLineOption';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatPort } from '../../common/helpers/formatPort.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';

import { IntegrationOrderSelectOption } from './IntegrationOrderSelect';

interface IntegrationOrderOptionDoubleLineOptionProps extends CustomDoubleLineOptionProps {
    data: IntegrationOrderSelectOption;
}

export const IntegrationOrderOptionDoubleLineOption = (props: IntegrationOrderOptionDoubleLineOptionProps) => {
    const vesselName = formatVessel({ vessel: props.data.order.vessel });
    const companyName = formatCompanyName({ company: props.data.order.customer, includeType: true }) || '';

    if (props.data.order.port) {
        return (
            <CustomDoubleLineOption
                {...props}
                description={translate('integrationsOverview.orderOption', {
                    vesselName,
                    portName: formatPort({ port: props.data.order.port }),
                    companyName,
                })}
            />
        );
    }

    return (
        <CustomDoubleLineOption
            {...props}
            description={translate('integrationsOverview.orderOptionWithoutPort', { vesselName, companyName })}
        />
    );
};
