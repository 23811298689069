import PropTypes from 'prop-types';

import { FuelShowOfferProductTable } from '../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { Section } from '../../common/Section/Section';
import { Box } from '../../common/ui/Box';
import { CustomerApprovalDataSheet } from '../OfferCoreData/CustomerApprovalDataSheet';

export const FuelApprovalContent = ({ offer }) => {
    return (
        <Box>
            <Box marginBottom={5}>
                <CustomerApprovalDataSheet offer={offer} />
            </Box>
            <Section>
                <FuelShowOfferProductTable offer={offer} />
            </Section>
        </Box>
    );
};

FuelApprovalContent.propTypes = {
    offer: PropTypes.object.isRequired,
};
