import { useLocation, useNavigate } from '@tanstack/react-router';
import moment from 'moment/moment';

import { ProductContext } from '../../../types/ProductContext';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { DataTable } from '../../common/DataTable/DataTable';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { translateOrderOfferState } from '../../common/helpers/translateOrderOfferState.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconAlertTriangle } from '../../common/icons/cdl/AlertTriangle';
import { Flex } from '../../common/ui/Flex';
import { theme } from '../../common/ui/theme';
import { YesOrNo } from '../../common/YesOrNo/YesOrNo';
import { OrderModel } from '../../order/model/OrderModel';
import { Port } from '../../port/Port';
import { CONTEXT_TYPE } from '../../user/model/ContextType';

interface SearchResultOrdersTableProps {
    orders: OrderModel[];
}

export const SearchResultOrderTable = ({ orders }: SearchResultOrdersTableProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const role = useRole();

    const columns = [
        {
            label: translate('order.ordernumber'),
            width: '10%',
            value: (order: OrderModel) =>
                order.type === ProductContext.LUBES || (order.type === ProductContext.FUEL && role.isAdmin())
                    ? order.orderNumber
                    : '-',
        },
        {
            label: translate('order.buyerreferenceshort'),
            width: '10%',
            condition: role.isCustomer(),
            value: (order: OrderModel) => order.buyerReference || '-',
        },
        {
            label: role.isCustomer() ? translate('order.company') : translate('order.customer'),
            width: '15%',
            condition: !role.isOneCompanyUser(),
            value: (order: OrderModel) =>
                formatCompanyName({ company: order.customer, includeType: role.isAdmin() }) || '-',
        },
        {
            label: translate('order.vessel'),
            width: '15%',
            value: (order: OrderModel) => formatVessel({ vessel: order.vessel, short: true }),
        },
        {
            label: translate('order.port'),
            width: '20%',
            value: function renderPort(order: OrderModel) {
                if (!order.port) {
                    return '-';
                }

                return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: translate('order.dateDeliveryShort'),
            width: '10%',
            value: function renderDeliveryDate(order: OrderModel) {
                if (!order.dateDelivery) {
                    return '-';
                }
                const today = moment.utc(moment()).startOf('day');

                if (moment(order.dateDelivery) < today) {
                    return (
                        <Flex flexDirection="row" alignItems="center" columnGap={4}>
                            {formatDate({
                                date: order.dateDelivery,
                                timeZone: 'UTC',
                            })}
                            <Tooltip label={translate('order.outdatedtooltip')}>
                                <div>
                                    <IconAlertTriangle height={16} width={16} color={theme.colors.foreground.subtle} />
                                </div>
                            </Tooltip>
                        </Flex>
                    );
                }

                return formatDate({
                    date: order.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: translate('order.status'),
            width: '10%',
            value: function renderOfferState(order: OrderModel) {
                const offerState = translateOrderOfferState(order, 'CUSTOMER');

                return offerState || '-';
            },
        },
        {
            label: translate('order.detail.spot'),
            width: '5%',
            condition: role.isAdmin(),
            value: (order: OrderModel) => (order.type === CONTEXT_TYPE.LUBES ? <YesOrNo value={!!order.spot} /> : '-'),
        },
        {
            label: translate('order.offercount'),
            width: '5%',
            value: (order: OrderModel) => order.offerCount,
        },
    ].filter(({ condition = true }) => condition);

    const onRowClick = (order: OrderModel) => {
        switch (order.type) {
            case CONTEXT_TYPE.FUEL:
                if (order.isClosed() && order.chosenOfferId) {
                    navigate({ to: '/fuel/offer/$id', params: { id: order.chosenOfferId } });
                } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
                    navigate({
                        to: '/fuel/create',
                        search: { orderId: order.id },
                        state: { originLocation: location },
                    });
                } else {
                    navigate({ to: '/fuel/order/$id', params: { id: order.id } });
                }
                break;

            case CONTEXT_TYPE.LUBES:
                if (order.isClosed() && order.chosenOfferId) {
                    navigate({ to: '/offer/$id', params: { id: order.chosenOfferId } });
                } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
                    navigate({ to: '/orders/draft/$id', params: { id: order.id } });
                } else {
                    navigate({ to: '/order/$id', params: { id: order.id } });
                }
        }
    };

    return (
        <DataTable
            columns={columns}
            rows={orders}
            onRowClick={({ row: order }) => {
                onRowClick(order);
            }}
            isRowUnread={({ row: order }) => role.isCustomer() && !order.read}
        />
    );
};
