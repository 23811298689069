import { createRouter } from '@tanstack/react-router';

import { queryClient } from './lib/queryClient';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
    routeTree,
    basepath: '/app/',
    context: {
        hasLoadedRoleInformation: false,
        queryClient,
    },
    defaultPreload: 'intent',
    defaultPreloadDelay: 100,
    defaultPreloadStaleTime: 0,
});
