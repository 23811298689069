import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { UserMessage } from '../../../types/UserMessage';
import { UserStatus } from '../../../types/UserStatus';
import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { HeaderWithLine } from '../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';
import { Route } from '../../routes/_app.user.detail.$id';

import { AdminUserDataSheet } from './components/AdminUserDataSheet';
import { EditUserButton } from './components/EditUserButton';
import { SetNewPasswordPopup } from './components/SetNewPasswordPopup';
import { UserDataSheet } from './components/UserDataSheet';
import { useUser } from './hooks/useUser';
import { useUserInvitationResend } from './hooks/useUserInvitationResend';

export const UserDetailPage = () => {
    const role = useRole();
    const { id } = Route.useParams();
    const { addErrorToast, addToast } = useToasts();
    const [isNewPasswordPopupOpen, setIsNewPasswordPopupOpen] = useState<boolean>(false);
    const resendInvitationMutation = useUserInvitationResend();

    const useUserQuery = useUser(id);

    const resendInvite = () => {
        return resendInvitationMutation.mutate(id, {
            onSuccess: () => addToast(translate('user.resendInvitationToasts.success')),
            onError: () => addErrorToast(translate('user.resendInvitationToasts.error')),
        });
    };
    const getUserFullName = (user?: UserMessage) => {
        if (!user || (!user.firstname && !user.lastname)) {
            return translate('user.userDetails');
        }
        return [user.firstname, user.lastname].filter(Boolean).join(' ');
    };

    return (
        <Page>
            <PageGrid>
                <HeaderWithLine>
                    {useUserQuery.isPending ? (
                        <>
                            <Box display="flex" alignItems="center" height="40px">
                                <Skeleton width={200} height={28} />
                            </Box>
                            <Skeleton width={50} height={32} />
                        </>
                    ) : (
                        <>
                            <Text as="h1" variant="headline">
                                {getUserFullName(useUserQuery.data)}
                            </Text>
                            <ButtonGroup>
                                {role.user.id === id ? (
                                    <Button onClick={() => setIsNewPasswordPopupOpen(true)}>
                                        {translate('user.resetpassword')}
                                    </Button>
                                ) : null}

                                {role.isAdmin() && useUserQuery.data?.status === UserStatus.INVITED ? (
                                    <Button onClick={resendInvite}>{translate('user.resendInvitation')}</Button>
                                ) : null}

                                {useUserQuery.data ? <EditUserButton user={useUserQuery.data} /> : null}
                            </ButtonGroup>
                        </>
                    )}
                </HeaderWithLine>

                <SetNewPasswordPopup
                    user={role.user}
                    isOpen={isNewPasswordPopupOpen}
                    onDismiss={() => setIsNewPasswordPopupOpen(false)}
                />

                {role.isAdmin() ? <AdminUserDataSheet /> : <UserDataSheet />}
            </PageGrid>
        </Page>
    );
};
