import * as Popover from '@radix-ui/react-popover';
import { config, useTransition } from '@react-spring/web';
import { DatePickerStateProvider } from '@rehookify/datepicker';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { CaptionProps } from '../Caption/Caption';
import { CalendarActions } from '../DateRangePicker/components/CalendarActions';
import { CalendarContainer } from '../DateRangePicker/components/CalendarContainer';

import { CalendarMonth } from './components/CalendarMonth';
import { CustomDateInput } from './components/CustomDateInput';

export interface DatePickerProps {
    label?: string;
    date?: Date;
    onDateChange?: (date?: Date) => void;
    markAsRequired?: boolean;
    onBlur?: () => void;
    negative?: boolean;
    caption?: string;
    CaptionIcon?: CaptionProps['Icon'];
}

const todayStartOfDay: Date = moment().startOf('day').toDate();

export const DatePicker = (props: DatePickerProps) => {
    const [internalOffsetDate, setInternalOffsetDate] = useState<Date>(new Date());
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const delayedDateReset = setTimeout(() => {
            if (!open) {
                setInternalOffsetDate(props.date ?? todayStartOfDay);
            }
        }, 75);

        return () => {
            clearTimeout(delayedDateReset);
        };
    }, [open, props.date]);

    const onDateChangeAndClosePopover = (date?: Date) => {
        props.onDateChange?.(date);

        if (date) {
            setOpen(false);
        }
    };

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.98 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.98 },
        config: {
            ...config.default,
            tension: 600,
            clamp: true,
        },
    });

    return (
        <DatePickerStateProvider
            config={{
                selectedDates: props.date ? [props.date] : [],
                onDatesChange: (selectedDates) => {
                    onDateChangeAndClosePopover(selectedDates[0] ?? undefined);
                },
                offsetDate: internalOffsetDate,
                onOffsetChange: setInternalOffsetDate,
                dates: {
                    mode: 'single',
                    toggle: true,
                },
                calendar: {
                    startDay: 1,
                    mode: 'fluid',
                },
                locale: {
                    day: 'numeric',
                },
            }}
        >
            <Popover.Root
                open={open}
                onOpenChange={(newOpenState) => {
                    if (open && !newOpenState) {
                        setTimeout(() => {
                            props.onBlur?.();
                        });
                    }

                    setOpen(newOpenState);
                }}
            >
                <Popover.Trigger asChild>
                    <div>
                        <CustomDateInput
                            $isFocused={open}
                            $negative={props.negative}
                            label={props.label}
                            markAsRequired={props.markAsRequired}
                            caption={props.caption}
                            CaptionIcon={props.CaptionIcon}
                        />
                    </div>
                </Popover.Trigger>
                <Popover.Portal forceMount>
                    {transitions((styles, item) =>
                        item ? (
                            <Popover.Content asChild align="start" side="bottom" sideOffset={12} forceMount>
                                <CalendarContainer style={styles}>
                                    <CalendarMonth />
                                    <CalendarActions
                                        onTodayClick={() => {
                                            onDateChangeAndClosePopover(todayStartOfDay);
                                        }}
                                    />
                                </CalendarContainer>
                            </Popover.Content>
                        ) : null
                    )}
                </Popover.Portal>
            </Popover.Root>
        </DatePickerStateProvider>
    );
};
