import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createSupplier } from '../../../../common/api/clients/company.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { SessionService } from '../../../../user/session/SessionService';

export const useSupplierCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createSupplier,
        onSuccess: async () => {
            await SessionService.getInstance().renewSession();
            return queryClient.invalidateQueries({ queryKey: queries.suppliers._def });
        },
    });
};
