import PropTypes from 'prop-types';

import { Button } from '../../../cdl/Button/Button';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { LogoHeader } from '../../../common/headers/LogoHeader';
import { translate } from '../../../common/helpers/translate.helper';
import { IconInfoCircle } from '../../../common/icons/cdl/InfoCircle';
import { IconX } from '../../../common/icons/cdl/X';
import { StatusBar } from '../../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../../common/StatusBar/StatusBarType';
import { Box } from '../../../common/ui/Box';

import { useCompareSelectionState } from './hooks/useCompareSelectionState';
import { OverlayPriceInfoSection } from './OverlayPriceInfoSection';

export const CompareOverlay = ({ items, portIds, customerId, schedules, onClose, onApply }) => {
    const { selection } = useCompareSelectionState();

    return (
        <Box paddingBottom={11} minHeight="100%" backgroundColor="#F6F7F7">
            <LogoHeader
                title={translate('compare.title')}
                rightElement={<IconButton Icon={IconX} onClick={onClose} />}
            />

            <Box paddingX={6}>
                <Box paddingTop={6}>
                    <StatusBar
                        headline={translate('compare.overlay.bar.headline')}
                        subHeadline={translate('compare.overlay.bar.description')}
                        type={StatusBarType.WHITE}
                        icon={<IconInfoCircle />}
                    />
                </Box>

                <OverlayPriceInfoSection
                    items={items}
                    portIds={portIds}
                    customerId={customerId}
                    schedules={schedules}
                />

                <Box display="flex" justifyContent="center" marginTop={11}>
                    <Box marginRight={4}>
                        <Button onClick={onClose} type="button">
                            {translate('actions.cancel')}
                        </Button>
                    </Box>

                    <Button
                        emphasis="high"
                        type="button"
                        onClick={onApply}
                        disabled={!selection.portId || !selection.supplierIds.length}
                    >
                        {translate('actions.applyselection')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

CompareOverlay.propTypes = {
    items: PropTypes.array.isRequired,
    portIds: PropTypes.array.isRequired,
    customerId: PropTypes.string.isRequired,
    schedules: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
};
