import useComponentSize from '@rehooks/component-size';
import { ReactNode, useRef } from 'react';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { IconX } from '../icons/cdl/X';
import { DiamondLogo } from '../icons/DiamondLogo/DiamondLogo';
import { Box } from '../ui/Box';

interface LogoHeaderProps {
    title?: ReactNode;
    children: ReactNode;
    trailingVisual?: ReactNode;
    onClose?: () => void;
}

export const OverlayHeader = ({ title, trailingVisual, onClose, children }: LogoHeaderProps) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(headerRef);

    return (
        <>
            <Box
                ref={headerRef}
                position="fixed"
                display="flex"
                justifyContent="space-between"
                backgroundColor="background.default"
                alignItems="center"
                top={0}
                left={0}
                right={0}
                zIndex={1}
                boxShadow="medium"
                paddingX={7}
                paddingY={5}
                minHeight="64px"
            >
                <Box display="flex" alignItems="center" gap={5}>
                    <Box borderRight={1} paddingRight={onClose ? 4 : 5}>
                        {onClose ? (
                            <IconButton
                                type="button"
                                onClick={onClose}
                                Icon={IconX}
                                tooltipLabel={translate('global.close')}
                            />
                        ) : (
                            <DiamondLogo />
                        )}
                    </Box>

                    {title ? typeof title === 'string' ? <Text variant="title">{title}</Text> : title : null}
                </Box>

                {trailingVisual ? <div>{trailingVisual}</div> : null}
            </Box>
            <Box paddingTop={`${height}px`}>{children}</Box>
        </>
    );
};
