import styled, { css } from 'styled-components';

import { Text } from '../../Text/Text';

const noWrapStyles = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface DefaultCellTextProps {
    noWrap: boolean;
}

export const DefaultCellText = styled(Text).attrs({
    variant: 'small',
})<DefaultCellTextProps>`
    display: block;
    ${(props) => (props.noWrap ? noWrapStyles : null)}
`;
