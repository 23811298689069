import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';
import { DownloadLubesReportButton } from '../../group/customer/detail/components/DownloadLubesReportButton';

import { CreateCustomerGroup } from './components/CreateCustomerGroup';
import { CustomerGroupSearch } from './components/CustomerGroupSearch';
import { CustomerGroupsTable } from './components/CustomerGroupsTable';

export const CustomerGroupsOverviewPage = () => {
    useDocumentTitle(translate('page.customergroupoverview'));
    const { push, pop } = useDrawer();

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                    <Text variant="headline" as="h1">
                        {translate('customergroups.headline')}
                    </Text>
                    <ButtonGroup>
                        <DownloadLubesReportButton />
                        <Button
                            emphasis="high"
                            onClick={() => push({ content: <CreateCustomerGroup onSuccess={pop} /> })}
                        >
                            {translate('customergroups.create')}
                        </Button>
                    </ButtonGroup>
                </Box>

                <CustomerGroupSearch />

                <CustomerGroupsTable />
            </PageGrid>
        </Page>
    );
};
