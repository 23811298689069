import PropTypes from 'prop-types';

import { translate } from '../../../common/helpers/translate.helper';
import { SortOption, SortSelect } from '../../../common/SortSelect/SortSelect';

interface Props {
    onChange: (value: SortOption['value']) => void;
    value: SortOption['value'];
}

export const LubesOfferOverviewSortSelect = ({ onChange, value }: Props) => {
    const orderSortOptions = [
        {
            label: translate('sortOptions.latest'),
            value: {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.deliveryDate'),
            value: {
                sortField: 'dateDelivery',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.dateCreated'),
            value: {
                sortField: 'dateCreated',
                sortDirection: 'DESC',
            },
        },
    ];

    const defaultValue = orderSortOptions.at(0)!;

    const selectedOption = orderSortOptions.find(
        (option) => option.value.sortField === value.sortField && option.value.sortDirection === value.sortDirection
    );

    return (
        <SortSelect
            value={selectedOption || defaultValue}
            options={orderSortOptions}
            onChange={(option) => {
                onChange(option!.value);
            }}
        />
    );
};

LubesOfferOverviewSortSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};
