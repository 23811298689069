import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { translate } from '../../common/helpers/translate.helper';
import { useNavigateToOriginLocation } from '../../common/hooks/useNavigateToOriginLocation';

import { FuelEnquiryHeader } from './FuelEnquiryHeader';

export const FormikFuelEnquiryHeader = ({ title }) => {
    const formik = useFormikContext();
    const navigateToOriginLocation = useNavigateToOriginLocation();

    const onClick = () => {
        if (!formik.dirty) {
            navigateToOriginLocation();
            return;
        }

        const confirmed = confirm(translate('confirmation.warning'));
        if (confirmed) {
            navigateToOriginLocation();
        }
    };

    return <FuelEnquiryHeader title={title} onCancelClick={onClick} />;
};

FormikFuelEnquiryHeader.propTypes = {
    title: PropTypes.string.isRequired,
};
