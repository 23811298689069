import { ApprovalRequestMessage, ApprovedApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { OfferModel } from '../model/OfferModel';

import { ApprovalRequestBasicContent } from './ApprovalRequestBasicContent';
import { ApprovedStatusBar } from './ApprovedStatusBar';

interface ApprovedStateProps {
    offer: OfferModel;
    approvalRequests: ApprovalRequestMessage[];
    approvedApprovalRequest: ApprovedApprovalRequestMessage;
}

export const ApprovedState = ({ offer, approvalRequests, approvedApprovalRequest }: ApprovedStateProps) => {
    return (
        <ApprovalRequestBasicContent approvalRequests={approvalRequests} offer={offer}>
            <ApprovedStatusBar approvalRequest={approvedApprovalRequest} />
        </ApprovalRequestBasicContent>
    );
};
