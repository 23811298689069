import { components, SingleValueProps } from 'react-select';
import styled from 'styled-components';

import { IconDiamond } from '../../icons/Diamond';
import { Box } from '../../ui/Box';
import { get } from '../../ui/get';

const CustomStyledValue = styled(components.SingleValue)`
    && {
        font-size: 18px;
        line-height: 24px;
        color: ${get('colors.foreground.default')};
        font-weight: ${get('fontWeights.semiBold')};
        margin-left: 0;
    }
`;

export const ContextSwitchSingleValue = (props: SingleValueProps) => {
    return (
        <CustomStyledValue {...props}>
            <Box display="grid" gridTemplateColumns="24px auto" alignItems="center" gap={4} paddingLeft={2}>
                <IconDiamond width={24} />
                {props.children}
            </Box>
        </CustomStyledValue>
    );
};
