import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { FuelCreateEnquiryFlow } from '../order/fuel/FuelCreateEnquiry';

const fuelCreateSearchSchema = z.object({
    orderId: fallback(z.string(), '').default(''),
});

export const Route = createFileRoute('/_app/fuel/create')({
    component: FuelCreateEnquiryFlow,
    validateSearch: zodSearchValidator(fuelCreateSearchSchema),
});
