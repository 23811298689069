import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { LubesOfferOverviewPage } from '../offer/OfferOverview/lubes/LubesOfferOverviewPage';

const offersSearchSchema = z.object({
    searchQuery: fallback(z.string(), '').default(''),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    page: fallback(z.number(), 0).default(0),
    tab: fallback(z.string(), 'enquired').default('enquired'),
    sortValue: fallback(
        z.object({
            sortField: z.string(),
            sortDirection: z.string(),
        }),
        {
            sortField: 'dateUpdated',
            sortDirection: 'DESC',
        }
    ).default({
        sortField: 'dateUpdated',
        sortDirection: 'DESC',
    }),
});

export const Route = createFileRoute('/_app/_lubes/offers')({
    component: LubesOfferOverviewPage,
    validateSearch: zodSearchValidator(offersSearchSchema),
});
