import { css } from 'styled-components';

import { get } from '../../common/ui/get';

import { StyledLinkProps, TanstackLinkProps } from './Link';

export const linkStyles = css<StyledLinkProps | TanstackLinkProps>`
    font-size: ${(props) => props.fontSize};
    color: ${get('colors.accent.foreground')};
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:focus,
    &:visited,
    &:active {
        color: ${get('colors.accent.foreground')};
        text-decoration: none;
    }
`;
