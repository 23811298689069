import { linkOptions } from '@tanstack/react-router';

import { SupplierGroupMessage } from '../../../../types/SupplierGroupMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Route } from '../../../routes/_app.supplier-groups';
import { useSupplierGroupsPagination } from '../hooks/useSupplierGroupsPagination';

export const SupplierGroupsTable = () => {
    const { getHumanReadableValue } = useEnums();

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const supplierGroupsQuery = useSupplierGroupsPagination(search.searchQuery, search.page);

    if (supplierGroupsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (supplierGroupsQuery.isError) {
        return <CenteredPageError />;
    }

    if (supplierGroupsQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder
                data={supplierGroupsQuery.data?.content}
                rowLink={(row) => linkOptions({ to: '/supplier-group/$id', params: { id: row.id } })}
                grow={false}
            >
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.name')} width="40%" truncate>
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.type')} width="15%" truncate>
                    {(row) => getHumanReadableValue('supplierGroupType', row.type)}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('global.date.created')} numeric width="20%">
                    {(row) => formatDateTime({ date: row.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('company.spotallowed')}>
                    {(row) => (row.spotAllowed ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.testcompany')}>
                    {(row) => (row.testCompany ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={search.page}
                pageTotal={supplierGroupsQuery.pageTotal}
                onPageChange={(page) => {
                    navigate({
                        search: (previousSearch) => ({ ...previousSearch, page }),
                    });
                }}
            />
        </div>
    );
};
