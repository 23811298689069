import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { CustomerGroupsOverviewPage } from '../admin/customergroup/CustomerGroupsOverviewPage';

const customerGroupsSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
});

export const Route = createFileRoute('/_app/customer-groups')({
    component: CustomerGroupsOverviewPage,
    validateSearch: zodSearchValidator(customerGroupsSearchSchema),
});
