import { Text } from '../cdl/Text/Text';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { useRole } from '../common/hooks/useRole';
import { Page } from '../common/Page/Page';
import { Flex } from '../common/ui/Flex';

import { AdminSearchResultOfferTable } from './components/AdminSearchResultOfferTable';
import { AdminSearchResultOrderTable } from './components/AdminSearchResultOrderTable';
import { CustomerSearchResult } from './components/CustomerSearchResult';
import { SupplierSearchResult } from './components/SupplierSearchResult';

export const SearchResultPage = () => {
    const role = useRole();

    useDocumentTitle(translate('searchResult.title'));

    if (role.isCustomer()) {
        return (
            <Page>
                <CustomerSearchResult />
            </Page>
        );
    }

    if (role.isSupplier()) {
        return (
            <Page>
                <SupplierSearchResult />
            </Page>
        );
    }

    if (role.isAdmin()) {
        return (
            <Page>
                <Flex flexDirection="column" rowGap={8} marginBottom={6}>
                    <Text as="h1" variant="headline" marginBottom={6}>
                        {translate('searchResult.title')}
                    </Text>
                    <AdminSearchResultOrderTable />
                    <AdminSearchResultOfferTable />
                </Flex>
            </Page>
        );
    }
};
