import { VesselCreateRequestMessage } from '../../../types/VesselCreateRequestMessage';
import { VesselMessage } from '../../../types/VesselMessage';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { useToasts } from '../../cdl/Toast/useToasts';
import { CreateVesselForm } from '../../vessel/CreateVessel/CreateVesselForm';
import { translate } from '../helpers/translate.helper';

import { useVesselCreate } from './useVesselCreate';

interface VesselCreateModalProps {
    onSuccess?: (vessel: VesselMessage) => void;
    onCancel?: () => void;
}

export const useVesselCreateModal = ({ onCancel, onSuccess }: VesselCreateModalProps = {}) => {
    const { push, pop, hasLayers } = useDrawer();
    const { addToast, addErrorToast } = useToasts();
    const vesselCreateMutation = useVesselCreate();

    const handleSubmit = (vessel: VesselCreateRequestMessage) => {
        vesselCreateMutation.mutate(
            { vessel },
            {
                onSuccess: (createdVessel) => {
                    onSuccess?.(createdVessel);
                    pop();
                    addToast(translate('createVesselForm.toast.success'));
                },
                onError: () => {
                    addErrorToast(translate('createVesselForm.toast.error'));
                },
            }
        );
    };

    return {
        isOpen: hasLayers,
        open: () => {
            push({
                onPop: () => {
                    onCancel?.();
                },
                content: <CreateVesselForm onSubmit={handleSubmit} />,
            });
        },
    };
};
