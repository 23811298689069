import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCircleDashedX = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11 2.998A1 1 0 0 1 12.002 2a10 10 0 0 1 3.822.767 1 1 0 0 1-.768 1.846A8 8 0 0 0 11.998 4 1 1 0 0 1 11 2.998Zm-1.516.31a1 1 0 0 1-.541 1.306 8 8 0 0 0-2.596 1.733 1 1 0 0 1-1.414-1.414 10 10 0 0 1 3.245-2.167 1 1 0 0 1 1.306.541Zm8.169 1.625a1 1 0 0 1 1.414 0 10 10 0 0 1 2.167 3.245 1 1 0 1 1-1.848.764 8.002 8.002 0 0 0-1.733-2.595 1 1 0 0 1 0-1.414ZM4.074 7.637a1 1 0 0 1 .54 1.307A8 8 0 0 0 4 12.002a1 1 0 0 1-2-.004 10 10 0 0 1 .767-3.822 1 1 0 0 1 1.307-.54Zm5.219 1.656a1 1 0 0 1 1.414 0L12 10.586l1.293-1.293a1 1 0 1 1 1.414 1.414L13.414 12l1.293 1.293a1 1 0 0 1-1.414 1.414L12 13.414l-1.293 1.293a1 1 0 0 1-1.414-1.414L10.586 12l-1.293-1.293a1 1 0 0 1 0-1.414ZM21.002 11A1 1 0 0 1 22 12.002a10.002 10.002 0 0 1-.767 3.822 1 1 0 0 1-1.846-.768A8 8 0 0 0 20 11.998 1 1 0 0 1 21.002 11ZM3.307 14.516a1 1 0 0 1 1.307.541 8 8 0 0 0 1.733 2.596 1 1 0 0 1-1.414 1.414 10 10 0 0 1-2.167-3.245 1 1 0 0 1 .541-1.306Zm15.76 3.137a1 1 0 0 1 0 1.414 10.003 10.003 0 0 1-3.245 2.167 1 1 0 0 1-.764-1.848 8 8 0 0 0 2.595-1.733 1 1 0 0 1 1.414 0Zm-11.43 2.273a1 1 0 0 1 1.307-.54 8 8 0 0 0 3.058.614 1 1 0 1 1-.004 2 10.002 10.002 0 0 1-3.822-.767 1 1 0 0 1-.54-1.307Z"
            clipRule="evenodd"
        />
    </svg>
);
