import { Text } from '../../../cdl/Text/Text';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { Error } from '../../../common/Error/Error';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { usePortDetails } from '../../../order/fuel/hooks/usePortDetails';
import { Route } from '../../../routes/_app.port.detail.$id';
import { formatPortAliases } from '../formatPortAliases';

import { EditPortButton } from './EditPortButton';

export const PortDetailPage = () => {
    const { id } = Route.useParams();
    const portDetailQuery = usePortDetails(id);

    const title = portDetailQuery.data
        ? translate('page.portshow', { name: portDetailQuery.data.name })
        : translate('port.subtitle');
    useDocumentTitle(title);

    if (portDetailQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (portDetailQuery.isError || !portDetailQuery.data) {
        return (
            <Page>
                <Section>
                    <Error />
                </Section>
            </Page>
        );
    }

    return (
        <Page>
            <PageGrid>
                <HeaderWithLine>
                    <Text variant="headline" as="h1">
                        {portDetailQuery.data.name}
                    </Text>

                    <EditPortButton port={portDetailQuery.data} />
                </HeaderWithLine>

                <Box width={1 / 2}>
                    <DataSheet>
                        <DataSheet.Row label={translate('port.name')} value={portDetailQuery.data.name} />

                        <DataSheet.Row label={translate('port.locode')} value={portDetailQuery.data.locCode} />

                        <DataSheet.Row
                            label={translate('port.latitude')}
                            value={portDetailQuery.data.latitude ? String(portDetailQuery.data.latitude) : undefined}
                        />

                        <DataSheet.Row
                            label={translate('port.longitude')}
                            value={portDetailQuery.data.longitude ? String(portDetailQuery.data.longitude) : undefined}
                        />

                        <DataSheet.Row label={translate('port.country')} value={portDetailQuery.data.country.name} />

                        <DataSheet.Row label={translate('port.timeZone')} value={portDetailQuery.data.timeZoneId} />

                        <DataSheet.Row
                            label={translate('port.aliases')}
                            value={formatPortAliases(portDetailQuery.data.aliases)}
                        />

                        <DataSheet.Row
                            label={translate('port.locCodeAliases')}
                            value={formatPortAliases(portDetailQuery.data.locCodeAliases)}
                        />
                    </DataSheet>
                </Box>
            </PageGrid>
        </Page>
    );
};
