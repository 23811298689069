import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconHistory = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.265 5.885A8 8 0 0 0 4.045 11.1a1 1 0 1 1-1.989-.202 10 10 0 1 1 1.995 7.074V20a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1h.477a.9.9 0 0 1 .046 0h4.477a1 1 0 1 1 0 2H5.128A8.001 8.001 0 1 0 17.265 5.885ZM12 7a1 1 0 0 1 1 1v3.586l1.707 1.707a1 1 0 0 1-1.414 1.414l-2-2A1 1 0 0 1 11 12V8a1 1 0 0 1 1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
