import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { PlanningPage } from '../demand/pages/PlanningPage';

const planningSearchSchema = z.object({
    groupId: z.string().optional(),
});

export const Route = createFileRoute('/_app/fuel/planning/demand')({
    component: PlanningPage,
    validateSearch: zodSearchValidator(planningSearchSchema),
});
