import { Form, FormikState, useFormikContext } from 'formik';

import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { Text } from '../../../cdl/Text/Text';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { Label } from '../../../common/form-elements/Label/Label';
import { translate } from '../../../common/helpers/translate.helper';
import { FormikTagListInput } from '../../../common/TagListInput/FormikTagListInput';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { FormikPortState } from '../FormikPortState';

import { FormikCountrySelect } from './FormikCountrySelect';
import { FormikTimeZoneSelect } from './FormikTimeZoneSelect';

type FormikPortFormProps = FormikState<FormikPortState> & {
    error: string | null;
};

export const FormikPortForm = (props: FormikPortFormProps) => {
    const formikState = useFormikContext<FormikPortState>();

    return (
        <Box height="100%">
            <Form>
                <Flex height="100vh" flexDirection="column" justifyContent="space-between">
                    <Box paddingX={7} display="grid" gridRowGap={5} marginBottom={7} marginTop={7}>
                        <Text as="h1" margin="0" variant="title">
                            {formikState.values.id ? translate('port.editTitle') : translate('port.createTitle')}
                        </Text>

                        <FormikInput label={translate('port.name')} name="name" />
                        <FormikInput label={translate('port.locode')} name="locCode" />
                        <FormikInput label={translate('port.latitudeOptionalLabel')} name="latitude" />
                        <FormikInput label={translate('port.longitudeOptionalLabel')} name="longitude" />
                        <FormikTimeZoneSelect label={translate('port.timeZone')} name="timeZoneId" />
                        <Label label={translate('port.country')}>
                            <FormikCountrySelect name="countryId" />
                        </Label>

                        <FormikTagListInput name="aliases" label={translate('port.aliasesOptionalLabel')} />

                        <FormikTagListInput
                            name="locCodeAliases"
                            label={translate('port.locCodeAliasesOptionalLabel')}
                        />

                        {props.error ? <Callout variant="negative" description={props.error} /> : null}

                        <FormikDebug />
                    </Box>

                    <Box padding={7}>
                        <Button
                            emphasis="high"
                            type="submit"
                            size="large"
                            disabled={!formikState.dirty || !formikState.isValid}
                            isLoading={formikState.isSubmitting}
                            width="100%"
                        >
                            {translate('btn.save.default')}
                        </Button>
                    </Box>
                </Flex>
            </Form>
        </Box>
    );
};
