import { useQuery } from '@tanstack/react-query';
import { linkOptions, useNavigate } from '@tanstack/react-router';

import { VesselMessage } from '../../../../types/VesselMessage';
import { Button } from '../../../cdl/Button/Button';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Text } from '../../../cdl/Text/Text';
import { queries } from '../../../common/api/queryKeys/queries';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../common/ui/Box';
import { OrderModel } from '../../../order/model/OrderModel';
import { Port } from '../../../port/Port';

interface VesselOrderTableProps {
    vessel: VesselMessage;
}

export const VesselOrderTable = ({ vessel }: VesselOrderTableProps) => {
    const role = useRole();
    const { context, isFuel } = useProductContext();

    const vesselOrdersQuery = useQuery(
        queries.vessels.detail(vessel.id)._ctx.orders({ vesselId: vessel.id, page: 0, types: [context] })
    );

    const navigate = useNavigate();

    const buildRowLink = (order: OrderModel) => {
        if (order.isClosed() && order.chosenOfferId) {
            return linkOptions({
                to: '/offer/$id',
                params: {
                    id: order.chosenOfferId,
                },
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            return linkOptions({
                to: '/orders/draft/$id',
                params: {
                    id: order.id,
                },
            });
        } else {
            return linkOptions({
                to: '/order/$id',
                params: {
                    id: order.id,
                },
            });
        }
    };

    if (vesselOrdersQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (vesselOrdersQuery.isError) {
        return <Error />;
    }

    if (!vesselOrdersQuery.data.content.length) {
        return <TableEmptyState text={translate('order.emptylist')} />;
    }

    const navigateToOrdersPage = () => {
        const path = isFuel ? '/fuel/orders' : '/orders';

        navigate({ to: path, search: { searchQuery: vessel.imo } });
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" marginBottom={5}>
                <Text variant="title" as="h2">
                    {translate('order.headline')}
                </Text>
                <Button onClick={navigateToOrdersPage}>{translate('order.showAll')}</Button>
            </Box>

            <TableBuilder data={vesselOrdersQuery.data.content} rowLink={(order) => buildRowLink(order)} grow={false}>
                <TableBuilderColumn header={translate('order.buyerreferenceshort')} width="30%" truncate>
                    {(order: OrderModel) => order.buyerReference || '-'}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('order.port')} width="40%">
                    {(order: OrderModel) =>
                        order.port ? <Port port={order.port} vesselId={order.vesselId} showTooltip /> : null
                    }
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('order.dateDeliveryShort')} width="20%">
                    {(order: OrderModel) =>
                        order.dateDelivery ? formatDate({ date: order.dateDelivery, timeZone: 'UTC' }) : null
                    }
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('order.status')} width="10%">
                    {(order: OrderModel) =>
                        order.offerState ? <OfferStatusTag state={order.offerState} variant="customer" /> : null
                    }
                </TableBuilderColumn>
            </TableBuilder>
        </div>
    );
};
