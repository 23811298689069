import { useEffect } from 'react';

import { SessionStorageService } from '../../user/session/SessionStorageService';

export const useOnboardingSessionReset = () => {
    // Make sure that there is no old token.
    useEffect(() => {
        SessionStorageService.resetToken();
    }, []);
};
