import { translate } from '../../helpers/translate.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const GeneralAdminSidebarItems = () => {
    const { isFuel } = useProductContext();
    return (
        <>
            <SidebarNavigationItem to={isFuel ? '/fuel/vessels' : '/vessels'}>
                {translate('navigation.vessels')}
            </SidebarNavigationItem>

            <SidebarNavigationItem to="/customer-groups">{translate('navigation.customers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/supplier-groups">{translate('navigation.suppliers')}</SidebarNavigationItem>

            <SidebarNavigationItem to={isFuel ? '/fuel/agents' : '/agents'}>
                {translate('navigation.agents')}
            </SidebarNavigationItem>

            <SidebarNavigationItem to="/assignments">{translate('navigation.assignments')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/ports">{translate('navigation.ports')}</SidebarNavigationItem>
        </>
    );
};
