import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconStar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.994 1.002a1 1 0 0 1 .897.557l2.853 5.782 6.38.924a1 1 0 0 1 .553 1.707l-4.623 4.5 1.09 6.354a1 1 0 0 1-1.451 1.054l-5.693-3-5.706 3a1 1 0 0 1-1.45-1.054l1.09-6.354-4.624-4.5a1 1 0 0 1 .554-1.707l6.38-.924 2.853-5.782a1 1 0 0 1 .897-.557Zm0 3.26-2.19 4.436a1 1 0 0 1-.753.547l-4.892.709 3.546 3.451a1 1 0 0 1 .288.886l-.836 4.876 4.379-2.302a1 1 0 0 1 .931 0l4.363 2.3-.836-4.874a1 1 0 0 1 .288-.886l3.546-3.451-4.892-.71a1 1 0 0 1-.753-.546l-2.19-4.436Z"
            clipRule="evenodd"
        />
    </svg>
);
