import { useField } from 'formik';
import React from 'react';

import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { FormikSwitch } from '../../../../cdl/Switch/FormikSwitch';
import { Text } from '../../../../cdl/Text/Text';
import { formatMoney } from '../../../helpers/formatMoney.helper';
import { IconX } from '../../../icons/cdl/X';
import { FormikMoneyInput } from '../../../MoneyInput/FormikMoneyInput';
import { Td } from '../../../Table/Td';
import { Tr } from '../../../Table/Tr';
import { Box } from '../../../ui/Box';
import { Flex } from '../../../ui/Flex';
import { useLubesProductTableConfig } from '../hooks/useLubesProductTableConfig';
import { getSurchargeOrWaiverString } from '../utils/getSurchargeOrWaiverString';

import { SurchargeName } from './SurchargeName';

interface SurchargeRowProps {
    name: string;
    onRowRemoveClicked: () => void;
}

export const SurchargeRow = (props: SurchargeRowProps) => {
    const [field] = useField(props.name);
    const surcharge = field.value;

    const { showPrices, editable, editSurcharges } = useLubesProductTableConfig();

    const emptyColumns = showPrices ? 7 : 8;

    const canEditPrices = editable && editSurcharges;

    return (
        <Tr inactive={surcharge.deleted} focused={surcharge.added && !surcharge.deleted}>
            <Td>
                <SurchargeName name={props.name} editable={editable && editSurcharges} />
            </Td>
            <Td colSpan={emptyColumns}>
                <Text variant="body">{getSurchargeOrWaiverString(surcharge.value.value)}</Text>
            </Td>

            {showPrices ? (
                <Td>
                    <Box display="grid">
                        {!canEditPrices ? (
                            <Text variant="body" textAlign="right">
                                {formatMoney({
                                    value: surcharge.value.value,
                                    currency: surcharge.value.currency,
                                    maximumFractionDigits: 2,
                                })}
                            </Text>
                        ) : (
                            <FormikMoneyInput
                                name={`${props.name}.value.value`}
                                decimals={2}
                                allowNegativeValue
                                disabled={surcharge.deleted}
                                required
                            />
                        )}
                    </Box>
                </Td>
            ) : null}

            {editable ? (
                <Td>
                    {editSurcharges ? (
                        <Flex justifyContent="center">
                            {surcharge.new ? (
                                <IconButton type="button" onClick={props.onRowRemoveClicked} Icon={IconX} />
                            ) : (
                                <FormikSwitch
                                    disabled={!surcharge.value?.value || Number(surcharge.value?.value) === 0}
                                    name={`${props.name}.deleted`}
                                    invert
                                />
                            )}
                        </Flex>
                    ) : null}
                </Td>
            ) : null}
        </Tr>
    );
};
