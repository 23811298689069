import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';

const activeStyles = css`
    box-shadow: inset 0 0 0 2px ${get('colors.accent.emphasis')};
    background-color: ${get('colors.accent.background')} !important;
`;

interface StyledDefaultTableBodyRowProps {
    active: boolean;
    selectable: boolean;
    alternateRowColors: boolean;
    disabled?: boolean;
    grow?: boolean;
}

const heightStyles = (props: StyledDefaultTableBodyRowProps) => {
    if (props.grow)
        return css`
            /* Padding for the first child element */
            > td:has(*) > *:first-child {
                padding-top: 8px;
                padding-bottom: 8px;
            }

            /* Padding for text-only cells */
            td:not(:has(*)) {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        `;

    return css`
        height: 40px;
    `;
};

export const StyledDefaultTableBodyRow = styled.tr<StyledDefaultTableBodyRowProps>`
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : 'inherit')};

    &:nth-child(odd) {
        background-color: ${(props) => (props.alternateRowColors ? get('colors.background.subtle')(props) : 'none')};
    }

    &:hover {
        cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
    }

    ${(props) => (props.active ? activeStyles : null)}
    ${heightStyles};
`;
