import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';

import { Button } from '../../cdl/Button/Button';
import { IconCircle } from '../../common/icons/cdl/Circle';
import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';

export const FuelEnquiryAssignedSupplier = ({ assignments }) => {
    const { values, setValues } = useFormikContext();

    const isSelected = (supplierId) => {
        return values.receiverSupplierIds?.some((receiverSupplierId) => {
            return receiverSupplierId === supplierId;
        });
    };

    const onSupplierClick = (selectedSupplierId) => {
        const receiverSupplierIdsShape = values.receiverSupplierIds ? Array.from(values.receiverSupplierIds) : [];

        const hasSupplier = receiverSupplierIdsShape.some((receiverSupplierId) => {
            return receiverSupplierId === selectedSupplierId;
        });

        if (!hasSupplier) {
            receiverSupplierIdsShape.push(selectedSupplierId);
            setValues({
                ...values,
                receiverSupplierIds: receiverSupplierIdsShape,
            });
            return;
        }

        const index = receiverSupplierIdsShape.findIndex((supplierId) => supplierId === selectedSupplierId);

        const isLastSupplier = receiverSupplierIdsShape.length === 1;

        if (isLastSupplier) {
            setValues({
                ...values,
                receiverSupplierIds: [],
                spot: true,
            });
        } else {
            receiverSupplierIdsShape.splice(index, 1);
            setValues({
                ...values,
                receiverSupplierIds: receiverSupplierIdsShape,
            });
        }
    };

    return assignments.map((assignment) => {
        const supplierId = assignment.supplierId;
        return (
            <Button
                key={supplierId}
                onClick={() => onSupplierClick(supplierId)}
                leadingVisual={
                    isSelected(supplierId) ? (
                        <IconCircleCheck width={16} height={16} />
                    ) : (
                        <IconCircle width={16} height={16} />
                    )
                }
                emphasis={isSelected(supplierId) ? 'high' : 'medium'}
            >
                {assignment.supplier.name}
            </Button>
        );
    });
};

FuelEnquiryAssignedSupplier.propTypes = {
    assignments: PropTypes.array.isRequired,
};
