import React from 'react';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { TextWithExternalLink } from './TextWithExternalLink';

interface UserModalContentProps {
    onLogOut: () => void;
}

const buildMailTo = () => {
    const parts: { [key: string]: string } = {
        subject: translate('termsAndConditions.mailTo.subject'),
        body: translate('termsAndConditions.mailTo.body'),
    };

    return Object.keys(parts).reduce((acc, key) => {
        return acc + `${key}=${encodeURIComponent(parts[key])}&`;
    }, 'mailto:?');
};

export const UserModalContent = (props: UserModalContentProps) => {
    const onClick = () => {
        window.location.href = buildMailTo();
    };

    return (
        <Box display="flex" flexDirection="column" rowGap={3} padding={6}>
            <Text variant="title" as="h3">
                {translate('termsAndConditions.user.headline')}
            </Text>
            <TextWithExternalLink
                dangerouslySetInnerHTML={{
                    __html: translate('termsAndConditions.user.description'),
                }}
            />

            <TextWithExternalLink
                dangerouslySetInnerHTML={{ __html: translate('termsAndConditions.user.legalCaption') }}
            />

            <Box display="flex" columnGap={4} justifyContent="end">
                <Button onClick={props.onLogOut}>{translate('termsAndConditions.logOutButton')}</Button>
                <Button emphasis="high" onClick={onClick}>
                    {translate('termsAndConditions.user.acceptButton')}
                </Button>
            </Box>
        </Box>
    );
};
