import { ApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { StatusBar } from '../../cdl/StatusBar/StatusBar';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconClock } from '../../common/icons/cdl/Clock';

interface PendingStatusBarProps {
    approvalRequest: ApprovalRequestMessage;
}

export const PendingStatusBar = ({ approvalRequest }: PendingStatusBarProps) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.requestMessage) {
            return translate('approvalRequest.statusBar.pendingNoComment.subHeadline', {
                date: formatDateTime({
                    date: approvalRequest.dateCreated,
                }),
                author: `${approvalRequest.createdByUser.firstname} ${approvalRequest.createdByUser.lastname}`,
            });
        }

        return translate('approvalRequest.statusBar.pending.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateCreated }),
            author: `${approvalRequest.createdByUser.firstname} ${approvalRequest.createdByUser.lastname}`,
            requestMessage: approvalRequest.requestMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.pending.headline');
    const subHeadline = makeSubHeadline();

    return <StatusBar variant="neutral" headline={headline} subHeadline={subHeadline} leadingVisual={<IconClock />} />;
};
