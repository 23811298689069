import { Text } from '../../../../../cdl/Text/Text';
import { SupplierInfoPopup } from '../../../../../common/CoreDataPopup/SupplierInfoPopup';
import { formatCompanyName } from '../../../../../common/helpers/formatCompanyName.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { useRole } from '../../../../../common/hooks/useRole';
import { OfferModel } from '../../../../model/OfferModel';

import { InvolvedParty } from './InvolvedParty';

interface SupplierPartyProps {
    offer: OfferModel;
}

export const SupplierParty = ({ offer }: SupplierPartyProps) => {
    const role = useRole();
    return (
        <InvolvedParty
            label={translate('offer.detail.coreData.supplier')}
            modalContent={<SupplierInfoPopup offer={offer} />}
        >
            <Text>
                {formatCompanyName({
                    company: offer.supplier,
                    includeType: role.isAdmin(),
                })}
            </Text>
            {offer.supplier.email ? (
                <Text
                    color="foreground.subtle"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}
                >
                    {offer.supplier.email}
                </Text>
            ) : null}
            {offer.supplier.phone ? <Text color="foreground.subtle">{offer.supplier.phone}</Text> : null}
        </InvolvedParty>
    );
};
