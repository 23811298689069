import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { createOrder, FuelCreateOrderRequest } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';

export const useEnquiryCreate = () => {
    const createOrderMutation = useMutation({ mutationFn: createOrder });

    const navigate = useNavigate();
    const { addErrorToast } = useToasts();

    return (order: FuelCreateOrderRequest) => {
        return createOrderMutation.mutateAsync(order, {
            onSuccess: (order: any) => {
                setTimeout(() => {
                    navigate({ to: '/fuel/order/$id', params: { id: order.id } });
                });
            },
            onError: () => {
                addErrorToast(translate('fuelEnquiry.toast.createEnquiry.error'));
            },
        });
    };
};
