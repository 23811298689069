import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelSupplierSidebar = () => {
    return (
        <div>
            <SidebarNavigationItem to="/fuel/dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/offers">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/fuel/statistics">{translate('navigation.reporting')}</SidebarNavigationItem>
        </div>
    );
};
